<template>
  <div
    v-if="!waitingOnRender"
    class="checkCellWrapper"
    :style="style"
  >
    <a-checkbox
      :checked="value"
      disabled
    />
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellCheckbox',
  extends: FieldCell,
  data () {
    return {
      waitingOnRender: true,
      style: {
        color: 'white',
      },
    }
  },
  mounted () {
    this.$nextTick()
      .then(() => {
        this.waitingOnRender = false
        if (this.parentCellHasStyling) {
          for (let i = 0; i < this.parentCellStyle.length; i++) {
            let property = this.parentCellStyle.item(i)
            this.style[property] = this.parentCellStyle[property]
          }
        }
      })
  },
}
</script>

<style scoped>
.checkCellWrapper {
  letter-spacing: 2px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  justify-content: center;
}
</style>
