import { BaseRecipeHandler } from '@/recipes/base'
import Vue from 'vue'
import _ from 'lodash'

export class ObjectHandler extends BaseRecipeHandler {
  constructor (object, property) {
    super()
    this.object = object
    this.property = property
  }

  getValue () {
    return _.get(this.object, this.property)
  }

  putValue (value) {
    Vue.set(this.object, this.property, value)
  }

  getChildHandler (field) {
    return null
  }
}
