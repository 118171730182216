import { Node } from '@tiptap/core'
import { mergeAttributes, VueNodeViewRenderer } from '@tiptap/vue-2'
import BrezelLinkComponent from '@/components/fields/wysiwyg/extensions/brezel-link/BrezelLink'

const BrezelLink = Node.create({
  name: 'brezel-link',

  content: 'inline*',

  inline () {
    return true
  },

  group () {
    return 'inline'
  },

  addNodeView () {
    return VueNodeViewRenderer(BrezelLinkComponent)
  },

  addAttributes () {
    return {
      id: {
        default: null,
      },
      module: {
        default: null,
      },
    }
  },

  parseHTML () {
    return [
      {
        tag: 'brezel-link',
      },
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return ['brezel-link', mergeAttributes(HTMLAttributes), 0]
  },

  addCommands () {
    return {
      addBrezelLink: options => ({ commands }) => {
        return commands.insertContent({
          type: 'brezel-link',
        })
      },
    }
  },
})

export default BrezelLink
