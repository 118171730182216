import Field from '@/layout/field'
import Module from '@/module/module'

export default class Layout {
  identifier
  type
  rawSpec = {}
  spec = {
    tabs: [],
    tab_position: 'top',
    tab_mode: 'tabs',
  }
  isRoot = true
  // Field source, if given
  module

  fieldMap = {}
  updateObservers = []

  /**
   *
   * @param layout the layout specification
   * @param {Module} module if given, the module where the fields come from
   * @param isRoot whether this is a root layout (CalculatesRecipes.vue only watches root layouts)
   */
  constructor (layout = null, module = null, isRoot = true) {
    this.module = module
    if (layout) {
      this.setLayoutSpec(layout)
    }
    this.setIsRoot(isRoot)
  }

  setLayoutSpec (layout) {
    this.identifier = layout.identifier
    this.rawSpec = layout.spec
    this.spec = this.buildSpec(layout.spec)
    this.type = layout.type
    this.update()
  }

  * elementsInComponents (components) {
    let globalIndex = 0
    for (const compIndex in components) {
      const comp = components[compIndex]
      yield [comp, globalIndex]
      globalIndex++
      if (comp.layout instanceof Layout) {
        for (const [element] of comp.layout.elements()) {
          yield [element, globalIndex]
          globalIndex++
        }
      }
      // Now it gets more specific... maybe extract this into classes.
      if (comp.options && comp.options.panels) {
        for (const panelIndex in Array.from(comp.options.panels)) {
          const panel = comp.options.panels[panelIndex]
          const panelComponents = panel.components
          for (const [element] of this.elementsInComponents(panelComponents)) {
            yield [element, globalIndex]
            globalIndex++
          }
        }
      }
      if (comp.options && comp.options.buttons) {
        for (const buttonIndex in Array.from(comp.options.buttons)) {
          const button = comp.options.buttons[buttonIndex]
          yield [button, globalIndex]
          globalIndex++
        }
      }
    }
  }

  * elementsInFields (fields) {
    let globalIndex = 0
    for (const field of fields) {
      if (field.type === 'list' && field.options && field.options.template && field instanceof Field) {
        for (const item of field.getItems()) {
          if (item.layout instanceof Layout) {
            for (const [element] of item.layout.elements()) {
              yield [element, globalIndex]
              globalIndex++
            }
          }
        }
      }
    }
  }

  * elements () {
    let globalIndex = 0
    for (const tabIndex in Array.from(this.spec.tabs)) {
      const tab = this.spec.tabs[tabIndex]
      yield [tab, globalIndex]
      globalIndex++
      for (const rowIndex in Array.from(tab.rows)) {
        const row = tab.rows[rowIndex]
        yield [row, globalIndex]
        globalIndex++
        for (const colIndex in Array.from(row.cols)) {
          const col = row.cols[colIndex]
          yield [col, globalIndex]
          globalIndex++
          for (const [element] of this.elementsInComponents(Array.from(col.components))) {
            yield [element, globalIndex]
            globalIndex++
          }
        }
      }
    }
    for (const [element] of this.elementsInFields(Array.from(this.fields()))) {
      yield [element, globalIndex]
      globalIndex++
    }
  }

  * fields () {
    for (const identifier in this.fieldMap) {
      yield this.fieldMap[identifier]
    }
  }

  rebuildSpec () {
    this.spec = this.buildSpec(this.rawSpec)
  }

  buildSpec (spec) {
    const builtSpec = Object.assign({}, spec)
    for (const tabIndex in Array.from(builtSpec.tabs)) {
      const tab = builtSpec.tabs[tabIndex]
      for (const rowIndex in Array.from(tab.rows)) {
        const row = tab.rows[rowIndex]
        for (const colIndex in Array.from(row.cols)) {
          const col = row.cols[colIndex]
          for (const compIndex in Array.from(col.components)) {
            const component = col.components[compIndex]
            this.buildComponent(component)
          }
        }
      }
    }
    return builtSpec
  }

  buildComponent (comp) {
    if (comp.options && comp.options.panels) {
      for (const panelIndex in Array.from(comp.options.panels)) {
        const panel = comp.options.panels[panelIndex]
        for (const compIndex in Array.from(panel.components)) {
          const panelComp = panel.components[compIndex]
          this.buildComponent(panelComp)
        }
      }
    }
    if (comp.options && comp.options.layout) {
      // Component of type layout
      comp.layout = new Layout({
        identifier: comp.options.layout.identifier || comp.identifier,
        spec: comp.options.layout,
      }, this.module, false)
      comp.layout.onUpdate(() => this.update())
      this.fieldMap = { ...this.fieldMap, ...comp.layout.fieldMap }
    }
    if (comp.options && comp.options.fields) {
      for (const fieldIndex in Array.from(comp.options.fields)) {
        let fieldSpec = comp.options.fields[fieldIndex]
        if (typeof fieldSpec === 'string' && this.module instanceof Module) {
          fieldSpec = this.module.getField(fieldSpec)
        }
        const field = new Field(fieldSpec)
        comp.options.fields[fieldIndex] = field
        this.fieldMap[field.identifier] = field
        field.setLayout(this)
      }
    }
  }

  update () {
    for (const observer of this.updateObservers) {
      observer()
    }
  }

  onUpdate (observer) {
    this.updateObservers.push(observer)
  }

  /**
   * @param fieldComponent
   */
  registerField (fieldComponent) {
    const field = fieldComponent.field
    if (field instanceof Field) {
      field.setComponent(fieldComponent)
    }
  }

  setComponent ($component) {
    this.$component = $component
  }

  setIsRoot (isRoot) {
    this.isRoot = isRoot
  }

  setModule (module) {
    this.module = module
  }
}
