<template>
  <div class="with-padding">
    {{ value }}
  </div>
</template>

<script>
import Field from '@/layout/field'
import FieldCell from '@/layout/cell'
import { formatDateTime } from '@/utils'
import dayjs from 'dayjs'
import _ from 'lodash'

export default {
  name: 'FieldCell',
  props: {
    field: Field,
    value: {
      type: undefined,
      default: () => { return '' },
    },
    cell: FieldCell,
    parentCellHasStyling: Boolean,
    parentCellStyleString: {
      type: String,
      default: '',
    },
  },
  computed: {
    translationPrefix () {
      return `modules.${this.field.module.identifier}`
    },
    parentCellStyle () {
      let style = document.createElement('style')
      style.style.cssText = this.parentCellStyleString
      return style.style
    },
    displayAsIcon () {
      return _.get(this.field, 'options.display_as_icon_in_table') === true
    },
    options () {
      return this.field.options || {}
    },
  },
  methods: {
    formatDateTime (dateTime) {
      if (!dateTime) {
        return ''
      }
      if (this.field.options && this.field.options.date_time_format) {
        return dayjs(dateTime).format(this.field.options.date_time_format)
      }
      return formatDateTime(dateTime, 'dateTime')
    },
  },
}
</script>

<style lang="scss">
.with-padding {
  padding: 8px 7px 8px 8px;
}

a i {
  pointer-events: none;
}

a.has-icon.disabled {
  color: rgba(0, 0, 0, 0.65) !important;
  pointer-events: none;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-45deg);
  }

  i {
    cursor: not-allowed;
    pointer-events: all;
  }
}
</style>
