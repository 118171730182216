<template>
  <div
    id="WidgetKanban"
    :style="{ 'padding': config.no_padding === true ? 0 : false }"
    :class="config.style_class"
  >
    <div
      v-if="entries.length"
      class="buttons-bar"
    >
      <a-button
        class="kanban-custom-btn"
        @click="addColVisible = !addColVisible"
      >
        <a-icon type="plus" />{{ $t('kanban.add_column') }}
      </a-button>
      <a-modal
        v-model="addColVisible"
        :title="$t('kanban.add_column')"
        class="column-modal"
        @ok="addColumn"
      >
        <a-form
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-form-item :label="$t('kanban.column_name')">
            <a-input
              id="inpColumnName"
              :placeholder="$t('kanban.column_name')"
              autocomplete="off"
              autofill="off"
            />
          </a-form-item>
          <a-form-item :label="$t('kanban.color')">
            <a-input
              id="inpColumnColor"
              type="color"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <div
      v-if="entries.length === 0"
      class="kanban-no-data"
    >
      <a-empty :description="$t('_.no_data')" />
    </div>
    <div
      v-else
      class="flex justify-center"
    >
      <div class="columns-wrapper">
        <a-row>
          <draggable
            :animation="200"
            ghost-class="ghost-card"
            group="cols"
            :list="columns"
            handle=".handle"
            @change="columnsOrder"
          >
            <a-col
              v-for="column in columns"
              :key="column.id"
              :column="column"
              class="taskColumn"
              :class="{ fallback: column.statusField.fallback }"
            >
              <div
                class="taskCol"
                :style="{ 'border-top': '4px solid ' + column.color }"
              >
                <div class="column-head">
                  <div class="handle">
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 9 13"
                      width="9"
                      height="13"
                    >
                      <path
                        id="Shape 3"
                        class="s0"
                        d="m1.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5zm0 5c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5zm0 5c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5zm6-10c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5zm0 5c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5zm0 5c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z"
                      />
                    </svg>
                  </div>
                  <span class="title">{{ column.title }}</span>
                  <a-button
                    v-if="!column.statusField.fallback"
                    shape="circle"
                    size="small"
                    style="float:right;"
                    @click="openColSettings(column.id)"
                  >
                    <a-icon type="setting"/>
                  </a-button>
                </div>
                <draggable
                  :list="column.tasks"
                  :animation="200"
                  class="column-body"
                  ghost-class="ghost-card"
                  group="tasks"
                  @change="saveChange($event, column, column.tasks)"
                >
                  <task-card
                    v-for="task in column.tasks"
                    :key="task.id"
                    :task="task"
                    :module="module"
                    :withrating="compRating"
                    :withphoto="compPhoto"
                    :openinmodal="compModal"
                    class="mt-3 cursor-move"
                    :xl="5"
                    @change="reloadRating"
                  />
                </draggable>
              </div>
            </a-col>
          </draggable>
        </a-row>
      </div>
      <a-drawer
        :title="$t('kanban.column_settings')"
        placement="right"
        width="300"
        :closable="true"
        :visible="showColSettings"
        @close="showColSettings = !showColSettings"
      >
        <a-form
          :label-col="{ span: 12 }"
          :wrapper-col="{ span: 12 }"
        >
          <input
            id="inpColID"
            type="number"
            style="display:none;"
          >
          <a-form-item :label="$t('kanban.column_name')">
            <a-input
              id="inpColRename"
              ref="inpColRename"
              type="text"
              name="inpColRename"
            />
          </a-form-item>
          <a-form-item :label="$t('kanban.color')">
            <a-input
              id="inpColColor"
              type="color"
              name="inpColColor"
            />
          </a-form-item>
          <a-button
            type="primary"
            style=""
            @click="saveColOptions"
          >
            {{ $t('_.save') }}
          </a-button>
          <a-divider />
          <a-popconfirm
            :title="$t('_.are_you_sure')"
            @confirm="deleteColumn"
          >
            <a-icon
              slot="icon"
              type="question-circle-o"
              style="color: red"
            />
            <a-button
              type="danger"
              :loading="deleting"
            >
              {{ $t('_.delete') }}
            </a-button>
          </a-popconfirm>
        </a-form>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Widget from '@/components/layout-components/widgets/Widget'
import Api, { apiGet } from '@/api'
import { apiLink, getFileURL } from '@/utils'
import Entity from '@/api/entity'
import store from '@/store'
import Module from '@/module/module'
import TaskCard from '@/components/layout-components/widgets/kanban/TaskCard'
import _ from 'lodash'

export default {
  name: 'WidgetKanban',
  components: {
    draggable,
    TaskCard,
  },
  extends: Widget,
  data () {
    return {
      entries: [],
      columns: [],
      columnsCount: 0,
      inpColumnsCount: 0,
      columnsArr: [],
      fieldsArr: [],
      groupByColumn: '',
      columnModuleName: '',
      module: null,
      colModule: null,
      compRating: false,
      compPhoto: false,
      compModal: false,
      addColVisible: false,
      showColSettings: false,
      deleting: false,
    }
  },
  watch: {},
  async mounted () {
    this.module = Api.getModule(this.config.module)
    this.groupByColumn = this.config.column
   if (this.config.rating) {
      this.rating = this.config.rating
    } else {
      this.rating = false
    }
    if (this.config.components) {
      this.config.components.forEach((comp) => {
      switch (comp) {
        case 'rating': this.compRating = true
        break
        case 'photo': this.compPhoto = true
        break
        case 'datamodal': this.compModal = true
        break
      }
      })
    }
    if (this.module instanceof Module) {
        const filteredEntities = await this.getFilteredEntities()
        if (filteredEntities.length) {
          var index = filteredEntities[0].module.fields.findIndex(x => x.identifier === this.groupByColumn)
          this.columnModuleName = filteredEntities[0].module.fields[index].options.references
          this.colModule = Api.getModule(this.columnModuleName)
          for (let fields of filteredEntities) {
            this.fieldsArr.push(fields)
            var statusField = 'untracked'
            var statusEntity = []
            if (fields[this.groupByColumn]) {
              statusField = fields[this.groupByColumn].brezel_name
              statusEntity = fields[this.groupByColumn]
            } else {
              statusEntity.sort_order = 0
              statusEntity.fallback = true
            }
            let photoUrl = await this.openFile(fields.photo)
            this.entries.push({ id: fields.id, title: fields.name, date: fields.start_date, status: statusField, statusEntity: statusEntity, rating: fields.rating, ratingcount: fields.ratingscount, photo: fields.photo, photoUrl: photoUrl })
          }

          var columnModule = Api.getModule(this.columnModuleName)
          if (columnModule instanceof Module) {
            columnModule.getEntities().then(entities => {
              this.columnsCount = 0
              entities.forEach(fields => {
                this.columnsCount++
                this.columnsArr.push({ id: fields.id, title: fields.title, color: fields.color, statusField: fields, tasks: [], sort_order: this.columnsCount })
              })
              this.columns = groupBy(this.entries, this.columnsArr, 'status')
              this.inpColumnsCount = this.columnsCount + 1
            })
          }
        }
    }
  },
  methods: {
  fetchFilteredEntities () {
   var module = this.module
    var url = new URL(apiLink(['modules', module.identifier, 'resources'], store.state.currentSystem))
    var filters = this.config.pre_filters

    var urlParams = {
      sortField: 'sort_order',
      sortOrder: 'ascend',
    }
    if (filters) {
          var newfilter = []
          let temp = {}
          filters.forEach((filter) => {
            if (filter.field) {
              temp.value = '' + _.get(this.resource, filter.field) + ''
            } else {
              temp.value = filter.value
            }
            temp.column = filter.column
            temp.operator = filter.operator
            newfilter.push(temp)
          })
          urlParams.pre_filters = JSON.stringify(newfilter)
     }
      Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))
      return apiGet(url)
      .then(response => {
        if (response.status !== 200) {
          this.$message.error(this.$t('_.something_went_wrong'))
        }
        return response
      })
      .then(response => response.json())
      .then(response => response.data.map(entity => new Entity(entity, module)))
  },
  getFilteredEntities () {
    return this.fetchFilteredEntities(this).then(entities => {
      entities.forEach(entity => {
        entity.module = this.module
      })
        return entities
    })
  },
  saveChange (e, val, tasks) {
   let count = 0
    if (e['added'] || e['moved']) {
    tasks.forEach(task => {
      let entity = []
      count++
      entity.module = this.module
      entity.id = task.id
      entity.sort_order = count
      entity.status = val

      entity = new Entity(entity, this.module)
      Api.updateEntity(entity)
    })
   }
  },
  columnsOrder (e) {
    let count = 0
    if (e['moved']) {
      this.columns.forEach(col => {
        let colEntity = []
        count++
        colEntity.module = this.colModule
        colEntity.id = col.id
        colEntity.sort_order = count
        col.sort_order = count

        let entity = new Entity(colEntity, this.module)
        Api.updateEntity(entity)
      })
      this.inpColumnsCount = count + 1
    }
  },
  reloadRating (val) {
    this.columns.forEach(column => {
      column.tasks.find(entity => {
        if (entity.id === val.id) {
          entity['rating'] = val.rating
        }
      })
    })
  },
  openFile (file) {
    if (!file) return
    return getFileURL(file.id)
  },
  addColumn () {
    let ColumnName = document.getElementById('inpColumnName')
    let ColumnColor = document.getElementById('inpColumnColor')

    if (ColumnName.value === '') {
      this.$message.error(this.$t('_.validation_error'))
    } else {
      let columnModule = Api.getModule(this.columnModuleName)

      let entity = []
      entity.module = columnModule
      entity.sort_order = this.inpColumnsCount
      entity.color = ColumnColor.value
      entity.title = ColumnName.value
      var newentity = new Entity(entity, module)
      Api.createEntity(newentity).then(response => {
        this.columns = []
        this.columnsArr.push({ id: response.resource.id, title: response.resource.title, color: response.resource.color, statusField: response.resource, tasks: [], sort_order: this.inpColumnsCount })
        this.columns = groupBy(this.entries, this.columnsArr, 'status')
        this.inpColumnsCount = this.columns.length + 1
        this.addColVisible = false
      })
    }
  },
  openColSettings (newid) {
    this.showColSettings = true
    var ColName
    var ColColor
    var ColID

    this.columns.find(entity => {
      if (entity.id === newid) {
        this.$nextTick(() => {
          ColID = document.getElementById('inpColID')
          ColName = document.getElementById('inpColRename')
          ColColor = document.getElementById('inpColColor')
          ColName.value = entity.title
          ColColor.value = entity.color
          ColID.value = entity.id
        })
      }
    })
  },
  saveColOptions () {
    let ColColor = document.getElementById('inpColColor')
    let ColName = document.getElementById('inpColRename')
    let ColID = document.getElementById('inpColID')
    let cID = parseInt(ColID.value)
    var columnModule = Api.getModule(this.columnModuleName)
    if (columnModule instanceof Module) {
      Api.fetchEntity(cID, columnModule).then(response => response).then(response => {
        var oldstatus = response.title
        response.title = ColName.value
        response.color = ColColor.value
        Api.updateEntity(response).then(response => {
          var data = response.resource
          this.columns.find(entity => {
            if (entity.id === cID) {
                entity.title = data.title
                entity.color = data.color
                entity.statusField = data
            }
          })
          this.columnsArr.find(col => {
            if (col.id === cID) {
                col.title = data.title
                col.color = data.color
                col.statusField = data
            }
          })
          this.entries.find(col => {
            if (col.status === oldstatus) {
              col.status = data.title
              col.color = data.color
              col.statusEntity = data
            }
          })
          this.$message.success(this.$t('_.save_successful'))
         this.showColSettings = false
        })
      })
    }
  },
  deleteColumn () {
    this.deleting = true
    let ColIDInput = document.getElementById('inpColID')
    let ColID = parseInt(ColIDInput.value)
    var columnModule = Api.getModule(this.columnModuleName)
    if (columnModule instanceof Module) {
      let entity = []
      entity.module = columnModule
      entity.id = ColID
      Api.deleteEntity(entity).then(response => response.json()).then(response => {
        if (response.success === true) {
          let i = this.columns.map(item => item.id).indexOf(ColID)
          this.columns.splice(i, 1)

          let ia = this.columnsArr.map(item => item.id).indexOf(ColID)
          this.columnsArr.splice(ia, 1)
          this.columns = groupBy(this.entries, this.columnsArr, 'status')
          this.showColSettings = false
          this.deleting = false
          this.inpColumnsCount = this.columns.length + 1
        }
      })
    }
  },
},
}
function groupBy (array, columnsArr, key) {
  const result = {}
  const paddedArray = []
  array.forEach(item => {
    if (!result[item[key]]) {
      result[item[key]] = []
    }
    result[item[key]].push(item)
  })
  var newArr = []
  Object.keys(result).forEach(function (key) {
    newArr = {
      'id': result[key][0].statusEntity.id,
      'title': key,
      'statusField': result[key][0].statusEntity,
      'tasks': result[key],
      'color': result[key][0].statusEntity.color,
      'sort_order': result[key][0].statusEntity.sort_order,
    }
    paddedArray.push(newArr)
  })
  var myDifferences = getDifference(columnsArr, paddedArray, 'id')
  if (myDifferences.length > 0) {
    myDifferences.forEach(item => {
      paddedArray.push(item)
    })
  }
  var sortedArray = paddedArray.slice().sort(function (a, b) {
    return a.statusField.sort_order - b.statusField.sort_order
  })
  return sortedArray
}
function getDifference (array1, array2, column) {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1[column] === object2[column]
    })
  })
}
</script>

<style lang="scss" scoped>
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.columns-wrapper {
  overflow-x:scroll;
  overflow-y:hidden;
  max-width:100%;
  white-space: nowrap;
  height:520px;
}
.columns-wrapper .ant-row {
  height:auto;
}
.taskColumn{
  padding:8px;
  float:none;
  display: inline-flex;
  flex:auto;
}
.taskColumn.fallback .taskCol{
  border: 5px dotted #f0f0f0;
  width:290px;
}
.taskCol {
  display: flex; flex-flow: column; height: 100%;
  min-height:500px;border-radius:5px;background:#f4f4f4;padding: 10px;
  overflow-y: scroll;
  max-height:500px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-top: 4px solid #f0f0f0;
    width:280px;
}
.taskColumn:first-child {
  padding-left:0px;
}
.taskColumn:last-child {
  padding-right:0px;
}
.taskCard{
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-radius:5px;
}
.font-semibold {
  font-weight: 600;
  font-size: .875rem;
}
div#WidgetKanban{
  margin: 20px 0px;
  padding:0px 20px;
}

.kanban-custom-btn {padding: 6px 12px;
    height: auto;
    border-radius: 5px;}

html *::-webkit-scrollbar, body *::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.buttons-bar {text-align:right; padding:6px 10px; background: #fafafa;
    border: 1px solid #f0f0f0;}
.kanban-no-data {
  padding: 20px;
}

#WidgetKanban .mt-3.cursor-move {border: 1px solid #e8e8e8;border-left:4px solid #1890ff;}
#WidgetKanban .column-head {border-bottom:1px solid #ccc; margin-bottom:10px; flex: 0 1 auto;}
#WidgetKanban .column-head:after {content:'';display:block; clear:both; }
#WidgetKanban .column-head span {background:none !important;padding-left:5px; font-weight:bold;margin:6px 0px 10px 0px; display:inline-block;}
#WidgetKanban .column-head .handle { display:inline-block;vertical-align: middle;padding:0px 5px; cursor:grab;}
#WidgetKanban .column-head .handle path{ fill:#808080;}
#WidgetKanban .column-head .title {vertical-align: middle;}
#WidgetKanban .column-body {flex: 1 1 auto;}
</style>
