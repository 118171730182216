import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import deDE from 'ant-design-vue/lib/locale-provider/de_DE'
import 'moment/locale/de'
import { apiLink } from '@/utils'
import { apiGet } from './api'

Vue.use(VueI18n)

export const locales = {
  en: {
    code: 'en_US',
    name: 'English',
    dir: 'ltr',
    provider: enUS,
  },
  de: {
    code: 'de_DE',
    name: 'Deutsch',
    dir: 'ltr',
    provider: deDE,
  },
}

let userLang = navigator.language || navigator.userLanguage
userLang = userLang.split('-')[0]
let defLocal = 'en'
if (locales[userLang]) { defLocal = userLang }

export const defaultLocale = defLocal

export const translations = {}

const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: 'en',
  messages: translations,
  silentTranslationWarn: true,
})

export default i18n

export const loadLanguagesAsync = () => new Promise((resolve, reject) => {
  apiGet(['translations'])
    .then((response) => {
      if (response.status === 401) {
        throw new Error('' + response.status)
      }
      return response
    })
    .then(response => response.json())
    .then((responseJson) => {
      for (const lang in responseJson) {
        i18n.setLocaleMessage(lang, responseJson[lang])
      }
      resolve()
    })
    .catch(reject)
})

export const loadPublicLanguagesAsync = () => new Promise((resolve, reject) => {
  apiGet(apiLink(['translations']))
    .then((response) => {
      if (response.status === 503) {
        throw new Error('' + response.status)
      }
      return response
    })
    .then(response => response.json())
    .then((translations) => {
      for (const lang in translations) {
        i18n.setLocaleMessage(lang, translations[lang])
      }
      resolve()
    })
    .catch((error) => {
      const fallbackTranslations = {
        de: {
          503: {
            description: 'Das System ist nicht erreichbar, versuchen sie es bitte später erneut. In der Regel handelt es sich um eine planmäßige Wartung.',
            message: '503',
          },
        },
        en: {
          503: {
            description: 'The system is not reachable, please try again later. Generally it is a planned maintenance.',
            message: '503',
          },
        },
      }
      for (const lang in fallbackTranslations) {
        i18n.setLocaleMessage(lang, fallbackTranslations[lang])
      }
      reject(error)
    })
})
