<template>
  <a @click="register">
    <a-icon
      v-if="webauthnLoading"
      type="loading"
    />
    <a-icon
      v-else-if="registered()"
      type="check"
      style="color: green"
    />
    <a-icon-fingerprint
      v-else
    />
    {{ $t('_.device_auth') }}
  </a>
</template>

<script>
import { apiDelete, apiPost } from '../api'
import Webauthn from '../mixins/Webauthn'
import AIconFingerprint from '../components/icons/Fingerprint'

export default {
  name: 'WebauthnMenuEntry',
  components: { AIconFingerprint },
  mixins: [Webauthn],
  data: () => ({
    webauthnLoading: false,
  }),
  computed: {},
  methods: {
    registered () {
      return !!localStorage.getItem('webauthn')
    },
    async register () {
      if (this.webauthnLoading) return

      if (this.registered()) return this.unregister()

      this.webauthnLoading = true

      try {
        let registrationOptions = await apiPost(['webauthn', 'register', 'options'])
          .then(response => response.json())

        registrationOptions.user.id = this.decodeBase64(registrationOptions.user.id)
        registrationOptions.challenge = this.decodeBase64(registrationOptions.challenge)

        if (registrationOptions.excludeCredentials) {
          for (let cred of registrationOptions.excludeCredentials) {
            cred.id = this.decodeBase64(cred.id)
          }
        }

        const cred = await navigator.credentials.create({
          publicKey: registrationOptions,
        })

        let credential = this.preparePublicKeyCredentials(cred)

        const registrationResponse = await apiPost(['webauthn', 'register'], {}, JSON.stringify(credential), { 'Content-Type': 'application/json' })
          .then(response => {
            this.webauthnLoading = false
            if (response.status >= 300) {
              throw new Error('Request failed')
            }
            return response.json()
          })

        localStorage.setItem('webauthn', JSON.stringify({
            username: this.$store.state.user.email,
            id: registrationResponse.publicKeyCredentialId,
          }
        ))

        this.$forceUpdate()

        this.$message.success(this.$t('_.device_auth_enabled'))
      } catch (e) {
        this.webauthnLoading = false
        console.error(e)
      }
    },
    unregister () {
      this.webauthnLoading = true
      const storageData = JSON.parse(localStorage.getItem('webauthn'))
      apiDelete(['webauthn', 'unregister'], storageData)
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            localStorage.removeItem('webauthn')
            this.$forceUpdate()
            this.webauthnLoading = false
            this.$message.info(this.$t('_.device_auth_disabled'))
          }
        })
        .catch(() => {
          this.webauthnLoading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
