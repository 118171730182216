<template>
  <div/>
</template>

<script>
import FieldCalculator from '@kibro/brezel-recipes'
import { EntityRecipeHandler } from '@/recipes/entity-handler'
import { FieldOptionHandler } from '@/recipes/field-option-handler'
import { ObjectHandler } from '@/recipes/object-handler'
import { canDelete, canUpdate } from '@/guard'
import Field from '@/layout/field'
import { standardFunctions } from '@/recipes'

export default {
  name: 'CalculatesRecipes',
  data () {
    return {
      recipes: {
        queuedBuild: false,
        cardinality: 0,
        fieldCalculator: null,
        functions: {
          ...standardFunctions,
          canUpdate: () => canUpdate(this.resource),
          canDelete: () => canDelete(this.resource),
          getMode: () => this.mode,
          getContext: () => {
            return this.context
          },
        },
      },
    }
  },

  methods: {
    /**
     * Start the calculator watch on a data, prop or function.
     *
     * @param on data key, prop key, or a function returning an expression
     */
    watchCalculator (on) {
      this.registerLayoutWatcher()

      let unwatch = () => {}
      const watcher = (value) => {
        unwatch()
        if (!this.recipes.fieldCalculator) {
          this.buildCalculator()
        }

        if (!this.queuedBuild) {
          this.runCalculator()
        }
        this.$nextTick().then(() => {
          unwatch = this.$watch(on, watcher, { deep: true })
        })
      }
      unwatch = this.$watch(on, watcher, { deep: true })

      this.buildCalculator()
      this.runCalculator()
    },

    registerLayoutWatcher () {
      this.layoutDef.onUpdate(() => {
        if (!this.queuedBuild) {
          this.queuedBuild = true
          this.$nextTick().then(() => {
            this.queuedBuild = false
            this.buildCalculator()
            this.runCalculator()
          })
        }
      })
    },

    addCalculatorField (field) {
      this.recipes.fieldCalculator.addRecipeNode(
        {
          ...field,
          handler: new EntityRecipeHandler(this.resource, field),
        },
        null,
        null,
        (field, node) => this.addCalculatorFieldOptions(field, node)
      )
    },

    addCalculatorFieldOptions (field, node) {
      if (field.options && field.options.recipes) {
        for (const property in field.options.recipes) {
          // field.options[property]
          const recipe = field.options.recipes[property]
          let optionNodeId = `fields.${node.id()}.options.${property}`
          const handler = new FieldOptionHandler(field, property)
          this.recipes.fieldCalculator.addRecipeNode({
            identifier: null,
            recipe,
            handler,
          }, optionNodeId, node)
        }
      }
    },

    addCalculatorLayoutElement (element, index) {
      if (element && element.recipes) {
        for (const property in element.recipes) {
          if (element.recipes.hasOwnProperty(property)) {
            const recipe = element.recipes[property]
            const handler = new ObjectHandler(element, property)
            let compNodeId = `elements.${index}.${property}`
            this.recipes.fieldCalculator.addRecipeNode({
              identifier: null,
              recipe,
              handler,
            }, compNodeId)
          }
        }
      }
    },

    buildCalculator () {
      this.recipes.fieldCalculator = new FieldCalculator({}, this.recipes.functions)
      this.addCalculatorField(new Field({ identifier: 'id' }))
      this.addCalculatorField(new Field({ identifier: 'meta' }))
      for (const field of this.layoutDef.fields()) {
        this.addCalculatorField(field)
      }
      for (const [element, index] of this.layoutDef.elements()) {
        this.addCalculatorLayoutElement(element, index)
        this.addCalculatorLayoutElement(element.options, `${index}.options`)

        if (['buttons', 'resource_table'].includes(element.type)) {
          for (const buttonIndex in element.options.buttons) {
            this.addCalculatorLayoutElement(element.options.buttons[buttonIndex], `${index}.buttons.${buttonIndex}`)
          }
        }
      }
      this.recipes.fieldCalculator.build()
    },

    runCalculator () {
      this.recipes.fieldCalculator.run()
    },
  },
}
</script>

<style scoped>

</style>
