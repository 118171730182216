<template>
  <div
    :style="{flex: 1, justifyContent: 'right'}"
    class="with-padding"
  >
    {{ value }}
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellNumber',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
