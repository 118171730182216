<template>
  <div>
    <h2 style="margin-top:10px;">
      {{ tr(component.options.headline, true) }}
    </h2>
    <GChart
      type="ColumnChart"
      :options="chartOptions"
      :data="chartData"
    />
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import { GChart } from 'vue-google-charts'

export default {
  name: 'ColumnWidget',
  components: {
    GChart,
  },
  extends: LayoutComponent,
  props: [],
  data () {
    let allValues = [['xaxis']]
    let allColors = [];

    (this.component.options.columns || []).forEach((element, i) => {
      allValues[0].push(this.component.options.columns[i].label)
      allColors.push(this.component.options.columns[i].color)
    });

    (this.component.options.axis || []).forEach((element, i) => {
      allValues.push([])
      allValues[i + 1].push(this.component.options.axis[i])
    });

    (this.component.options.columns || []).forEach((element, i) => {
      (this.component.options.columns[i].values || []).forEach((element, t) => {
        allValues[t + 1].push(this.component.options.columns[i].values[t])
      })
    })

    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: allValues,
      chartOptions: {
        // curveType: 'function',  Enable this to smooth out the graphs
        theme: 'maximized',
        colors: allColors,
        bar: { width: '100%' },
        chartArea: {
          top: 20,
          left: 20,
          right: 20,
          bottom: 20,
          backgroundColor: {
            stroke: '#000000',
            strokeWidth: 0,
            // fill: 'yellow', //rest of the progressbar can be filled with a color instead of being transparent
            // opacity: '1',
          },
        },
        hAxis: {
          gridlines: { color: 'black' },
        },
        animation: {
          duration: 500,
          easing: 'out',
        },
      },
    }
  },
  watch: {
    component: {
      deep: true,
      handler () {
        // this redraws the chart when a value changes
      },
    },
  },
  mounted () {
    window.addEventListener('resize', function () {
    })
  },
}

</script>
