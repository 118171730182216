export class BaseRecipeHandler {
  getValue () {
    return null
  }

  putValue (value) {

  }

  getScope () {
    return null
  }

  getChildHandler (field) {
    return new BaseChildRecipeHandler()
  }
}

export class BaseChildRecipeHandler {
  hasItems () {
    return false
  }

  getItems () {
    return []
  }

  getValue (item) {
    return null
  }

  putValue (item, index, value) {

  }

  getChildren (item) {
    return []
  }

  getScopedSymbols (item) {
    return {}
  }

  getHandler (item, child) {
    return new BaseRecipeHandler()
  }
}
