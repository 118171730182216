<template>
  <div
    style="display: flex; align-items: center; border: 1px solid #d9d9d9;"
  >
    <a-input-number
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :placeholder="options.placeholder"
      :step="getStepBasedOnDecimalDigits()"
      :title="field.identifier"
      decimal-separator=","
      style="width: 100%; margin-right: 11px; border: none; border-right: 1px solid #d9d9d9; border-top-right-radius: 0; border-bottom-right-radius: 0"
    />
    <a-icon
      v-if="currencyIcon"
      :type="currencyIcon"
      style="color: rgba(0, 0, 0, 0.25); margin-right: 11px"
    />
    <span
      v-else
      style="color: rgba(0, 0, 0, 0.25); margin-right: 11px"
    >
      {{ currency }}
    </span>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldCurrency',
  extends: Field,
  data () {
    return {
      currency: 'USD',
    }
  },
  computed: {
    currencyIcon () {
      switch (this.currency) {
        case 'USD':
          return 'dollar'
        case 'GBP':
          return 'pound'
        case 'CNY':
          return 'pay-circle'
        case 'EUR':
          return 'euro'
        default:
          return false
      }
    },
  },
  mounted () {
    if (this.options.currency_code) {
      this.currency = this.options.currency_code
    } else {
      this.currency = (this.$store.state.client.currency) ? this.$store.state.client.currency : 'USD'
    }
  },
}
</script>

<style scoped>
@media (max-width: 767px) {
  .field .ant-input-number-input {
    padding: 0 11px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}
</style>
