<template>
  <div>
    <a-icon
      v-if="!loaded && error === ''"
      type="loading"
      style="font-size: 100px; position: absolute; margin:auto; margin-top: 15vh; z-index: 1; display:block; width: 100%;"
    />
    <h2
      v-if="error !== ''"
      style="font-size: 20px; color: red; position: absolute; margin:auto; margin-top: 15vh; z-index: 1; display:block; width: 100%; text-align: center;"
    >
      {{ tr('_.map_could_not_be_loaded', true) }}
    </h2>
    <div
      id="map-wrapper"
      style="display:flex; justify-content: space-around; z-index: 2;"
    >
      <LMap
        id="map"
        ref="map"
        style="height: 40vh; min-height: 300px;"
        :zoom="zoom"
        :center="center"
      >
        <LTile-layer
          :url="url"
          :attribution="attribution"
        />
        <LMarker :lat-lng="center" />
      </LMap>
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import Vue from 'vue'
import { latLng, Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import _ from 'lodash'
import HeatmapOverlay from 'leaflet-heatmap'
import { fireWebhookEvent } from '@/webhooks'

Vue.component('LMap', LMap)
Vue.component('LTileLayer', LTileLayer)
Vue.component('LMarker', LMarker)

let geocodeurl = ''

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'Widget',
  extends: LayoutComponent,
  props: [],
  data () {
    return {
      zoom: 16,
      center: latLng(0, 0),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      loaded: false,
      error: '',
    }
  },
  watch: {
    component: {
      deep: true,
      handler () {
        this.dimMap()
        this.loaded = false
        this.error = ''
        this.debounce()
      },
    },
  },
  mounted () {
    this.dimMap()
    this.debounce()
    this.addHeatmapLayer()
  },
  methods: {
    addHeatmapLayer () {
      var cfg = {
        radius: 20,
        maxOpacity: 0.8,
        scaleRadius: false,
        useLocalExtrema: true,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count',
      }
      const loadEventIdentifier = this.options.event
      if (loadEventIdentifier) {
        fireWebhookEvent(loadEventIdentifier)
          .then(response => {
            var heatmapLayer = new HeatmapOverlay(cfg)
            heatmapLayer.setData({ data: response })
            if (this.$refs.map) {
              this.$refs.map.mapObject.addLayer(heatmapLayer)
            }
          })
      }
    },
    dimMap () {
      document.getElementById('map-wrapper').style.setProperty('filter', 'grayscale(80%) blur(3px)')
    },
    geocoderequest () { // This function gets lat and long coordinates from the address in the api
      geocodeurl = 'https://nominatim.openstreetmap.org/search?q=' + this.component.options.address.split(' ').join('+') + '&format=json&polygon_geojson=1&addressdetails=1'
      fetch(geocodeurl)
        .then(response => response.json())
        .then(data => {
          if (data.length === 0) {
            throw new Error('No Data received. Location not found')
          }
          this.center = latLng(data[0].boundingbox[0], data[0].boundingbox[2])
          document.getElementById('map-wrapper').style.setProperty('filter', 'none')
          this.loaded = true
          return this.center
        })
        .catch((error) => {
          this.dimMap()
          this.error = error
        })
    },
    debounce: _.debounce(function () {
      this.geocoderequest()
    }, 1500),
  },
}

</script>
