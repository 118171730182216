<template>
  <div :brezel-component-id="component.id">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="component.options.identifier"
      :class="isBordered ? 'ant-card-bordered' : ''"
      style="padding: 8px; white-space: pre-line"
      v-html="tr(`${translationPrefix}.texts.${component.options.identifier}`)"
    />
    <div
      v-else
      :class="isBordered ? 'ant-card-bordered' : ''"
    >
      <vue-markdown
        :source="component.options.text"
        table-class="ant-table markdown-table"
        quotes="„“‚‘"
      />
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'LayoutComponentMarkdown',
  components: {
    VueMarkdown,
  },
  extends: LayoutComponent,
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  computed: {
    isBordered () {
      return (this.component.options && this.component.options.has_border)
    },
  },
}
</script>

<style lang="scss">
.markdown-table {
  border-collapse:collapse;
  border:1px solid #e8e8e8;

  td, th {
    border:1px solid #e8e8e8;
  }
}
</style>
