import FieldCalculator from '@kibro/brezel-recipes'
import store from '@/store'
import { ObjectHandler } from '@/recipes/object-handler'

export const standardFunctions = {
  user: () => store.getters.user,
  hasRole: slug => (store.state.userInfo.roles || []).includes(slug),
  isRoot: () => store.state.userInfo.isRoot,
  getMode: () => this.mode,
}

export const recipe = (recipe, context = null, symbols = {}) => {
  const fieldCalculator = new FieldCalculator({ ...symbols, 'this': context }, standardFunctions)
  return fieldCalculator.calculate(recipe)
}

export const recipes = (recipes, context = null, symbols = {}) => {
  const fieldCalculator = new FieldCalculator({ ...symbols, 'this': context }, standardFunctions)
  const results = {}
  for (const key in recipes) {
    const recipe = recipes[key]
    const field = { identifier: key, recipe }
    fieldCalculator.addRecipeNode(
      {
        ...field,
        handler: new ObjectHandler(results, key),
      }
    )
  }
  fieldCalculator.build()
  fieldCalculator.run()
  return results
}
