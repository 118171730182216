<template>
  <div>
    LayoutComponentFieldGroup
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import { isScreenMobile, shouldShow } from '@/utils'
import { can } from '@/guard'

export default {
  name: 'LayoutComponentFieldGroup',
  extends: LayoutComponent,
  props: {
    /**
     * The field tree has the exact same structure as resource, but contains field components
     * or arrays of field components instead of values. This makes it possible to use the same JSONPath to refer
     * to both kinds of objects and to e.g. dynamically change select options.
     */
    fieldTree: {
      type: Object,
      default: undefined,
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      screenIsMobile: isScreenMobile(),
    }
  },
  computed: {
    action () {
      return this.mode === 'module.edit' ? 'UPDATE' : 'READ'
    },

    fields () {
      return this.component.options.fields
          .map((field, index) => [field, index])
          .filter(([field, _]) => can('READ', field))
          .filter(([field, _]) => shouldShow(field, this.mode))
    },
  },
  methods: {
    getStyleForField (field, screenIsMobile) {
      return {
        display: shouldShow(field, this.mode) ? (screenIsMobile && ['module.create', 'module.edit'].includes(this.mode) ? 'flex' : null) : 'none',
        flexDirection: screenIsMobile && ['module.create', 'module.edit'].includes(this.mode) ? 'column' : null,
      }
    },

    /**
     * Set the field tree of the field.
     *
     * @param field
     * @param tree VueComponent|Array either a component or a list of subtrees.
     */
    setFieldTree (field, tree) {
      this.fieldTree[field.identifier] = tree
    },
  },
}
</script>
