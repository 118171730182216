import Vue from 'vue'
import Router from 'vue-router'
import * as process from 'process'
import { routes } from '@/./routes'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        Vue.nextTick()
          .then(() => {
            resolve({ selector: to.hash, offset: { x: 0, y: 50 }, behavior: 'smooth' })
          })
      })
    }

    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: routes,
})
