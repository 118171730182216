<template>
  <div>
    <a-spin
      v-if="loading"
      style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);"
    >
      <a-icon
        slot="indicator"
        style="font-size: 50px"
        type="loading"
      />
    </a-spin>
    <a-row
      v-else
      :style="{ height: '100vh' }"
      align="middle"
      justify="center"
      type="flex"
    >
      <a-col
        :style="{ padding: '10px', maxWidth: '350px' }"
        :xs="24"
      >
        <img
          v-if="logoUrl !== ''"
          :src="logoUrl"
          :style="{ maxWidth: '100%', margin: '0 auto 26px auto', display: 'block' }"
          alt="logo"
        >
        <!-- Password Reset Form -->
        <div v-if="resetPassword">
          <a-form
            layout="vertical"
            @submit="setNewPassword"
          >
            <strong>{{ $t('new_password') }}</strong>
            <FieldContainer
              v-model="fields.password.value"
              :field="fields.password"
              :style="{ marginBottom: '18px' }"
            />
            <strong>{{ $t('repeat_password') }}</strong>
            <FieldContainer
              v-model="fields.password_confirmation.value"
              :field="fields.password_confirmation"
              :style="{ marginBottom: '18px' }"
            />
            <a-form-item>
              <a-button
                :disabled="fields.submit.loading"
                :loading="fields.submit.loading"
                :style="{ width: '100%' }"
                html-type="submit"
                type="primary"
              >
                {{ $t('reset_password') }}
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { handleToast, logoExists } from '@/utils'
import { defaultLocale, loadPublicLanguagesAsync } from '@/i18n'
import FieldContainer from '@/components/FieldContainer'
import { apiGet, apiPost } from '../api'

export default {
  name: 'PasswordReset',
  components: {
    FieldContainer,
  },
  data () {
    return {
      customLogoUrl: this.$systemPath('/logo.svg'),
      fields: {
        password: {
          value: undefined,
          id: 'password',
          type: 'password',
          prefix: 'lock',
          validateStatus: '',
          help: '',
          options: {
            allow_prefill: true,
          },
        },
        password_confirmation: {
          value: undefined,
          id: 'repeat_password',
          type: 'password',
          prefix: 'lock',
          validateStatus: '',
          help: '',
          options: {
            allow_prefill: true,
          },
        },
        submit: {
          loading: false,
        },
      },
      loading: true,
      logoUrl: '',
      reactedToApiDown: false,
      resetPassword: false,
      resetPasswordEmail: '',
      resetPasswordToken: '',
    }
  },
  created () {
    this.checkToken(this.$route.query.token)
    logoExists(this.customLogoUrl)
      .then(() => {
        this.logoUrl = this.customLogoUrl
      })
      .catch(() => {
        this.logoUrl = '/brezel_icon.svg'
      })
    document.title = this.$t('login') + ' | ' + this.$store.state.systemName
  },
  methods: {
    checkToken (token) {
      this.reloadIfApiIsDownOrDo(() => {
        this.resetPasswordToken = token
        apiGet(['password', 'find', token], {}, undefined, { 'Content-Type': 'application/json' })
          .then(response => response.json())
          .then((response) => {
            if (response.success) {
              this.resetPassword = true
              this.resetPasswordEmail = response.data.email
              loadPublicLanguagesAsync()
                .then(() => {
                  this.loading = false
                })
                .catch(this.reactToApiDown)
            } else {
              this.$emit('error')
            }
          })
          .catch(e => {
            console.error(e)
            handleToast({
              toast_type: 'error',
              message: '401',
              description: e.message,
            })
          })
      })
    },
    setNewPassword (e) {
      e.preventDefault()
      this.reloadIfApiIsDownOrDo(() => {
        this.fields.submit.loading = true
        this.fields.password.validateStatus = 'validating'
        this.fields.password_confirmation.validateStatus = 'validating'

        apiPost(
          ['password', 'reset'],
          {},
          JSON.stringify({
            password: this.fields.password.value,
            password_confirmation: this.fields.password_confirmation.value,
            email: this.resetPasswordEmail,
            token: this.resetPasswordToken,
          }),
          { 'Content-Type': 'application/json' }
        )
          .then((response) => {
            this.fields.submit.loading = false
            if (response.status === 401) {
              this.fields.password.validateStatus = 'error'
              this.fields.password_confirmation.validateStatus = 'error'

              throw new Error('' + response.status)
            }
            return response.json()
          })
          .then(() => {
            this.$router.push(`/${defaultLocale}`)
            window.location.reload()
          })
          .catch((e) => {
            handleToast({
              toast_type: 'error',
              message: this.$t('errors.401_password_request_reset_message'),
              description: this.$t('errors.401_password_request_reset_description'),
            })
          })
      })
    },
    reactToApiDown () {
      if (this.reactedToApiDown === false) {
        this.reactedToApiDown = true
        this.loading = false
        handleToast({
          toast_type: 'error',
          message: this.$t('503.message'),
          description: this.$t('503.description'),
        })
      }
    },
    reloadIfApiIsDownOrDo (callback) {
      if (this.reactedToApiDown) {
        window.location.reload()
      } else {
        callback()
      }
    },
  },
}
</script>
