<template>
  <div
    :brezel-component-id="uniqueId"
    class="layout-component-view"
  >
    <a-spin
      v-if="loading"
      style="padding-bottom: 24px;"
    />
    <div v-else>
      <div style="display: flex">
        <form
          v-if="hasFilters"
          class="view-filters"
          style="flex-grow: 1; display: flex; align-items: center; padding: 16px 32px; overflow-x: auto;"
          @submit="fetchView"
        >
          <div
            v-for="filter in filters"
            :key="filter.identifier"
            style="min-width: 126px;"
          >
            <FieldContainer
              v-model="filterValues[filter.identifier]"
              :field="filter"
            />
          </div>
          <a-button
            type="primary"
            html-type="submit"
          >
            <a-icon type="check-circle"/>
            {{ $t('_.apply_view_filter') }}
          </a-button>
          <a-button
            type="danger"
            @click="resetFilters"
          >
            <a-icon type="close-circle"/>
            {{ $t('_.reset_view_filter') }}
          </a-button>
        </form>
        <div
          v-if="isPrintable"
          class="view-print-action"
          style="flex-grow: 1; display: flex; align-items: center; justify-content: flex-end; padding: 16px 32px;"
        >
          <a-button
            size="small"
            type="primary"
            @click="printView"
          >
            <a-icon type="printer"/>
          </a-button>
        </div>
      </div>
      <div v-html="html"/>
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import { isDescendant, uniqid8 } from '@/utils'
import FieldContainer from '@/components/FieldContainer'
import { apiGet } from '@/api'

export default {
  name: 'LayoutComponentView',
  components: {
    FieldContainer,
  },
  extends: LayoutComponent,
  props: {
    module: {
      type: Object,
      default: undefined,
    },
  },
  data () {
    return {
      html: '',
      loading: true,
      filters: [],
      filterValues: {},
      uniqueId: uniqid8(),
    }
  },
  computed: {
    hasFilters () {
      return this.filters.length > 0
    },
    isPrintable () {
      return this.component.options.printable !== false
    },
  },
  created () {
    apiGet(['views', this.component.options.view, 'filters'])
      .then(response => response.json())
      .then(filters => {
        this.filters = filters.map(filter => {
          let f = filter
          f.options = {
            'placeholder': f.placeholder,
          }
          return f
        })
        this.fetchView()
      })
      .catch((error) => {
        console.error(error)
        this.html = this.component.options.view
        this.loading = false
      })
  },
  methods: {
    fetchView () {
      this.loading = true
      let path = ['views', this.component.options.view]
      if (this.module && this.module.identifier && this.resource && this.resource.id) {
        path.push(this.module.identifier)
        path.push(this.resource.id)
      }
      apiGet(path, this.filterValues)
        .then(response => response.text())
        .then(response => {
          this.html = response
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
          this.html = this.component.options.view
          this.loading = false
        })
    },
    resetFilters () {
      this.filterValues = {}
      this.fetchView()
    },
    printView () {
      const self = document.querySelector('[brezel-component-id="' + this.uniqueId + '"]')
      document.querySelectorAll('.layout-col').forEach(layoutCol => {
        if (isDescendant(layoutCol, self) === false) {
          layoutCol.style.display = 'none'
        }
      })
      window.print()
      document.querySelectorAll('.layout-col').forEach(layoutCol => {
        layoutCol.style.display = 'block'
      })
    },
  },
}
</script>

<style>
.layout-component-view .ant-form-extra {
  display: none;
}

.layout-component-view .ant-row.ant-form-item {
  margin-bottom: 0;
}
</style>
