<template>
  <node-view-wrapper class="brezel-link">
    <EntityLink
      v-if="entity && !editor.options.editable"
      :entity="entity"
    >
      {{ node.textContent }}
    </EntityLink>
    <span v-else><node-view-content/></span>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'
import EntityLink from '@/components/EntityLink'
import Api from '@/api'

export default {
  name: 'BrezelLink',
  components: {
    NodeViewWrapper,
    NodeViewContent,
    EntityLink,
  },
  props: nodeViewProps,
  data () {
    return {
      entity: null,
    }
  },
  mounted () {
    this.handleUpdate()
  },
  methods: {
    handleUpdate () {
      const module = Api.getModule(this.node.attrs.module)
      this.entity = module.newEntity({ id: this.node.attrs.id })
    },
  },
}
</script>

<style lang="scss" scoped>
.brezel-link {
  display: inline;
}
</style>
