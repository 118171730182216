<template>
  <div class="wysiwyg-viewer">
    <iframe
      v-if="iframe"
      ref="frame"
      style="width: 100%;  height: 500px; border: none;"
    />
    <editor-content
      v-else
      :editor="editor"
      class="editor__content"
    />
  </div>
</template>

<script>
import FieldViewer from '@/components/fields/FieldViewer'
import EditorMixin from '@/components/fields/wysiwyg/EditorMixin'
import { EditorContent } from '@tiptap/vue-2'

export default {
  name: 'FieldViewerWYSIWYG',
  components: { EditorContent },
  extends: FieldViewer,
  mixins: [EditorMixin],
  computed: {
    iframe () {
      return this.field.options?.show_as_iframe
    },
  },
  watch: {
    value () {
      this.editor.commands.setContent(this.value)
      this.editor.emit('update', { editor: this.editor })
    },
  },
  mounted () {
    this.editor.commands.setContent(this.value)
    this.editor.setEditable(false)
    if (this.iframe) {
      this.$nextTick(() => {
        const iframe = this.$refs.frame
        iframe.contentWindow.document.open()
        iframe.contentWindow.document.write(this.value)
        iframe.contentWindow.document.body.style = `font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                                                  'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
                                                  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
        iframe.contentWindow.document.close()
      })
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
}
</script>

<style scoped>
.wysiwyg-viewer {
  text-align: initial;
}
</style>
