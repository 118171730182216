<template>
  <div :class="{ 'notab': layout.spec.tabs.length < 2 }">
    <a-skeleton
      v-if="loading"
      :paragraph="{rows: 10}"
      active
    />
    <div v-else>
      <Layout
        v-if="!loading"
        :layout="layout"
        :module="module"
        :resource="resource"
        mode="module.show"
        :context="context"
        :buttons="buttons"
        @delete="handleDelete(routeParams.module_identifier, routeParams.resource_identifier)"
      />
    </div>
    <a-drawer
      :title="$t('_.additional_information._')"
      :visible="infoDrawerVisible"
      placement="right"
      width="62.8vw"
      class="drawer-xs"
      @close="closeInfoDrawer"
    >
      <p v-if="$store.getters.isMultiClientAndCurrentIsDefaultClient">
        {{ $t('_.additional_information.client') }}: {{ resource.client_id }}
      </p>
      <WidgetHistory :resource="resource"/>
    </a-drawer>
    <a-drawer
      :title="relatedDrawer.title"
      :visible="relatedDrawer.visible"
      height="auto"
      placement="bottom"
      @close="closeRelatedDrawer"
    >
      <!-- WIP -->
      <module-show
        v-if="relatedDrawer.visible"
        :key="1"
        :module-identifier="'employees'"
        :resource-identifier="1"
      />
    </a-drawer>
  </div>
</template>

<script>
import store from '@/store'
import { cloneDeep, get } from 'lodash'
import { bakeryRedirectLink, isScreenMobile, redirect, setDocumentTitle } from '@/utils'
import { EventBus } from '@/event-bus'
import Layout from '@/components/Layout'
import LayoutDef from '@/layout/layout'
import * as guard from '@/guard'
import { webhookButtonHandler } from '@/webhooks'
import Api, { apiGet, fetchModuleLayout, printEntity } from '@/api'
import { loadLanguagesAsync } from '@/i18n'
import Module from '@/module/module'
import Entity from '@/api/entity'
import WidgetHistory from '@/components/layout-components/widgets/history/WidgetHistory'
import HandlesDelete from '../mixins/HandlesDelete'
import { buildCustomButtons } from '../utils'

export default {
  name: 'ModuleShow',
  components: {
    Layout, WidgetHistory,
  },
  mixins: [
    HandlesDelete,
  ],
  props: {
    // WIP
    componentMode: {
      required: false,
      type: Boolean,
      default: false,
    },
    moduleIdentifier: {
      required: false,
      type: String,
      default: null,
    },
    resourceIdentifier: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loadingRequests: 1,
      infoDrawerVisible: false,
      module: {
        identifier: '',
        name: '',
        buttons: {},
      },
      relatedDrawer: {
        title: '',
        visible: false,
      },
      resource: new Entity(),
      routeParams: {
        module_identifier: this.$route.params.module_identifier,
        resource_identifier: this.$route.params.resource_identifier,
      },
      screenIsMobile: isScreenMobile(),
      layout: new LayoutDef(),
      webhooks: [],
      // This is currently necessary because the API does not give us created_by and updated_by with full module info,
      // instead only the user base fields.
      entityMeta: {
        loaded: false,
        createdBy: null,
        updatedBy: null,
      },
    }
  },
  computed: {
    buttons () {
      let buttons = []

      let editLink = this.routeParams.module_identifier + '/' +
        this.routeParams.resource_identifier + '/edit' +
        (this.$route.query ? '?' + (new URLSearchParams(this.$route.query).toString()) : '')

      let buttonOptions = this.module.buttons !== {} ? this.module.buttons : null
      let displayEdit = true
      let editTitle
      let editWebhook
      let displayDelete = true
      let deleteTitle
      let deleteWebhook
      let displayPrint = true
      let printTitle

      if (buttonOptions !== null && buttonOptions !== {} && buttonOptions !== undefined) {
        if (buttonOptions.edit !== undefined) {
          displayEdit = buttonOptions.edit.display !== undefined ? buttonOptions.edit.display : true
          editTitle = buttonOptions.edit.title !== undefined ? buttonOptions.edit.title : undefined
          editWebhook = buttonOptions.edit.trigger !== undefined ? buttonOptions.edit.trigger : undefined
        }

        if (buttonOptions.delete !== undefined) {
          displayDelete = buttonOptions.delete.display !== undefined ? buttonOptions.delete.display : true
          deleteTitle = buttonOptions.delete.title !== undefined ? buttonOptions.delete.title : undefined
          deleteWebhook = buttonOptions.delete.trigger !== undefined ? buttonOptions.delete.trigger : undefined
        }

        if (buttonOptions.print !== undefined) {
          displayPrint = buttonOptions.print.display !== undefined ? buttonOptions.print.display : true
          printTitle = buttonOptions.print.title !== undefined ? buttonOptions.print.title : undefined
        }
      }

      if (guard.canUpdate(this.resource) && displayEdit) {
        buttons.push({
          'title': editTitle || '_.edit',
          'function': editWebhook ? () => {
            const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
            const webhook = {
              'identifier': editWebhook,
              'layouts': [],
              'type': 'webhook',
            }
            webhookButtonHandler(webhook, module, this.$route.params.resource_identifier, { context: this.context })
          } : null,
          'link': editLink,
          'style': 'primary',
          'icon': 'edit',
        })
      }
      if (!this.module.options || (this.module.options.show_history !== false)) {
        buttons.push({
          'function': this.openInfoModal,
          'style': 'default',
          'icon': 'info-circle',
        })
      }
      buttons.push({
        'function': this.reload,
        'style': 'default',
        'icon': 'reload',
      })
      if (this.module.identifier !== 'iframe' && displayPrint) {
        buttons.push({
          'title': printTitle || '_.print',
          'function': () => printEntity(this.resource),
          'style': 'default',
          'icon': 'printer',
        })
      }

      let displayCopy = get(buttonOptions, 'copy.display') ?? false
      if (guard.canCreate(this.module) && displayCopy) {
        let copyWebhook = get(buttonOptions, 'copy.trigger')
        buttons.push({
          'title': get(buttonOptions, 'copy.title', '_.copy'),
          'function': copyWebhook ? () => {
            const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
            const webhook = {
              'identifier': copyWebhook,
              'layouts': [],
              'type': 'webhook',
            }
            webhookButtonHandler(webhook, module, this.$route.params.resource_identifier, { context: this.context })
          } : null,
          'link': `${this.module.identifier}/${this.resource.id}/copy`,
          'style': 'default',
          'icon': 'copy',
        })
      }

      if (guard.canDelete(this.resource) && displayDelete) {
        buttons.push({
          'title': deleteTitle || '_.delete',
          'action': deleteWebhook === undefined ? 'delete' : 'function',
          'function': deleteWebhook ? () => {
            const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
            const webhook = {
              'identifier': deleteWebhook,
              'layouts': [],
              'type': 'webhook',
            }
            webhookButtonHandler(webhook, module, this.$route.params.resource_identifier, { context: this.context })
          } : null,
          'style': 'danger',
          'icon': 'delete',
          'popconfirm': true,
        })
      }

      if (buttonOptions) {
        const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
        const resourceIdentifier = this.$route.params.resource_identifier
        const customButtons = buildCustomButtons(
          buttonOptions,
          module,
          resourceIdentifier,
          () => ({
            prototype: { ...this.resource, module: undefined },
            module_identifier: module.identifier,
          }),
          this.context,
          'module.show'
        )

        if (customButtons) buttons = buttons.concat(customButtons)
      }

      this.webhooks.forEach(webhook => {
        buttons.push({
          'title': webhook.title,
          'function': () => {
            const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
            webhookButtonHandler(webhook, module, this.$route.params.resource_identifier, { context: this.context })
          },
          'style': 'default',
          'icon': 'apartment',
        })
      })
      if (process.env.VUE_APP_DEBUG === 'true') {
        buttons.push({
          'title': 'bakery.dev.buttons.open_bakery',
          'link': {
            external: true,
            href: bakeryRedirectLink(this.routeParams.module_identifier),
          },
          'style': 'dashed',
          'icon': 'sliders',
        })
        buttons.push({
          'title': 'bakery.dev.buttons.reload_translations',
          'function': loadLanguagesAsync,
          'style': 'dashed',
          'icon': 'reload',
        })
      }

      return buttons.reverse()
    },

    loading () {
      return this.loadingRequests !== 0
    },

    context () {
      return {
        entity: this.resource,
        module: this.module,
      }
    },
  },
  watch: {
    module: {
      deep: true,
      handler (module) {
        // prevents the breadcrumbs to change when using this as a component
        if (!this.componentMode) {
          store.commit('setModule', module)
        }
      },
    },
  },
  created () {
    this.routeParams.module_identifier = (this.moduleIdentifier ? this.moduleIdentifier : this.routeParams.module_identifier)
    this.routeParams.resource_identifier = (this.resourceIdentifier ? this.resourceIdentifier : this.routeParams.resource_identifier)
    this.module = Module.byIdentifier(this.routeParams.module_identifier)

    if (!this.module) {
      EventBus.$emit('showErrorOrInformation', [true, 404])
      return
    }

    // cloneDeep so that layouts (with cyclic field objects) don't appear in the store.
    this.layout = new LayoutDef(cloneDeep(this.module.getLayoutSpec('detail')), this.module)
    this.fetchWebhooks()
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.screenIsMobile = isScreenMobile()
    })

    this.fetchResource()
  },
  methods: {
    closeInfoDrawer () {
      this.infoDrawerVisible = false
    },
    closeRelatedDrawer () {
      this.relatedDrawer.visible = false
    },
    fetchLayout () {
      fetchModuleLayout(this.routeParams.module_identifier, 'detail')
        .then(layout => {
          // initialize new Layout, otherwise Vue does not add obs dependencies to field options
          this.layout = new LayoutDef(layout, this.module)
          this.loadingRequests--
        })
    },
    fetchResource () {
      Api.fetchEntity(this.routeParams.resource_identifier, this.routeParams.module_identifier)
        .then(resource => {
          this.resource = resource
          this.resource.identifier = resource.id
          setDocumentTitle(this, resource)
          this.resource.brezel_name = resource.brezel_name
          this.loadingRequests--
        })
        .catch(console.error)
    },
    /**
     * Temporary until layout components are implemented.
     */
    fetchWebhooks () {
      apiGet(['modules', this.routeParams.module_identifier, 'buttonBarWebhooks'])
        .then(response => response.json())
        .then(events => {
          this.webhooks = events
        })
    },
    openInfoModal () {
      if (!this.entityMeta.loaded) {
        const fetchCreated = this.resource.created_by
          ? Api.fetchEntity(this.resource.created_by).then(createdBy => {
            this.entityMeta.createdBy = createdBy
          }) : Promise.resolve()
        const fetchUpdated = this.resource.updated_by
          ? Api.fetchEntity(this.resource.updated_by).then(updatedBy => {
            this.entityMeta.updatedBy = updatedBy
          }) : Promise.resolve()
        Promise.all([fetchCreated, fetchUpdated]).then(() => {
          this.entityMeta.loaded = true
        })
      }
      this.infoDrawerVisible = true
    },
    reload () {
      redirect('module.show', {
        module_identifier: this.module.identifier,
        resource_identifier: this.resource.identifier,
      })
    },
  },
}
</script>

<style lang="scss">
.module-name {
  color: gray;
  font-weight: normal;
}

.ant-card-body {
  overflow-x: auto;

  > div {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}
</style>
