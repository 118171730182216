<!--suppress HtmlDeprecatedAttribute -->
<template>
  <a-row
    type="flex"
    justify="space-around"
    align="middle"
    style="height: 100%"
  >
    <a-col>
      <a-result
        :status="resultType"
        :title="(isError ? 'Error' : 'Info') + ' ' + type + ': ' + information.header"
        :sub-title="information.paragraph"
      >
        <template #extra>
          <a-button
            v-if="type === 404"
            type="primary"
            @click="goBack"
          >
            <a-icon type="left-circle" /> Go back
          </a-button>
          <a-button
            type="primary"
            @click="redirectHome"
          >
            <a-icon type="reload" /> Reload Application
          </a-button>
        </template>
      </a-result>
    </a-col>
  </a-row>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'Error',
  props: {
    isError: {
      default: true,
      type: Boolean,
    },
    type: {
      default: 404,
      type: Number,
    },
  },
  data () {
    return {
      errors: {
        403: {
          header: 'Forbidden!',
          paragraph: 'You are not allowed to access the requested resource.',
        },
        404: {
          header: 'Resource not found!',
          paragraph: 'The requested resource was not found.',
        },
        500: {
          header: 'Internal Server Error!',
          paragraph: 'Something went wrong on the server.',
        },
      },
      info: {
        418: {
          header: 'I’m a teapot',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        },
        503: {
          header: 'The system is not reachable, please try again later.',
          paragraph: 'Generally this is due to planned maintenance.',
        },
      },
    }
  },
  computed: {
    information () {
      // is error and error is found
      if (this.isError) {
        if (this.errors[this.type]) {
          return this.errors[this.type]
        }

        // is error fallback to error 500
        return this.errors[500]
      } else {
        // is info and info is found
        if (this.info[this.type]) {
          return this.info[this.type]
        }

        // is info fallback to info 418
        return this.info[418]
      }
    },
    resultType () {
      if (['success', 'error', 'info', 'warning', 404, 403, 500].includes(this.type)) {
        return this.type.toString()
      }
      return 'info'
    },
  },
  methods: {
    redirectHome () {
      EventBus.$emit('redirectHome')
    },
    goBack () {
      window.history.back()
      window.addEventListener('popstate', this.handlePopstate)
    },
    handlePopstate () {
      window.removeEventListener('popstate', this.handlePopstate)
      window.location.reload()
    },
  },
}
</script>
