<script>
import { checkFieldForErrors } from '@/utils'

export default {
  name: 'Validations',
  data () {
    return {
      errors: {},
    }
  },
  methods: {
    resetErrorCounter () {
      this.layout.spec.tabs.forEach(tab => {
        tab.fieldsWithValidationError = 0
      })
    },
    processValidations (params = {}) {
      if (params.hasOwnProperty('errors')) {
        this.errors = params.errors
      }
      this.resetErrorCounter()
      this.layout.spec.tabs.forEach(tab => {
        tab.rows.forEach(row => {
          row.cols.forEach(col => {
            col.components.forEach(component => {
              component.options.fields.forEach(field => {
                checkFieldForErrors(this.$refs.layout.fieldTree, field.identifier, this.errors, tab)
              })
            })
          })
        })
      })
      this.$forceUpdate()
      if (this.$refs.layout) {
        this.$refs.layout.$forceUpdate()
      }
    },
  },
}
</script>
