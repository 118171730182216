import { render, staticRenderFns } from "./LayoutComponentCardsView.vue?vue&type=template&id=809319d8&scoped=true&style=border%3A0px%3B&"
import script from "./LayoutComponentCardsView.vue?vue&type=script&lang=js&"
export * from "./LayoutComponentCardsView.vue?vue&type=script&lang=js&"
import style0 from "./LayoutComponentCardsView.vue?vue&type=style&index=0&id=809319d8&prod&scoped=true&lang=css&"
import style1 from "./LayoutComponentCardsView.vue?vue&type=style&index=1&id=809319d8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "809319d8",
  null
  
)

export default component.exports