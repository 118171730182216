<template>
  <div>
    <a-row>
      <a-col
        :xs="9"
        class="field-cell"
      >
        <slot name="indent"/>
        <span v-if="readonly">
          <CheckIcon :permission="allChecked(module, field)"/> {{ $t('modules.' + module.identifier + '.fields.' + field.identifier) }}
        </span>
        <a-checkbox
          v-else
          :checked="allChecked(module, field)"
          :indeterminate="allChecked(module, field) === null"
          :disabled="readonly"
          @change="changeAll($event, module, field)"
        >
          {{ $t('modules.' + module.identifier + '.fields.' + field.identifier) }}
        </a-checkbox>
      </a-col>
      <a-col
        v-for="action in ['read', 'list_add', 'write', 'list_remove']"
        :key="action"
        :xs="3"
        class="checkbox-cell"
      >
        <div v-if="field.type === 'list' || false === ['list_add', 'list_remove'].includes(action)">
          <CheckIcon
            v-if="readonly"
            :permission="permChecked(module, field, action)"
          />
          <a-checkbox
            v-else
            :checked="permChecked(module, field, action)"
            @change="changePerm($event, module, field, action)"
          />
        </div>
      </a-col>
    </a-row>
    <div class="child-fields">
      <FieldPermissionRow
        v-for="child in field.children"
        :key="child.id"
        ref="childRow"
        :module="module"
        :field="child"
        :readonly="readonly"
        :all-checked="allChecked"
        :all-change="allChange"
        :perm-checked="permChecked"
        :perm-change="permChange"
      >
        <template #indent>
          <div class="indent"/>
          <slot name="indent"/>
        </template>
      </FieldPermissionRow>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/components/layout-components/widgets/permissions/CheckIcon'
export default {
  name: 'FieldPermissionRow',
  components: { CheckIcon },
  props: {
    module: {
      type: Object,
      default: undefined,
    },
    field: {
      type: Object,
      default: undefined,
    },
    permChecked: {
      type: Function,
      default: undefined,
    },
    permChange: {
      type: Function,
      default: undefined,
    },
    allChecked: {
      type: Function,
      default: undefined,
    },
    allChange: {
      type: Function,
      default: undefined,
    },
    readonly: Boolean,
  },
  methods: {
    changeAll (...args) {
      this.allChange(...args)
      this.$forceUpdate()
    },

    changePerm (...args) {
      this.permChange(...args)
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
.indent {
  display: inline-block;
  width: 40px;
}

.field-cell {
  padding-left: 40px;
}

.checkbox-cell {
  padding: 10px;
  user-select: none;
  text-align: center;
}
</style>
