import FieldCell from '@/layout/cell'
import _ from 'lodash'

export default class FieldColumn {
  field
  identifier
  cells = []
  settings = {}

  constructor (field, identifier = null) {
    this.field = field
    this.identifier = typeof identifier === 'string' ? identifier : field.identifier
    this.fieldOptions = field.options || {
      multiple: false,
      no_filter_in_entity_table: false,
      no_sorter_in_entity_table: false,
    }
  }

  get key () {
    return this.identifier
  }

  get filterHidden () {
    return this.fieldOptions.no_filter_in_entity_table === true
  }

  get isSortable () {
    if (this.fieldOptions.no_sorter_in_entity_table === true) {
      return false
    }
    if (this.field.type === 'multiselect') {
      return false
    }
    return !(['upload', 'file', 'select'].includes(this.field.type) && this.fieldOptions.multiple === true)
  }

  get hasPopover () {
    return this.hasBasePopover || this.hasLegendPopover
  }

  get hasLegendPopover () {
    return _.get(this.fieldOptions, 'popover_legend_in_entity_table') === true
  }

  get hasBasePopover () {
    return _.get(this.fieldOptions, 'cell_popover_in_entity_table') === true
  }

  get fieldChoices () {
    return _.get(this.fieldOptions, 'values', [])
  }

  get moduleIdentifier () {
    return this.field.module.identifier
  }

  setCell (rowIndex, cell) {
    this.cells[rowIndex] = cell
  }

  setRow (rowIndex, row) {
    if (this.cells[rowIndex] instanceof FieldCell) {
      this.cells[rowIndex].row = row
      this.cells[rowIndex].column = this
      this.cells[rowIndex].settings = Object.assign({}, this.settings)
    } else {
      this.cells[rowIndex] = new FieldCell(row, this)
    }
  }
}
