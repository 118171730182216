import { render, staticRenderFns } from "./CalculatesRecipes.vue?vue&type=template&id=0bbad3ea&scoped=true&"
import script from "./CalculatesRecipes.vue?vue&type=script&lang=js&"
export * from "./CalculatesRecipes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbad3ea",
  null
  
)

export default component.exports