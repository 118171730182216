export default class TableRow {
  data
  rowIndex
  settings = {}

  constructor (data, rowIndex) {
    this.data = data
    this.rowIndex = rowIndex
  }
}
