<template>
  <div
    id="calendar"
    ref="CalendarWrapper"
    :brezel-component-id="component ? component.id : null"
  >
    <a-skeleton
      v-if="loading"
      :paragraph="{rows: 10}"
      active
    />
    <div v-else>
      <div
        class="calendar-wrapper"
        :style="{ display: isSmallViewport || options.filter_sidebar === false ? 'block' : 'flex' }"
      >
        <layout-component-buttons
          v-if="!componentEventsDef"
          :component="buttonComponent"
          style="width: 100%"
          @event="anyEvent"
        />
        <a-row
          align="middle"
          justify="space-between"
          type="flex"
          style="padding: 8px; width: 100%"
        >
          <a-col
            :span="isSmallViewport ? 24 : 9"
            :style="{ textAlign: isSmallViewport ? 'center' : null, paddingBottom: isSmallViewport ? '16px' : null }"
          >
            <a-button
              v-if="showFilterSidebar"
              :icon="menuCollapsed ? 'menu-unfold' : 'menu-fold'"
              @click="menuCollapsed = !menuCollapsed"
            />
            <a-divider
              v-if="showFilterSidebar && showNavButtons"
              type="vertical"
            />
            <a-button-group v-if="showNavButtons">
              <a-button
                icon="double-left"
                @click="handleCalendarControls('prevYear')"
              />
              <a-button
                icon="left"
                @click="handleCalendarControls('prev')"
              />
              <a-button @click="handleCalendarControls('today')">
                {{ $t(`calendar.today`) }}
              </a-button>
              <a-button
                icon="right"
                @click="handleCalendarControls('next')"
              />
              <a-button
                icon="double-right"
                @click="handleCalendarControls('nextYear')"
              />
            </a-button-group>
          </a-col>
          <a-col
            :span="isSmallViewport ? 24 : 6"
            style="text-align: center"
          >
            <h2 style="margin-bottom: 0">
              {{ title }}
            </h2>
          </a-col>
          <a-col
            v-if="!isSmallViewport && availableViews.length > 1"
            :span="9"
            :style="{ textAlign: isSmallViewport ? 'center' : 'right', paddingBottom: isSmallViewport ? '16px' : null }"
          >
            <a-radio-group
              v-model="calendarView"
              @change="handleViewChange($event.target.value)"
            >
              <a-radio-button
                v-for="(view, viewIndex) in availableViews"
                :key="viewIndex"
                :value="getViewByIdentifier(view)"
              >
                {{ $t(`calendar.` + view) }}
              </a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
        <component
          :is="isSmallViewport ? 'a-drawer' : 'div'"
          v-if="showFilterSidebar"
          :visible="!menuCollapsed"
          placement="left"
          class="menu"
          width="300px"
          :title="$t('calendar.settings')"
          :style="{ flex: menuCollapsed ? 0 : 1 }"
          @close="menuCollapsed = true"
        >
          <div
            style="width: 100%; padding: 8px 0; cursor: pointer;"
            @click="fetchAll"
          >
            <a-icon type="reload"/>
            <Transition name="menu-collapse">
              <span
                v-if="!menuCollapsed"
                style="padding: 0 8px; "
              >
                {{ $t(`_.update`) }}
              </span>
            </Transition>
          </div>
          <a-divider type="horizontal"/>
          <a-form-item
            v-if="isSmallViewport"
            :label="$t('_.view')"
          >
            <a-select
              v-model="calendarView"
              style="width: 120px"
              @change="handleViewChange"
            >
              <a-select-option
                v-for="(view, viewIndex) in availableViews"
                :key="viewIndex"
                :value="getViewByIdentifier(view)"
              >
                {{ $t(`calendar.` + view) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-divider
            v-if="isSmallViewport"
            type="horizontal"
          />
          <a-spin
            v-if="Object.keys(sidebarFilters).length === 0"
          />
          <div v-else>
            <div
              v-for="(fields, moduleIdentifier) in sidebarFilters"
              :key="moduleIdentifier"
              style="transition: margin .2s"
              :style="{ marginBottom: menuCollapsed ? 0 : '14px' }"
            >
              <Transition name="menu-collapse">
                <h3 v-if="!menuCollapsed">
                  <strong>{{ $t(`modules.${moduleIdentifier}.title`) }}</strong>
                </h3>
              </Transition>
              <div
                v-for="(field, fieldKey) in fields"
                :key="fieldKey"
                style="width: 100%; display: flex; padding: 4px 0;"
              >
                <a-checkbox
                  :key="'field-' + fieldKey"
                  v-model="selectedFields[fieldKey]"
                  style="margin-left: 0; width: 300%;"
                  :style="{
                    '--checkbox-color': field.color || field.options.calendar_color || '#1890ff'
                  }"
                  class="calendar-checkbox"
                  @change="updateSelectedFields(fieldKey)"
                >
                  <Transition name="menu-collapse">
                    <span
                      v-if="!menuCollapsed"
                      class="menu-collapse-item"
                      style="white-space: pre"
                    >
                      {{
                        field.eventDef ? $t(`calendar.${field.eventDef.identifier}`) : $t(`modules.${moduleIdentifier}.fields.${field.identifier}`)
                      }}
                    </span>
                  </Transition>
                </a-checkbox>
                <Transition name="menu-collapse">
                  <div
                    v-if="!menuCollapsed"
                    style="text-align: right"
                  >
                    <a
                      v-if="field._filters"
                      @click="deleteFilters(field)"
                    >
                      <small>{{ $t('_.reset_view_filter') }}</small>
                    </a>
                    <a-divider
                      v-if="field._filters"
                      type="vertical"
                    />
                    <a-icon
                      :type="field.loading ? 'loading' : 'filter'"
                      :class="{'ant-btn-link': !!field._filters || field.loading}"
                      @click="openFilterDrawer(field)"
                    />
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </component>
        <div
          style="position: relative; width: 100%"
          :style="{ flex: isSmallViewport ? 'auto' : 3 }"
        >
          <FullCalendar
            ref="Calendar"
            :options="calendarOptions"
          >
            <template
              v-if="componentEventsDef"
              #eventContent="arg"
            >
              <div
                v-if="arg.event.extendedProps.props.html"
                v-html="arg.event.extendedProps.props.html"
              />
              <div
                v-else
                style="display: flex; justify-content: space-between; align-items: center"
              >
                <span>
                  <span
                    v-if="arg.view.type.includes('dayGrid') && arg.event.extendedProps.displayEventTime"
                    class="fc-daygrid-event-dot"
                    :style="{borderColor: arg.event.borderColor}"
                  />
                  <span
                    v-if="arg.timeText"
                    class="fc-event-time"
                  >{{ arg.timeText }}</span>
                  <span class="fc-event-title-container">
                    <span class="fc-event-title fc-sticky">
                      {{ arg.event.title }}
                    </span>
                  </span>
                </span>
                <a-icon
                  v-if="arg.event.extendedProps.props.icon"
                  :type="arg.event.extendedProps.props.icon"
                />
              </div>
            </template>
          </FullCalendar>
        </div>
      </div>
      <a-drawer
        :closable="true"
        :visible="showDrawerData.visible"
        height="85vh"
        placement="bottom"
        @close="closeShowResourceDrawer"
      >
        <div
          :style="{ backgroundColor: showDrawerData.color }"
          class="resource-color-indicator"
          style="width: 100%; height: 3px"
        />
        <div style="padding: 16px;">
          <module-show
            :key="showDrawerData.resourceIdentifier"
            :module-identifier="showDrawerData.moduleIdentifier"
            :resource-identifier="showDrawerData.resourceIdentifier"
            component-mode
          />
        </div>
      </a-drawer>
      <a-drawer
        :visible="!!openedFilter"
        placement="bottom"
        height="70vh"
        @close="openedFilter = false"
      >
        <div
          v-if="openedFilter"
          style="padding: 16px"
        >
          <div style="float: right">
            <a-button
              type="danger"
              icon="close"
              style="margin-right: 16px"
              @click="openedFilter = false"
            >
              {{ $t('_.cancel') }}
            </a-button>
            <a-button
              type="primary"
              icon="check"
              @click="applyFilter"
            >
              {{ $t('_.apply_view_filter') }}
            </a-button>
          </div>
          <h1>
            {{ $t(`_.filter`) }}: {{ $t(`modules.${openedFilter.module.identifier}.title`) }}
          </h1>
          <filters
            v-model="filterPrototype"
            :module="openedFilter.module"
          />
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import allLocales from '@fullcalendar/core/locales-all'
import { apiLink, isScreenMobile } from '@/utils'
import store from '@/store'
import ModuleShow from '@/views/ModuleShow'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Filters from '@/components/Filters'
import duration from 'dayjs/plugin/duration'
import Module from '@/module/module'
import Filter from '@/components/filter'
import _ from 'lodash'
import { apiGet, apiPut } from '@/api'
import HasPreFilters from '../HasPreFilters'
import { recipes } from '../../recipes'
import { handleToast } from '../../utils'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

export default {
  name: 'LayoutComponentCalendar',
  components: {
    FullCalendar,
    ModuleShow,
    Filters,
  },
  extends: LayoutComponent,
  mixins: [HasPreFilters],
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      calendarEvents: [],
      calendarView: '',
      createModules: [],
      filterFields: {},
      filterPrototype: [],
      isScreenMobile: isScreenMobile(),
      loading: false,
      menuCollapsed: true,
      nodata: false,
      openedFilter: false,
      quickCreateModule: {},
      selectedFields: {},
      showDrawerData: {
        visible: false,
        buttons: [],
        color: '#000',
        moduleIdentifier: '',
        resourceIdentifier: 0,
      },
      title: '',
    }
  },
  computed: {
    availableViews () {
      return this.options?.available_views ?? [
        'year',
        'month',
        'week',
        'day',
        'list',
      ]
    },
    buttonComponent () {
      let button = []
      let dropdownItems = []
      this.createModules.forEach(module => {
        dropdownItems.push({
          identifier: module.identifier,
          title: 'modules.' + module.identifier + '.title',
          link: '/' + this.$store.state.currentLocale + '/' + module.identifier + '/create',
          icon: module.icon,
        })
      })
      button.push({
        title: '_.create',
        items: dropdownItems,
        style: 'primary',
      })
      return {
        type: 'buttons',
        options: {
          buttons: button,
          justify_content: 'flex-end',
        },
      }
    },
    calendarApi () {
      return this.$refs.Calendar.getApi()
    },
    calendarOptions () {
      return {
        datesSet: this.updateTitle,
        editable: this.options.editable ?? true,
        events: this.calendarEvents,
        headerToolbar: {
          start: '',
          center: '',
          end: '',
        },
        height: this.options.height || '80vh',
        locale: this.$store.state.currentLocale,
        locales: allLocales,
        navLinks: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin],
        selectMirror: true,
        // selectable: true,
        unselectAuto: false,
        views: {
          dayGridYear: {
            slotDuration: { months: 1 },
            type: 'timeline',
            duration: { months: 12 },
            buttonText: 'Year',
          },
          ...(this.options.custom_views || {}),
        },
        initialView: this.getViewByIdentifier(this.defaultView),
        navLinkDayClick: date => this.handleViewChange('timeGridDay', date),
        navLinkWeekClick: date => this.handleViewChange('timeGridWeek', date),
        weekNumbers: this.options && this.options.week_numbers,
        eventDrop: this.updateEvent,
        eventResize: this.updateEvent,
        eventClick: this.openShowResourceDrawer,
      }
    },
    componentEventsDef () {
      return this.options.events
    },
    defaultView () {
      return this.options?.default_view ?? 'month'
    },
    isSmallViewport () {
      if (this.loading) return false
      return this.isScreenMobile || (this.$refs.CalendarWrapper && this.$refs.CalendarWrapper.clientWidth < 768)
    },
    showFilterSidebar () {
      return this.options.filter_sidebar !== false
    },
    showNavButtons () {
      return this.options.nav_buttons !== false
    },
    sidebarFilters () {
      let filters = {}
      if (Object.keys(this.filterFields).length > 0) {
        for (let item of Object.values(this.filterFields)) {
          const moduleIdentifier = item.module.identifier
          if (typeof filters[moduleIdentifier] !== 'object') {
            filters[moduleIdentifier] = {
              [item.id]: item,
            }
          } else {
            filters[moduleIdentifier][item.id] = item
          }
        }
      }
      return filters
    },
  },
  watch: {
    resource: {
      deep: true,
      handler: function () {
        if (this.component) {
          for (let field of Object.keys(this.selectedFields)) {
            if (this.selectedFields[field] && this.filterFields[field].eventDef.pre_filters) {
              this.fetchEvents(field, true)
            }
          }
        }
      },
    },
    menuCollapsed () {
      window.setTimeout(
        () => {
          this.calendarApi.render()
        },
        300
      )
    },
  },
  created () {
    this.calendarView = this.getViewByIdentifier(this.defaultView)
    window.addEventListener('resize', () => {
      if (Array.isArray(this.fields) && this.fields.length > 0) {
        this.checkFixedActions()
      }
    })
  },
  mounted () {
    this.loading = true
    if (this.componentEventsDef) {
      this.componentEventsDef.forEach(event => {
        const moduleDef = this.$store.getters.getModuleByIdentifier(event.module)
        if (!moduleDef) return
        const fieldDef = moduleDef.fieldMap[event.field]
        if (!fieldDef) return
        const fieldId = event.identifier || fieldDef.id // backwards compability
        this.selectedFields[fieldId] = true
        this.$set(this.filterFields, fieldId, {
          id: fieldId,
          fieldDef,
          color: event.color,
          eventDef: event,
          module: moduleDef,
        })
      })
      this.fetchAll()
      this.loading = false
      return
    }
    this.fetchSetupData().then(response => {
      this.filterFields = {}
      for (let field of response.data.fields) {
        this.filterFields[field.id] = field
        this.selectedFields[field.id] = true
      }
      this.fetchAll()
      this.loading = false
    }).catch(() => {
      this.nodata = true
    })
  },
  methods: {
    addEvent (newEvent) {
      let event = this.calendarEvents.find(item => item.id === newEvent.id)
      if (event) {
        event = newEvent
      } else {
        this.calendarEvents.push(newEvent)
      }
    },
    anyEvent ($event) {
      this.$emit('event', $event)
    },
    applyFilter () {
      this.openedFilter._filters = _.cloneDeep(this.filterPrototype)
      this.fetchEvents(this.openedFilter.id, true)
      this.openedFilter = false
    },
    closeShowResourceDrawer () {
      this.showDrawerData.visible = false
    },
    deleteFilters (field) {
      if (!field) {
        field = this.openedFilter
      }
      delete field._filters
      this.fetchEvents(field.id, true)
      this.openedFilter = false
    },
    fetchEvents (filterFieldId, reload = false) {
      if (this.componentEventsDef) {
        // if the calendar is used as a layout component, the events will be fetched from the standard index route
        this.fetchEntities(filterFieldId, reload)
      } else {
        // otherwise, the "/calendar" routes will be used (legacy)
        this.fetchCalendarEventsByField(filterFieldId, reload)
      }
    },
    fetchSetupData () {
      const url = new URL(apiLink(['calendar'], store.state.currentSystem))
      return apiGet(url)
        .then(response => response.json())
        .then(response => {
          this.createModules = response.data.create_modules
          this.quickCreateModule = response.data.quick_create_module
          this.loading = false
          return response
        })
        .catch(console.error)
    },
    fetchCalendarEventsByField (fieldId, reload = false) {
      const url = new URL(apiLink(['calendar', fieldId], store.state.currentSystem))
      const range = this.calendarApi.currentData.dateProfile.activeRange
      const urlParams = {
        start_date: dayjs(range.start).format('YYYY-MM-DD'),
        end_date: dayjs(range.end).format('YYYY-MM-DD'),
      }

      if (this.filterFields[fieldId]._filters) {
        const filters = new Filter(this.filterFields[fieldId]._filters)
        urlParams.filters = JSON.stringify(filters.toRequest())
      }

      Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))
      return apiGet(url)
        .then(response => response.json())
        .then(response => {
          if (reload) this.removeEvents(fieldId)
          this.parseLegacyCalendarEvents(fieldId, response.data)
          return response
        })
        .catch(console.error)
    },
    fetchEntities (fieldId, reload = false) {
      const filter = this.filterFields[fieldId]
      const eventDef = filter.eventDef
      const range = this.getActiveRange()

      if (!filter.rendered) {
        this.$set(filter, 'rendered', [])
      }
      if (!reload && filter.rendered) {
        for (const renderedRange of filter.rendered) {
          if (renderedRange.start <= range.start && renderedRange.end >= range.end) return
        }
      }
      filter.rendered.push(range)

      this.$set(filter, 'loading', true)

      const url = new URL(apiLink(['table'], store.state.currentSystem))

      let urlParams = {
        module: eventDef.module,
        results: -1,
        filters: JSON.stringify(this.getViewFilterByIdentifier(fieldId)),
      }

      if (eventDef.pre_filters) {
        urlParams.pre_filters = JSON.stringify(this.getFiltersWithContext(eventDef.pre_filters, this.resource, this.context))
      }

      Object.keys(urlParams)
        .forEach(paramKey => url.searchParams.append(paramKey, urlParams[paramKey]))

      return apiGet(url)
        .then(response => response.json())
        .then(response => {
          if (reload) this.removeEvents(fieldId)
          this.parseCalendarEvents(fieldId, response.resources.data)
          this.$set(filter, 'loading', false)
        })
    },
    getActiveRange () {
      return this.calendarApi.currentData.dateProfile.activeRange
    },
    getViewByIdentifier (identifier) {
      switch (identifier) {
        case 'month':
          return 'dayGridMonth'
        case 'year':
          return 'dayGridYear'
        case 'day':
          return 'timeGridDay'
        case 'week':
          return 'timeGridWeek'
        case 'list':
          return 'listMonth'
        default:
          return identifier
      }
    },
    getViewFilterByIdentifier (fieldId) {
      const filter = this.filterFields[fieldId]
      const field = filter.fieldDef
      const eventDef = filter.eventDef
      const recipes = eventDef?.recipes
      const start = dayjs(this.getActiveRange().start)
      const end = dayjs(this.getActiveRange().end)
      const diff = end.diff(start)

      let output = [[]]

      // For smoother navigation and to hide loading times the previous and next timeframe are also fetched
      const startDate = start.subtract(diff).format('YYYY-MM-DD')
      const endDate = end.add(diff).format('YYYY-MM-DD')

      switch (field.type) {
        case 'dateRange':
          if (eventDef.recurrence || recipes?.recurrence) {
            // if the event has a recurrence definition set, we will fetch all events that begin before the current timeframe,
            // because we cannot determine the exact timeframe the events will be in. We only know that the recurring events might
            // be in the current timeframe when the start date is before it.
            output = [
              [
                {
                  field: field.identifier + '.start',
                  operator: '<',
                  value: endDate,
                },
              ],
            ]
          } else {
            output = [
              [
                // when the start date lies within the visible timeframe
                {
                  field: field.identifier + '.start',
                  operator: '>',
                  value: startDate,
                },
                {
                  field: field.identifier + '.start',
                  operator: '<',
                  value: endDate,
                },
              ],
              [
                // when the end date lies within the visible timeframe
                {
                  field: field.identifier + '.end',
                  operator: '>',
                  value: startDate,
                },
                {
                  field: field.identifier + '.end',
                  operator: '<',
                  value: endDate,
                },
              ],
              [
                // or the start and end dates encloses the visible timeframe
                {
                  field: field.identifier + '.start',
                  operator: '<',
                  value: startDate,
                },
                {
                  field: field.identifier + '.end',
                  operator: '>',
                  value: endDate,
                },
              ],
            ]
          }
          break
        case 'datetime':
        case 'date':
          if (eventDef.recurrence || recipes?.recurrence) {
            output = [
              [
                {
                  field: field.identifier,
                  operator: '<',
                  value: endDate,
                },
              ],
            ]
          } else {
            output = [
              [
                {
                  field: field.identifier,
                  operator: '>',
                  value: startDate,
                },
                {
                  field: field.identifier,
                  operator: '<',
                  value: endDate,
                },
              ],
            ]
          }
      }
      if (filter._filters) {
        const filters = new Filter(filter._filters).toRequest()
        // merge filters from sidebar with timeframe filter
        output = output.flatMap(and => filters.map(filterAnd => [...and, ...filterAnd]))
      }
      return output
    },
    handleCalendarControls (e) {
      this.calendarApi[e]()
      this.fetchAll(false)
    },
    handleViewChange (value, date) {
      this.calendarView = value
      this.calendarApi.changeView(value, date)
      this.fetchAll(false)
    },
    openFilterDrawer (field) {
      field.module = new Module(field.module)
      this.openedFilter = this.filterFields[field.id]
      this.filterPrototype = _.cloneDeep(this.openedFilter._filters)
    },
    openShowResourceDrawer (e) {
      this.showDrawerData.resourceIdentifier = null
      this.$nextTick(() => {
        const props = e.event.extendedProps
        this.showDrawerData.moduleIdentifier = props.moduleIdentifier
        this.showDrawerData.resourceIdentifier = props.entityId || props.calendarEvent.resource_id
        this.showDrawerData.color = e.event.backgroundColor
        this.showDrawerData.visible = true
      })
    },
    parseCalendarEvents (fieldId, events) {
      const field = this.filterFields[fieldId].fieldDef
      const eventDef = this.filterFields[fieldId].eventDef

      for (let entity of events) {
        const eventFieldData = entity[field.identifier]
        let start = eventFieldData
        let end = eventFieldData
        if (field.type === 'dateRange') {
          start = eventFieldData.start
          end = eventFieldData.end
        }
        start = dayjs(start)
        end = dayjs(end)

        let recipeValues = {}

        if (eventDef.recipes) {
          // evaluate recipes from the layout component config
          recipeValues = recipes(eventDef.recipes, { _context: this.resource, ...entity })
        }
        const props = {
          ...eventDef,
          ...recipeValues,
        }
        const isFullDay = field.type === 'date' || eventFieldData.fullDay || props.full_day || false

        const event = {
          id: fieldId + '-' + entity.id,
          start: start.toDate(),
          end: end.toDate(),
          editable: props.editable ?? true,
          allDay: isFullDay,
          title: props.title || '',
          color: props.color,
          textColor: props.text_color,
          displayEventTime: true,
          classNames: ['event-' + fieldId],
          extendedProps: {
            entityId: entity.id,
            eventDef,
            field,
            moduleIdentifier: eventDef.module,
            identifier: fieldId,
            props,
          },
        }
        if (props.recurrence) {
          const recurrence = props.recurrence
          if (typeof recurrence === 'object') {
            event.rrule = {
              dtstart: start.toISOString(),
              ...recurrence,
            }
          } else if (typeof recurrence === 'string') {
            event.rrule = {
              freq: props.recurrence,
              dtstart: start.toISOString(),
            }
          }
        }

        this.addEvent(event)
      }
    },
    parseLegacyCalendarEvents (fieldId, events) {
      for (let item of events) {
        const start = item.start
        let end = item.end
        if (item.fullDay) {
          end = new Date(end)
          end.setDate(end.getDate() + 1)
        }
        const module = this.$store.getters.getModuleById(item.field.module_id)
        const event = {
          id: fieldId + '-' + item.id,
          start: start,
          end: end,
          allDay: item.fullDay,
          title: item.title,
          color: item.field.options.calendar_color || item.field.options.calendar_color,
          displayEventTime: true,
          extendedProps: {
            calendarEvent: item,
            moduleIdentifier: (module ? module.identifier : ''),
            identifier: fieldId,
          },
        }
        if (item.field.options.calendar_recurrence) {
          event.rrule = {
            freq: item.field.options.calendar_recurrence,
            dtstart: start,
          }
        }
        this.addEvent(event)
      }
    },
    fetchAll (reload) {
      for (let field of Object.keys(this.selectedFields)) {
        if (this.selectedFields[field]) {
          this.fetchEvents(field, reload)
        }
      }
    },
    removeEvents (fieldId) {
      this.filterFields[fieldId].rendered = []
      this.calendarEvents = this.calendarEvents.filter(event => event.extendedProps.identifier !== fieldId)
    },
    updateEvent (eventInfo) {
      eventInfo.event._def.ui.classNames = ['event-loading']
      this.$forceUpdate()

      const props = eventInfo.event.extendedProps

      const calendarEvent = props.eventDef || props.calendarEvent
      const field = props.field || calendarEvent.field
      const entityId = props.entityId || calendarEvent.resource_id

      let dayStart = dayjs(eventInfo.event.start)
      let dayEnd = dayjs(eventInfo.event.end)

      if (calendarEvent.recurrence) {
        const delta = dayjs.duration(eventInfo.delta)
        const startDelta = dayjs.duration(eventInfo.startDelta)
        const endDelta = dayjs.duration(eventInfo.endDelta)

        dayStart = dayjs(calendarEvent.start)
          .add(startDelta.isValid() ? startDelta : delta)
        dayEnd = dayjs(calendarEvent.end)
          .add(endDelta.isValid() ? endDelta : delta)
      }

      let data = ''
      switch (field.type) {
        case 'date':
          data = dayStart.format('YYYY-MM-DD')
          break
        case 'datetime':
          data = dayStart.toISOString()
          break
        case 'time':
          data = dayStart.format('HH:mm:ss')
          break
        case 'dateRange':
          data = {
            start: dayStart.format('YYYY-MM-DD HH:mm:ss'),
            end: dayEnd.format('YYYY-MM-DD HH:mm:ss'),
            fullDay: eventInfo.event._def.allDay,
          }
          break
        default:
          return null
      }

      apiPut(
        ['modules', props.moduleIdentifier, 'resources', entityId],
        {},
        JSON.stringify({
          [field.identifier]: data,
        }),
        {
          'Content-Type': 'application/json',
        }
      )
        .then(response => {
          if (response.success) {
            this.parseCalendarEvents(props.identifier, response.data)
          }
          // eventInfo.event._def.ui.classNames = []
          this.$forceUpdate()
        })
        .catch(() => {
          handleToast({
            toast_type: 'error',
          })

          eventInfo.event._def.ui.classNames = []
          this.$forceUpdate()
        })
    },
    updateSelectedFields (e) {
      if (this.selectedFields[e]) {
        this.fetchEvents(e)
      } else {
        this.removeEvents(e)
      }
      this.$forceUpdate()
    },
    updateTitle (e) {
      this.title = e.view.title
    },
  },
}
</script>

<style scoped>
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/list/main.css';

.resource-color-indicator {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
}

.calendar-wrapper {
  flex-wrap: wrap;
}
</style>

<style lang="scss">
.calendar-checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--checkbox-color);
    border-color: rgba(0, 0, 0, 0.3);
  }
}

#calendar {
  --fc-today-bg-color: rgb(2, 117, 216, 0.15);

  .ant-collapse-header {
    display: flex;
    align-items: center;
  }

  .ant-collapse-extra {
    flex: 1;
    padding-left: 14px;
  }

  .ant-radio-button-wrapper {
    padding: 0 10px;
  }

  .fc-list {
    .fc-list-day > * {
      z-index: 1;
    }
  }

  .fc-timegrid {
    .fc-event-title-container {
      display: block;
    }
  }

  .fc-event:not(.fc-list-event) {
    border-radius: 0 !important;
    padding: 2px 5px !important;
    transition: opacity 0.4s, background-color 0.4s;
    display: flex;
    align-items: center;

    .fc-event-main {
      width: 100%;
    }

    &.event-loading {
      opacity: 0.75;
    }
  }

  .filter-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 12px;
  }

  .fc-daygrid-event-dot {
    display: none;
  }

  .fc-daygrid-dot-event {
    .fc-daygrid-event-dot {
      display: inline-block;
    }
  }

  .menu {
    height: 100%;
    flex: 1;
    border-right: none;
    padding: 0 14px;
    margin-top: 1.5em;
    transition: flex .3s;

    .menu-collapse-item {
      display: inline-block;
    }

    .menu-collapse-enter-active, .menu-collapse-leave-active {
      transition: all .2s;
    }

    .menu-collapse-enter, .menu-collapse-leave-to {
      opacity: 0;
      transform: translateX(-10px);
    }

    .ant-checkbox + span:empty {
      padding: 0;
    }
  }
}
</style>
