import { BaseChildRecipeHandler, BaseRecipeHandler } from '@/recipes/base'
import Vue from 'vue'

export class EntityRecipeHandler extends BaseRecipeHandler {
  entity
  field

  constructor (entity, field) {
    super()
    this.entity = entity
    this.field = field
  }

  getValue () {
    return this.entity[this.field.identifier]
  }

  putValue (value) {
    Vue.set(this.entity, this.field.identifier, value)
  }

  getChildHandler () {
    return new EntityListItemRecipeHandler(this)
  }
}

export class EntityListItemRecipeHandler extends BaseChildRecipeHandler {
  entityHandler

  /**
   * @param {EntityRecipeHandler} entityHandler
   */
  constructor (entityHandler) {
    super()
    this.entityHandler = entityHandler
  }

  hasItems () {
    let value = this.entityHandler.getValue()
    return value && value.length > 0 && this.entityHandler.field.type === 'list'
  }

  getItems () {
    return this.entityHandler.field.getItems()
  }

  getValue (item) {
    return item.value
  }

  putValue (item, index, value) {
    const identifier = this.entityHandler.field.identifier
    if (this.entityHandler.entity[identifier]) {
      this.entityHandler.entity[identifier][index] = value
    }
  }

  getChildren (item) {
    if (item.layout) {
      return Array.from(item.layout.fields()).map(field => ({
        ...field,
        handler: new EntityRecipeHandler(item.value, field),
      }))
    }
    return item.fields.map(field => ({
      ...field,
      handler: new EntityRecipeHandler(item.value, field),
    }))
  }

  getScopedSymbols (item, index) {
    return { i: item.value.index }
  }
}
