<template>
  <FileUpload
    :default-file-list="out_value"
    :module="fileModule"
    :multiple="hasMultiple"
    :placeholder="options.placeholder"
    :doc-scan="options.scan"
    :delete-old-files="!hasMultiple"
    @change="updateUploadField"
  />
</template>

<script>
import FieldFile from '@/components/fields/file/FieldFile'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'FieldUpload',
  components: {
    FileUpload,
  },
  extends: FieldFile,
  methods: {
    updateUploadField (eventData) {
      let data = []
      if (this.hasMultiple) {
        eventData.fileList.forEach(file => {
          if (file.status === 'done') {
            data.push(file.response.data)
          }
        })
        this.out_value = data
      } else {
        if (eventData.file.status === 'done') {
          this.out_value = eventData.file.response.data
        }
      }
      if (eventData.fileList === null || !this.out_value || this.out_value.length === 0) {
        this.out_value = null
      }
    },
  },
}
</script>

<style lang="scss">
.ant-upload-list-item-info {
  position: relative;

  .anticon-loading, .anticon-paper-clip {
    top: auto;
    line-height: 1.325rem
  }
}
</style>
