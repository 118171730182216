<template>
  <a-spin v-if="loading"/>
  <ResourceTableComponent
    v-else
    v-bind="$props"
    :element-identifier="newLayoutPath"
    v-on="$listeners"
  />
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import ResourceTableComponent from '@/components/ResourceTableComponent'

export default {
  name: 'LayoutComponentResourceTable',
  components: { ResourceTableComponent },
  extends: LayoutComponent,
  props: {
    maxSelections: {
      type: Number,
      default: undefined,
    },
  },
  data () {
    return {
      loading: false,
      manyErrors: false,
    }
  },
  computed: {
    layoutElementIdentifier () {
      if (!this.component) {
        return `${this.module.identifier}.index`
      }
      return this.component.identifier || this.component.frontendIndex
    },
  },
}
</script>

<style scoped>

</style>
