class Widgets {
  widgets = {}
  listeners = {}

  register (identifier, widget) {
    this.widgets[identifier] = widget
  }

  listen (identifier, callback) {
    if (!this.listeners[identifier]) {
      this.listeners[identifier] = []
    }
    this.listeners[identifier].push(callback)
  }

  broadcast (identifier, ...args) {
    const listeners = this.listeners[identifier] || []
    listeners.forEach(func => {
      func(...args)
    })
  }

  get (identifier) {
    return this.widgets[identifier]
  }
}

const widgets = new Widgets()

export class WidgetObject {
  identifier
  listeners = {}

  constructor (identifier) {
    this.identifier = identifier
  }

  setComponent (component) {
    this.component = component
  }

  on (identifier, callback) {
    if (!this.listeners[identifier]) {
      this.listeners[identifier] = []
    }
    this.listeners[identifier].push(callback)
  }

  listen (identifier, callback) {
    widgets.listen(identifier, callback)
  }

  emit (identifier, ...args) {
    const listeners = this.listeners[identifier] || []
    listeners.forEach(func => {
      func(...args)
    })
  }

  broadcast (identifier, ...args) {
    widgets.broadcast(identifier, ...args)
  }

  register () {
    widgets.register(this.identifier, this)
  }
}
