/*
  Structure:
  {
    <type>: {
      <type settings, e.g. "icon">,
      subtypes: {
        <subtype>: {
          <type settings>
        }
    }
  }
*/
export default
{
  application: {
    subtypes: {
      doc: {
        icon: 'file-word',
        color: '#185abc',
      },
      gzip: {
        icon: 'file-zip',
        color: '#fdd25b',
      },
      msword: {
        icon: 'file-word',
        color: '#185abc',
      },
      'octet-stream': {
        icon: 'file-text',
      },
      pdf: {
        icon: 'file-pdf',
        color: 'red',
      },
      'vnd.ms-excel': {
        icon: 'file-excel',
        color: '#107c41',
      },
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        icon: 'file-excel',
        color: '#107c41',
      },
      'vnd.openxmlformats-officedocument.wordprocessingml.document': {
        icon: 'file-word',
        color: '#185abc',
      },
      'vnd.openxmlformats-officedocument.presentationml.presentation': {
        icon: 'file-ppt',
        color: '#c33e1c',
      },
      zip: {
        icon: 'file-zip',
        color: '#fdd25b',
      },
    },
    icon: 'file-unknown',
  },
  audio: {
    icon: 'sound',
  },
  font: {
    icon: 'font-size',
  },
  image: {
    icon: 'picture',
  },
  text: {
    icon: 'file-text',
    subtypes: {
      html: {
        icon: 'html5',
        color: 'red',
      },
    },
  },
  video: {
    icon: 'video-camera',
  },
}
