<template>
  <a :href="'tel:' + value">
    {{ value }}
  </a>
</template>

<script>
import FieldViewer from '@/components/fields/FieldViewer'

export default {
  name: 'FieldViewerTel',
  extends: FieldViewer,
}
</script>

<style scoped>

</style>
