import { apiGet, apiPost } from '../index'

/**
 * API module for files.
 */
export default class Files {
  getFile (fileId, size = null) {
    let sizeString = (size ? '?size=' + size : '')
    return new Promise((resolve, reject) => {
      apiGet(['files', fileId + sizeString])
        .then(response => {
          if (!response) {
            throw new Error('response is undefined')
          }
          if (!response.ok) {
            throw new Error('' + response.status)
          }
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  }

  createDirectory (params) {
    return apiPost(['files'], '', JSON.stringify({
      ...params,
      directory: true,
    }), { 'Content-Type': 'application/json' })
      .then(response => response.json())
      .then(response => {
        if (!response.success) {
          throw new Error(response.errors)
        }
        return response
      })
      .catch(console.error)
  }

  uploadFile (file) {
    let formData = new FormData()
    formData.append('file', file.file, file.name)
    if (file.module) {
      if (typeof file.module === 'string') {
        formData.append('module', file.module)
      } else {
        formData.append('module', file.module.identifier)
      }
    }
    if (file.virtualPath) {
      formData.append('path', file.virtualPath)
    }
    return apiPost(['files'], {}, formData)
  }

  /**
   * Upload files to the API files route.
   *
   * @param files list of ApiFile objects
   */
  uploadFiles (files) {
    const uploads = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      uploads.push(this.uploadFile(file))
    }
    return Promise.all(uploads)
  }
}
