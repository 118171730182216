<template>
  <div/>
</template>

<script>
import { Editor } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import TableRow from '@tiptap/extension-table-row'
import Image from '@tiptap/extension-image'
import Media from '@/components/fields/wysiwyg/extensions/media'
import Subscript from '@tiptap/extension-subscript'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import _ from 'lodash'
import Underline from '@tiptap/extension-underline'
import BrezelLink from '@/components/fields/wysiwyg/extensions/brezel-link'

export default {
    name: 'EditorMixin',
    props: {},
    data () {
        const imageModuleName = _.get(this.field, 'options.imageModule', 'files')
        const imageModule = this.$store.getters.getModuleByIdentifier(imageModuleName)
        const imageDirectory = _.get(this.field, 'options.imageDirectory', '/')
        return {
            editor: new Editor({
                useBuiltInExtensions: false,
                extensions: [
                    StarterKit,
                    Link,
                    Table,
                    TableHeader,
                    TableCell,
                    TableRow,
                    Image,
                    Color,
                    Highlight.configure({
                        multicolor: true,
                    }),
                    BrezelLink,
                    Media.extend({
                        addOptions: {
                            ...Media.options,
                            module: imageModule,
                            directory: imageDirectory,
                        },
                        addStorage () {
                            return {
                                directory: imageDirectory,
                            }
                        },
                    }),
                    Subscript,
                    TextStyle,
                    Underline,
                ],
                editable: this.editable,
                onUpdate: (state) => {
                    this.out_value = this.editor.getHTML()
                    this.updatedFromEditor = true
                },
            }),
        }
    },
    computed: {
        imageModule () {
            return this.$store.getters.getModuleByIdentifier(this.options.imageModule || 'files')
        },

        imageDirectory () {
            return this.options.imageDirectory || '/'
        },
    },
    mounted () {
        this.$nextTick(function () {
            this.editor.storage.media.directory = _.get(this.field, 'options.imageDirectory', '/')
        })
    },
}
</script>

<style scoped>

</style>
