// API functions

import { apiLink } from '@/utils'
import store from '@/store'
import { EventBus } from '@/event-bus'
import { openWebhookResponse } from '@/webhooks'
import Files from '@/api/files'
import Module from '@/module/module'
import Entity from '@/api/entity'
import BrezelEvent from '@/api/event'
import Error from '@/components/Error'
import ApiKey from '@/api/key'

export const apiCall = (method = 'GET', path, params = {}, body = undefined, headers = {}) => {
  const url = path instanceof URL ? path : apiLink(path, store.state.currentSystem, params)
  return fetch(url, {
    headers: {
      'Accept-Language': store.state.currentLocale,
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      'X-Brezel-Frontend': window.location.href,
      ...headers,
    },
    credentials: 'include', // send and store cookies from API, e.g. to swap access tokens with sessions for redirect flows
    method,
    body,
  })
}

export const apiGet = (path, params, body, headers) => apiCall('GET', path, params, body, headers)
export const apiPut = (path, params, body, headers) => apiCall('PUT', path, params, body, headers)
export const apiPost = (path, params, body, headers) => apiCall('POST', path, params, body, headers)
export const apiPatch = (path, params, body, headers) => apiCall('PATCH', path, params, body, headers)
export const apiDelete = (path, params, body, headers) => apiCall('DELETE', path, params, body, headers)

export const fetchLayout = (identifier) => {
  return apiGet(['layouts', identifier])
    .then(response => response.json())
}

export const fetchLayouts = () => {
  return apiGet(['layouts'])
    .then(response => response.json())
    .then(layouts => {
      store.commit('setLayouts', layouts)
      return layouts
    })
    .catch(console.error)
}

export const fetchModuleLayout = (moduleIdentifier, layoutType) => {
  return apiGet(['modules', moduleIdentifier, 'layouts', layoutType])
    .then(response => {
      if (response.status !== 200) {
        EventBus.$emit('showErrorOrInformation', [true, response.status])
        throw Error
      }
      return response
    })
    .then(response => response.json())
    .then(layout => {
      return layout
    })
    .catch(console.error)
}

export const fetchResource = (moduleIdentifier, resourceId) => {
  return apiGet(['modules', moduleIdentifier, 'resources', resourceId])
    .then(response => {
      if (response.status !== 200) {
        EventBus.$emit('showErrorOrInformation', [true, response.status])
        throw Error
      }
      return response
    })
    .then(response => response.json())
}

export const printEntity = (entity) => {
  if (entity.module.print_view_id) {
    return apiGet(['modules', entity.module.identifier, 'resources', entity.id, 'print'])
      .then(openWebhookResponse)
      .then(fileView => fileView.print())
  }
  window.print()
}

function resolveModule (key) {
  if (typeof key === 'string') {
    return store.getters.getModuleByIdentifier(key)
  }
  if (typeof key === 'number') {
    return store.getters.getModuleById(key)
  }
  if (key instanceof Module) {
    return key
  }
  if (key !== null && key !== undefined && typeof key === 'object') {
    return new Module(key)
  }
  throw new Error(`Could not resolve module for key [${key}]`)
}

export default class Api {
  static files () {
    return Api.prototype._files
  }

  static getModule (module) {
    return resolveModule(module)
  }

  static getModules () {
    return store.state.modules
  }

  static getUser () {
    return store.getters.user
  }

  /**
   * Fire an event by identifier for a given module (optional) and entity (optional).
   *
   * @param identifier
   * @param module
   * @param entity
   * @param data
   * @param localArgs
   * @return {*|Promise<*>}
   */
  static fireEvent (identifier, module = null, entity = null, data = {}, localArgs = {}) {
    // Do not look the event up. If it does not exists, event.fire() is rejected with a 404.
    const event = new BrezelEvent({ identifier, module })
    return event.fire(entity, data, localArgs)
  }

  static getEvent (identifier) {
    // Look the event up and try to fetch it if it does not exist.
    const event = store.getters.getEvent(identifier)
    if (!event) {
      return apiGet(['events', identifier])
        .then(response => response.json())
        .then(response => {
          const event = new BrezelEvent(response)
          store.commit('putEvent', event)
          return event
        })
    }
    return Promise.resolve(event)
  }

  static fetchModules (layouts = false) {
    return apiGet(['modules'], { layouts })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('accessToken')
          window.dispatchEvent(new Event('accessTokenChange'))
          throw new Error('' + response.status)
        }
        return response
      })
      .then(response => {
        if (response.status !== 200) {
          EventBus.$emit('showErrorOrInformation', [true, response.status])
          throw Error
        }
        return response
      })
      .then(response => response.json())
      .then((modules) => {
        store.commit('setModules', modules.map(module => new Module(module)))
        return modules
      })
  }

  static fetchModule (identifier) {
    return apiGet(['modules', identifier])
      .then(response => {
        if (response.status !== 200) {
          EventBus.$emit('showErrorOrInformation', [true, response.status])
          throw Error
        }
        return response
      })
      .then(response => response.json())
      .then(module => new Module(module))
      .then(module => {
        store.commit('putModule', module)
        return module
      })
  }

  static fetchEntity (id, module = null) {
    if (typeof id === 'object' && id.id && id.module_id) {
      module = id.module_id
      id = id.id
    }
    module = resolveModule(module)
    return apiGet(['modules', module.identifier, 'resources', id])
      .then(response => {
        if (response.status !== 200) {
          EventBus.$emit('showErrorOrInformation', [true, response.status])
          throw Error
        }
        return response
      })
      .then(response => response.json())
      .then(entity => new Entity({ ...entity, module }))
  }

  static fetchEntities (module, page = 0) { // If Page = 0 this function returns all entities
    module = resolveModule(module)
    if (page !== 0) {
      return apiGet(['modules', module.identifier, 'resources?page=' + page])
        .then(response => {
          if (response.status !== 200) {
            EventBus.$emit('showErrorOrInformation', [true, response.status])
            throw Error
          }
          return response
        })
        .then(response => response.json())
        .then(response => response.data.map(entity => new Entity(entity)))
    } else {
      return this.fetchAllEntities(module)
        .then(result => result.data.map(entity => new Entity(entity)))
    }
  }

  static fetchAllEntities (module, page = 1) {
    module = resolveModule(module)
    return apiGet(['modules', module.identifier, 'resources?page=' + page])
      .then(response => {
        if (response.status !== 200) {
          EventBus.$emit('showErrorOrInformation', [true, response.status])
          throw Error
        }
        return response
      })
      .then(response => response.json())
      .then(response => {
        if (response.next_page_url != null) {
          return this.fetchAllEntities(module, page + 1) // Rekursionsschritt
            .then(result => {
              response.data = response.data.concat(result.data)
              return response
            })
        } else {
          return response
        }
      })
  }

  static fetchView (view) {
    return apiGet(['views', view])
      .then(response => {
        if (response.status !== 200) {
          throw Error
        }
        return response
      })
      .then(response => response.text())
  }

  static createEntity (entity, params = {}) {
    const module = resolveModule(entity.module)
    return apiPost(
      ['modules', module.identifier, 'resources'],
      params,
      JSON.stringify({ ...entity, module: undefined }),
      {
        'Content-Type': 'application/json',
      }
    )
      .then(response => response.json())
  }

  static updateEntity (entity, params = {}) {
    const module = resolveModule(entity.module)
    return apiPut(['modules', module.identifier, 'resources', entity.id],
      params,
      JSON.stringify(entity),
      {
        'Content-Type': 'application/json',
      }
    ).then(response => response.json())
  }

  static saveEntity (entity, params = {}) {
    if (entity.id) {
      return Api.updateEntity(entity, params)
    }
    return Api.createEntity(entity, params)
  }

  static deleteEntity (entity) {
    const module = resolveModule(entity.module)
    return apiDelete(['modules', module.identifier, 'resources', entity.id])
  }

  static entityHistory (entity, page = 1, user = undefined) {
    const module = resolveModule(entity.module)
    return apiGet(['modules', module.identifier, 'resources', entity.id, 'history'], { page, user })
      .then(response => response.json())
  }

  static entityHistoryAuthors (entity) {
    const module = resolveModule(entity.module)
    return apiGet(['modules', module.identifier, 'resources', entity.id, 'history', 'authors'])
      .then(response => response.json())
  }

  static fetchKeys () {
    return apiGet(['keys'])
      .then(response => response.json())
      .then(response => response.data.map(key => new ApiKey({ ...key })))
  }

  static fetchSpec () {
    return apiGet(['spec'])
      .then(response => response.json())
  }

  static fetchLicensePlans () {
    return apiGet(['plans'])
      .then(response => response.json())
  }

  static fetchCurrentPlan () {
    return apiGet(['plans', 'currentPlan'])
      .then(response => response.json())
  }
}

Api.prototype._files = new Files()
