/**
 * Return whether one can perform a specific action on this resource/module/field.
 * @param action
 * @param guarded
 * @return {boolean}
 */
export const can = (action, guarded) => {
  return guarded && (!guarded.allowedOperations || guarded.allowedOperations.includes(action))
}

/**
 * Return whether one can perform a CREATE on this resource/module.
 * @param guarded
 * @return {boolean}
 */
export const canCreate = guarded => {
  return can('CREATE', guarded)
}

/**
 * Return whether one can perform a READ on this resource/module/field.
 * @param guarded
 * @return {boolean}
 */
export const canRead = guarded => {
  return can('READ', guarded)
}

/**
 * Return whether one can perform an UPDATE on this resource/module/field.
 * @param guarded
 * @return {boolean}
 */
export const canUpdate = guarded => {
  return can('UPDATE', guarded)
}

/**
 * Return whether one can perform a DELETE on this resource/module/field.
 * @param guarded
 * @return {boolean}
 */
export const canDelete = guarded => {
  return can('DELETE', guarded)
}
