<template>
  <a-list-item
    :class="{ selected: isSelected, list: !isGrid, dragover: dragover }"
    class="file-select-item"
    @dragover.prevent="highlight"
    @dragleave.prevent="highlight"
    @drop.prevent="drop"
  >
    <div
      v-if="!isGrid"
      slot="actions"
    >
      <span v-if="!isDirectory">
        <a-icon
          :title="tr('_.preview', true)"
          type="fullscreen"
          size="small"
          @click="open"
        />
        <a-divider type="vertical"/>
      </span>
      <span v-else>
        <a-icon
          type="folder-open"
          size="small"
          @click="open"
        />
        <a-divider type="vertical"/>
      </span>
      <a-icon
        v-if="canUpdate(item)"
        :title="tr('_.edit', true)"
        type="edit"
        size="small"
        @click="editMode = !editMode"
      />
      <a-divider
        v-if="canUpdate(item)"
        type="vertical"
      />
      <a-icon
        :title="tr('_.download', true)"
        type="download"
        size="small"
        @click="downloadFile"
      />
      <a-divider type="vertical"/>
      <a-popconfirm
        v-if="canDelete(item)"
        :title="$t('_.are_you_sure')"
        @confirm="deleteFile"
      >
        <a-icon
          :title="tr('_.delete', true)"
          type="delete"
          size="small"
        />
      </a-popconfirm>
      <a-divider
        v-if="canDelete(item)"
        type="vertical"
      />
    </div>
    <a-list-item-meta
      v-if="!isGrid"
      @touchend="isDirectory ? $emit('directoryOpen', item) : ''"
    >
      <div
        slot="title"
        style="display: flex; align-items: center"
      >
        <h4
          v-if="!editMode"
          @click="open"
        >
          {{ item.brezel_name }}
        </h4>
        <a-input
          v-if="editMode"
          v-model="renameString"
          v-focus="editMode"
          class="rename-input"
          @pressEnter="renameFile"
        />
        <h4 v-if="editMode && fileExtensionString">
          {{ '.' + fileExtensionString }}
        </h4>
      </div>
      <div slot="avatar">
        <div class="avatar-container">
          <a-checkbox
            :checked="isSelected"
            @change="select"
          />
          <div v-if="loadingThumbnail">
            <a-spin/>
          </div>
          <div
            v-else-if="thumbnailUrl"
            class="list-thumbnail"
            :style="{ backgroundImage: 'url(' + thumbnailUrl + ')' }"
          />
          <a-icon
            v-else
            :style="{color: getTypeConfig().color}"
            :type="getTypeConfig().icon"
            :theme="isDirectory ? 'filled' : 'outlined'"
            class="avatar-icon"
            @click="open"
          />
        </div>
        <span slot="description"/>
      </div>
    </a-list-item-meta>
    <div v-if="!isGrid">
      <div
        class="size"
        style="display: inline-block; min-width: 80px"
      >
        <span v-if="item.size && item.size > 0"><a-icon type="file"/> {{ humanFileSize(item.size) }}</span>
      </div>
      <a-divider
        v-if="item.size && item.size > 0"
        type="vertical"
      />
      <div
        class="date"
        style="display: inline-block;"
      >
        <a-icon type="calendar"/>
        {{ echoDateTime(item.created_at, 'dateTime') }}
      </div>
    </div>
    <a-card
      v-if="isGrid"
      @click="selectOrOpen($event)"
    >
      <div
        slot="title"
        style="text-align: center"
      >
        <a-checkbox :checked="isSelected"/>
        <div
          v-if="hasThumbnail"
          style="max-height: 100px"
        >
          <div v-if="loadingThumbnail">
            <a-spin/>
          </div>
          <img
            v-else-if="thumbnailUrl"
            :src="thumbnailUrl"
            alt="File Thumbnail"
            style="max-width: 100%; max-height: 100px;"
          >
        </div>
        <h4 v-else>
          <a-icon
            :style="{color: getTypeConfig().color}"
            :type="getTypeConfig().icon"
          />
        </h4>
      </div>
      <a-card-meta @click="isDirectory ? selectOrOpen($event) : null">
        <div
          slot="title"
          style="display: flex; align-items: center"
        >
          <h4 v-if="!editMode">
            {{ item.brezel_name }}
          </h4>
          <a-input
            v-if="editMode"
            v-model="renameString"
            v-focus="editMode"
            class="rename-input"
            @pressEnter="renameFile"
          />
          <h4 v-if="editMode && fileExtensionString">
            {{ '.' + fileExtensionString }}
          </h4>
        </div>
        <span slot="description"/>
      </a-card-meta>
      <template
        v-if="!isTrash"
        slot="actions"
      >
        <a
          v-if="!isDirectory"
          @click="open"
        >
          <a-tooltip
            :title="$t('open')"
            placement="bottom"
          >
            <a-icon type="fullscreen"/>
          </a-tooltip>
        </a>
        <a
          v-else
          @click="open"
        >
          <a-tooltip
            :title="$t('open')"
            placement="bottom"
          >
            <a-icon type="folder-open"/>
          </a-tooltip>
        </a>
        <a
          v-if="canUpdate(item)"
          @click="editMode = !editMode"
        >
          <a-tooltip
            :title="$t('_.rename')"
            placement="bottom"
          >
            <a-icon type="edit"/>
          </a-tooltip>
        </a>
        <a @click="downloadFile">
          <a-tooltip
            :title="$t('_.download')"
            placement="bottom"
          >
            <a-icon type="download"/>
          </a-tooltip>
        </a>
        <a-popconfirm
          v-if="canDelete(item)"
          :title="$t('_.are_you_sure')"
          @confirm="deleteFile"
        >
          <a-tooltip
            :title="$t('_.delete')"
            placement="bottom"
          >
            <a-icon type="delete"/>
          </a-tooltip>
        </a-popconfirm>
      </template>
      <template
        v-else
        slot="actions"
      >
        <a @click="handleRestore">
          <a-tooltip
            :title="$t('trash.restore')"
            placement="bottom"
          >
            <a-icon type="reload"/>
          </a-tooltip>
        </a>
        <a-popconfirm
          :title="$t('_.are_you_sure')"
          @confirm="deleteFile"
        >
          <a-tooltip
            :title="$t('trash.delete_hard')"
            placement="bottom"
          >
            <a-icon type="delete"/>
          </a-tooltip>
        </a-popconfirm>
      </template>
    </a-card>
  </a-list-item>
  <!--  <a-list-item
      v-else
      :class="{ selected: isSelected, list: !isGrid }"
      class="file-select-item"
    >
      <a-card v-else>
        <a-card-meta @click="$emit('directoryOpen', item)">
          <div
            slot="title"
            style="display: flex; align-items: center"
          >
            <a-icon
              style="margin-right: 7px"
              type="folder-open"
            />
            <div v-if="!editMode">
              {{ item.brezel_name }}
            </div>
            <a-input
              v-if="editMode"
              v-model="renameString"
              v-focus="editMode"
              class="rename-input"
              @pressEnter="renameFile"
            />
            <h4 v-if="editMode && fileExtensionString">
              {{ '.' + fileExtensionString }}
            </h4>
          </div>
        </a-card-meta>
        <template
          slot="actions"
        >
          <a @click="editMode = !editMode">
            <a-tooltip
              :title="$t('_.rename')"
              placement="bottom"
            >
              <a-icon type="edit"/>
            </a-tooltip>
          </a>
          <a @click="downloadFile">
            <a-tooltip
              :title="$t('_.download')"
              placement="bottom"
            >
              <a-icon type="download"/>
            </a-tooltip>
          </a>
          <a-popconfirm
            :title="$t('_.are_you_sure')"
            @confirm="deleteFile"
          >
            <a-tooltip
              :title="$t('_.delete')"
              placement="bottom"
            >
              <a-icon type="delete"/>
            </a-tooltip>
          </a-popconfirm>
        </template>
      </a-card>
    </a-list-item>-->
</template>

<script>
import store from '@/store'
import { apiLink, downloadBuffer, echoDateTime, getFilenameFromResponse, getFileURL, openBuffer } from '@/utils'
import mimeTypeConfig from './mime-type-config'
import Upload from '@/components/upload'
import { apiDelete, apiGet, apiPut } from '../api'
import { canDelete, canUpdate } from '@/guard'

export default {
  name: 'FileSelectItem',
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      },
    },
  },
  mixins: [Upload],
  props: {
    isGrid: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: undefined,
    },
    module: {
      type: Object,
      default: undefined,
    },
    defaultSelected: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dblClick: false,
      dblClickTimeout: null,
      dragover: false,
      isSelected: this.selected,
      editMode: false,
      fileExtensionString: '',
      renameString: '',
      loadingThumbnail: true,
      thumbnailUrl: '',
      isTrash: false,
      isDirectory: this.item.directory,
    }
  },
  computed: {
    hasThumbnail () {
      switch (this.item.mime_type) {
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
          return true
        default:
          return false
      }
    },
    uploadUrl () {
      return apiLink(['files'], store.state.currentSystem).toString()
    },
    uploadPath () {
      if (this.isDirectory) {
        // Upload to the directory, which is this item itself
        return [this.item.virtual_path, this.item.name].join('/')
      }
      // Upload to the currently selected directory, in which this item lies
      return this.item.virtual_path
    },
  },
  watch: {
    dragover () {
      this.$emit('highlight', this.dragover)
    },
    selected () {
      this.isSelected = this.selected
    },
  },
  created () {
    if (this.$route.name === 'module.trash') {
      this.isTrash = true
    }
  },
  mounted () {
    this.renameString = this.item.brezel_name
    if (this.item.brezel_name.includes('.')) {
      this.renameString = this.item.brezel_name.substring(0, this.item.brezel_name.lastIndexOf('.'))
      this.fileExtensionString = this.item.brezel_name.split('.').pop()
    }
    this.isSelected = this.defaultSelected
    this.loadingThumbnail = false
    if (this.hasThumbnail) {
      this.loadingThumbnail = true
      getFileURL(this.item.id, 'default')
        .then(url => {
          this.loadingThumbnail = false
          this.thumbnailUrl = url
        })
        .catch(res => {
          this.loadingThumbnail = false
          console.error(res)
        })
    }
  },
  methods: {
    canUpdate,
    canDelete,
    echoDateTime: echoDateTime,
    drop (e) {
      let files = e.dataTransfer.items || e.dataTransfer.files
      if (files) {
        this.getFilesDataTransferItems(files, this.uploadPath, this.module.identifier)
          .then(response => {
            this.dragover = false
            this.$emit('uploaded', response)
          })
      }
    },
    downloadFile () {
      let filename = this.item.brezel_name
      return new Promise((resolve) => {
        apiGet(['files', this.item.id])
          .then(response => {
            filename = getFilenameFromResponse(response.headers.get('content-disposition'))
            return response.blob()
          })
          .then(file => downloadBuffer(file, filename))
      })
    },
    openFile () {
      return new Promise((resolve) => {
        apiGet(['files', this.item.id])
          .then(response => response.blob())
          .then(response => openBuffer(response, this.item.name))
      })
    },
    deleteFile () {
      let path = ['files', this.item.id]
      if (this.isTrash) {
        path = ['files', 'trash', this.item.id]
      }
      apiDelete(path)
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.$emit('delete')
          }
        })
        .catch(console.error)
    },
    handleRestore () {
      apiPut(['files', 'trash', this.item.id])
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.$emit('restore')
          }
        })
        .catch(console.error)
    },
    getTypeConfig () {
      if (this.isDirectory) {
        return {
          color: '#fdd25b',
          icon: 'folder',
        }
      }
      if (!this.item.mime_type) {
        return {
          icon: 'file-unknown',
        }
      }
      const type = this.item.mime_type.split('/')
      const typeConfig = mimeTypeConfig[type[0]]
      let output
      if (typeConfig) {
        const subtypeConfig = typeConfig.subtypes ? typeConfig.subtypes[type[1]] : null
        if (subtypeConfig) {
          output = subtypeConfig
        }
        if (!output) {
          output = typeConfig
        }
      }
      return output || { icon: 'file-unknown' }
    },
    renameFile () {
      const newName = this.renameString + (this.fileExtensionString ? '.' + this.fileExtensionString : '')
      const out = {
        'name': newName,
        'mimeType': this.item.mime_type,
        'path': this.item.path,
        'size': this.item.size,
      }
      this.editMode = false
      apiPut(['modules', this.module.identifier, 'resources', this.item.id], {}, JSON.stringify(out), { 'Content-Type': 'application/json' })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.item.brezel_name = newName
          }
        })
        .catch(console.error)
    },
    highlight (event) {
      if (event.type !== 'dragleave' && event.dataTransfer && event.dataTransfer.items) {
        event.dataTransfer.dropEffect = 'copy'
        this.dragover = true
      } else {
        this.dragover = false
      }
    },
    humanFileSize (size) {
      const i = Math.floor(Math.log(size) / Math.log(1024))
      return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    },
    select () {
      this.isSelected = !this.isSelected
      this.$emit('change', this.item)
    },
    open () {
      if (this.isDirectory) {
        this.$emit('directoryOpen', this.getDirectoryPath())
      } else {
        this.openFile()
      }
    },
    // Selects the item if single click and opens it if it is a double click
    selectOrOpen (event) {
      if (this.dblClick && this.isDirectory) {
        this.$emit('directoryOpen', this.getDirectoryPath())
        this.dblClick = false
        clearTimeout(this.dblClickTimeout)
      } else {
        this.dblClickTimeout = setTimeout(() => {
          this.select()
          this.dblClick = false
        }, 200)
        this.dblClick = true
      }
    },
    getDirectoryPath () {
      return [this.item.virtual_path, this.item.name].join('/').replace(/\/+/gm, '/')
    },
  },
}
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';

.file-select-item {
  .list-thumbnail {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .ant-list-item {
    margin-bottom: 0 !important;
  }

  .avatar-icon {
    font-size: 25px;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }

  .ant-list-item {
    border: 2px solid transparent !important;
    transition: none !important;
  }

  &.dragover {
    .ant-list-item {
      border: 2px dashed @primary-2 !important;
      transition: none !important;
    }
  }

  &.list {
    &.selected {
      .ant-list-item {
        background-color: @item-active-bg;
      }
    }

    .ant-list-item {
      display: flex !important;
      align-items: center;
      transition: .6s;
      padding: 8px !important;

      .ant-list-item-content {
        max-width: 50%;
        overflow: hidden;
      }

      .ant-list-item-meta {
        margin-bottom: 0;
        cursor: pointer;

        .ant-list-item-meta-title {
          margin-bottom: 0;
        }
      }
    }
  }

  &.selected .ant-card {
    border-color: #40a9ff;
    transform: scale(0.97);
  }

  .rename-input {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 0;
    padding-top: 0;
    height: 24px;
    max-width: 300px;
  }

  .ant-avatar {
    background-color: transparent;
    color: @text-color;
  }

  .ant-card-meta {
    cursor: pointer;
  }

  .ant-card-head, .file-select-item .ant-card-head-title {
    padding: 0;
  }

  .ant-card-head .ant-card-head-wrapper {
    width: 100%;
  }

  .ant-card .ant-checkbox-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
