<template>
  <div :brezel-component-id="component.id">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="component.options.identifier"
      :class="(isBordered ? 'ant-card-bordered ' : '') + styleClass"
      :style="'padding: 8px; white-space: pre-line; ' + styleCss + ' ' + setRecipeStyleCss(resource)"
      v-html="tr(`${translationPrefix}.texts.${component.options.identifier}`)"
    />
    <div
      v-else
      :class="(isBordered ? 'ant-card-bordered ' : '') + styleClass"
      :style="'padding: 8px; white-space: pre-line; ' + styleCss"
    >
      {{ component.options.text }}
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import FieldCalculator from '@kibro/brezel-recipes/src/field-calculator'
import _ from 'lodash'

export default {
  name: 'LayoutComponentText',
  extends: LayoutComponent,
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  computed: {
    isBordered () {
      return (this.component.options && this.component.options.has_border)
    },
    styleClass () {
      if (this.component.options && this.component.options.style && this.component.options.style.class) { return this.component.options.style.class } else { return '' }
    },
    styleCss () {
      if (this.component.options && this.component.options.style && this.component.options.style.css) { return this.component.options.style.css } else { return '' }
    },
  },
  methods: {
   setRecipeStyleCss (data) {
    let css = ''
    if (this.component && _.has(this.component, 'options.settings')) {
      if (this.component.options.settings.recipes.style) {
        const headSetting = this.component.options.settings.recipes.style
        const calculator = new FieldCalculator(data)
        css = calculator.calculate(headSetting)
      }
      return css
    }
    },
  },
}
</script>
