import FieldCell from '@/components/fields/FieldCell'
import CellText from '@/components/fields/text/CellText'
import CellLink from '@/components/fields/link/CellLink'
import CellSelect from '@/components/fields/select/CellSelect'
import CellMultiselect from '@/components/fields/multiselect/CellMultiselect'
import CellFile from '@/components/fields/list/CellFile'
import CellRating from '@/components/fields/rating/CellRating'
import CellCheckbox from '@/components/fields/checkbox/CellCheckbox'
import CellChoice from '@/components/fields/choice/CellChoice'
import CellNumber from '@/components/fields/number/CellNumber'
import CellWYSIWYG from '@/components/fields/wysiwyg/CellWYSIWYG'
import CellCurrency from '@/components/fields/currency/CellCurrency'
import CellColor from '@/components/fields/color/CellColor'
import CellSignature from '@/components/fields/signature/CellSignature'
import CellDateRange from '@/components/fields/date_range/CellDateRange'
import CellDate from '@/components/fields/date/CellDate'
import CellDateTime from '@/components/fields/datetime/CellDateTime'
import CellTel from '@/components/fields/tel/CellTel'
import CellEmail from '@/components/fields/email/CellEmail'
import CellTextarea from '@/components/fields/textarea/CellTextarea'
import CellMonth from '@/components/fields/month/CellMonth'

// Registry is filled by external function registerFields() to prevent circular dependencies that break Vue
const registry = {
  edit: {},
  view: {},
}

export function registerFieldEdit (type, component) {
  registry.edit[type] = component
}

export function registerFieldView (type, component) {
  registry.view[type] = component
}

export class Fields {
  static getFieldComponent (type, options = {}) {
    if (!(type in registry.edit)) {
      type = 'default'
    }
    if (type === 'select' && options.multiple) {
      type = 'multiselect'
    }
    return registry.edit[type]
  }

  static getFieldViewerComponent (type) {
    if (!(type in registry.view)) {
      type = 'default'
    }
    return registry.view[type]
  }

  static getFieldCellComponent (type) {
    switch (type) {
      case 'checkbox':
        return CellCheckbox
      case 'choice':
        return CellChoice
      case 'color':
        return CellColor
      case 'currency':
        return CellCurrency
      case 'date':
        return CellDate
      case 'month':
        return CellMonth
      case 'dateRange':
        return CellDateRange
      case 'datetime':
        return CellDateTime
      case 'email':
        return CellEmail
      case 'file':
      case 'upload':
        return CellFile
      case 'link':
        return CellLink
      case 'multiselect':
        return CellMultiselect
      case 'number':
        return CellNumber
      case 'rating':
        return CellRating
      case 'select':
        return CellSelect
      case 'signature':
        return CellSignature
      case 'tel':
        return CellTel
      case 'text':
        return CellText
      case 'textarea':
        return CellTextarea
      case 'wysiwyg':
        return CellWYSIWYG
      default:
        return FieldCell
    }
  }
}

/**
 * Mixin to provide field types to components
 */
export default {
  methods: {
    getFieldComponent: (type, options) => Fields.getFieldComponent(type, options),
    getFieldViewerComponent: type => Fields.getFieldViewerComponent(type),
    getFieldCellComponent: type => Fields.getFieldCellComponent(type),
  },
}
