import { uniqid } from '@/utils'

export default class FieldItem {
  value
  fields

  constructor (value, fields) {
    this._uid = uniqid()
    this.value = value
    this.fields = fields
  }

  getUID () {
    return this._uid
  }

  set index (value) {
    this.value.index = value
  }

  get index () {
    return this.value.index
  }
}
