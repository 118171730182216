import { render, staticRenderFns } from "./LayoutComponentResourceTable.vue?vue&type=template&id=906c1ae8&scoped=true&"
import script from "./LayoutComponentResourceTable.vue?vue&type=script&lang=js&"
export * from "./LayoutComponentResourceTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "906c1ae8",
  null
  
)

export default component.exports