<template>
  <div class="with-padding">
    {{ value }}
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellTextarea',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
