<template>
  <node-view-wrapper class="media-image">
    <a-modal
      :visible="imageModal"
      width="70%"
      @cancel="closeModal"
      @ok="selectMedia"
    >
      <MediaManager
        v-model="image"
        :module="module"
        :directory="directory"
        :alt.sync="alt"
        :link.sync="link"
        :width.sync="width"
        :height.sync="height"
        :with-scan="false"
      />
    </a-modal>
    <div
      class="image-container"
      draggable="true"
      data-drag-handle
    >
      <a-spin v-if="loading"/>
      <img
        v-else
        :src="url"
        :alt="alt"
        :width="width"
        :height="height"
      >
      <div
        v-if="editor.options.editable"
        class="actions"
      >
        <a-icon
          class="action"
          type="edit"
          @click="openModal"
        />
        <a-icon
          class="action danger"
          type="delete"
          @click="deleteNode"
        />
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper } from '@tiptap/vue-2'
import { getFileURL } from '@/utils'
import MediaManager from '@/components/MediaManager'

export default {
  name: 'MediaImage',
  components: {
    NodeViewWrapper,
    MediaManager,
  },
  props: {
    editor: {
      type: Object,
      default: undefined,
    },
    node: {
      type: Object,
      required: true,
    },
    updateAttributes: {
      type: Function,
      required: true,
    },
    extension: {
      type: Object,
      default: undefined,
    },
    deleteNode: {
      type: Function,
      default: undefined,
    },
  },
  data () {
    return {
      url: null,
      imageModal: false,
      image: null,
      module: this.extension.options.module,
      directory: this.extension.storage.directory,
      alt: null,
      link: null,
      width: null,
      height: null,
      loading: false,
    }
  },
  mounted () {
    if (this.extension.options.open) {
      this.imageModal = true
      this.extension.options.open = false
    }
    this.editor.on('update', this.handleUpdate)
    this.$nextTick().then(this.handleUpdate)
  },
  methods: {
    handleUpdate () {
      if (this.node.attrs.id && (!this.image || this.node.attrs.id !== this.image.id)) {
        this.image = { id: this.node.attrs.id }
        this.loadImage()
        this.$forceUpdate()
      }
    },

    openModal () {
      this.imageModal = true
    },

    closeModal () {
      this.imageModal = false
    },

    selectMedia () {
      this.closeModal()
      this.loadImage()
      this.setImage()
    },

    loadImage () {
      if (this.image) {
        this.loading = true
        getFileURL(this.image.id, 'default').then(url => {
          this.url = url
          this.loading = false
        })
      }
    },

    setImage () {
      this.updateAttributes({
        id: this.image ? this.image.id : undefined,
        alt: this.alt || undefined,
        link: this.link || undefined,
        width: this.width || undefined,
        height: this.height || undefined,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.media-image {
  border: 1px solid #eeeeee;
  display: inline-block;
}

.image-container {
  position: relative;

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  .actions {
    display: flex;
    opacity: 0;
    transition: all 0.1s;
    gap: 10px;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 15px;

    .action {
      padding: 8px;
      color: white;
      background-color: rgba(0, 0, 0, 0.7);
      box-shadow: 3px 1px 1px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: rgba(0, 0, 0, 1);
      }

      &.success:hover {
        background-color: rgba(0, 250, 0, 0.7);
      }

      &.danger:hover {
        background-color: rgba(250, 0, 0, 0.7);
      }
    }
  }
}
</style>
