<template>
  <div
    :brezel-tab-id="tab.id"
    :style="(tab.options || {}).style"
    :class="(tab.options || {}).class"
    v-bind="(tab.options || {}).attrs"
  >
    <layout-row
      v-for="row in rows"
      :key="row.frontendIndex"
      ref="row"
      :class="row === last(rows) ? 'layout-row --last-row' : 'layout-row'"
      :field-tree="fieldTree"
      :module="module"
      :prop-mode="mode"
      :resource="resource"
      :row="row"
      :translation-prefix="translationPrefix"
      :context="context"
      :layout="layout"
      :layout-path="newLayoutPath"
      @event="anyEvent"
    />
  </div>
</template>

<script>
import LayoutRow from '@/components/LayoutRow'
import { last, shouldShow } from '@/utils'
import { fireWebhookEvent } from '@/webhooks'
import LayoutDef from '@/layout/layout'
import LayoutElement from '@/components/LayoutElement'

export default {
  name: 'LayoutTab',
  components: {
    LayoutRow,
  },
  extends: LayoutElement,
  props: {
    fieldTree: {
      type: Object,
      default: undefined,
    },
    mode: {
      type: String,
      default: undefined,
    },
    resource: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      default: undefined,
    },
    tab: {
      type: Object,
      required: true,
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
    layout: {
      type: LayoutDef,
      default: () => new LayoutDef(),
    },
  },
  computed: {
    layoutElementIdentifier () {
      return this.tab.identifier
    },

    rows () {
      return this.tab.rows
        .map((row, index) => {
          row.frontendIndex = index
          return row
        })
        .filter(row => shouldShow(row, this.mode))
    },
  },
  mounted () {
    this.$nextTick(() => {
      if (this.tab.loaded_on_create_webhook) {
        this.fireTabEvent(this.tab.loaded_on_create_webhook)
      }
      if (this.tab.on_load_webhooks && this.tab.on_load_webhooks.length > 0) {
        for (let webhook of this.tab.on_load_webhooks) {
          this.fireTabEvent(webhook)
        }
      }
    })
  },
  methods: {
    last,

    anyEvent ($event) {
      this.$emit('event', $event)
    },
    fireTabEvent (eventIdentifier) {
      fireWebhookEvent(eventIdentifier,
        this.$store.getters.getModuleByIdentifier(this.module.identifier),
        this.$route.params.resource_identifier,
        {},
        {
          resource: this.resource,
          fieldTree: this.fieldTree,
        }
      )
    },
  },
}
</script>
