<template>
  <div
    v-if="value"
    class="with-padding"
  >
    <span v-if="Array.isArray(value)">{{ value.map(a => a.brezel_name).join(', ').substring(0, 50) }}</span>
    <span v-else-if="value.brezel_name">{{ value.brezel_name }}</span>
    <span v-else>{{ value }}</span>
  </div>
  <div v-else/>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellSelect',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
