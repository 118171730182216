<template>
  <codemirror
    ref="code"
    v-model="formattedCode"
    :options="codemirrorOptions"
    read-only="nocursor"
    style="width: 100%;"
  />
</template>

<script>
import Field from '@/components/fields/Field'
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/php/php.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/lib/codemirror.css'
import FieldCode from '@/components/fields/code/FieldCode'

export default {
  name: 'FieldViewerCode',
  components: {
    codemirror,
  },
  extends: Field,
  mixins: [FieldCode],
  computed: {
    codemirrorOptions () {
      return {
        extraKeys: {
          'Tab': 'emmetExpandAbbreviation',
          'Enter': 'emmetInsertLineBreak',
        },
        line: false,
        lineNumbers: false,
        lineWrapping: true,
        mode: this.mode,
        readOnly: 'nocursor',
        styleActiveLine: false,
        tabSize: this.mode.name === 'javascript' ? 2 : 4,
        viewportMargin: Infinity,
      }
    },
  },
}
</script>
