<template>
  <div class="filters">
    <div style="text-align: center">
      <a-button
        @click="addOr(0)"
      >
        <a-icon type="plus"/>
        {{ $t('_.or') }}
      </a-button>
    </div>
    <div
      v-for="(or, orKey) in out_value"
      :key="orKey"
      class="filter-group"
    >
      <div style="text-align: right">
        <a-button
          type="danger"
          style="margin-right: 16px"
          @click="removeOr(orKey)"
        >
          <a-icon type="delete"/>
          {{ $t('_.delete') }}
        </a-button>
        <a-button
          style="margin-right: 16px"
          @click="duplicateOr(orKey)"
        >
          <a-icon type="copy"/>
          {{ $t(`_.duplicate`) }}
        </a-button>
        <a-button
          type="primary"
          @click="addAnd(orKey)"
        >
          <a-icon type="plus"/>
          {{ $t(`_.add_filter`) }}
        </a-button>
      </div>
      <draggable
        v-model="out_value[orKey]"
        animation="150"
        easing="cubic-bezier(1, 0, 0, 1)"
        handle=".and-drag-handler"
      >
        <a-row
          v-for="(and, andKey) in or"
          :key="andKey"
          type="flex"
          align="middle"
          justify="center"
          :gutter="8"
          style="width: 100%; padding: 14px"
        >
          <a-col
            span="2"
            class="and-drag-handler"
            style="text-align: center"
          >
            <a-icon
              type="menu"
              style="font-size: 24px"
            />
          </a-col>
          <a-col span="18">
            <a-row>
              <a-col span="10">
                <h4>{{ $t(`_.field`) }}</h4>
                <a-select
                  v-if="filterOptions.length > 0"
                  :default-value="and.field"
                  option-filter-prop="title"
                  show-search
                  style="width: 100%"
                  @change="updateValue($event, and)"
                >
                  <a-select-option
                    v-for="field in filterOptions"
                    :key="field.identifier"
                    :value="field.identifier"
                    :title="$t(fieldTranslationPrefix + '.fields.' + field.identifier)"
                  >
                    {{ $t(fieldTranslationPrefix + '.fields.' + field.identifier) }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col span="4">
                <h4>{{ $t(`_.operator`) }}</h4>
                <a-select
                  v-model="and.operator"
                  show-search
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="operator in getOperatorsForFieldType(and.field, true)"
                    :key="operator.value"
                  >
                    {{ $t('_.' + [operator.key]) }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col
                v-if="and.field && getColumnField(and.field)"
                span="10"
              >
                <h4>{{ $t(`_.value`) }}</h4>
                <div style="display: flex">
                  <FieldContainer
                    v-model="and.value"
                    :translation-prefix="fieldTranslationPrefix"
                    :field="getColumnField(and.field)"
                    style="width: 100%"
                  />
                  <a-radio-button
                    v-if="and.operator === '=' || and.operator === '<>'"
                    style="margin-left: 16px"
                    :checked="and.value === null"
                    @change="$event ? and.value = null : and.value"
                  >
                    {{ $t(`_.empty`) }}
                  </a-radio-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col
            span="4"
            style="text-align: center"
          >
            <a-button
              type="danger"
              size="small"
              @click="removeAnd(orKey, andKey)"
            >
              <a-icon type="delete"/>
            </a-button>
            <a-button
              size="small"
              @click="duplicateAnd(orKey, andKey)"
            >
              <a-icon type="copy"/>
            </a-button>
          </a-col>
        </a-row>
      </draggable>
    </div>
  </div>
</template>

<script>
import Module from '../module/module'
import FieldContainer from './FieldContainer'
import Draggable from 'vuedraggable'
import Filter from './filter'
import _ from 'lodash'

export default {
  name: 'Filters',
  components: {
    FieldContainer,
    Draggable,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    fields: {
      type: Array,
      default: undefined,
    },
    module: Module,
    value: {
      type: Array,
      default: () => [],
    },
    translationPrefix: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      filterOptionsUnmodified: [],
      filterOptions: [],
      out_value: this.value,
    }
  },
  computed: {
    fieldTranslationPrefix () {
      return this.translationPrefix || 'modules.' + this.module.identifier
    },
    firstColumn () {
      return this.filterOptions.length > 0 ? this.filterOptions[0].identifier : null
    },
  },
  watch: {
    'out_value': {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      },
    },
  },
  mounted () {
    if (module) {
      this.filterOptionsUnmodified = this.module.fields
    }
    if (this.fields) {
      this.filterOptionsUnmodified = this.fields
    }
    this.filterOptions = Filter.getFilterFieldsForFields(this.filterOptionsUnmodified)
    if (!this.value) {
      this.out_value = [[
        {
          field: this.firstColumn,
          operator: '',
          value: '',
        },
      ]]
    }
  },
  methods: {
    addOr (orKey) {
      const newItem = [{
        field: this.firstColumn,
        operator: '',
        value: '',
      }]
      if (typeof orKey === 'undefined') {
        this.out_value.push(newItem)
      } else {
        this.out_value.splice(orKey, 0, newItem)
      }
    },
    addAnd (orKey) {
      this.out_value[orKey].push({
        field: this.firstColumn,
        operator: '',
        value: '',
      })
    },
    duplicateAnd (orKey, andKey) {
      this.out_value[orKey].splice(andKey, 0, _.cloneDeep(this.out_value[orKey][andKey]))
    },
    duplicateOr (orKey) {
      this.out_value.splice(orKey, 0, _.cloneDeep(this.out_value[orKey]))
    },
    getColumnField (identifier, unmodified = false) {
      const fieldList = unmodified ? this.filterOptionsUnmodified : this.filterOptions
      return fieldList.find(field => field.identifier === identifier)
    },
    getOperatorsForFieldType (fieldKey, unmodified = false) {
      const field = this.getColumnField(fieldKey, unmodified)
      return Filter.getOperatorsForField(field)
    },
    removeAnd (orKey, andKey) {
      this.out_value[orKey].splice(andKey, 1)
    },
    removeOr (orKey) {
      this.out_value.splice(orKey, 1)
    },
    updateValue (fieldKey, and) {
      const newField = this.getColumnField(fieldKey)
      const oldField = this.getColumnField(and.field)
      if (newField.type !== oldField.type) {
        and.value = null
      }
      and.field = fieldKey
    },
  },
}
</script>

<style lang="less">
.filters .ant-form-item {
  margin-bottom: 0;
}

.filters .ant-form-item-children {
  line-height: 1.5;
}

.filters .filter-group {
  padding: 16px;
  margin: 16px;
  background-color: hsv(0, 0, 98%);
  border: 1px solid hsv(0, 0, 85%);
}

.filters .ant-select {
  width: 100%;
}
</style>
