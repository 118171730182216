import { render, staticRenderFns } from "./FieldLink.vue?vue&type=template&id=2366ae80&scoped=true&"
import script from "./FieldLink.vue?vue&type=script&lang=js&"
export * from "./FieldLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2366ae80",
  null
  
)

export default component.exports