<template>
  <div style="padding:20px;">
    <component
      :is="'script'"
      v-if="!cv"
      src="https://docs.opencv.org/4.5.4/opencv.js"
      async
    />
    <div
      id="viewport-box"
      class="viewport-box"
    >
      <div v-if="currentStep !== 0">
        <a-steps
          v-show="currentStep !== 0"
          direction="horizontal"
          :current="currentStep"
          size="small"
          class="document-scan-steps-container"
          style="margin-bottom:20px"
        >
          <a-step :title="tr('_.select_image', true)"/>
          <a-step :title="tr('_.apply_selection', true)"/>
          <a-step :title="tr('_.apply_filter', true)"/>
          <a-step :title="tr('_.export_options', true)"/>
        </a-steps>
      </div>
      <div
        v-show="currentStep === 0"
        class="imageFileFrom"
      >
        <h3 v-text="tr('_.select_scan_image_file_from', true) + ':'"/>
        <a-divider/>
        <div
          class="imageFileFrom-item"
          @click="OpenFileFromCamera"
        >
          <span>
            <a-icon type="camera"/>
            <div v-text="tr('_.camera', true)"/>
          </span>
        </div>
        <div
          class="imageFileFrom-item"
          style="margin:0px;"
          @click="OpenFileFromFiles"
        >
          <span>
            <a-icon type="file-image"/>
            <div v-text="tr('_.files', true)"/>
          </span>
        </div>
      </div>
      <div
        v-show="currentStep === 0"
        class="imageupload-wrapper"
      >
        <input
          id="ImageImport"
          type="file"
          name="ImageImport"
          accept="image/png, image/jpeg, image/gif"
          style="display:none;"
          @change="imageUploadChange"
        >
        <input
          id="CameraImport"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          capture="camera"
          style="display:none;"
          @change="imageUploadChange"
        >
      </div>
      <div
        v-show="currentStep === 1"
        id="image-wrapper"
      >
        <div
          v-show="!isLoaded"
          class="img-loading"
        >
          <a-spin/>
        </div>
        <img
          id="image-prev"
          src=""
          style="width:100px;"
        >
        <div v-show="ViewImage">
          <div
            id="CanvasWrapper"
            class="CanvasWrapper"
            @touchmove="handleMouseMove"
            @mousemove="handleMouseMove"
            @mousedown="handleMouseDown"
            @mouseup="handleMouseUp"
            @touchend="handleMouseUp"
            @mouseleave="handleMouseLeave"
          >
            <div
              v-show="bbControl"
              id="control_ne"
              class="control"
              alt="NorthEast"
              @touchstart="moveControl($event, 'ne')"
              @mousedown="moveControl($event, 'ne')"
            />
            <div
              v-show="bbControl"
              id="control_se"
              class="control"
              alt="SouthEast"
              @touchstart="moveControl($event, 'se')"
              @mousedown="moveControl($event, 'se')"
            />
            <div
              v-show="bbControl"
              id="control_sw"
              class="control"
              alt="SouthWest"
              @touchstart="moveControl($event, 'sw')"
              @mousedown="moveControl($event, 'sw')"
            />
            <div
              v-show="bbControl"
              id="control_nw"
              class="control"
              alt="NorthWest"
              @touchstart="moveControl($event, 'nw')"
              @mousedown="moveControl($event, 'nw')"
            />
            <div
              v-show="bbControl"
              id="control_n"
              class="control"
              @touchstart="moveControl($event, 'n')"
              @mousedown="moveControl($event, 'n')"
            />
            <div
              v-show="bbControl"
              id="control_s"
              class="control"
              @touchstart="moveControl($event, 's')"
              @mousedown="moveControl($event, 's')"
            />
            <div
              v-show="bbControl"
              id="control_e"
              class="control"
              @touchstart="moveControl($event, 'e')"
              @mousedown="moveControl($event, 'e')"
            />
            <div
              v-show="bbControl"
              id="control_w"
              class="control"
              @touchstart="moveControl($event, 'w')"
              @mousedown="moveControl($event, 'w')"
            />
            <canvas
              v-show="bbControl"
              id="boundingbox"
              :width="scaledWidth + 'px'"
              :height="scaledHeight + 'px'"
            />
            <div
              v-show="showZoom"
              class="zoomBox"
              :class="{ zoomPosRight: zoomBoxPos }"
            >
              <canvas
                id="zoomCanvas"
                width="200px"
                height="200px"
              />
              <div class="zoomCenter">
                +
              </div>
            </div>
            <canvas
              id="previewCanvas"
              :width="mainCanvasWidth + 'px'"
              :height="mainCanvasHeight + 'px'"
            />
          </div>
        </div>
      </div>
      <div
        v-show="currentStep === 2"
        class="output-wrapper"
      >
        <canvas
          id="outputTempCanvas"
          style="position:absolute"
        />
        <canvas id="outputCanvas"/>
        <canvas
          id="outputOriginalCanvas"
          style="display:none;"
        />
        <canvas
          id="resultOriginalCanvas"
          style="display:none;"
        />
      </div>
      <div v-show="currentStep === 3">
        <div class="scan-files-list">
          <div
            v-for="(img, index) in exportImages"
            :key="index"
            ref="exportImgs"
            class="scan-files-list-item"
          >
            <div
              class="scan-files-list-item-img"
              :style="{background: 'url(' + img.thumbimg + ') center center / cover no-repeat'}"
            >
              <div class="scan-files-list-item-options">
                <span>
                  <svg
                    viewBox="64 64 896 896"
                    data-icon="delete"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    focusable="false"
                    class=""
                    @click="deleteListFile(index)"
                  ><path
                    d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                  /></svg>
                </span>
              </div>
            </div>
          </div>
          <div
            class="scan-files-add-button"
            @click="addNewImage"
          >
            <span class="add-button">
              <i>
                <svg
                  viewBox="64 64 896 896"
                  data-icon="plus"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""
                ><path
                  d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                /><path
                  d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"
                /></svg>
              </i>
              <div> {{ tr('_.more', true) }}</div>
            </span>
          </div>
        </div>
        <a-divider/>
      </div>
      <div
        v-show="currentStep === 1"
        id="crop_footer"
        class="btn_footer"
        style="margin-top:10px;"
      >
        <a-button
          type="danger"
          @click="DeleteSelectedImage"
        >
          <a-icon type="delete"/>
          <span class="hide-xs">{{ tr('_.delete', true) }}</span>
        </a-button>
        <a-space
          :size="'small'"
          style="float:right;"
        >
          <a-button
            :loading="toggleLoading"
            @click="resetSelection"
          >
            <a-icon type="gateway"/>
            <span class="hide-xs">{{ tr('_.toggle', true) }}</span>
          </a-button>
          <a-button
            type="primary"
            :loading="loadingCropping"
            @click="transformSelection"
          >
            <a-icon type="check"/>
            <span class="hide-xs">{{ tr('_.apply', true) }}</span>
          </a-button>
        </a-space>
      </div>
      <div
        v-show="currentStep === 2"
        class="btn_footer"
      >
        <div
          v-show="currentOption === 1"
          class="range_options"
        >
          <div style="display:flex; margin:10px 0px;">
            <label
              for="inp_brightness"
              style="margin-right:10px"
            >
              <img
                width="18px"
                style="display:block;"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABOZJREFUaEPtmscLXUUUh7/Yu2BiXKkLNehGXKgJsVesILagLsSaxAZqRPMfWKIbe9eFBRtiB7txI1HELBTLxuhC7A27Il+YkXEy9965970LCeSsHvfOnDm/mXPm/M65bwbjym5B/cdjLTNjLMXALcDioN/fF46x1lgA3PmPMoPnAFM/ibEAHAy8mgE4BHht2qewHkDDjq5VJ7ARsDHwa4/j3wt4Nxvvs/d66Ngc+AP4u21OlwudBNwNbAXcCVwM/FVhxIbB3/cPY5cDxkCrMWGsG3YjcB7wE3Au8HjTmm0AVPQ1sG0y+VHg9EoQmwJugKIBv1cAd80HgVOSsT8AM5vAtwHYAvgRcDdTEcRplbtZYfN/Q1xH40/NJnlqWze5cJcL3QosKlhhUjI5lWQzYF9gR2B2GPAlsApYAfzWMO8C4ObCO23wXVG6AJSOVEX3AmdnGo8HzgSOArZsWO9n4AXgfuCZbMw9wFmF02512S4A6hPEA9nR6qOPhcXc7euBGLC1bmNgXx5OxTknA7pnlEeAM7rirQaACvXP84F9gOcS470pPHav2SHijXZZuHUiiGMCqDtq4qwWQMm4a4ErhlhdmHMNcNUQXUMBGNgGV0leBDx+XeTzMMCAPiC44eEN8xYC7novGQJgbjAudxvZpy71RocFB4WkGGuFOPxPYD7wdh8EQwC4s3nAavQJwHeVi28HPBlOJZ3yOiCPqpa+ALwqn8q0u/PzehgfpwviLWDXTN9xwLO1CPoCkBKcmCk/tMD9a9fXnfIawas0z8aN+voAkB1+lSUpA/bIWmsbxr0EHJa8k8CZwZsy9v/UpAAMqp2Tt98E+vtPeHYgoI+mYtDeNSEA88vtmQ5vrDfDM3PQnoHQxWGfxvI0AkgL8FSXSrz2ZJKmdDNyKrsDH04IQB0fZDpc6yFARvsysF9hjdWNAgGUCvB0vOlclrgEuC5TJEuU30wi1hq6TSquJT0pbVo6bk4NgLgb8pZlIwDYBpDzp+JaN9QCcKJ8pkRZvfOPGNmF9gDezwBYbzwcXMggLxFFbb6oLYitxlYCbUFsAFpqTiJSiNsyBWkQbxCCeFYyZo0grjHAQsVrVJ+N4u54QpOIQWouiWI8bF9ZgtInD7iANUCsc+OC3uGvDERQar9IBBfU6usLwDT/dKbcdqFU4tvaRcM4C3WpxC7ZPOuB52t19QWgXombPpqKwS6ZqwWh8ZK5PDilFbZfqmUIAEtIE1xOpz8JVVveE82N0d/l/fnO28SSTr9TbT30joGou3RzxHcGZSxoPgsPd0oKmjRgU1sH3WhDTiAuejVwZZ/dahmrrqVDdNUCkFC563tnRb1tPxPKJkMWDx2HS4Gbwny7HUdPu6gvtVXk67EFIigphty+jxiwcp7o8xqv60WZSlulT2Pr2NDYcgfTZJeCMknZ2LovnGT6bpTGVlNrUd7U1JUwY3sqBu4OwcIvQmvRgr2pyev3tFK7UpoRv7WtccLrSnNXxvpLyT/bABi4VmVD2+ueRKyfJ2mvfw9I5IrfFrpuIXmPJaOFi8nnkq5eZdglwVt+xkrKxCfvGfKB4xzgiabboQuA8zTGrFtVZIeFpvGJyRO02TXRJ6Y+12I6dq36yDcExHoAtbtWEwO1utJx6/xfDQSTNgpWF+BDdqJrzlgnENcd/e82/wJE7QSKDBdKHgAAAABJRU5ErkJggg=="
              >
            </label>
            <input
              id="inp_brightness"
              v-model="brightnessVal"
              type="range"
              max="100"
              style="width:100%"
            >
            <span style="margin-left:20px">{{ brightnessVal }}</span>
          </div>
          <div style="display:flex">
            <label
              for="inp_contrast"
              style="margin-right:10px"
            >
              <img
                width="18px"
                style="display:block;"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAPNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWIeG4QAAAFF0Uk5TAAETYpq1vtTfBXe6/3gHiNjTA13Lz9v9xYJBMYMLxnAQqw7M2XL+DQzNr61/mfvKBt2H0oC4vba/4vrgt59jb3GJ/HtfmIR+9qyBrtxAMNp5MYkD6AAAAfxJREFUeJylVulCwjAMLmNjMDvQgYjcCgxv8eAQkFsU7/d/GpsOBt26rWB+dG3zfU3apUkR+q+EpLCsRNRoVI0oclgKBaBj2h5mRNdiPpx4Yh+75CAR94AbyZSNOkwfrSmZpMFdXl7qj/VsDiHGSp5jpKBQVbFUtsasXycFJ/60AvPVmj1hY00T2lSdxZ+dw+xFDLkJKwZjI35Jnd/AO1wiomzsw8jDzBVjkwFTG/L6rJIwvkbeBEwPOWk7VHT4zyHckOa2YalCCTK4c+CdezDBxr0VJTGV9GvIn0CdqlqraqT74MS7Twk2rlGPHkmvFEwAaYJPEvTKYgQsEU2YfFsuPIcABxUmGghSXYQAIhNNm3yzooQ20XTINydK6BANuZVPbjyP0MVYJZoexmlhQm8XwtYubb3pPt7yWJ/xMqhECPDjBlgwNCBcB2iH4KPhPQwmgAEa3kjHQhcIrpwVpPSKjoIIYGB5RUNjHJwEJkCYLksFTTN9/zQDDq3SDEIzmHvxI0ygmdlKYw7jV2/CGzQbqRLFFzDz7pWMu9AoTFEp0Gr1IZzuEapTxufITeAXFLtkZYZl3qYXrpK1URTz2te3RTBXpXTOrbzGrGKvqP6YE3vQmnHLLhgZV7FL1GnDA47g6aA3Wfiv7vd0oBxpILc7aq+ndtryIOhxIiB/895NLPhLJI4AAAAASUVORK5CYII="
              >
            </label>
            <input
              id="inp_contrast"
              v-model="contrastVal"
              type="range"
              max="100"
              min="0"
              style="width:100%"
            >
            <span style="margin-left:20px">{{ contrastVal }}</span>
          </div>
        </div>
        <div
          v-show="currentOption === 0"
          class="filters"
        >
          <a-radio-group
            :value="filter"
            style="margin:auto;"
            @change="changeFilter"
          >
            <a-space>
              <a-radio-button value="original">
                {{ tr('_.original', true) }}
              </a-radio-button>
              <a-radio-button value="scan">
                {{ tr('_.scan', true) }}
              </a-radio-button>
              <a-radio-button value="gray">
                {{ tr('_.gray', true) }}
              </a-radio-button>
              <a-radio-button value="sepia">
                {{ tr('_.sepia', true) }}
              </a-radio-button>
              <a-radio-button value="invert">
                {{ tr('_.invert', true) }}
              </a-radio-button>
            </a-space>
          </a-radio-group>
        </div>
        <br>
        <div
          class="image_options"
          style="text-align:center;"
        >
          <a-space :size="'small'">
            <a-button
              @click="currentStep = 1"
            >
              <a-icon type="left"/>
              <span class="hide-xs">{{ tr('_.back', true) }}</span>
            </a-button>
            <a-button
              :class="{active_btn: currentOption === 1}"
              @click="currentOption = 1"
            >
              <a-icon type="control"/>
              <span class="hide-xs">{{ tr('_.adjust', true) }}</span>
            </a-button>
            <a-button
              :class="{active_btn: currentOption === 0}"
              @click="currentOption = 0"
            >
              <a-icon type="bar-chart"/>
              <span class="hide-xs">{{ tr('_.filter', true) }}</span>
            </a-button>
            <a-button
              @click="rotateImage"
            >
              <a-icon type="redo"/>
              <span class="hide-xs">{{ tr('_.rotate', true) }}</span>
            </a-button>
            <a-button
              type="primary"
              :loading="loadingExport"
              @click="goToExport"
            >
              <a-icon type="check"/>
              <span class="hide-xs">{{ tr('_.apply', true) }}</span>
            </a-button>
          </a-space>
        </div>
      </div>
      <div
        v-show="currentStep === 3"
        class="btn_footer"
      >
        <div class="image_options">
          <a-row>
            <a-col :span="8">
              <a-button
                type="dashed"
                :disabled="!allowBack"
                @click="currentStep = 2;"
              >
                <a-icon type="left"/>
                <span class="hide-xs">{{ tr('_.back', true) }}</span>
              </a-button>
            </a-col>
            <a-col
              :span="8"
              :offset="8"
            >
              <a-button
                type="primary"
                style="float:right"
                :disabled="exportImages.length == 0"
                :loading="loadingPDFUpload"
                @click="uploadToFiles"
              >
                <a-icon type="upload"/>
                <span class="hide-xs">Upload PDF</span>
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
      <div
        v-show="currentStep === 0 && ImageStep > 0"
        class="btn_footer"
      >
        <a-divider/>
        <a-button
          type="primary"
          @click="currentStep = 3; ImageStep = ImageStep - 1"
        >
          {{ tr('_.finish', true) }}
          <a-icon type="right"/>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api'
import { jsPDF as JSPDF } from 'jspdf'
import Widget from '@/components/layout-components/widgets/Widget'

export default {
  name: 'WidgetScan',
  extends: Widget,
  props: {
    openstart: {
      type: Boolean,
      default: false,
    },
    filelist: {
      type: [Array, Object],
      default: undefined,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ViewImage: false,
    currentStep: 0,
    currentOption: 1,
    filter: 'original',
    imageFile: null,
    imageData: null,
    zoomCanvas: null,
    imageCropFile: null,
    maxImageSize: 2000,
    brightnessVal: 50,
    contrastVal: 50,
    grayscaleVal: 0,
    sepiaVal: 0,
    invertVal: 0,
    rotationVal: 0,
    viewportWidth: 600,
    initialWidth: 0,
    activeImageWidth: 0,
    activeImageHeight: 0,
    mainCanvasWidth: 600,
    mainCanvasHeight: 800,
    startScaledWidth: 600,
    startSCaledHeight: 800,
    scaledWidth: 600,
    scaledHeight: 800,
    control_w: 40,
    control_h: 40,
    mouseX: 0,
    mouseY: 0,
    startX: 0,
    startY: 0,
    controlA: 0,
    controlB: 0,
    initialMouseX: 0,
    initialMouseY: 0,
    initialTouchX: 0,
    initialTouchY: 0,
    selectedControl: null,
    selectedControlExt: 'n',
    controlMouseDown: false,
    showZoom: false,
    scaleFactor: 1,
    isLoaded: true,
    cropLoaded: false,
    bbControl: false,
    exportImages: [],
    ImageStep: 0,
    allowBack: true,
    loadingPDFUpload: false,
    loadingExport: false,
    loadingCropping: false,
    initialCords: [],
    toggleSelection: false,
    toggleLoading: false,
  }),
  computed: {
    cv () {
      return window.cv
    },
    identifier () {
      return this.config.identifier
    },
    canvasSize () {
      var ret = {}
      ret.width = document.getElementById('CanvasWrapper').offsetWidth
      ret.height = document.getElementById('CanvasWrapper').offsetHeight
      return ret
    },
    zoomBoxPos () {
      if (this.selectedControlExt === 'nw') {
        return true
      } else {
        return false
      }
    },
    requestData () {
      const data = {}
      if (this.module) {
        data['module'] = this.module.identifier
      }
      if (this.path) {
        data['path'] = this.path
      }
      return data
    },
    fileName () {
      var currentdate = new Date()

      var month = String(currentdate.getMonth() + 1).padStart(2, '0')
      var day = String(currentdate.getDate()).padStart(2, '0')
      var year = currentdate.getFullYear()
      var hour = currentdate.getHours()
      var minute = currentdate.getMinutes()
      var outputdate = day + '-' + month + '-' + year + ' ' + hour + ':' + minute
      outputdate = Date.now()

      return 'Scan_' + outputdate + '.pdf'
    },
  },
  watch: {
    'brightnessVal': function (val, oldVal) {
      this.setCtxFilter()
    },
    'contrastVal': function (val, oldVal) {
      this.setCtxFilter()
    },
    'currentStep': function (val, oldVal) {
      if (val === 1) {
        this.$nextTick(() => {
          this.resizeHandler()
        })
      }
    },
  },
  mounted () {
    var zc = document.getElementById('zoomCanvas')
    this.zoomCanvas = zc
    this.drawBoundingBox()

    if (this.openstart) {
      this.OpenFileFromCamera()
    }
    window.addEventListener('resize', this.resizeHandler)
    this.viewportWidth = document.getElementById('viewport-box').offsetWidth
  },
  methods: {
    imageUploadChange (e) {
      var reader = e.target.files
      var files = e.target.files
      reader = new FileReader()
      reader.readAsDataURL(files[0])
      this.isLoaded = false
      this.bbControl = false
      this.ViewImage = true
      this.currentStep = 1
      reader.onload = (e) => {
        var imageFile = e.target.result

        var img = document.getElementById('image-prev')

        img.removeAttribute('height')
        img.removeAttribute('width')
        img.src = imageFile
        var vue = this

        img.onload = function () {
          var imgWidth = this.width
          var imgHeight = this.height

          /* Rescale */
          var maxSize = parseInt(vue.maxImageSize)
          if (imgWidth > maxSize || imgHeight > maxSize) {
            if (imgWidth > imgHeight) {
              imgHeight *= maxSize / imgWidth
              imgWidth = maxSize
            } else {
              imgWidth *= maxSize / imgHeight
              imgHeight = maxSize
            }
          }
          /* Rescale End */

          img.width = imgWidth
          img.height = imgHeight

          vue.mainCanvasWidth = imgWidth
          vue.mainCanvasHeight = imgHeight

          vue.$nextTick(function () {
            this.isLoaded = true
            this.bbControl = true

            let prevCan = document.getElementById('previewCanvas')
            let prevCanCtx = prevCan.getContext('2d')

            prevCanCtx.clearRect(0, 0, imgWidth, imgHeight)
            prevCanCtx.drawImage(img, 0, 0, imgWidth, imgHeight)

            vue.scaleFactor = imgWidth / document.getElementById('viewport-box').offsetWidth
            this.viewportWidth = document.getElementById('viewport-box').offsetWidth
            this.initialWidth = document.getElementById('CanvasWrapper').offsetWidth
            this.activeImageWidth = imgWidth
            this.activeImageHeight = imgHeight

            vue.scaledWidth = imgWidth / vue.scaleFactor
            vue.scaledHeight = imgHeight / vue.scaleFactor

            var scale = vue.scaleCanvasImage(img).scalefactor
            img = vue.scaleCanvasImage(img).img

            vue.edgeDetection(img, scale)
          })
        }
      }
    },
    resortCoords (cArr) {
      var ret = []
      var x = {}
      var y = {}
      for (var i = 0; i < 4; i++) {
        x[i] = (cArr[i].x)
        y[i] = (cArr[i].y)
      }
      var smallest = x[0]
      var smallestSec = x[0]
      var smallestX = 0
      var smallestSecX = 0

      var highest = x[0]
      var highestSec = x[0]
      var highestX = 0
      var highestSecX = 0

      var TopLeft = []
      var BottomLeft = []
      var TopRight = []
      var BottomRight = []

      /* Get Left Cords */
      for (let j = 1; j < 4; j++) {
        if (x[j] < smallest) {
          smallest = x[j]
          smallestX = j
        } else if (x[j] < smallestSec) {
          smallestSec = x[j]
          smallestSecX = j
        }
      }
      if (y[smallestX] < y[smallestSecX]) {
        TopLeft.x = x[smallestX]
        TopLeft.y = y[smallestX]
        BottomLeft.x = x[smallestSecX]
        BottomLeft.y = y[smallestSecX]
      } else {
        TopLeft.x = x[smallestSecX]
        TopLeft.y = y[smallestSecX]
        BottomLeft.x = x[smallestX]
        BottomLeft.y = y[smallestX]
      }
      /* Get Left Cords */

      /* Get Right Cords */

      for (let j = 1; j < 4; j++) {
        if (x[j] > highest) {
          highestSec = highest
          highestSecX = highestX
          highest = x[j]
          highestX = j
        } else if (x[j] > highestSec) {
          highestSec = x[j]
          highestSecX = j
        }
      }

      if (y[highestX] < y[highestSecX]) {
        TopRight.x = x[highestX]
        TopRight.y = y[highestX]
        BottomRight.x = x[highestSecX]
        BottomRight.y = y[highestSecX]
      } else {
        TopRight.x = x[highestSecX]
        TopRight.y = y[highestSecX]
        BottomRight.x = x[highestX]
        BottomRight.y = y[highestX]
      }

      ret = [{ x: TopLeft.x, y: TopLeft.y }, { x: BottomLeft.x, y: BottomLeft.y }, {
        x: BottomRight.x,
        y: BottomRight.y,
      }, { x: TopRight.x, y: TopRight.y }]
      return ret
    },
    scaleCanvasImage (image, size) {
      var ret = []
      var maxSize
      if (!size) { maxSize = 300 } else { maxSize = size }
      var canvas = document.createElement('canvas')

      var width = image.width
      var height = image.height

      ret.width = image.width
      ret.height = image.height

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width
          width = maxSize
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }
      }
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(image, 0, 0, width, height)

      ret.scalefactor = image.width / width
      ret.img = canvas
      return ret
    },
    handleMouseMove (e) {
      this.mouseX = parseInt(e.offsetX)
      this.mouseY = parseInt(e.offsetY)
      this.zoomHelper(e, this.selectedControlExt)
      if (this.controlMouseDown) {
        var dx = e.clientX - this.initialMouseX
        var dy = e.clientY - this.initialMouseY

        if (e.type === 'touchmove') {
          dx = e.touches[0].clientX - this.initialTouchX
          dy = e.touches[0].clientY - this.initialTouchY
        }
        var a = ''
        var b = ''

        var newX = this.startX + dx
        var newY = this.startY + dy

        newX = this.isValidCord('x', newX)
        newY = this.isValidCord('y', newY)
        switch (this.selectedControlExt) {
          case 'n':
            a = document.getElementById('control_nw')
            b = document.getElementById('control_ne')

            a.style.top = this.isValidCord('y', this.controlA + dy) + 'px'
            b.style.top = this.isValidCord('y', this.controlB + dy) + 'px'

            newY = this.isValidCord('y', this.startY + dy)

            this.selectedControl.style.top = newY + 'px'
            break
          case 's':
            a = document.getElementById('control_sw')
            b = document.getElementById('control_se')
            a.style.top = this.isValidCord('y', this.controlA + dy) + 'px'
            b.style.top = this.isValidCord('y', this.controlB + dy) + 'px'

            newY = this.isValidCord('y', this.startY + dy)

            this.selectedControl.style.top = newY + 'px'
            break
          case 'e':
            a = document.getElementById('control_ne')
            b = document.getElementById('control_se')
            a.style.left = this.isValidCord('x', this.controlA + dx) + 'px'
            b.style.left = this.isValidCord('x', this.controlB + dx) + 'px'

            newX = this.isValidCord('x', this.startX + dx)

            this.selectedControl.style.left = newX + 'px'
            break
          case 'w':
            a = document.getElementById('control_nw')
            b = document.getElementById('control_sw')
            a.style.left = this.isValidCord('x', this.controlA + dx) + 'px'
            b.style.left = this.isValidCord('x', this.controlB + dx) + 'px'

            newX = this.isValidCord('x', this.startX + dx)

            this.selectedControl.style.left = newX + 'px'
            break
          default:
            this.selectedControl.style.top = newY + 'px'
            this.selectedControl.style.left = newX + 'px'
            break
        }
        this.drawBoundingBox()
      }
    },
    handleTouchMove (e) {
      this.mouseX = parseInt(e.offsetX)
      this.mouseY = parseInt(e.offsetY)
    },
    handleMouseLeave (e) {
      this.controlMouseDown = false
      this.showZoom = false
    },
    handleMouseDown (e) {
      this.clearSelection()
      this.zoomHelper(e, this.selectedControlExt)
    },
    handleMouseUp (e) {
      this.controlMouseDown = false
      this.showZoom = false
      this.getControlCords()
    },
    moveControl (e, which) {
      this.selectedControl = document.getElementById('control_' + which)
      this.selectedControlExt = which
      this.controlMouseDown = true
      this.showZoom = true

      this.startX = this.selectedControl.offsetLeft
      this.startY = this.selectedControl.offsetTop

      switch (which) {
        case 'n':
          this.controlA = document.getElementById('control_nw').offsetTop
          this.controlB = document.getElementById('control_ne').offsetTop
          break
        case 's':
          this.controlA = document.getElementById('control_sw').offsetTop
          this.controlB = document.getElementById('control_se').offsetTop
          break
        case 'e':
          this.controlA = document.getElementById('control_ne').offsetLeft
          this.controlB = document.getElementById('control_se').offsetLeft
          break
        case 'w':
          this.controlA = document.getElementById('control_nw').offsetLeft
          this.controlB = document.getElementById('control_sw').offsetLeft
          break
      }

      this.initialMouseX = e.clientX
      this.initialMouseY = e.clientY
      e.preventDefault()
      if (e.type === 'touchstart') {
        this.initialTouchX = e.touches[0].clientX
        this.initialTouchY = e.touches[0].clientY
      }
    },
    setBoundingBox (x1, y1, x2, y2, x3, y3, x4, y4) {
      var controlNw = document.getElementById('control_nw')
      var controlNe = document.getElementById('control_ne')
      var controlSw = document.getElementById('control_sw')
      var controlSe = document.getElementById('control_se')

      var controlNwLeft = ((parseInt(x1) / this.scaleFactor) / this.scaleFactor)
      var controlNwTop = ((parseInt(y1) / this.scaleFactor) / this.scaleFactor)

      var controlSwLeft = ((parseInt(x2) / this.scaleFactor) / this.scaleFactor)
      var controlSwTop = ((parseInt(y2) / this.scaleFactor) / this.scaleFactor)

      var controlSeLeft = ((parseInt(x3) / this.scaleFactor) / this.scaleFactor)
      var controlSeTop = ((parseInt(y3) / this.scaleFactor) / this.scaleFactor)

      var controlNeLeft = ((parseInt(x4) / this.scaleFactor) / this.scaleFactor)
      var controlNeTop = ((parseInt(y4) / this.scaleFactor) / this.scaleFactor)

      if (controlNwLeft < controlNeLeft && controlNwTop < controlSwTop && controlSeLeft > controlSwLeft && controlSeTop > controlNeTop) {
        // correct
      } else {
        controlNwLeft = 0
        controlNwTop = 0
        controlSwLeft = 0
        controlSwTop = this.scaledHeight
        controlSeLeft = this.scaledWidth
        controlSeTop = this.scaledHeight
        controlNeLeft = this.scaledWidth
        controlNeTop = 0
      }

      if ((controlNeLeft - controlNwLeft) < 100 || (controlSeLeft - controlSwLeft) < 100 || (controlSeTop - controlNeTop) < 100 || (controlSwTop - controlNwTop) < 100) {
        controlNwLeft = 0
        controlNwTop = 0
        controlSwLeft = 0
        controlSwTop = this.scaledHeight
        controlSeLeft = this.scaledWidth
        controlSeTop = this.scaledHeight
        controlNeLeft = this.scaledWidth
        controlNeTop = 0
      }
      controlNw.style.left = controlNwLeft + 'px'
      controlNw.style.top = controlNwTop + 'px'

      controlSw.style.left = controlSwLeft + 'px'
      controlSw.style.top = controlSwTop + 'px'

      controlSe.style.left = controlSeLeft + 'px'
      controlSe.style.top = controlSeTop + 'px'

      controlNe.style.left = controlNeLeft + 'px'
      controlNe.style.top = controlNeTop + 'px'
      this.drawBoundingBox()
    },
    drawBoundingBox () {
      var BoundingBoxCanvas = document.getElementById('boundingbox')
      var ctx = BoundingBoxCanvas.getContext('2d')
      var BBctx = ctx
      var controlNw = document.getElementById('control_nw')
      var controlNe = document.getElementById('control_ne')
      var controlSw = document.getElementById('control_sw')
      var controlSe = document.getElementById('control_se')
      var controlNorth = document.getElementById('control_n')
      var controlSouth = document.getElementById('control_s')
      var controlEast = document.getElementById('control_e')
      var controlWest = document.getElementById('control_w')

      var NorthEastX = (controlNe.offsetLeft)
      var NorthEastY = (controlNe.offsetTop)

      var NorthWestX = (controlNw.offsetLeft)
      var NorthWestY = (controlNw.offsetTop)

      var SouthEastX = (controlSe.offsetLeft)
      var SouthEastY = (controlSe.offsetTop)

      var SouthWestX = (controlSw.offsetLeft)
      var SouthWestY = (controlSw.offsetTop)

      var calcNorthY = 0
      var calcNorthX = 0
      var calcSouthY = 0
      var calcSouthX = 0
      var calcWestX = 0
      var calcWestY = 0
      var calcEastX = 0
      var calcEastY = 0

      calcNorthX = this.calcMid(NorthEastX, NorthWestX)
      calcNorthY = this.calcMid(NorthEastY, NorthWestY)
      calcSouthX = this.calcMid(SouthEastX, SouthWestX)
      calcSouthY = this.calcMid(SouthEastY, SouthWestY)

      calcWestX = this.calcMid(NorthWestX, SouthWestX)
      calcWestY = this.calcMid(NorthWestY, SouthWestY)
      calcEastX = this.calcMid(NorthEastX, SouthEastX)
      calcEastY = this.calcMid(NorthEastY, SouthEastY)

      controlSouth.style.top = calcSouthY + 'px'
      controlSouth.style.left = calcSouthX + 'px'

      controlNorth.style.top = calcNorthY + 'px'
      controlNorth.style.left = calcNorthX + 'px'

      controlWest.style.top = calcWestY + 'px'
      controlWest.style.left = calcWestX + 'px'

      controlEast.style.top = calcEastY + 'px'
      controlEast.style.left = calcEastX + 'px'

      BBctx.clearRect(0, 0, this.scaledWidth, this.scaledHeight)
      BBctx.beginPath()
      BBctx.moveTo(NorthWestX, NorthWestY)
      BBctx.lineTo(NorthEastX, NorthEastY)
      BBctx.lineTo(SouthEastX, SouthEastY)
      BBctx.lineTo(SouthWestX, SouthWestY)
      BBctx.closePath()
      BBctx.strokeStyle = 'rgb(26,170,233,1)'
      BBctx.lineWidth = 3
      BBctx.stroke()
    },
    calcMid (a, b) {
      var ret
      if (a > b) {
        ret = a + ((b - a) / 2)
      } else {
        ret = b + ((a - b) / 2)
      }
      return ret
    },
    resetSelection () {
      if (this.toggleSelection) {
        this.toggleLoading = true
        setTimeout(() => {
          var image = document.getElementById('image-prev')
          var scale = this.scaleCanvasImage(image).scalefactor
          var img = this.scaleCanvasImage(image).img
          this.edgeDetection(img, scale)
          this.toggleSelection = false
          this.toggleLoading = false
        }, 20)
      } else {
        this.setBoundingBox(0, 0, 0, 0, 0, 0, 0, 0)
        this.toggleSelection = true
      }
    },
    DeleteSelectedImage () {
      var prevc = document.getElementById('previewCanvas')
      var prevctx = prevc.getContext('2d')
      prevctx.clearRect(0, 0, this.mainCanvasWidth, this.mainCanvasHeight)
      this.ViewImage = false
      this.imageFile = null
      document.getElementById('ImageImport').value = ''
      document.getElementById('CameraImport').value = ''
      this.currentStep = 0
    },
    transformSelection () {
      this.isLoaded = false
      this.loadingCropping = true
      this.toggleLoading = false
      setTimeout(() => {
        this.getControlCords()
        this.currentStep = 2
        this.rotationVal = 0
        this.brightnessVal = 50
        this.contrastVal = 50
        this.grayscaleVal = 0
        this.sepiaVal = 0
        this.invertVal = 0
        this.filter = 'original'

        var controlNw = document.getElementById('control_nw')
        var controlNe = document.getElementById('control_ne')
        var controlSw = document.getElementById('control_sw')
        var controlSe = document.getElementById('control_se')

        var controlN = document.getElementById('control_n')
        var controlE = document.getElementById('control_e')
        var controlS = document.getElementById('control_s')
        var controlW = document.getElementById('control_w')

        var NorthEastX = (controlNe.offsetLeft) * this.scaleFactor
        var NorthEastY = (controlNe.offsetTop) * this.scaleFactor

        var NorthWestX = (controlNw.offsetLeft) * this.scaleFactor
        var NorthWestY = (controlNw.offsetTop) * this.scaleFactor

        var SouthEastX = (controlSe.offsetLeft) * this.scaleFactor
        var SouthEastY = (controlSe.offsetTop) * this.scaleFactor

        var SouthWestX = (controlSw.offsetLeft) * this.scaleFactor
        var SouthWestY = (controlSw.offsetTop) * this.scaleFactor

        var pointsArray = []
        pointsArray = [NorthWestX, NorthWestY, NorthEastX, NorthEastY, SouthEastX, SouthEastY, SouthWestX, SouthWestY]

        const imageHeight = document.getElementById('image-prev').height
        const imageWidth = document.getElementById('image-prev').width

        var newHeight = (controlS.offsetTop * this.scaleFactor) - (controlN.offsetTop * this.scaleFactor)
        var newWidth = (controlE.offsetLeft * this.scaleFactor) - (controlW.offsetLeft * this.scaleFactor)

        let src = window.cv.imread('previewCanvas')
        let dst = new window.cv.Mat()
        let dsize = new window.cv.Size(newWidth, newHeight)
        let srcTri = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, pointsArray)
        let dstTri = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, [0, 0, imageHeight, 0, imageHeight, imageWidth, 0, imageWidth])
        dstTri = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, [0, 0, newWidth, 0, newWidth, newHeight, 0, newHeight])
        let M = window.cv.getPerspectiveTransform(srcTri, dstTri)
        srcTri.delete()
        dstTri.delete()
        window.cv.warpPerspective(src, dst, M, dsize, window.cv.INTER_LINEAR, window.cv.BORDER_CONSTANT, new window.cv.Scalar())
        window.cv.imshow('outputOriginalCanvas', dst)
        src.delete()
        M.delete()

        if (newWidth > this.viewportWidth) {
          var scalFact = newWidth / this.viewportWidth
          var NscaledWidth = this.viewportWidth
          var NscaledHeight = newHeight / scalFact

          let nsize = new window.cv.Size(NscaledWidth, NscaledHeight)
          window.cv.resize(dst, dst, nsize, 0, 0, window.cv.INTER_AREA)
        }
        window.cv.imshow('outputCanvas', dst)
        window.cv.imshow('outputTempCanvas', dst)
        this.isLoaded = true
        this.loadingCropping = false
        dst.delete()
      }, 10)
    },
    zoomHelper (e, which) {
      var control = document.getElementById('control_' + which)
      var x = (control.offsetLeft) * this.scaleFactor
      var y = (control.offsetTop) * this.scaleFactor
      var c = document.getElementById('zoomCanvas')
      var mainC = document.getElementById('previewCanvas')
      var ctx = c.getContext('2d')
      ctx.clearRect(0, 0, 200, 200)
      ctx.drawImage(mainC, x - 100, y - 100, 200, 200, 0, 0, 200, 200)
    },
    edgeDetection (img, scale) {
      if (!scale) scale = 1
      let src = window.cv.imread(img)
      let dst = new window.cv.Mat()
      window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0)
      let ksize = new window.cv.Size(5, 5)
      window.cv.GaussianBlur(dst, dst, ksize, 0, 0, window.cv.BORDER_DEFAULT)
      window.cv.threshold(dst, dst, 100, 200, window.cv.THRESH_BINARY)
      let edges = new window.cv.Mat()
      window.cv.Canny(dst, edges, 100, 200)

      let contours = new window.cv.MatVector() // Var Contours
      let hierarchy = new window.cv.Mat() // Var Hierachy

      window.cv.findContours(edges, contours, hierarchy, window.cv.RETR_LIST, window.cv.CHAIN_APPROX_SIMPLE) // Alle Contours in Var speichern
      var points = this.getBiggestContour(contours, scale)

      edges.delete()
      src.delete()
      dst.delete()
      contours.delete()
      hierarchy.delete()
      var x = {}
      var y = {}
      points = this.resortCoords(points)
      for (var i = 0; i < points.length; i++) {
        x[i] = (points[i].x * this.scaleFactor) * scale
        y[i] = (points[i].y * this.scaleFactor) * scale
      }
      this.$nextTick(function () {
        this.setBoundingBox(x[0], y[0], x[1], y[1], x[2], y[2], x[3], y[3])
        this.getControlCords()
      })
    },
    getBiggestContour (contours, scale) {
      if (!scale) scale = 1
      let cnts = [] // cnts Array
      for (let i = 0; i < contours.size(); i++) { // Für alle Contours do ->
        const tmp = contours.get(i) // Speicher aktive Contour in Var
        // let area = window.cv.contourArea(tmp)

        // if (area > 3000) {
        const peri = window.cv.arcLength(tmp, true) // Get Contour lenght
        let approx = new window.cv.Mat() // Var Approx

        let result = { // Ergebniss Var
          area: window.cv.contourArea(tmp),
          points: [],
        }

        window.cv.approxPolyDP(tmp, approx, 0.02 * peri, true)
        const pointsData = approx.data32S
        for (let j = 0; j < pointsData.length / 2; j++) {
          result.points.push({ x: pointsData[2 * j], y: pointsData[2 * j + 1] })
        }
        if (result.points.length === 4) { cnts.push(result) }
        /* } else {
           cnts[0] = { area: '6000' }
           cnts[0].points = [ { x: 0, y: 0 }, { x: 0, y: 0 }, { x: 0, y: 0 }, { x: 0, y: 0 } ]
         } */
      }
      if (contours.size() === 0) {
        cnts[0] = { area: '6000' }
        cnts[0].points = [{ x: 0, y: 0 }, { x: 0, y: 0 }, { x: 0, y: 0 }, { x: 0, y: 0 }]
      }
      cnts.sort((a, b) => b.area - a.area) // Sortiere nach Größe -> 0 = Größte
      return cnts[0].points
    },
    isValidCord (orientation, value) {
      var CanWidth = document.getElementById('CanvasWrapper').offsetWidth
      var CanHeight = document.getElementById('CanvasWrapper').offsetHeight
      if (orientation === 'x') {
        if (value >= 0 && value <= CanWidth) {
          return value
        } else {
          if (value <= 0) {
            return 0
          } else {
            return CanWidth
          }
        }
      } else if (orientation === 'y') {
        if (value >= 0 && value <= CanHeight) {
          return value
        } else {
          if (value <= 0) {
            return 0
          } else {
            return CanHeight
          }
        }
      }
    },
    clearSelection () {
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
    },
    rotateImage () {
      if (this.rotationVal > -270) {
        this.rotationVal = this.rotationVal - 90
      } else {
        this.rotationVal = 0
      }
      let src = window.cv.imread('outputCanvas')
      let dst = new window.cv.Mat()
      let dsize = new window.cv.Size(src.rows, src.cols)
      let center = new window.cv.Point(src.rows / 2, src.rows / 2)
      // You can try more different parameters
      let M = window.cv.getRotationMatrix2D(center, -90, 1)
      window.cv.warpAffine(src, dst, M, dsize, window.cv.INTER_LINEAR, window.cv.BORDER_CONSTANT, new window.cv.Scalar())
      window.cv.imshow('outputCanvas', dst)
      window.cv.imshow('outputTempCanvas', dst)
      this.setCtxFilter()
      src.delete()
      dst.delete()
      M.delete()
    },
    setCtxFilter () {
      var grayscale = this.grayscaleVal
      var sepia = this.sepiaVal
      var invert = this.invertVal
      var brightness = this.brightnessVal /* min 0 max 200 */
      var contrast = this.contrastVal /* min -100 max 100 */
      var src = document.getElementById('outputTempCanvas')

      brightness = 200 / 100 * brightness
      contrast = (200 / 100 * contrast)

      if (this.filter === 'scan') {
        let src = window.cv.imread('outputCanvas')
        let dst = new window.cv.Mat()
        window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0)
        window.cv.adaptiveThreshold(dst, dst, 255, 1, 1, 7, 2)
        window.cv.bitwise_not(dst, dst)
        window.cv.medianBlur(dst, dst, 3)
        window.cv.imshow('outputTempCanvas', dst)
        dst.delete()
      } else {
        src = document.getElementById('outputCanvas')
      }
      var c = document.getElementById('outputTempCanvas')
      var ctx = c.getContext('2d')

      ctx.filter = 'brightness(' + brightness + '%) contrast(' + contrast + '%) grayscale(' + grayscale + '%) sepia(' + sepia + '%) invert(' + invert + '%)'
      ctx.drawImage(src, 0, 0)

      // src.delete()
    },
    changeFilter (e) {
      this.filter = e.target.value

      let src = window.cv.imread('outputCanvas')

      if (this.filter === 'gray') { this.grayscaleVal = 100 } else { this.grayscaleVal = 0 }
      if (this.filter === 'sepia') { this.sepiaVal = 100 } else { this.sepiaVal = 0 }
      if (this.filter === 'invert') { this.invertVal = 100 } else { this.invertVal = 0 }

      if (this.filter === 'original') {
        window.cv.imshow('outputTempCanvas', src)
      }

      this.setCtxFilter()
    },
    goToExport () {
      this.loadingExport = true
      setTimeout(() => {
        var finalCanvas = document.getElementById('resultOriginalCanvas')
        var finalCtx = finalCanvas.getContext('2d')

        var originalCropped = document.getElementById('outputOriginalCanvas')

        var CanvasSrc = document.getElementById('resultOriginalCanvas')

        var finalWidth = originalCropped.width
        var finalHeight = originalCropped.height
        var rotation

        finalCanvas.width = finalWidth
        finalCanvas.height = finalHeight
        finalCtx.clearRect(0, 0, finalWidth, finalHeight)

        var grayscale = this.grayscaleVal
        var sepia = this.sepiaVal
        var invert = this.invertVal
        var brightness = this.brightnessVal /* min 0 max 200 */
        var contrast = this.contrastVal /* min -100 max 100 */

        brightness = 200 / 100 * brightness
        contrast = (200 / 100 * contrast)
        let src = window.cv.imread('outputOriginalCanvas')
        let dst = new window.cv.Mat()
        if (this.rotationVal !== 0) {
          if (this.rotationVal === -90) {
            rotation = window.cv.ROTATE_90_CLOCKWISE
            finalWidth = originalCropped.height
            finalHeight = originalCropped.width
          }
          if (this.rotationVal === -180) { rotation = window.cv.ROTATE_180 }
          if (this.rotationVal === -270) {
            rotation = window.cv.ROTATE_90_COUNTERCLOCKWISE
            finalWidth = originalCropped.height
            finalHeight = originalCropped.width
          }
          window.cv.rotate(src, dst, rotation)
        }
        if (this.filter === 'scan') {
          if (this.rotationVal === 0) {
            window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0)
          } else {
            window.cv.cvtColor(dst, dst, window.cv.COLOR_RGBA2GRAY, 0)
          }
          window.cv.adaptiveThreshold(dst, dst, 255, 1, 1, 7, 2)
          window.cv.bitwise_not(dst, dst)
          window.cv.medianBlur(dst, dst, 3)
        }
        if (this.rotationVal !== 0 || this.filter === 'scan') {
          window.cv.imshow('resultOriginalCanvas', dst)
          src.delete()
          dst.delete()
          CanvasSrc = document.getElementById('resultOriginalCanvas')
        } else {
          CanvasSrc = document.getElementById('outputOriginalCanvas')
        }

        finalCtx.filter = 'brightness(' + brightness + '%) contrast(' + contrast + '%) grayscale(' + grayscale + '%) sepia(' + sepia + '%) invert(' + invert + '%)'
        finalCtx.drawImage(CanvasSrc, 0, 0, finalWidth, finalHeight)
        var originalURL = finalCanvas.toDataURL()

        this.currentStep = 3
        this.allowBack = true
        var canvas = document.getElementById('outputTempCanvas')
        var dataURL = canvas.toDataURL()
        this.exportImages.length = this.ImageStep
        this.exportImages.push({
          thumbimg: dataURL,
          fullimg: originalURL,
          brightness: brightness,
          contrast: contrast,
          filter: this.filter,
          width: originalCropped.width,
          height: originalCropped.height,
        })
        this.loadingExport = false
      }, 10)
    },
    addNewImage () {
      this.currentStep = 0
      this.ImageStep = this.ImageStep + 1
      this.brightnessVal = 50
      this.contrastVal = 50
      this.filter = 'original'
      this.imageFile = null
      document.getElementById('ImageImport').value = ''
      document.getElementById('CameraImport').value = ''
    },
    deleteListFile (index) {
      var vue = this
      this.$confirm({
        title: vue.tr('_.delete_scan', true) + '?',
        onOk () {
          vue.ImageStep = vue.ImageStep - 1
          vue.exportImages.splice(index, 1)
          vue.allowBack = false
        },
        onCancel () {
        },
        class: 'test',
      })
    },
    OpenFileFromCamera () {
      document.getElementById('CameraImport').click()
    },
    OpenFileFromFiles () {
      document.getElementById('ImageImport').click()
    },
    resizeHandler (event) {
      if (this.currentStep === 1) {
        this.scaledWidth = document.getElementById('CanvasWrapper').offsetWidth
        this.scaledHeight = document.getElementById('CanvasWrapper').offsetHeight

        var image = document.getElementById('image-prev')
        this.scaleFactor = image.width / document.getElementById('CanvasWrapper').offsetWidth

        if (this.initialCords.length > 0) {
          this.rescaleBoundingBox(this.scaleFactor)
          this.$nextTick(function () {
            this.drawBoundingBox()
          })
        }
      }
    },
    rescaleBoundingBox (sFactor) {
      var controlNw = document.getElementById('control_nw')
      var controlNe = document.getElementById('control_ne')
      var controlSw = document.getElementById('control_sw')
      var controlSe = document.getElementById('control_se')
      var controlN = document.getElementById('control_n')
      var controlE = document.getElementById('control_e')
      var controlS = document.getElementById('control_s')
      var controlW = document.getElementById('control_w')

      var fact = (this.initialWidth) / (document.getElementById('boundingbox').offsetWidth)

      var NorthEastX = this.initialCords[0].x / fact
      var NorthEastY = this.initialCords[0].y / fact
      var NorthWestX = this.initialCords[1].x / fact
      var NorthWestY = this.initialCords[1].y / fact

      var SouthEastX = this.initialCords[2].x / fact
      var SouthEastY = this.initialCords[2].y / fact
      var SouthWestX = this.initialCords[3].x / fact
      var SouthWestY = this.initialCords[3].y / fact

      var calcNorthY = 0
      var calcNorthX = 0
      var calcSouthY = 0
      var calcSouthX = 0
      var calcWestX = 0
      var calcWestY = 0
      var calcEastX = 0
      var calcEastY = 0

      calcNorthX = this.calcMid(NorthEastX, NorthWestX)
      calcNorthY = this.calcMid(NorthEastY, NorthWestY)
      calcSouthX = this.calcMid(SouthEastX, SouthWestX)
      calcSouthY = this.calcMid(SouthEastY, SouthWestY)

      calcWestX = this.calcMid(NorthWestX, SouthWestX)
      calcWestY = this.calcMid(NorthWestY, SouthWestY)
      calcEastX = this.calcMid(NorthEastX, SouthEastX)
      calcEastY = this.calcMid(NorthEastY, SouthEastY)

      controlS.style.top = calcSouthY + 'px'
      controlS.style.left = calcSouthX + 'px'

      controlN.style.top = calcNorthY + 'px'
      controlN.style.left = calcNorthX + 'px'

      controlW.style.top = calcWestY + 'px'
      controlW.style.left = calcWestX + 'px'

      controlE.style.top = calcEastY + 'px'
      controlE.style.left = calcEastX + 'px'

      controlNe.style.top = NorthEastY + 'px'
      controlNe.style.left = NorthEastX + 'px'

      controlNw.style.top = NorthWestY + 'px'
      controlNw.style.left = NorthWestX + 'px'

      controlSe.style.left = SouthEastX + 'px'
      controlSe.style.top = SouthEastY + 'px'
      controlSw.style.left = SouthWestX + 'px'
      controlSw.style.top = SouthWestY + 'px'
    },
    drawToPDF () {
      var ImageCount = this.exportImages.length
      const doc = new JSPDF({ unit: 'px', format: 'a4' })
      var pageWidth = doc.internal.pageSize.getWidth()
      var pageHeight = doc.internal.pageSize.getHeight()

      var imageWidth = 0
      var imageHeight = 0
      var marginX = 0
      var marginY = 0
      var imgDataUrl = ''

      if (ImageCount) {
        for (let i = 0; i < ImageCount; ++i) {
          imgDataUrl = this.exportImages[i].fullimg
          imageWidth = this.exportImages[i].width
          imageHeight = this.exportImages[i].height

          var imgProps = doc.getImageProperties(imgDataUrl)

          if (imgProps.width > pageWidth || imgProps.height > pageHeight) {
            if (imgProps.width > imgProps.height) {
              imageWidth = pageWidth
              imageHeight = (imgProps.height * pageWidth) / imgProps.width
            } else {
              imageWidth = (imgProps.width * pageHeight) / imgProps.height
              imageHeight = pageHeight
            }
          }

          marginX = (pageWidth - imageWidth) / 2
          marginY = (pageHeight - imageHeight) / 2

          if (i !== 0) { doc.addPage() }
          doc.addImage(imgDataUrl, 'PNG', marginX, marginY, imageWidth, imageHeight, '', 'FAST')
        }
        return doc.output('blob')
      }
    },
    uploadToFiles () {
      this.loadingPDFUpload = true
      setTimeout(() => {
        const blob = this.drawToPDF()
        const pdfBlob = new Blob([blob], { type: 'application/pdf' })
        var id = 0
        if (this.filelist !== null) {
          const ids = this.filelist.map(object => { return object.uid })
          id = Math.max(ids) + 1
        }
        var fileListObject = []
        var FileObject = []
       if (this.filelist !== null) {
         fileListObject = this.filelist
        }
        Api.files().uploadFile({
          file: pdfBlob,
          module: this.data.module,
          name: this.fileName,
          virtualPath: this.data.path,
        }).then(response => response.json())
          .then(res => {
            FileObject = {
              name: this.fileName,
              originFileObj: new File([pdfBlob], this.fileName, { type: 'application/pdf' }),
              status: 'done',
              type: 'application/pdf',
              response: res,
              size: res.data.size,
              uid: id,
            }
            /* fileListObject.push({
              name: this.fileName,
              originFileObj: new File([pdfBlob], this.fileName, { type: 'application/pdf' }),
              response: res,
              status: 'done',
              type: 'application/pdf',
              size: res.data.size,
              percent: 100,
            }) */
            fileListObject.push(FileObject)
            this.$emit('change', { file: FileObject, fileList: fileListObject })
            this.$message.success('PDF Uploaded')
            this.loadingPDFUpload = false
          })
      }, 10)
    },
    getControlCords () {
      var controlNw = document.getElementById('control_nw')
      var controlNe = document.getElementById('control_ne')
      var controlSw = document.getElementById('control_sw')
      var controlSe = document.getElementById('control_se')

      this.initialCords.length = 0
      this.initialCords.push({ x: controlNe.offsetLeft, y: controlNe.offsetTop })
      this.initialCords.push({ x: controlNw.offsetLeft, y: controlNw.offsetTop })
      this.initialCords.push({ x: controlSe.offsetLeft, y: controlSe.offsetTop })
      this.initialCords.push({ x: controlSw.offsetLeft, y: controlSw.offsetTop })
      this.initialWidth = document.getElementById('CanvasWrapper').offsetWidth
    },
  },
}
</script>

<style scoped>
.viewport-box {
  display: inline-block;
  margin: 5px;
  width: 100%;
  max-width: 800px;
}

#image-wrapper {
  margin: auto;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  min-height: 100px;
}

.img-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#image-prev {
  display: none;
}

#mainCanvas {
  position: absolute;
  z-index: 0;
  display: none;
}

#outputCanvas {
  /* max-width:500px; */
  max-width: 100%;
  max-height: 600px;
}

#outputTempCanvas {
  /* max-width:500px; */
  max-width: 100%;
  max-height: 600px;
}

.output-wrapper {
  text-align: Center;
  position: relative;
}

#previewCanvas {
  position: relative;
  width: 100%;
  z-index: 5;
  touch-action: none;
}

.zoomBox {
  position: absolute;
  z-index: 10;
  width: 200px;
  height: 200px;
  pointer-events: 'none';
}

.zoomBox, #zoomCanvas, .zoomBox .zoomCenter {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zoomBox.zoomPosRight {
  right: 20px;
}

#zoomCanvas {
  border-radius: 100%;
  background: #fff;
  border: 2px solid #fff;
}

.zoomBox .zoomCenter {
  color: rgb(26, 170, 233, 1);
  font-size: 30px;
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.imageupload-wrapper {
  margin: 20px 10px;
}

.CanvasWrapper canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.CanvasWrapper {
  position: relative;
  display: inline-block;
  display: flex;
}

.control {
  z-index: 8;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  transform: translate(-20px, -20px);
  position: absolute;
  background: rgb(255, 255, 255, 0.3);
  border: 4px solid rgb(26, 170, 233, 1);
  border-radius: 100%;
}

#control_ne {
  left: 220px;
}

#control_se {
  left: 220px;
  bottom: 0px;
}

#control_sw {
  left: 0px;
  bottom: 0px;
}

#control_nw {
  left: 0px;
  top: 0px;
}

#control_n {
  right: 100px;
}

#control_s {
  right: 100px;
}

#boundingbox {
  z-index: 6;
  width: 100%
}

.filters {
  display: flex;
}

.filters .block {
  height: 50px;
  background: #ccc;
}

.image_options .active_btn {
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
}

.scan-files-list:after {
  clear: both;
  content: ' ';
  display: block;
}

.scan-files-list .scan-files-list-item {
  position: relative;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}

.scan-files-list .scan-files-list-item:hover .scan-files-list-item-options {
  opacity: 1;
}

.scan-files-list .scan-files-list-item img {
  width: 100%;
}

.scan-files-list .scan-files-list-item-options {
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 0.5s;
  display: table;
}

.scan-files-list .scan-files-list-item-options span {
  color: #eee;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.scan-files-list .scan-files-list-item-options span svg:hover {
  color: #fff;
  cursor: pointer;
}

.scan-files-list .scan-files-list-item-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
}

.scan-files-list .scan-files-add-button {
  display: inline-table;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color .3s ease;
}

.scan-files-list .scan-files-add-button:hover {
  border-color: #1890ff;
}

.scan-files-list .scan-files-add-button .add-button {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  color: #666;
}

.scan-files-list .scan-files-add-button .add-button i {
  font-size: 32px;
  color: #999;
}

.imageFileFrom:after {
  content: ' ';
  display: block;
  clear: both;
}

.imageFileFrom-item {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color .3s ease;
}

.imageFileFrom-item:hover {
  border-color: #1890ff;
}

.imageFileFrom-item:hover span i {
  color: #1890ff;
}

.imageFileFrom-item span {
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
}

.imageFileFrom-item span div {
  margin-top: 10px;
}

.imageFileFrom-item i {
  font-size: 44px;
  transition: color .3s ease;
}

/* Media Querys */
@media only screen and (max-width: 599px) {
  #crop_footer {
    margin-top: 40px !important;
  }

  .filters {
    overflow-x: scroll;
    height: 60px;
  }
}

@media only screen and (min-width: 600px ) {
}

@media only screen and (min-width: 980px ) {
}

@media only screen and (min-width: 1260px ) {
}
</style>

<style>
@media (max-width: 480px) {
  .document-scan-steps-container {
    margin-bottom: 0px !important;
    display: flex !important;
    min-height: auto !important;
  }

  .document-scan-steps-container .ant-steps-item-tail {
    display: none !important;
  }

  .document-scan-steps-container .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    min-height: auto;
    display: inline-block;
  }

  .document-scan-steps-container .ant-steps-item-title {
    display: none;
  }

  .document-scan-steps-container .ant-steps-item-title::after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #e8e8e8;
    content: '';
  }
}
</style>
