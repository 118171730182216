<template>
  <component
    :is="compiled"
    v-if="compiled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      v-for="(_, name) in $slots"
      :slot="name"
      :name="name"
    />
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </component>
</template>

<script>
import { addScopedStyle, compile } from 'vue-inbrowser-compiler'
import requireAtRuntime from '@/components/layout-components/widgets/runtime/utils/requireAtRuntime'

export default {
  name: 'Runtime',
  props: {
    runtimeConfig: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      compiled: null,
      scopeId: null,
    }
  },
  watch: {
    'runtimeConfig.code' () {
      this.compiled = (resolve, reject) => resolve(this.compileComponent(this.runtimeConfig.code.trim()))
    },
  },
  mounted () {
    this.scopeId = this.generateScope()
    if (this.runtimeConfig.code) {
      this.compiled = (resolve, reject) => resolve(this.compileComponent(this.runtimeConfig.code.trim()))
    }
  },
  methods: {
    generateScope () {
      return 'v-xxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },

    compileComponent (code) {
      const options = compile(code)

      // eslint-disable-next-line no-new-func
      const func = new Function('require', options.script)
      const component = func((filepath) => requireAtRuntime(this.runtimeConfig.requires, filepath))

      if (options.style) {
        component._scopeId = `data-${this.scopeId}`
        addScopedStyle(options.style, this.scopeId)
      }

      if (this.runtimeConfig.dataScope) {
        const mergeData = { ...component.data(), ...this.runtimeConfig.dataScope }
        component.data = () => mergeData
      }

      if (this.runtimeConfig.components) {
        if (!component.components) {
          component.components = this.runtimeConfig.components
        } else {
          component.components = { ...component.components, ...this.runtimeConfig.components }
        }
      }

      return component
    },
  },
}
</script>

<style scoped>

</style>
