<template>
  <div
    :brezel-component-id="component.id"
    style="text-align: center;"
  >
    <h2
      style="margin-top:1rem; margin-right: 1rem; margin-left: 1rem"
      v-text="tr(component.options.headline, true)"
    />
    <div class="BarChartContainer">
      <a-spin
        v-if="loading"
        style="margin-bottom: 16px"
      />
      <template v-else>
        <div
          v-if="dataPresent"
          style="min-height: 20vw"
        >
          <canvas :id="id"/>
        </div>
        <template v-else>
          {{ $t('_.no_data') }}
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import _ from 'lodash'
import tinycolor from 'tinycolor2'
import { redirect, uniqid } from '@/utils'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { fireWebhookEvent } from '@/webhooks'

export default {
  name: 'BarWidget',
  extends: LayoutComponent,
  props: [],
  data () {
    return {
      chart: null,
      dataPresent: false,
      id: 'chart' + uniqid(),
      loadingTimeout: undefined,
      loading: true,
    }
  },
  computed: {
    chartData () {
      return this.instances.map(instance => instance.value)
    },
    nonNullchartData () {
      return this.chartData.filter(x => !!x)
    },
    colorValues () {
      return _.get(this.component, 'options.colorValues', [])
    },
    maxDisplayedInstances () {
      return _.get(this.component, 'options.maxInstances', 20)
    },
    instances () {
      const allInstances = _.get(this.component, 'options.instances', [])

      if (allInstances.length <= this.maxDisplayedInstances) return allInstances

      // Only display the first n instances and reduce the rest into a single one
      const limitedInstances = allInstances.slice(0, this.maxDisplayedInstances)
      const otherInstances = allInstances.slice(this.maxDisplayedInstances, allInstances.length)
      const otherInstance = otherInstances.reduce((total, current) => ({
        'label': total.label,
        'value': total.value + current.value,
      }), { 'label': `other (${otherInstances.length})`, 'value': 0 })

      limitedInstances.push(otherInstance)
      return limitedInstances
    },
    instancesWithValuesLength () {
      return this.instances.filter(value => value.value > 0).length
    },
  },
  watch: {
    nonNullchartData: function () {
      this.checkForValues()
    },
  },
  beforeDestroy () {
    this.clearLoadingTimeout()
    this.destroyChart()
  },
  mounted () {
    this.loadingTimeout = window.setTimeout(() => {
      if (!this.dataPresent) {
        let chartName = _.get(this.component, 'options.headline', this.name)
        console.info('No data received in 10 seconds, stopping loading animation for widget ' + chartName)
        this.clearLoadingTimeout()
      }
    }, 10000)
    this.initChartDeb = _.debounce(this.initChart, 500)
  },
  methods: {
    clearLoadingTimeout () {
      window.clearTimeout(this.loadingTimeout)
      this.loadingTimeout = -1
      this.checkForValues()
      this.loading = this.loadingTimeout !== -1 && !this.dataPresent
    },
    chartColors () {
      let colors = this.colorValues

      let allColors = []
      this.instances.forEach((element, i) => {
          allColors.push(element.color || colors[i % colors.length])
        }
      )

      return this.mapToHexColors(allColors)
    },
    chartBorderWidths () {
      return this.instances.map(() => 0)
    },
    getDataset (index = 0) {
      return _.get(this.chart, ['data', 'datasets', index])
    },
    getTrigger () {
      return _.get(this.chart, ['data', 'trigger'])
    },
    mapToHexColors (colors) {
      return colors.map(color => {
        const hexColorRegex = /^#([0-9a-f]{3}){1,2}$/i
        if (hexColorRegex.test(color)) return color

        const hexColorWithoutHashRegex = /^([0-9a-f]{3}){1,2}$/i
        if (hexColorWithoutHashRegex.test(color)) return '#' + color
      })
    },
    checkForValues () {
      if (this.nonNullchartData.length > 0) {
        if (!this.dataPresent) {
          this.dataPresent = true
          this.clearLoadingTimeout()
        }
        this.$nextTick(this.initChartDeb)
      }
    },
    addBordersToCurrentElement (itemIndex) {
      const dataset = this.getDataset()

      if (dataset.borderWidth) {
        let setWidth = 3
        if (dataset.borderWidth[itemIndex] !== 0) {
          setWidth = 0
        }
        dataset.borderWidth = this.chartBorderWidths()
        dataset.borderWidth[itemIndex] = setWidth
      }
    },
    showTooltipForCurrentElement (itemIndex) {
      const chartArea = this.chart.chartArea
      this.chart.tooltip.setActiveElements([{
        datasetIndex: 0,
        index: itemIndex,
      }], {
        x: (chartArea.left + chartArea.right) / 2,
        y: (chartArea.top + chartArea.bottom) / 2,
      })
    },
    handleClick (e, items) {
      const element = items.length >= 1 ? items[0] : items
      if (!element) return

      const trigger = _.get(this.getTrigger(), element.index, false)
      if (!trigger || !trigger.type) return

      switch (trigger.type) {
        case 'redirect': {
          this.fireRedirectTrigger(trigger)
          break
        }
        case 'webhook': {
          this.fireWebhookTrigger(trigger)
          break
        }
      }
    },
    handleLabelFocus (event, item) {
      this.addBordersToCurrentElement(item.index)
      this.showTooltipForCurrentElement(item.index)
      this.chart.update()
    },
    fireRedirectTrigger (trigger) {
      const mode = trigger.target_action ?? 'module'
      redirect(mode, {
        module_identifier: trigger.target_module,
        resource_identifier: trigger.target_resource,
      }, trigger.target_anchor)
    },
    fireWebhookTrigger (trigger) {
      fireWebhookEvent(trigger.identifier,
        _.get(trigger, 'target_module', null),
        _.get(trigger, 'target_resource', null),
        _.get(trigger, 'target-data', null)
      )
    },
    destroyChart () {
      if (this.chart !== undefined && this.chart !== null) {
        try {
          this.chart.destroy()
        } catch (e) {
          console.debug('Could not destroy chart', e)
        }
      }
    },
    getCanvasContext () {
      const canvasElement = document.getElementById(this.id)
      if (!canvasElement) return

      return canvasElement.getContext('2d')
    },
    initChart () {
      this.destroyChart()

      const ctx = this.getCanvasContext()
      if (!ctx) return

      let chartPlugins = []
      if (_.get(this.component, 'options.displayDataLabels', false)) chartPlugins.push(ChartDataLabels)

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.instances.map(instance => this.tr(instance.label, true)),
          trigger: this.instances.map(instance => _.get(instance, 'trigger', null)),
          datasets: [
            {
              data: this.chartData,
              backgroundColor: this.chartColors(),
              borderWidth: this.chartBorderWidths(),
            },
          ],
        },
        plugins: chartPlugins,
        options: {
          indexAxis: _.get(this.component, 'options.indexAxis', 'y'),
          responsive: true,
          onClick: this.handleClick,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: function (context) {
                const backgroundColors = context.dataset.backgroundColor
                const backgroundColor = backgroundColors[context.dataIndex % backgroundColors.length]
                return tinycolor(backgroundColor).isDark() ? 'white' : 'black'
              },
              formatter: function (value) {
                if (_.isNumber(value)) return parseFloat(value.toFixed(2))
                return value
              },
              display: function (context) {
                const dataset = context.dataset
                const value = dataset.data[context.dataIndex]
                return value === 0 ? false : 'auto'
              },
              clip: true,
              clamp: true,
              anchor: 'end',
              align: 'start',
              offset: 2,
              font: {
                size: 14,
                weight: 500,
              },
            },
          },
        },
      })
    },
  },
}
</script>
<style>

.BarChartContainer {
  position: relative;
}

</style>
