<template>
  <div :class="{ 'notab': layout.spec.tabs.length < 2 }">
    <a-skeleton
      v-if="loading"
      :paragraph="{rows: 10}"
      active
    />
    <a-form
      v-else
      id="createResourceForm"
      layout="vertical"
      @submit.prevent="handleSubmitOnEnter"
    >
      <Layout
        ref="layout"
        :layout="layout"
        :module="module"
        :resource="resource"
        mode="module.create"
        :buttons="buttons"
        @tabChanged="tabChanged"
        @save="handleSubmit"
      />
    </a-form>
  </div>
</template>

<script>
import store from '@/store'
import { bakeryRedirectLink, checkFieldForErrors, isScreenMobile, optimizeRelationData } from '@/utils'
import { EventBus } from '@/event-bus'
import Layout from '@/components/Layout'
import LayoutDef from '@/layout/layout'
import moment from 'moment'
import Validations from '@/mixins/Validations'
import { fireWebhookEvent, handleRedirect } from '@/webhooks'
import { loadLanguagesAsync } from '@/i18n'
import Api from '../api'
import Module from '@/module/module'
import _, { cloneDeep } from 'lodash'
import { buildCustomButtons, setDocumentTitle } from '../utils'

export default {
  name: 'ModuleCreate',
  components: {
    Layout,
  },
  mixins: [
    Validations,
  ],
  beforeRouteLeave (to, from, next) {
    if (this.canLeave) {
      next()
    } else {
      this.$confirm({
        title: this.$t('_.leave_page_title'),
        content: this.$t('_.leave_page_message'),
        onOk () {
          next()
        },
        onCancel () {
          next(false)
        },
      })
    }
  },
  props: {
    moduleIdentifier: {
      required: false,
      type: String,
      default: '',
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      copy: this.$route.name === 'module.copy',
      /**
       * Compute a JSON object (tree) holding all fields with their components, where the object has exactly the same
       * structure as the resource object. This makes it possible to use the same JSONPath for accessing a specific
       * field value and for accessing a specific field/<Field>.
       */
      fieldTree: {},
      loadingRequests: 0,
      module: {
        identifier: '',
        name: '',
        buttons: {},
      },
      resource: {},
      routeParams: {
        module_identifier: this.$route.params.module_identifier,
      },
      screenIsMobile: isScreenMobile(),
      submitting: false,
      layout: new LayoutDef(),
      changes: false,
      canLeave: true,
    }
  },
  computed: {
    buttons () {
      let buttons = []
      if (!this.inModal) {
        let buttonOptions = this.module.buttons !== {} ? this.module.buttons : null
        let displaySave = true
        let saveTitle
        let saveWebhook

        if (buttonOptions !== null && buttonOptions !== {} && buttonOptions !== undefined) {
          if (buttonOptions.save !== undefined) {
            displaySave = buttonOptions.save.display !== undefined ? buttonOptions.save.display : true
            saveTitle = buttonOptions.save.title !== undefined ? buttonOptions.save.title : undefined
            saveWebhook = buttonOptions.save.trigger !== undefined ? buttonOptions.save.trigger : undefined
          }
        }

        if (displaySave) {
          buttons.push({
            'title': saveTitle || '_.save',
            'action': saveWebhook ? 'function' : null,
            'function': saveWebhook ? () => {
              const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
              fireWebhookEvent(saveWebhook, module, undefined,
                {
                  prototype: { ...this.resource, module: undefined },
                  module_identifier: module.identifier,
                },
                {
                  resource: this.resource,
                  fieldTree: this.fieldTree,
                  context: this.context,
                }
              )
            } : null,
            'style': 'primary',
            'icon': 'save',
            'form': 'createResourceForm',
            'loading': this.submitting,
          })
        }

        if (buttonOptions) {
          const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
          const resourceIdentifier = this.$route.params.resource_identifier
          const customButtons = buildCustomButtons(
            buttonOptions,
            module,
            resourceIdentifier,
            () => ({
              prototype: { ...this.resource, module: undefined },
              module_identifier: module.identifier,
            }),
            this.context,
            'module.create'
          )

          if (customButtons) buttons = buttons.concat(customButtons)
        }

        if (process.env.VUE_APP_DEBUG === 'true') {
          buttons.push({
            'title': 'bakery.dev.buttons.open_bakery',
            'link': {
              external: true,
              href: bakeryRedirectLink(this.$route.params.module_identifier),
            },
            'style': 'dashed',
            'icon': 'sliders',
          })
          buttons.push({
            'title': 'bakery.dev.buttons.reload_translations',
            'function': loadLanguagesAsync,
            'style': 'dashed',
            'icon': 'reload',
          })
        }
      }

      return buttons.reverse()
    },
    loading () {
      return this.loadingRequests !== 0
    },
  },
  watch: {
    module: {
      handler (module) {
        store.commit('setModule', module)
      },
      deep: true,
    },
    resource: {
      handler: function (v) {
        return this.hasChanged()
      },
      deep: true,
    },
  },
  async created () {
    this.routeParams.module_identifier = (this.module_identifier ? this.module_identifier : this.routeParams.module_identifier)
    if (this.moduleIdentifier === '') {
      this.module = Module.byIdentifier(this.$route.params.module_identifier)
    } else {
      this.module = Module.byIdentifier(this.moduleIdentifier)
    }
    if (!this.module) {
      EventBus.$emit('showErrorOrInformation', [true, 404])
      return
    }

    if (this.copy) {
      this.loadingRequests = 1
      await this.fetchResource(true)
    }
    this.initLayout()
    window.addEventListener('resize', () => {
      this.screenIsMobile = isScreenMobile()
    })
  },
  mounted () {
    EventBus.$on('handeRedirect', () => { this.canLeave = true })
  },
  methods: {
    fetchResource (copy = false) {
      Api.fetchEntity(this.$route.params.resource_identifier, this.$route.params.module_identifier)
        .then(resource => {
          this.resource = resource
          if (copy) {
            this.resource.traverse((field, value, object) => {
              object[field.identifier] = field.getOption('copyable', true) ? value : undefined
            })
          }
          this.module = store.getters.getModuleByIdentifier(this.$route.params.module_identifier)
          setDocumentTitle(this, resource)
          this.loadingRequests--
        })
        .catch(console.error)
    },
    initLayout () {
      // cloneDeep so that layouts (with cyclic field objects) don't appear in the store.
      const layout = cloneDeep(this.module.getLayoutSpec('detail'))
      // set pre-fill values
      if (this.$route.params.resource) {
        this.resource = this.$route.params.resource
      }

      // set defaults for the layout
      layout.spec.tabs.forEach(tab => {
        tab.fieldsWithValidationError = 0
        tab.rows.forEach(row => {
          row.cols.forEach(col => {
            col.components.forEach(component => {
              component.options.fields.forEach(field => {
                // set default field values
                // using Vue.set to make the watchers work
                if (this.resource[field.identifier] === undefined) {
                  let defaultValue = null
                  if (field.type === 'number' && field.default_number !== null) {
                    defaultValue = field.default_number
                  } else if (field.type === 'currency' && field.default_currency !== null) {
                    defaultValue = field.default_currency
                  } else if (field.type === 'text' && field.default_text !== null) {
                    defaultValue = field.default_text
                  } else if ((field.type === 'date' || field.type === 'month' || field.type === 'datetime') && field.options.default === 'NOW') {
                    defaultValue = moment()
                  }
                  this.$set(this.resource, field.identifier, defaultValue)
                } else {
                  if (field.type === 'incrementing') {
                    this.$set(this.resource, field.identifier, null)
                  }
                }
              })
            })
          })
        })
      })
      // fill layout
      this.layout = new LayoutDef(layout, this.module)
    },
    handleSubmitOnEnter () {
      if (!this.inModal) {
        this.handleSubmit()
      }
    },
    handleSubmit (redirect = true) {
      this.canLeave = true
      this.submitting = true
      const optimizedAttributes = optimizeRelationData(Object.assign({}, this.resource), this.layout.fieldMap)
      const entity = this.module.newEntity(optimizedAttributes)
      entity.traverse((field, value, object) => {
        if (value === null || value === undefined) {
          delete object[field.identifier]
        }
      })
      return entity.save(this.copy ? { 'copy': true } : {})
        .then(data => {
          this.submitting = false

          this.processValidations({ 'errors': data.errors })
          if (data.success) {
            this.layout.spec.tabs.forEach(tab => {
              tab.fieldsWithValidationError = 0
              tab.rows.forEach(row => {
                row.cols.forEach(col => {
                  col.components.forEach(component => {
                    component.options.fields.forEach(field => {
                      checkFieldForErrors(this.$refs.layout.fieldTree, field.identifier, {}, tab)
                    })
                  })
                })
              })
            })

            let eventResponseOriginal = _.get(data, 'eventResponse.original', false)
            if (eventResponseOriginal) {
              if (redirect) {
                handleRedirect(eventResponseOriginal)
              }
            } else {
              if (redirect) {
                this.$router.push('/' + this.$store.state.currentLocale + '/' + this.$route.params.module_identifier)
              }
            }
            if (this.inModal) {
              this.$emit('created')
            }
          } else if (data.hasOwnProperty('errors')) {
            this.canLeave = false
            this.$message.error(this.$t('_.validation_error'))
          }
        })
        .catch(error => {
          this.submitting = false

          this.$message.error(this.$t('_.something_went_wrong'))
          console.error(error)
        })
    },
    tabChanged () {
      this.$nextTick(() => {
        this.processValidations()
      })
    },
    hasChanged () {
      if (!this.changes) {
        this.changes = true
      } else {
        this.canLeave = false
      }
    },
  },
}
</script>

<style lang="scss">
.ant-card-body {
  overflow-x: auto;

  > div {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}

.ant-badge-count {
  font-size: 10px;
  height: 17px;
  line-height: 16px;
  min-width: 17px;
  padding: 0 1px 0 0;
}
</style>
