<template>
  <div>
    <a-range-picker
      v-model="dateRange"
      :default-value="dateRange"
      :format="dateTimeFormat"
      :show-time="(!result.fullDay && !fullDay ? { format: dateTimeTimeFormat } : false)"
      style="width: auto"
      @change="changedDateRange"
    >
      <template
        slot="dateRender"
        slot-scope="current"
      >
        <div
          :style="getCurrentStyle(current)"
          class="ant-calendar-date"
        >
          {{ current.date() }}
        </div>
      </template>
    </a-range-picker>
    <div v-if="showFullDayOption">
      {{ $t(`_.fullDay`) }}:
      <a-switch
        v-model="result.fullDay"
        :default-checked="result.fullDay"
        :disabled="disabled || !dateRange.length"
        size="small"
        style="margin-left: 5px"
        @change="changedDateRange"
      />
    </div>
    <div v-if="options.select_weekdays">
      <a-divider
        v-if="!screenIsMobile"
        type="vertical"
      />
      <br>
      <a-checkbox-group
        :default-value="checked"
        :options="daySelector"
        :value="checked"
        @change="changedDateRange"
      />
    </div>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

import moment from 'moment'

export default {
  name: 'FieldDateRange',
  extends: Field,
  data () {
    return {
      result: {
        start: [],
        end: [],
        weekdays: [1, 2, 3, 4, 5, 6, 7],
        fullDay: null,
      },
      dateRange: [],
      daySelector: [
        { label: 'Mo', value: 1 },
        { label: 'Tu', value: 2 },
        { label: 'We', value: 3 },
        { label: 'Th', value: 4 },
        { label: 'Fr', value: 5 },
        { label: 'Sa', value: 6 },
        { label: 'Su', value: 7 },
      ],
      defaultDateAndTimeFormats: {
        date: {
          store: 'YYYY-MM-DD',
          view: 'DD.MM.YYYY',
        },
        time: {
          store: 'HH:mm:ss',
          view: 'HH:mm',
        },
        dateTime: {
          store: 'YYYY-MM-DD HH:mm:ss',
          view: 'YYYY-MM-DD HH:mm',
          timeView: 'HH:mm',
        },
        week: {
          store: 'YYYY-WW',
          view: 'YYYY-WW',
        },
      },
    }
  },
  computed: {
    moment () {
      return moment
    },
    dateTimeFormat () {
      return this.field && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.dateTime.view
    },
    dateTimeTimeFormat () {
      return this.field && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.dateTime.timeView
    },
    fullDay () {
      return this.options.full_day
    },
    showFullDayOption () {
      return this.options.full_day_option
    },
  },
  watch: {
    value () {
      this.parseValue(this.value)
    },
  },
  mounted () {
    this.parseValue(this.value)
    this.result.fullDay = this.fullDay
  },
  methods: {
    parseValue (value) {
      if (typeof value === 'object' && value != null && value.length !== 0) {
        let parsedDateRange = value
        if (parsedDateRange.start !== null && parsedDateRange.end !== null) {
          this.$set(this.dateRange, 0, moment(parsedDateRange.start, this.dateTimeFormat))
          this.$set(this.dateRange, 1, moment(parsedDateRange.end, this.dateTimeFormat))
        }
        this.checked = parsedDateRange.weekdays
        this.result.fullDay = (parsedDateRange.fullDay ? parsedDateRange.fullDay : false) || this.fullDay
        this.out_value.fullDay = this.result.fullDay
      } else {
        this.out_value = {
          'start': null,
          'end': null,
          'weekdays': this.checked,
          'fullDay': this.fullDay,
        }
      }
    },
    changedDateRange (selectedDays) {
      let parsedstart = null
      let parsedend = null
      let parseddays = null
      let parsedfullday = false
      if (this.dateRange.length > 0) {
        parsedstart = this.dateRange[0].format(this.dateTimeFormat)
        parsedend = this.dateRange[1].format(this.dateTimeFormat)
        parsedfullday = this.result.fullDay
      }
      if (!moment.isMoment(selectedDays[0]) && typeof selectedDays !== 'boolean') {
        parseddays = selectedDays
      } else {
        parseddays = this.result.weekdays
      }
      this.result = {
        start: parsedstart,
        end: parsedend,
        weekdays: parseddays,
        fullDay: parsedfullday,
      }
      this.checked = this.result.weekdays
      this.out_value = this.result
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff' // like in the ant example
        style.borderRadius = '50%'
      }
      return style
    },
  },
}
</script>

<style scoped>

</style>
