<template>
  <a-rate
    :default-value="value"
    allow-half
    disabled
  />
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellRating',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
