<template>
  <div>
    <a-week-picker
      :allow-clear="true"
      :auto-focus="!screenIsMobile && options.autofocus"
      :default-value="value ? moment(value, defaultDateAndTimeFormats.week.store) : null"
      :disabled="disabled"
      :format="weekFormat"
      :placeholder="options.placeholder"
      :title="field.identifier"
      :value="out_value ? moment(out_value, defaultDateAndTimeFormats.week.store) : null"
      style="width: 100%"
      @change="setOutValue"
    />
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldWeek',
  extends: Field,

  computed: {
    weekFormat () {
      return this.options.week_format || this.defaultDateAndTimeFormats.week.view
    },
  },

  methods: {
    setOutValue (date) {
      this.out_value = date ? date.format(this.defaultDateAndTimeFormats.week.store) : null
    },
  },
}
</script>

<style scoped>

</style>
