/**
 * Based on http://jsfiddle.net/danieljoeblack/feq1tpgm/6/
 */

import _ from 'lodash'

/**
 * Options:
 * - text ?string : null -> The Text to display
 * - color ?string : #000 -> color of the text
 * - fontStyle ?string : sans-serif -> font family and other canvas font styles
 * - minFontSize ?number: 14 -> min Font Size in px
 * - maxFontSize ?number: 75 -> max Font Size in px
 * - lineHeight ?number: 20 -> Line Height in px
 * - allowTextWrap ?boolean: true -> whether to allow the text to be wrapped
 * - sidePadding ?number: 8 -> total padding left and right in %
 */
export default {
  id: 'doughnut-centertext',
  beforeDraw: function (chart) {
    const ctx = chart.ctx
    if (!ctx) {
      console.error(`Chart plugin "${this.id}" could not get context!`, chart.canvas)
      return
    }

    const pluginOptions = _.get(chart, 'config.options.plugins.doughnut-centertext', false)

    // If we don't get text don't continue
    const text = getText(pluginOptions)
    if (text === false) return

    const fontStyle = pluginOptions.fontStyle || 'sans-serif'
    const color = pluginOptions.color || '#000'
    const sidePadding = pluginOptions.sidePadding || 8
    const minFontSize = pluginOptions.minFontSize || 14
    const maxFontSize = pluginOptions.maxFontSize || 75
    const lineHeight = pluginOptions.lineHeight || 20
    const allowTextWrap = pluginOptions.allowTextWrap || true

    // Start font size calculation with a base font of 16px
    const baseFontSize = 16
    ctx.font = baseFontSize + 'px ' + fontStyle

    // Get the width of the string and also the width of the element minus calculated side padding
    let innerRadius = chart._metasets[chart._metasets.length - 1].data[0].innerRadius
    const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)
    let stringWidth = ctx.measureText(text).width
    let elementWidth = (innerRadius * 2) - sidePaddingCalculated

    // Find out how much the font can grow in width.
    let widthRatio = elementWidth / stringWidth
    let newFontSize = Math.floor(baseFontSize * widthRatio)
    let elementHeight = (innerRadius * 2)

    // Pick a new font size, so it will not be larger than the total height of the label.
    let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
    let wrapText = false

    // If text wrap is allowed and the fontsize we would need to use to make it fit is smaller
    // than minFontSize we clamp the font size to min and prepare to wrap the text
    if (allowTextWrap && fontSizeToUse < minFontSize) {
      fontSizeToUse = minFontSize
      wrapText = true
    }

    let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
    let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)

    setupCanvasToDrawCorrectly(ctx, fontSizeToUse, fontStyle, color)

    if (wrapText) {
      drawTextAsMultipleLines(text, ctx, elementWidth, centerX, centerY, lineHeight)
    } else {
      centerY += lineHeight * 0.25
      ctx.fillText(text, centerX, centerY)
    }
  },
}

function getText (pluginOptions) {
  const text = _.get(pluginOptions, 'text', false)
  return stringWithOnlyWhitespace(text) ? false : text
}

function stringWithOnlyWhitespace (str) {
  if (typeof str !== 'string') return false
  return str.trim().length === 0
}

function setupCanvasToDrawCorrectly (ctx, fontSizeToUse, fontStyle, color) {
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.font = fontSizeToUse + 'px ' + fontStyle
  ctx.fillStyle = color
}

function drawTextAsMultipleLines (txt, ctx, elementWidth, centerX, centerY, lineHeight) {
  let words = txt.split(' ')
  let line = ''
  let lines = []

  // Break words up into multiple lines if necessary
  for (let n = 0; n < words.length; n++) {
    let testLine = line + words[n] + ' '
    let metrics = ctx.measureText(testLine)
    let testWidth = metrics.width
    if (testWidth > elementWidth && n > 0) {
      lines.push(line)
      line = words[n] + ' '
    } else {
      line = testLine
    }
  }

  // Move the center up depending on line height and number of lines
  centerY -= (lines.length / 2) * lineHeight

  // Draw the lines
  for (let n = 0; n < lines.length; n++) {
    ctx.fillText(lines[n], centerX, centerY)
    centerY += lineHeight
  }

  // Draw last line
  ctx.fillText(line, centerX, centerY)
}
