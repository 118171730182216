<template>
  <div
    :brezel-component-id="component.id"
    :class="component.options.size ? 'ant-card-head-' + component.options.size : 'ant-card-head'"
  >
    <div class="ant-card-head-wrapper">
      <!-- eslint-disable vue/no-v-html -->
      <h4
        :id="component.options.identifier"
        :class="component.options.size ? 'ant-card-head-title-' + component.options.size : 'ant-card-head-title'"
        :style="component.options.align ? 'text-align: ' + component.options.align + ';' : ''"
        v-html="tr(`${translationPrefix}.headlines.${component.options.identifier}`)"
      />
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'

export default {
  name: 'LayoutComponentHeadline',
  extends: LayoutComponent,
  props: {
    translationPrefix: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
h4 {
  margin-bottom: 0;
}
</style>

<style>
.ant-card-head-title-small {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}

.ant-card-head-title-medium {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
}

.ant-card-head, .ant-card-head-small, .ant-card-head-medium {
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
</style>
