import _ from 'lodash'

export default class Filter {
  value = []

  static operators = [
    {
      value: '=',
      key: 'equal',
    },
    {
      value: '<>',
      key: 'not_equal',
    },
    {
      value: '<',
      key: 'less_than',
    },
    {
      value: '<=',
      key: 'less_than_or_equal',
    },
    {
      value: '>',
      key: 'greater_than',
    },
    {
      value: '>=',
      key: 'greater_than_or_equal',
    },
    {
      value: 'like',
      key: 'contains',
    },
    {
      value: 'not like',
      key: 'does_not_contain',
    },
  ]

  static getOperatorsForField (field) {
    if (!field) return this.operators
    switch (field.type) {
      case 'text':
      case 'checkbox':
      case 'color':
      case 'wysiwyg':
        return this.operators.filter(op => !['less_than', 'less_than_or_equal', 'greater_than', 'greater_than_or_equal'].includes(op.key))
      case 'number':
      case 'currency':
      case 'incrementing':
        return this.operators.filter(op => !['contains', 'does_not_contain'].includes(op.key))
      case 'choice':
      case 'select':
      case 'multiselect':
        let operators = this.operators.filter(op => !['less_than', 'less_than_or_equal', 'greater_than', 'greater_than_or_equal', 'contains', 'does_not_contain'].includes(op.key))
        if (field.type === 'multiselect' || field.multiple || field.options.multiple) {
          // In this case the same operators like in a regular select are used,
          // but the "=" and "<>" operators get the translations of "like" and "not like"
          // because they fit better (e.g. the API returns every resource CONTAINing the selected value when using "=")
          operators = _.cloneDeep(operators).map(op => {
            if (op.key === 'equal') {
              op.key = 'contains'
            } else if (op.key === 'not_equal') {
              op.key = 'does_not_contain'
            }
            return op
          })
        }
        return operators
      default:
        return this.operators
    }
  }

  constructor (filters = []) {
    this.value = filters
  }

  * values () {
    for (let or of this.value) {
      for (let and of or) {
        if (typeof and === 'object') {
          yield and
        }
      }
    }
  }

  /**
   * prepares filters for GET request
   */
  toRequest () {
    const out = _.cloneDeep(this.value)
    for (let or of out) {
      for (let and of or) {
        if ((and.operator === 'like' || and.operator === 'not like')) {
          and.value = `%${and.value}%`
        }
        if (and.value && and.value.id) {
          and.field = and.field + '.id'
          and.value = and.value.id
        }
      }
    }
    return out
  }

  /**
   * modifies a field list for better usage as a filter value (e.g. wysiwyg -> text)
   */
  static getFilterFieldsForFields (fields) {
    return _.cloneDeep(fields)
      .filter(field => (!['file', 'upload', 'list', 'dateRange'].includes(field.type) && !field.options.hidden_from_frontend))
      .map(field => {
        for (let key of ['recipe', 'readonly']) {
          delete field.options[key]
          delete field[key]
        }
        if (field.type === 'incrementing') {
          field.type = 'number'
        }
        if (field.type === 'wysiwyg' || field.type === 'textarea') {
          field.type = 'text'
        }
        if (field.type === 'multiselect' || (field.type === 'select' && (field.multiple || field.options.multiple))) {
          field.type = 'select'
          field.multiple = false
          field.options.multiple = false
        }
        return field
      })
  }
}
