<template>
  <div>
    <div v-if="Array.isArray(value)">
      <ul
        style="margin-bottom: 0; padding-left: 0; list-style: none;"
      >
        <li
          v-for="(option, index) in value"
          :key="index"
        >
          {{ tr(`${translationPrefix}.choice.${field.identifier}.${option}`, true) }}
        </li>
      </ul>
    </div>
    <span v-else>
      {{ value ? tr(`${translationPrefix}.choice.${field.identifier}.${value}`, true) : '' }}
    </span>
  </div>
</template>

<script>
import FieldViewer from '@/components/fields/FieldViewer'

export default {
  name: 'FieldViewerChoice',
  extends: FieldViewer,
}
</script>

<style scoped>

</style>
