<template>
  <codemirror
    ref="code"
    v-model="out_value"
    :auto-focus="!screenIsMobile && options.autofocus"
    :disabled="disabled"
    :options="codemirrorOptions"
    :placeholder="options.placeholder"
    :title="field.identifier"
    :merge="mergeEnabled"
    style="width: 100%; text-align: left"
  />
</template>

<script>
import Field from '@/components/fields/Field'
import { codemirror } from 'vue-codemirror'
import CodeMirror from 'codemirror'
import emmet from '@emmetio/codemirror-plugin'
import 'codemirror/addon/mode/overlay'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/twig/twig.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/merge/merge'
import 'codemirror/addon/merge/merge.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/edit/closebrackets.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/lint/json-lint'
import 'codemirror/addon/lint/javascript-lint'
import 'codemirror/addon/hint/javascript-hint'
import jsonlint from 'jsonlint-mod'
/* eslint-disable camelcase */
import { DIFF_DELETE, DIFF_EQUAL, DIFF_INSERT, diff_match_patch } from 'diff-match-patch'

window.jsonlint = jsonlint

/* eslint-disable camelcase */
window.diff_match_patch = diff_match_patch
window.DIFF_EQUAL = DIFF_EQUAL
window.DIFF_DELETE = DIFF_DELETE
window.DIFF_INSERT = DIFF_INSERT

CodeMirror.defineMode('html-twig', (config, parserConfig) => {
  return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || 'text/html'), CodeMirror.getMode(config, 'twig'))
})

emmet(CodeMirror)

export default {
  name: 'FieldCode',
  components: {
    codemirror,
  },
  extends: Field,
  props: {
    merge: {
      type: Boolean,
      default: false,
    },
    orig: {
      type: String,
      default: null,
    },
  },
  computed: {
    codemirrorOptions () {
      const options = {
        extraKeys: {
          'Tab': 'emmetExpandAbbreviation',
          'Enter': 'emmetInsertLineBreak',
        },
        line: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: this.mode,
        styleActiveLine: true,
        tabSize: this.mode.name === 'javascript' ? 2 : 4,
        viewportMargin: Infinity,
        highlightDifferences: true,
        connect: true,
        origLeft: this.mergeOrig,
        autoCloseBrackets: true,
        matchBrackets: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-lint-markers', 'CodeMirror-foldgutter'],
        lint: true,
      }
      if (this.mergeEnabled) {
        options.value = this.value
      }
      return options
    },

    codemirror () {
      return this.$refs.code.codemirror
    },

    mode () {
      return this.options.mode || 'application/x-httpd-php'
    },

    mergeEnabled () {
      return this.options.merge || this.merge
    },

    mergeOrig () {
      return this.options.orig || this.orig
    },
    formattedCode () {
      if (this.mode.json) {
        return this.prettifyJsonString(this.out_value)
      }
      return this.out_value
    },
  },
  mounted () {
    if (this.mode.json) {
      this.out_value = this.prettifyJsonString(this.out_value)
    }
    this.out_value = this.out_value || ''
  },
  methods: {
    prettifyJsonString (string) {
      return JSON.stringify(JSON.parse(string), null, 2)
    },
  },
}
</script>
