<template>
  <div
    v-if="value !== null && value.start !== undefined && value.end !== undefined"
    class="with-padding"
  >
    {{ formatDateTime(value.start) + ' ' + $t('_.to') + ' ' + formatDateTime(value.end) }}
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellDateRange',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
