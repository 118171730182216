<template>
  <div :brezel-component-id="component.id">
    <component
      :is="widgetComponent"
      v-if="widgetComponent"
      :config="options"
      :resource="resource"
      :mode="mode"
      :component="component"
    />
    <ProgressWidget
      v-if="component.chart === 'progress'"
      :component="component"
    />
    <BarWidget
      v-if="component.chart === 'bar'"
      :component="component"
    />
    <DonutWidget
      v-if="component.chart === 'donut'"
      :component="component"
    />
    <LineWidget
      v-if="component.chart === 'line'"
      :component="component"
    />
    <ColumnWidget
      v-if="component.chart === 'column'"
      :component="component"
    />
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import ProgressWidget from '@/components/layout-components/widgets/LayoutComponentWidgetProgress'
import DonutWidget from '@/components/layout-components/widgets/donut/LayoutComponentWidgetDonut'
import LineWidget from '@/components/layout-components/widgets/LayoutComponentWidgetLine'
import ColumnWidget from '@/components/layout-components/widgets/LayoutComponentWidgetColumn'
import WidgetCustom from '@/components/layout-components/widgets/custom/WidgetCustom'
import WidgetCanvas from '@/components/layout-components/widgets/canvas/WidgetCanvas'
import WidgetPermissions from '@/components/layout-components/widgets/permissions/WidgetPermissions'
import WidgetHistory from '@/components/layout-components/widgets/history/WidgetHistory'
import { camelCase } from 'lodash'
import CanvasDrawWidget from '@/components/layout-components/widgets/LayoutComponentWidgetCanvasDraw'
import WidgetScan from '@/components/layout-components/widgets/scan/WidgetScan'
import WidgetKanban from '@/components/layout-components/widgets/kanban/WidgetKanban'
import BarWidget from '@/components/layout-components/widgets/LayoutComponentWidgetBar'

export default {
  name: 'Widget',
  components: {
    BarWidget,
    WidgetHistory,
    WidgetPermissions,
    ProgressWidget,
    DonutWidget,
    LineWidget,
    ColumnWidget,
    WidgetCustom,
    WidgetCanvas,
    CanvasDrawWidget,
    WidgetScan,
  },
  extends: LayoutComponent,
  props: [],
  data () {
    return {
      widgets: {
        'custom': WidgetCustom,
        'history': WidgetHistory,
        'permissions': WidgetPermissions,
        'canvas': WidgetCanvas,
        'canvasdraw': CanvasDrawWidget,
        'scan': WidgetScan,
        'kanban': WidgetKanban,
      },
    }
  },
  computed: {
    widgetComponent () {
      return this.widgets[camelCase(this.options.widget)]
    },
  },
}

</script>
