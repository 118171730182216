<template>
  <div :class="{ 'notab': layout.spec.tabs.length < 2 }">
    <a-skeleton
      v-if="loading"
      :paragraph="{rows: 10}"
      active
    />
    <a-form
      v-else
      id="editResourceForm"
      layout="vertical"
      @submit.prevent="handleSubmit"
    >
      <Layout
        ref="layout"
        :layout="layout"
        :module="module"
        :resource="resource"
        mode="module.edit"
        :context="context"
        :buttons="buttons"
        @tabChanged="tabChanged"
        @save="handleSubmit"
        @saveAndContinue="handleSubmit($event, true)"
      />
    </a-form>
  </div>
</template>

<script>
import store from '@/store'
import {
  bakeryRedirectLink,
  checkFieldForErrors,
  isScreenMobile,
  optimizeRelationData,
  setDocumentTitle,
} from '@/utils'
import { EventBus } from '@/event-bus'
import Layout from '@/components/Layout'
import LayoutDef from '@/layout/layout'
import Validations from '@/mixins/Validations'
import { loadLanguagesAsync } from '@/i18n'
import { fireWebhookEvent } from '@/webhooks'
import Api, { apiPut } from '../api'
import Module from '@/module/module'
import { cloneDeep } from 'lodash'
import { buildCustomButtons } from '../utils'

export default {
  name: 'ModuleEdit',
  components: {
    Layout,
  },
  mixins: [
    Validations,
  ],
  beforeRouteLeave (to, from, next) {
    if (this.canLeave) {
      next()
    } else {
     this.$confirm({
        title: this.$t('_.leave_page_title'),
        content: this.$t('_.leave_page_message'),
        onOk () {
          next()
        },
        onCancel () {
          next(false)
        },
      })
    }
  },
  props: {
    moduleIdentifier: {
      required: false,
      type: String,
      default: '',
    },
    ressourceID: {
      required: false,
      type: Number,
      default: 0,
    },
    openInModal: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      resource: {},
      /**
       * Compute a JSON object (tree) holding all fields with their components, where the object has exactly the same
       * structure as the resource object. This makes it possible to use the same JSONPath for accessing a specific
       * field value and for accessing a specific field/<Field>.
       */
      fieldTree: {},
      loadingRequests: 2,
      module: {
        identifier: '',
        name: '',
        buttons: {},
      },
      resIdentifier: '',
      modIdentifier: '',
      screenIsMobile: isScreenMobile(),
      submitting: false,
      layout: new LayoutDef(),
      changes: false,
      canLeave: true,
    }
  },
  computed: {
    buttons () {
      if (this.openInModal) return
      let buttons = []

      let buttonOptions = this.module.buttons !== {} ? this.module.buttons : null
      let displayUpdate = true
      let updateTitle
      let updateWebhook
      let displayContinue = true
      let continueTitle
      let continueWebhook

      if (buttonOptions !== null && buttonOptions !== {} && buttonOptions !== undefined) {
        if (buttonOptions.update !== undefined) {
          displayUpdate = buttonOptions.update.display !== undefined ? buttonOptions.update.display : true
          updateTitle = buttonOptions.update.title !== undefined ? buttonOptions.update.title : undefined
          updateWebhook = buttonOptions.update.trigger !== undefined ? buttonOptions.update.trigger : undefined
        }

        if (buttonOptions.continue !== undefined) {
          displayContinue = buttonOptions.continue.display !== undefined ? buttonOptions.continue.display : true
          continueTitle = buttonOptions.continue.title !== undefined ? buttonOptions.continue.title : undefined
          continueWebhook = buttonOptions.continue.trigger !== undefined ? buttonOptions.continue.trigger : undefined
        }
      }

      if (displayUpdate) {
        buttons.push({
          'title': updateTitle || '_.save',
          'action': updateWebhook === undefined ? null : 'function',
          'form': updateWebhook ? null : 'editResourceForm',
          'function': updateWebhook ? () => {
            this.submitting = true
            const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
            fireWebhookEvent(updateWebhook, module, this.resIdentifier,
              {
                prototype: { ...this.resource, module: undefined },
                module_identifier: module.identifier,
              },
              {
                resource: this.resource,
                fieldTree: this.fieldTree,
                context: this.context,
              }
            )
              .then(() => { this.submitting = false })
              .catch(() => { this.submitting = false })
          } : null,
          'style': 'primary',
          'icon': 'save',
          'loading': this.submitting,
        })
      }

      if (displayContinue) {
        buttons.push({
          'title': continueTitle || '_.save_and_continue',
          'action': continueWebhook ? 'function' : 'saveAndContinue',
          'form': continueWebhook ? null : 'editResourceForm',
          'htmlType': 'button',
          'function': continueWebhook ? () => {
            this.submitting = true
            const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
            fireWebhookEvent(continueWebhook, module, this.resIdentifier,
              {
                prototype: { ...this.resource, module: undefined },
                module_identifier: module.identifier,
              },
              {
                resource: this.resource,
                fieldTree: this.fieldTree,
                context: this.context,
              }
            )
              .then(() => { this.submitting = false })
              .catch(() => { this.submitting = false })
          } : null,
          'style': 'default',
          'icon': 'check-circle',
          'loading': this.submitting,
        })
      }

      if (buttonOptions) {
        const module = this.$store.getters.getModuleByIdentifier(this.module.identifier)
        const resourceIdentifier = this.resIdentifier
        const customButtons = buildCustomButtons(
          buttonOptions,
          module,
          resourceIdentifier,
          () => ({
            prototype: { ...this.resource, module: undefined },
            module_identifier: module.identifier,
          }),
          this.context,
          'module.edit'
        )

        if (customButtons) buttons = buttons.concat(customButtons)
      }

      if (process.env.VUE_APP_DEBUG === 'true') {
        buttons.push({
          'title': 'bakery.dev.buttons.open_bakery',
          'link': {
            external: true,
            href: bakeryRedirectLink(this.$route.params.module_identifier),
          },
          'style': 'dashed',
          'icon': 'sliders',
        })
        buttons.push({
          'title': 'bakery.dev.buttons.reload_translations',
          'function': loadLanguagesAsync,
          'style': 'dashed',
          'icon': 'reload',
        })
      }

      return buttons.reverse()
    },

    loading () {
      return this.loadingRequests !== 0
    },

    context () {
      return {
        entity: this.resource,
        module: this.module,
      }
    },
  },
  watch: {
    resource: {
      handler: function (v) {
        return this.hasChanged()
      },
      deep: true,
    },
    loadingRequests: {
      handler (number) {
        if (number === 1) {
          this.fillLayout()
        }
      },
    },
    module: {
      deep: true,
      handler (module) {
        store.commit('setModule', module)
      },
    },
  },
  created () {
    if (this.moduleIdentifier !== '') {
      this.module = Module.byIdentifier(this.moduleIdentifier)
      this.modIdentifier = this.moduleIdentifier
    } else {
      this.module = Module.byIdentifier(this.$route.params.module_identifier)
      this.modIdentifier = this.$route.params.module_identifier
    }

    if (this.ressourceID !== 0) {
      this.resIdentifier = this.ressourceID
    } else {
      this.resIdentifier = this.$route.params.resource_identifier
    }

    if (!this.module) {
      EventBus.$emit('showErrorOrInformation', [true, 404])
      return
    }

    this.layout = new LayoutDef(cloneDeep(this.module.getLayoutSpec('detail')), this.module)
    this.fetchResource()
    window.addEventListener('resize', () => {
      this.screenIsMobile = isScreenMobile()
    })
  },
  mounted () {
    EventBus.$on('handeRedirect', () => { this.canLeave = true })
  },
  methods: {
    fetchResource () {
      Api.fetchEntity(this.resIdentifier, this.modIdentifier)
        .then(resource => {
          this.resource = resource
          this.module = store.getters.getModuleByIdentifier(this.modIdentifier)
          setDocumentTitle(this, resource)

          this.loadingRequests--
        })
        .catch(console.error)
    },
    fillLayout () {
      this.layout.spec.tabs.forEach(tab => {
        tab.fieldsWithValidationError = 0
        tab.rows.forEach(row => {
          row.cols.forEach(col => {
            col.components.forEach(component => {
              component.options.fields.forEach(field => {
                this.$set(this.resource, field.identifier, this.resource[field.identifier])
              })
            })
          })
        })
      })
      this.loadingRequests--
    },
    handleSubmit (e, continueEditing = false, inModal = false) {
      this.submitting = true
      this.canLeave = true
      const resource = optimizeRelationData(Object.assign({}, this.resource), this.layout.fieldMap)
      apiPut(
        ['modules', this.modIdentifier, 'resources', this.resIdentifier],
        {},
        JSON.stringify(resource),
        {
          'Content-Type': 'application/json',
        }
      )
        .then(response => {
          if (response.status === 403) {
            throw new Error(this.$t('_.unauthorized').toString())
          }
          return response
        })
        .then(response => response.json())
        .then(data => {
          this.submitting = false
          this.processValidations({ 'errors': data.errors })
          if (data.success) {
            this.layout.spec.tabs.forEach((tab) => {
              tab.fieldsWithValidationError = 0
              tab.rows.forEach((row) => {
                row.cols.forEach((col) => {
                  col.components.forEach((component) => {
                    component.options.fields.forEach((field) => {
                      checkFieldForErrors(this.$refs.layout.fieldTree, field.identifier, {}, tab)
                    })
                  })
                })
              })
            })
            if (this.module.class === 'users' && store.state.user.id === data.resource.id) {
              store.commit('setUser', data.resource)
            }
            if (!inModal) {
              if (continueEditing || document.querySelector('[form=' + e.target.id + '][triggered=true]') !== null) {
                this.$message.success(this.$t('_.save_successful'))
              } else {
                this.$router.push(`/${this.$store.state.currentLocale}/${this.modIdentifier}/${this.resIdentifier}${this.$route.query ? '?' +
                  (new URLSearchParams(this.$route.query).toString()) : ''}`)
              }
            } else {
              this.$message.success(this.$t('_.save_successful'))
              this.$emit('edited')
            }
          } else {
            this.canLeave = false
            this.$message.error(this.$t('_.validation_error'))
          }
        })
        .catch(error => {
          this.submitting = false
          this.$message.error(error.message || this.$t('_.something_went_wrong'))
          console.error(error)
        })
    },
    tabChanged () {
      this.$nextTick(() => {
        this.processValidations()
      })
    },
    hasChanged () {
      if (!this.changes) {
        this.changes = true
      } else {
        this.canLeave = false
      }
    },
  },
}
</script>

<style lang="scss">
.ant-card-body {
  overflow-x: auto;

  > div {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}

.ant-badge-count {
  font-size: 10px;
  height: 17px;
  line-height: 16px;
  min-width: 17px;
  padding: 0 1px 0 0;
}
</style>
