import { apiLink, getFileURL } from '@/utils'
import store from '@/store'
import { apiGet, apiPost } from '../../api'
import _ from 'lodash'
import { canUpdate } from '@/guard'
import HasPreFilters from '@/components/HasPreFilters'
import LayoutDef from '@/layout/layout'

export default {
  mixins: [HasPreFilters],
  data () {
    return {
      loadedResources: [],
      loadedResourcesTotal: null,
      modalBodyStyle: {
        padding: 0,
      },
      optionsLoaded: false,
      selectModules: [],
      selectOpen: false,
      tableOpened: false,
      addTableOpened: false,
      editTableOpened: false,
      optionsReloaded: false,
      tableSelectProps: {},
      tableEditProps: {},
      selectEntities: [],
      selectOptions: [],
      iconMap: {},
      Addlayout: new LayoutDef(),
    }
  },
  computed: {
    relatedEntriesLoadLimit () {
      if (this.options && this.options.related_entries_load_limit) {
        return this.options.related_entries_load_limit
      }
      return 15
    },
    mode () {
      return this.options.mode || 'select'
    },
    referencesAll () {
      return this.field.is_referencing_all
    },
    showSearch () {
      return this.field.options.table_select !== false &&
        !this.referencesAll &&
        this.mode !== 'transfer' &&
        !_.get(this.field, 'options.events.options') &&
        !_.get(this.field, 'options.readonly') &&
        !(Array.isArray(this.field.allowedOperations) && canUpdate(this.field) === false)
    },
    addOption () {
      return this.options.add_options
    },
    editOption () {
      return this.options.edit_options
    },
  },
  watch: {
    loadedResources () {
      // Important for webhooks that change the available options
      this.optionsLoaded = true
      this.initSelectEntities()
      this.initSelectOptions()
      this.mapSelectOptions().then(selectOptions => {
        this.selectOptions = selectOptions
      })
    },
  },
  methods: {
    initSelectEntities () {
      let resources = this.loadedResources
      if (this.out_value && this.out_value.id) {
        const currentValueExists = this.loadedResources.find(r => r.id === this.out_value.id)
        if (!currentValueExists) {
          resources = [this.out_value, ...this.loadedResources]
        }
      }
      if (this.out_value && Array.isArray(this.out_value) && this.fieldIsRelation && !this.fieldIsScalar) {
        const relationIds = this.out_value.map(entity => entity.id)
        const nonExistingRelations = this.loadedResources.filter(r => !relationIds.includes(r.id))
        resources = [...this.out_value, ...nonExistingRelations]
      }
      this.selectEntities = resources
    },
    mapSelectOptions () {
      let toMap = this.selectEntities
      if (!this.optionsLoaded && this.out_value) {
        toMap = [this.out_value]
      }
      let iconLoaders = []
      if (this.options.icon) {
        iconLoaders = toMap.map(this.loadIcon)
      }
      return Promise.all(iconLoaders).then(() => {
        return toMap.map(this.entityToOption)
      })
    },
    loadIcon (entity) {
      if (!this.options.icon || !entity[this.options.icon]) {
        return Promise.resolve()
      }
      if (!this.iconMap[entity.id]) {
        return getFileURL(entity[this.options.icon].id, 'mini').then(url => {
          this.iconMap[entity.id] = url
          return url
        })
      }
      return Promise.resolve(this.iconMap[entity.id])
    },
    entityToOption (entity) {
      return {
        value: entity.id,
        name: entity.brezel_name,
      }
    },
    async applyTableSelect (record = null) {
      if (record) {
        if (this.fieldIsScalar) {
          this.loadedResources.push(record)
          this.out_value = record
          this.tableOpened = false
        }
        return
      }
      this.tableOpened = false
      let value = this.tableSelectProps.component.options.selections
      if (!this.optionsReloaded) {
        await this.reloadSelect()
      }
      if (this.selectEntities.find(resource => resource.id === value[0]['id']) && this.field.type === 'select') {
        this.changedSelect(value[0]['id'])
      } else {
        value = value.filter(item => item !== null)
        this.loadedResources.push(...value)
        this.out_value = this.fieldIsScalar ? value[0] : value
      }
    },
    async createNewOption () {
      await this.$refs.createSelect.handleSubmit(false)
      let res = await this.reloadSelect()
      if (this.field.type === 'select') {
        this.changedSelect(res[0]['id'])
      } else {
        let value = this.emit_value
        value.push(res[0])
        value = value.filter(item => item !== null)
        this.loadedResources.push(...value)
        this.out_value = this.fieldIsScalar ? value[0] : value
      }
    },
    saveEditOption () {
      this.$refs.editSelect.handleSubmit(null, false, true)
    },
    async optionEdited () {
      this.editTableOpened = false
       await this.reloadSelect()
      this.changedSelect(this.selectValue)
    },
    reloadSelect () {
       return this.resourcesFetcher().then(response => {
        this.loadedResources = []
        this.loadedResources = response.data.filter(item => item)

        this.initSelectEntities()
        this.loadedResourcesTotal = response.total

        this.initSelectOptions()
        return this.loadedResources
      })
    },
    loadModules (searchQuery = null) {
      const url = new URL(apiLink(['modules'], store.state.currentSystem))
      const urlParams = {
        results: this.relatedEntriesLoadLimit,
        name: searchQuery,
      }
      Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))
      return apiGet(url)
        .then(response => response.json())
        .then(data => {
          this.selectModules = data.filter(item => item).map(e => {
            return {
              value: e.id,
              name: e.brezel_name,
              label: this.$t(`modules.${e.identifier}.title`),
              isLeaf: false,
            }
          })
        })
    },
    loadWildcardEntities (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true

      this.loadOptions().then(options => {
        targetOption.loading = false
        targetOption.children = options.map(option => ({
          value: option.id,
          label: option.brezel_name,
        }))
        this.selectModules = [...this.selectModules]
      })
    },
    loadOptions (searchQuery = null) {
      if (this.optionsLoaded && searchQuery === null) {
        return Promise.resolve(this.selectOptions)
      }
      this.optionsLoaded = false
      let fetcher = new Promise(() => {})
      if (this.options.events && this.options.events.options) {
        // event fetcher
        fetcher = this.optionsEventFetcher()
      } else {
        fetcher = this.resourcesFetcher(searchQuery)
      }

      return fetcher.then(response => {
        this.loadedResources = response.data.filter(item => item)
        this.initSelectEntities()

        if (this.loadedResourcesTotal === null) {
          this.loadedResourcesTotal = response.total
        }

        this.initSelectOptions()
        this.optionsLoaded = true
        return this.mapSelectOptions().then(selectOptions => {
          this.selectOptions = selectOptions
          return selectOptions
        })
      })
    },
    openTableModal () {
      this.tableSelectProps = {
        ref: 'tableSelect',
        component: {
          options: {
            hide_crud_buttons: true,
            module: this.references || this.options.references,
            pre_filters: this.options.pre_filters,
            select_on_click: true,
            selections: this.fieldIsScalar ? [_.cloneDeep(this.out_value)] : _.cloneDeep(this.out_value),
          },
        },
      }
      this.tableOpened = true
    },
    editTableModal () {
      this.editTableOpened = true
    },
    optionsEventFetcher () {
      return new Promise((resolve, reject) => {
        this.propagateEvent('options', {
          event: 'options',
          component: this,
          target: this.field,
          value: this.emit_value,
          resolve: firePromise => {
            firePromise.then(response => {
              resolve({
                data: response,
                total: response.length,
              })
            })
          },
        })
      })
    },
    resourcesFetcher (searchQuery = null) {
      const url = new URL(apiLink(['relations'], store.state.currentSystem))
      const urlParams = {
        results: this.relatedEntriesLoadLimit,
        sortField: this.options.sortField,
        sortOrder: this.options.sortOrder,
        name: searchQuery,
        depth: this.options.depth,
      }
      let filters = this.options.pre_filters || []
      const context = this.getFiltersWithContext(filters, this.resource, this.context)
      if (this.referencesAll) {
        urlParams.from = this.selectedModule ? this.selectedModule : (this.out_value ? this.out_value.module_id : null)
        if (!urlParams.from) {
          return new Promise((resolve) => {
            resolve({ data: [] })
          })
        }
      }
      Object.keys(urlParams)
        .filter(key => urlParams[key])
        .forEach(key => url.searchParams.append(key, urlParams[key]))
      return apiPost(
        url,
        {},
        JSON.stringify({
          relation: {
            id: this.field.id,
            identifier: this.field.identifier,
            options: this.field.options,
          },
          context,
        })
      ).then(response => response.json())
    },
    searchResources (queryString) {
      if (this.relatedEntriesLoadLimit !== -1) {
        this.loadOptions(queryString)
      }
    },
    defaultOptionByIndex (index) {
      if (this.selectOptions.length > index) {
        return this.selectOptions[index].value
      }
    },
  },
  created () {
    this.searchResourcesDeb = _.debounce(this.searchResources, 200)
  },
}
