import { BaseRecipeHandler } from '@/recipes/base'
import Vue from 'vue'

export class FieldOptionHandler extends BaseRecipeHandler {
  property
  field

  constructor (field, property) {
    super()
    this.field = field
    this.property = property
  }

  getValue () {
    return this.field.options[this.property]
  }

  putValue (value) {
    Vue.set(this.field.options, this.property, value)
  }

  getChildHandler () {
    return null
  }
}
