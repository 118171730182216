import Api from '../api'

export default {
  methods: {
    createNewDirectory (params) {
      return Api.files().createDirectory(params)
    },
    getFilesDataTransferItems (dataTransferItems, uploadPath, moduleIdentifier) {
      return new Promise(resolve => {
        let entriesPromises = []
        for (let it of dataTransferItems) {
          entriesPromises.push(
            this.traverseFileTreePromise(it.webkitGetAsEntry(), uploadPath, moduleIdentifier)
          )
        }
        Promise.all(entriesPromises).then(entries => {
          resolve(entries)
        })
      })
    },
    traverseFileTreePromise (item, path = '', moduleIdentifier) {
      if (item.isFile) {
        return new Promise(resolve => {
          item.file(file => Api.files().uploadFile({
              file: file,
              virtualPath: path,
              module: moduleIdentifier,
            }).then(resolve)
          )
        })
      } else if (item.isDirectory) {
        let dirReader = item.createReader()
        return this.createNewDirectory({
          name: item.name,
          path: path,
          module: moduleIdentifier,
        })
          .then(response => {
            const directory = response.data
            dirReader.readEntries(entries => {
              let entriesPromises = []
              for (let entr of entries) {
                entriesPromises.push(
                  this.traverseFileTreePromise(entr, [directory.virtual_path, directory.name].join('/'), moduleIdentifier)
                )
              }
              return Promise.all(entriesPromises)
            })
          })
      }
    },
  },
}
