/* eslint-disable no-unused-vars */
import de from 'numeral/locales/de'
/* eslint-enable no-unused-vars */
import { EventBus } from '@/event-bus'
import numeral from 'numeral'
import moment from 'moment'
import store from '@/store'
import _ from 'lodash'
import Api from './api'
import { webhookButtonHandler } from '@/webhooks'
import Field from '@/layout/field'
import { locales } from '@/i18n'

numeral.locale('de')

export const apiLink = (path, system, params) => {
  // Do not use store
  let urlString = store.getters.apiUrl
  if (system !== undefined) {
    urlString += '/' + system
  }
  urlString = urlString + '/' + path.join('/')
  if (urlString.endsWith('/')) {
    // Remove trailing slashes because the API responds with a redirect otherwise, leading to CORS errors.
    // TODO: discuss whether we can remove this behaviour defined in public/.htaccess
    urlString = urlString.substring(0, urlString.length - 1)
  }
  let url = new URL(urlString)
  if (params !== undefined && Object.keys(params).length > 0) {
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) {
        url.searchParams.append(key, params[key])
      }
    })
  }
  return url
}

/**
 * Return the total "length" of an object (key count including recursive cardinality of values)
 */
export const cardinality = object => {
  let cdn = Object.keys(object).length
  for (let i in object) {
    if (object.hasOwnProperty(i) && Array.isArray(object[i])) {
      cdn += cardinality(object[i])
    }
  }
  return cdn
}

export const getFilenameFromResponse = contentDisposition => {
  if (!contentDisposition) {
    return ''
  }
  const regex = /(?:filename\*=utf-8''['"]?([^;'"\n]*)['"]?)|(?:filename=['"]?([^'";\n]*)['"]?)/gmu
  let output = _.filter(_.last(Array.from(contentDisposition.matchAll(regex))))
  return decodeURIComponent(_.last(output))
}

export const downloadBuffer = (buffer, filename) => {
  const blob = new Blob([buffer], { type: buffer.type })
  const blobLink = document.createElement('a')
  const objectURL = window.URL.createObjectURL(blob)
  blobLink.href = objectURL
  blobLink.download = filename.replace('attachment; filename=', '')
  blobLink.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(objectURL)
  }, 0)
}

export const exportLink = (path, system, params) => {
  let url = store.getters.apiUrl + '/export'
  if (system !== undefined) {
    url += '/' + system
  }
  return url + '/' + path.join('/')
}

export const bakeryRedirectLink = (module) => {
  let link = new URL(process.env.VUE_APP_BAKERY_URL + '/' + store.state.currentLocale + '/modules/' + module + '/detail')
  link.searchParams.append('url', store.getters.apiUrl)
  link.searchParams.append('system', store.state.currentSystem)
  link.searchParams.append('email', store.state.user.email)
  return link.toString()
}

export const formatFieldOutputValue = (field, value) => {
  if (field.type === 'number' || field.type === 'currency') {
    let digits = (field.options || {}).decimal_digits
    if (field.type === 'currency') {
      digits = digits || 2
    }
    let format = '0.'
    for (let i = 0; i < digits; i++) {
      format += '0'
    }
    if (field.type === 'currency') {
      format += ' $'
    }
    return numeral(value).format(format)
  }
  return value
}

export const internalLink = (path, route, params) => '/' + route.params.locale + '/' + path.join('/')

export const isScreenMobile = () => window.innerWidth <= 992

export const last = (array) => {
  return array[array.length - 1]
}

export const logoExists = filePath => new Promise((resolve, reject) => {
  fetch(filePath)
    .then((response) => {
      if (response.headers.get('content-type').includes('image/svg+xml') === false) {
        throw new Error()
      }
      resolve()
    })
    .catch(reject)
})

export const getFileURL = (fileId, size = null) => {
  return Api.files().getFile(fileId, size)
    .then(response => response.blob())
    .catch(console.error)
    .then(getBuffer)
}

export const redirect = (name, params = {}, anchor, target, queryParams) => {
  EventBus.$emit('redirect', {
    name: name,
    params: params,
    hash: anchor ? '#' + anchor : undefined,
    target: target,
    queryParams: queryParams,
  })
}

export const refresh = () => {
  EventBus.$emit('refresh')
}

export const getBuffer = (buffer) => {
  const blob = new Blob([buffer], { type: (buffer || {}).type })
  return URL.createObjectURL(blob)
}

export const openBuffer = (buffer, filename) => {
  const blob = new Blob([buffer], { type: buffer.type })
  const blobURL = URL.createObjectURL(blob)
  return new Promise((resolve, reject) => {
    EventBus.$emit('open-buffer', {
      url: blobURL,
      type: buffer.type,
      name: filename ? filename.replace('attachment; filename=', '') : 'Output',
      onLoad: resolve,
    })
  })
}

export const uniqid = () => uniqid8() + uniqid8()

export const uniqid8 = () => Math.random().toString(36).substring(2, 8)

export const checkFieldForErrors = (fieldTree, identifier, errors, tab, prefix = '') => {
  // match error
  if (prefix.length > 0) {
    prefix = prefix + '.'
  }
  let errorIdentifier = prefix + identifier
  let error = _.get(errors, errorIdentifier)

  // show error count in tab
  if (error) {
    tab.fieldsWithValidationError++
  }

  // show field validations
  if (fieldTree[identifier] && fieldTree[identifier].field) {
    if (error) {
      fieldTree[identifier].field.validateStatus = 'error'
      fieldTree[identifier].field.validateMessage = error.join('<br>')
    } else {
      fieldTree[identifier].field.validateStatus = null
      fieldTree[identifier].field.validateMessage = ''
    }
  }

  // recursive call for list fields
  if (Array.isArray(fieldTree[identifier])) {
    fieldTree[identifier].forEach((subTree, index) => {
      for (let childIdentifier in subTree) {
        if (subTree.hasOwnProperty(childIdentifier)) {
          checkFieldForErrors(subTree, childIdentifier, errors, tab, errorIdentifier + '.' + index)
        }
      }
    })
  }
}

export const isDescendant = (parent, child) => {
  let node = child.parentNode
  while (node != null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

export const formatDateTime = (dateTime, format) => {
  if (dateTime === null || dateTime === undefined) {
    return undefined
  }
  const defaults = store.state.defaults
  switch (format) {
    case 'time':
      return moment(dateTime, defaults.timeFormat.store).format(defaults.timeFormat.view)
    case 'date':
      return moment(dateTime, defaults.dateFormat.store).format(defaults.dateFormat.view)
    case 'month':
      return moment(dateTime, defaults.monthFormat.store).format(defaults.monthFormat.view)
    case 'datetime':
    case 'dateTime':
      return moment(dateTime, defaults.dateTimeFormat.store).format(defaults.dateTimeFormat.view)
    default:
      if (format) {
        return moment(dateTime, defaults.dateTimeFormat.store).format(format)
      }
      return dateTime
  }
}

export const echoDateTime = (dateTime, format) => {
  return dateTime ? formatDateTime(dateTime, format) : ''
}

export const guessSystem = () => {
  const parts = window.location.host.split('.')
  const isBrezel = parts[1] === 'brezel' || parts[1] === 'tunnel'
  const isBrezelDev = parts[2] === 'brezel' && parts[1] === 'dev'
  const isLocal = parts[1].includes('localhost')
  const isLocalWithBrezel = parts[2].includes('localhost') && parts[1] === 'brezel'
  const hostWithoutPort = window.location.host.split(':')[0]
  if (isBrezel || isBrezelDev || isLocal || isLocalWithBrezel) {
    if (parts.length >= 3) {
      return parts[0]
    } else {
      return encodeURI(hostWithoutPort)
    }
  } else {
    return encodeURI(hostWithoutPort)
  }
}

export const isEmptyObject = (object) => {
  return object && Object.keys(object).length === 0 && object.constructor === Object
}
export const isNotEmptyObject = (object) => {
  return isEmptyObject(object) === false
}

export const isNumeric = (num) => {
  return !isNaN(num)
}

function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Fallback: Could not copy text: ', err)
    document.body.removeChild(textArea)
    return false
  }

  document.body.removeChild(textArea)
  return true
}

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text)
  }
  return navigator.clipboard.writeText(text).then(function () {
    return true
  }, function (err) {
    console.error('Async: Could not copy text: ', err)
    return false
  })
}

export const handleResponseError = (response) => {
  if (!response.ok) {
    throw response
  }
  return response
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const shouldShow = (component, mode) => {
  if (component) {
    if (component.hidden) {
      return false
    }
    if (component.options) {
      if (component.options.hidden_from_frontend === true) {
        return false
      }
      if (component.options.show_in && Array.isArray(component.options.show_in)) {
        return component.options.show_in.includes(mode)
      }
    }
  }
  return true
}

export const handleToast = (json) => {
  if (typeof json.toast_type !== 'string') {
    console.warn('Cant send toast notification, toast_type not defined!', json)
    return
  }
  json.toast_type = json.toast_type.toLowerCase()
  const toastTypes = ['success', 'info', 'warning', 'error']
  if (toastTypes.indexOf(json.toast_type) < 0) {
    console.warn('Cant send toast notification, wrong toast_type given!', json)
    return
  }
  window.app.$notification[json.toast_type]({
    message: json.message,
    description: json.description,
  })
}

/*
 * Remove all keys except 'id', 'module_id' and 'brezel_name' from relations to reduce data usage.
 * brezel_name is included for display and debug purposes
 */
export const optimizeRelationData = (source, fields) => {
  if (!source || !fields) return source

  for (const [key, value] of Object.entries(source)) {
    if (fields.hasOwnProperty(key) && value !== null) {
      const fieldProperties = fields[key]
      source[key] = optimizeRelationField(value, fieldProperties)
    } else if (['module', 'clients', 'updated_by'].includes(key)) {
      source[key] = getOnlyEssentialKeysFromField(value)
    }
  }

  return source
}

function optimizeRelationField (value, fieldProperties) {
  const type = fieldProperties.type
  const options = fieldProperties.options

  if (fieldIsScalar(type, options)) {
    return getOnlyEssentialKeysFromField(value)
  } else if (fieldIsRelation(type)) {
    if (value) {
      return value.map(v => getOnlyEssentialKeysFromField(v))
    }
  } else if (type === 'list') {
    if (value) {
      return value.map(listItem => {
        const listFields = _.keyBy(fieldProperties.children, 'identifier')
        return optimizeRelationData(Object.assign({}, listItem), listFields)
      })
    }
  }

  return value
}

function getOnlyEssentialKeysFromField (field) {
  if (!field) return null

  return {
    id: field.id,
    module_id: field.module_id,
    brezel_name: field.brezel_name,
  }
}

export const fieldIsRelation = (type) => {
  return ['select', 'multiselect', 'upload', 'file'].includes(type)
}

export const fieldIsScalar = (type, options) => {
  return fieldIsRelation(type) && (
    (['upload', 'file'].includes(type) && options.multiple !== true) ||
    (type === 'select' && !options.multiple)
  )
}

export const buildCustomButtons = (buttonOptions, module, resourceIdentifier, dataGetter, context, view) => {
  const defaultButtons = [
    'create',
    'save',
    'edit',
    'continue',
    'update',
    'delete',
    'excel',
    'print',
    'copy',
  ]

  let buttons = []

  for (const [key, value] of Object.entries(buttonOptions)) {
    if (!defaultButtons.includes(key)) {
      if (!value.hasOwnProperty('show_in') || value['show_in'].includes(view)) {
        let button = buildCustomButton(key, value, module, resourceIdentifier, dataGetter, context)
        if (button) buttons.push(button)
      }
    }
  }

  return buttons
}

export const buildCustomButton = (identifier, buttonDefinition, module, resourceIdentifier, dataGetter, context) => {
  let button = {
    identifier: identifier,
    icon: buttonDefinition['icon'] ? buttonDefinition['icon'] : 'apartment',
    title: buttonDefinition['title'],
    style: buttonDefinition['style'] ? buttonDefinition['style'] : '',
    action: 'function',
  }

  button.function = (e) => {
    const webhook = {
      'identifier': _.get(buttonDefinition, 'trigger', identifier),
      'layouts': [],
      'type': 'webhook',
    }
    return webhookButtonHandler(webhook, module, resourceIdentifier, { context: context }, dataGetter())
  }

  return button
}

export const setDocumentTitle = (component, resource = null) => {
  if (resource !== null) {
    store.commit('setBreadcrumbData', {
      'resource': {
        'id': resource.id,
        'brezel_name': resource.brezel_name,
      },
    })
  }

  const expression = component.$route?.meta?.title
  let output
  if (expression) {
    let titleArray = expression.split(' ').map(item => evaluateExpressionItem(item, component))
    output = titleArray.join(' ')
  }
  if (output) {
    output += ' | '
  }
  document.title = output + component.$store.state.systemName
}

export const evaluateExpressionItem = (item, component, mode = 'string') => {
  const expressionId = item.substring(1)
  if (item.charAt(0) === ':') {
    if (expressionId === 'module') {
      item = _.get(component.$store.state.module, 'identifier', null)
      if (mode === 'string') {
        item = component.tr(`modules.${item}.title`, true, '')
      }
    } else if (expressionId === 'resource') {
      if (mode === 'string') {
        item = _.get(component.$store.state.breadcrumbData, 'resource.brezel_name', '')
      } else if (mode === 'id') {
        item = _.get(component.$store.state.breadcrumbData, 'resource.id', '')
      }
    }
  } else if (item.charAt(0) === '$') {
    item = component.tr(expressionId, true)
  }
  return item
}

export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('accessToken')
  return _.isString(accessToken) && accessToken.length > 0
}

export const entityDecycler = (key, value) => {
  if (value instanceof Field) {
    return value.toJSON()
  }
  return value
}

export const groupByDotPath = (items) => {
  const grouped = {}
  items.forEach(item => {
    const path = item.split('.')
    grouped[path[0]] = path.slice(1)
  })
  return grouped
}

export const setLocale = (component, locale, allowOverwrite = false) => {
  if (store.state.currentLocale !== locale && !allowOverwrite) return

  if (locales[locale]) {
    store.commit('setCurrentLocale', locale)
    component.$i18n.locale = locale
    component.locale = locales[locale].provider
    moment.locale(locale)
    updateRouterParams(component.$router, { locale: locale })
    return true
  }
  return false
}

export function updateRouterParams ($router, newParams) {
  const currentParams = $router.currentRoute.params
  const mergedParams = { ...currentParams, ...newParams }

  // Only actually update the route if something changed to avoid duplicate navigation
  if (_.isEqual(currentParams, mergedParams)) return

  // When router is not supplied all params, it simply tries to update current route with the new params.
  // Almost everything is optional, the old params will remain unchanged.
  // We only merge and supply all params to router in order to compare the two states,
  // and decide if we actually want an update.
  // $router.push({ path: $router.currentRoute.path, params: mergedParams })
  $router.push({ params: mergedParams })
}
