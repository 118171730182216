<template>
  <div
    v-if="value"
    :style="{background: value ? value : '#fffffff'}"
    style="height: 28px; line-height: 28px; margin: -2px -4px; text-align: center"
  >
    <span
      :style="{color: textColor}"
      style="padding: 0 5px;"
    >{{ value }}</span>
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'
import tinycolor from 'tinycolor2'

export default {
  name: 'CellColor',
  extends: FieldCell,
  computed: {
    textColor () {
      return tinycolor(this.value).isDark() ? 'white' : 'black'
    },
  },
}
</script>

<style scoped>

</style>
