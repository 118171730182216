<template>
  <a-icon
    :style="{'color': color}"
    :type="type"
  />
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    permission: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      type: this.permission === null ? 'minus-circle' : (this.permission ? 'check-circle' : 'close-circle'),
      color: this.permission === null ? 'orange' : (this.permission ? 'green' : '#ddd'),
    }
  },
}
</script>

<style scoped>
</style>
