import Api from '@/api'
import Module from '@/module/module'

export default class Entity {
  module = new Module()

  constructor (attributes = {}) {
    Object.assign(this, attributes)
  }

  save (params = {}) {
    return Api.saveEntity(this, params)
  }

  delete () {
    return Api.deleteEntity(this)
  }

  getHistory (page = 1, user = undefined) {
    return Api.entityHistory(this, page, user)
  }

  getHistoryAuthors () {
    return Api.entityHistoryAuthors(this)
  }

  fireEvent (identifier, data = {}, localArgs = {}) {
    return this.module.fireEvent(identifier, this, data, localArgs)
  }

  /**
   * Traverses all fields of the entity recursively.
   * The callback is called with (field, value, object) where object is either the entity or a list item.
   * @param callback
   */
  traverse (callback) {
    const traverse = (fields, obj, func) => {
      fields.forEach(field => {
        const value = obj[field.identifier]
        func(field, value, obj)
        if (field.type === 'list' && Array.isArray(value)) {
          value.forEach(item => {
            traverse(field.children, item, func)
          })
        }
      })
    }
    traverse(this.module.fields, this, callback)
  }
}
