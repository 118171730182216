<template>
  <a-col
    :key="col.position"
    :brezel-col-id="col.id"
    :md="col.span"
    :xs="24"
    class="layout-col"
  >
    <div
      :class="isBordered ? 'ant-card-bordered' : ''"
      class="ant-card ant-card-wider-padding ant-card-padding-transition"
      :style="style"
    >
      <div
        v-for="component in components"
        :key="component.frontendIndex"
      >
        <component
          :is="getLayoutComponent(component, mode)"
          ref="component"
          :component="component"
          :field-tree="fieldTree"
          :mode="mode"
          :resource="resource"
          :module="module"
          :translation-prefix="translationPrefix"
          :context="context"
          :layout="layout"
          :layout-path="newLayoutPath"
          @event="anyEvent"
        />
      </div>
    </div>
  </a-col>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import LayoutComponents from '@/components/layout-components'
import LayoutDef from '@/layout/layout'
import { shouldShow } from '@/utils'
import LayoutElement from '@/components/LayoutElement'

export default {
  name: 'LayoutCol',
  components: {
    LayoutComponent,
  },
  extends: LayoutElement,
  mixins: [LayoutComponents],
  props: {
    col: {
      type: Object,
      required: true,
    },
    fieldTree: {
      type: Object,
      default: undefined,
    },
    propMode: {
      type: String,
      default: undefined,
    },
    resource: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      default: undefined,
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
    layout: {
      type: LayoutDef,
      default: () => new LayoutDef(),
    },
  },
  data () {
    return {
      mode: (this.propMode ? this.propMode : this.$router.currentRoute.name),
    }
  },
  computed: {
    layoutElementIdentifier () {
      return this.col.identifier || this.col.frontendIndex
    },

    isBordered () {
      return !(this.col.options || {}).borderless
    },
    components () {
      return this.col.components
        .map((component, index) => {
          component.frontendIndex = index
          return component
        })
        .filter(component => shouldShow(component, this.mode))
    },
    style () {
      return 'height: 100%; ' + (this.col.options || {}).style
    },
  },
  methods: {
    anyEvent ($event) {
      this.$emit('event', $event)
    },
  },
}
</script>
