<template>
  <div :brezel-component-id="component.id">
    <Layout
      :layout="layoutSpec"
      :module="module"
      :mode="mode"
      :resource="resource"
      :context="context"
      :translation-prefix="nestedTranslationPrefix"
      :layout-path="newLayoutPath"
    />
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import LayoutDef from '@/layout/layout'

export default {
  name: 'LayoutComponentLayout',
  components: {
    Layout: () => import('@/components/Layout.vue'),
  },
  extends: LayoutComponent,
  props: {},
  data () {
    return {
      layoutSpec: new LayoutDef(),
    }
  },
  computed: {
    nestedTranslationPrefix () {
      return this.translationPrefix + '.layouts.' + this.layoutSpec.identifier
    },
  },
  mounted () {
    this.layoutSpec = this.component.layout
  },
}
</script>
