<template>
  <div>
    <Icon
      :type="getDiffIcon(diff.mode.name)"
      :style="{color: getDiffColor(diff.mode.name)}"
    /> <strong>{{ getFieldName(field) }}</strong>
    <div v-if="field.type === 'list'">
      <div v-if="diff.changed && diff.diffs && diff.diffs.length > 0">
        <div
          v-for="(listDiff, index) in diff.diffs"
          :key="index"
          class="list-diff"
        >
          <div v-if="listDiff.changed">
            <a-icon
              :type="getDiffIcon(listDiff.mode.name)"
              :style="{color: getDiffColor(listDiff.mode.name)}"
            />
            {{ index + 1 }}.
            <div
              v-if="listDiff.diffs"
              class="list-item-diffs"
            >
              <div
                v-for="(schemaDiff, schemaKey) in getDiffsOfListItem(listDiff)"
                :key="schemaKey"
                class="list-item-diff"
              >
                <DiffItem
                  :field="getChild(schemaKey)"
                  :diff="schemaDiff"
                  :resource="resource"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="field.type === 'wysiwyg'">
      <FieldCode
        v-if="diff.mode.name === 'add'"
        :merge="true"
        :orig="''"
        :value="diff.new"
      />
      <FieldCode
        v-if="diff.mode.name === 'update'"
        :merge="true"
        :orig="diff.old"
        :value="diff.new"
      />
      <FieldCode
        v-if="diff.mode.name === 'destroy'"
        :value="diff.old"
      />
    </div>
    <div v-else>
      <div v-if="diff.mode.name === 'add'">
        <component
          :is="getFieldViewerComponent(field.type)"
          v-model="diff.new"
          :field="field"
          :module="resource.module"
          :translation-prefix="translationPrefix"
        />
      </div>
      <div
        v-if="diff.mode.name === 'update'"
        style="display: flex; gap: 10px"
      >
        <div>
          <component
            :is="getFieldViewerComponent(field.type)"
            v-model="diff.old"
            :field="field"
            :module="resource.module"
            :translation-prefix="translationPrefix"
          />
        </div>
        <div>
          <Icon type="arrow-right"/>
        </div>
        <div>
          <component
            :is="getFieldViewerComponent(field.type)"
            v-model="diff.new"
            :field="field"
            :module="resource.module"
            :translation-prefix="translationPrefix"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'ant-design-vue'
import EntityLink from '@/components/EntityLink'
import Entity from '@/api/entity'
import Field from '@/layout/field'
import Fields from '@/components/fields'
import FieldCode from '@/components/fields/code/FieldCode'

export default {
  name: 'DiffItem',
  components: {
    Icon,
    EntityLink,
    FieldCode,
  },
  mixins: [Fields],
  props: {
    resource: {
      type: Entity,
      default: undefined,
    },
    diff: {
      type: Object,
      default: undefined,
    },
    field: {
      type: Field,
      default: undefined,
    },
  },
  computed: {
    translationPrefix () {
      return `modules.${this.resource.module.identifier}`
    },
  },
  methods: {
    shouldShowDiff (log, field) {
      return log.show[field] || false
    },

    toggleDiff (log, field) {
      log.show[field] = !log.show[field]
      this.$forceUpdate()
    },

    makeEntity (entity) {
      return new Entity(entity)
    },

    getFieldName (field) {
      return this.tr(`modules.${this.resource.module.identifier}.fields.${field.identifier}`, true)
    },

    getChild (identifier) {
      return this.field.getChild(identifier)
    },

    getDiffsOfListItem (listDiff) {
      const entries = Object.entries(listDiff.diffs).filter(([key, _]) => this.getChild(key))
      return Object.fromEntries(entries)
    },

    getDiffIcon (name) {
      switch (name) {
        case 'add':
          return 'plus'
        case 'update':
          return 'edit'
        case 'destroy':
          return 'minus'
        case 'none':
          return 'stop'
        default:
          return ''
      }
    },

    getDiffColor (name) {
      switch (name) {
        case 'add':
          return 'green'
        case 'update':
          return 'orange'
        case 'destroy':
          return 'red'
        case 'none':
          return 'lightgray'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-diff {
  padding-left: 15px;
}

.list-item-diffs {
  padding-left: 15px;
}
</style>
