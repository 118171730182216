<template>
  <div/>
</template>

<script>
import _ from 'lodash'
import jsonpath from 'jsonpath'
import { recipe } from '../recipes/index'

export default {
  name: 'HasPreFilters',
  methods: {
    getFiltersWithContext (filters, entity, context = {}) {
      let filtersCopy = _.cloneDeep(filters)
      // Modals need access to both the context (with the entity behind the modal) and the modal draft
      const contextSource = { '_context': context, ...entity }
      filtersCopy = filtersCopy.map(ands => {
        if (Array.isArray(ands)) {
          return ands.map(item => this.getFilterValue(item, contextSource)).filter(x => x)
        }
        return this.getFilterValue(ands, contextSource)
      })
        .filter(x => x)
      return _.compact(filtersCopy)
    },
    getFilterValue (item, contextSource) {
      if (item.recipe) {
        item.value = recipe(item.recipe, contextSource)
        delete item.recipe
      }
      if (item.field) {
        item.value = _.get(contextSource, item.field, jsonpath.query(contextSource, `$.${item.field}`))
        delete item.field
      }
      if (
        typeof item.value === 'undefined' ||
        (Array.isArray(item.value) && item.value.length === 0)
      ) {
        return null
      }
      return item
    },
  },
}
</script>

<style scoped>

</style>
