<script>

export default {
  name: 'Validations',
  data () {
    return {
      errors: {},
    }
  },
  methods: {
    arrayToBase64String (arrayBuffer) {
      return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
    },
    decodeBase64 (input) {
      input = input
        .replace(/-/g, '+')
        .replace(/_/g, '/')

      const pad = input.length % 4
      if (pad) {
        if (pad === 1) {
          throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding')
        }
        input += new Array(5 - pad).join('=')
      }

      return Uint8Array.from(
        window.atob(input),
        c => c.charCodeAt(0)
      )
    },
    preparePublicKeyCredentials (data) {
      let publicKeyCredential = {
        id: data.id,
        type: data.type,
        rawId: this.arrayToBase64String(data.rawId),
        response: {},
      };
      [
        'clientDataJSON',
        'attestationObject',
        'authenticatorData',
        'signature',
        'userHandle',
      ]
        .filter((key) => typeof data.response[key] !== 'undefined')
        .forEach((key) => {
          publicKeyCredential.response[key] = this.arrayToBase64String(
            data.response[key]
          )
        })
      return publicKeyCredential
    },
    prepareLoginOptions (options) {
      if (options.user) {
        options.user.id = this.decodeBase64(options.user.id)
      }
      options.challenge = this.decodeBase64(options.challenge)

      if (options.excludeCredentials) {
        for (let cred of options.excludeCredentials) {
          cred.id = this.decodeBase64(cred.id)
        }
      }

      if (options.allowCredentials) {
        for (let cred of options.allowCredentials) {
          cred.id = this.decodeBase64(cred.id)
        }
      }

      return options
    },
  },
}
</script>
