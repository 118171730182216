import store from '../store'
import Api from '@/api'
import Entity from '@/api/entity'
import Field from '@/layout/field'
import { groupByDotPath } from '@/utils'
import LayoutDef from '@/layout/layout'
import { cloneDeep } from 'lodash'

/**
 * This should be the default class for module objects in the future.
 * Currently, most module usages are plain JSON objects.
 */
export default class Module {
  fields = []
  fieldMap = {}
  layouts = []

  constructor (module) {
    Object.assign(this, module)
    this.fields = this.fields.map(field => field instanceof Field ? field : new Field(field))
    this.fieldMap = Object.fromEntries(this.fields.map(field => [field.identifier, field]))
  }

  static byIdentifier (identifier) {
    return store.getters.getModuleByIdentifier(identifier)
  }

  getField (identifier) {
    return this.fieldMap[identifier]
  }

  getFields () {
    return this.fields
  }

  getAllFieldsFlat () {
    const fields = []
    const addFields = field => {
      field.children.forEach(addFields)
      fields.push(field)
    }
    this.getFields().forEach(addFields)
    return fields
  }

  getEntity (id) {
    return Api.fetchEntity(id, this)
  }

  getEntities (page = 0) {
    return Api.fetchEntities(this, page).then(entities => {
      entities.forEach(entity => {
        entity.module = this
      })
      return entities
    })
  }

  newEntity (attrs = {}) {
    return new Entity({ ...attrs, module: this })
  }

  fireEvent (identifier, entity = null, data = {}, localArgs = {}) {
    return Api.fireEvent(identifier, this, entity, data, localArgs)
  }

  hasLayoutType (type) {
    return !!this.layouts.find(layout => layout.type === type)
  }

  getLayoutSpec (type) {
    return this.layouts.find(layout => layout.type === type)
  }

  getSummaryLayout () {
    const summary = this.getLayoutSpec('summary')
    if (summary) {
      return new LayoutDef(cloneDeep(summary), this)
    }
    const autoLayout = {
      spec: {
        tabs: [
          {
            rows: [
              {
                cols: [
                  {
                    components: [
                      {
                        type: 'field_group',
                        options: {
                          vertical_labels: true,
                          fields: this.getFields().slice(0, 3).map(field => ({ ...field })),
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    }
    return new LayoutDef(autoLayout, this)
  }

  getExampleEntity (existing = false, idsOnly = false, withRelations = [], deepIdsOnly = false) {
    const example = {}
    if (existing) {
      example.id = 73
    }
    example.module_id = this.id
    if (!idsOnly) {
      let fields = this.getFields()
        .filter(field => (!field.isRelation() && !field.isList()) || !withRelations || groupByDotPath(withRelations)[field.identifier])
        .map(field => [field.identifier, field.getExampleValue(withRelations ? groupByDotPath(withRelations)[field.identifier] : (field.options.with || []), deepIdsOnly)])
      // Return only defined examples
      fields = fields.filter(([_, example]) => example)
      Object.assign(example, Object.fromEntries(fields))
    }
    return example
  }
}
