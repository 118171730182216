<template>
  <div
    :style="{background: value ? '#00ca72' : '#e44258', color: 'white', letterSpacing: '2px', height: '28px', lineHeight: '28px', textAlign: 'center'}"
  >
    <span
      v-if="value"
      style="flex: 1"
    >{{ $t('_.signed') }}</span>
    <span
      v-else
      style="flex: 1"
    >{{ $t('_.unsigned') }}</span>
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellText',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
