<template>
  <div
    :brezel-component-id="component.id"
    style="padding: 0 32px;"
  >
    <FileSelect
      :module="componentModule"
      :with-upload="options.with_upload || false"
      :with-upload-small="options.with_upload_small"
      :directory="directory"
    />
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import FileSelect from '@/components/FileSelect'
import Module from '@/module/module'

export default {
  name: 'LayoutComponentFileView',
  components: {
    FileSelect,
  },
  extends: LayoutComponent,
  props: {
    resource: {
      type: Object,
      default: undefined,
    },
    src: {
      type: Object,
      default: undefined,
    },
    type: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    componentModule () {
      if (this.component.options) {
        return Module.byIdentifier(this.component.options.module)
      }
      return null
    },
    directory () {
      return this.component.options.directory
    },
  },
  mounted () {
    // this.fileModule = Module.byIdentifier(this.options)
  },
}
</script>
