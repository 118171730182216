<template>
  <div
    :brezel-component-id="component.id"
    style="padding: 0 32px;"
  >
    <a-divider/>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'

export default {
  name: 'LayoutComponentHr',
  extends: LayoutComponent,
}
</script>

<style lang="scss" scoped>
.ant-divider-horizontal {
  margin: 0;
}
</style>
