<template>
  <div>
    <a-input
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :placeholder="options.placeholder"
      :title="field.identifier"
      type="text"
      @blur="fireBlur"
    >
      <a-icon
        v-if="options.prefix && options.prefix !== ''"
        slot="prefix"
        :type="options.prefix"
        style="color: rgba(0, 0, 0, 0.25)"
      />
    </a-input>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldLink',
  extends: Field,
}
</script>

<style scoped>

</style>
