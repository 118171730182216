<template>
  <div class="ant-card-body">
    <pre>{{ component }}</pre>
  </div>
</template>

<script>
import LayoutDef from '@/layout/layout'
import LayoutElement from '@/components/LayoutElement'

export default {
  name: 'LayoutComponent',
  extends: LayoutElement,
  props: {
    component: {
      type: Object,
      default: undefined,
    },
    fieldTree: {
      type: Object,
      default: undefined,
    },
    mode: {
      type: String,
      default: '',
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    module: {
      type: Object,
      default: undefined,
    },
    resource: {
      type: Object,
      required: false,
      default: undefined,
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
    layout: {
      type: LayoutDef,
      default: () => new LayoutDef(),
    },
  },
  computed: {
    layoutElementIdentifier () {
      if (!this.component) {
        return null
      }
      return this.component.identifier || this.component.frontendIndex
    },

    options () {
      return this.component.options || {}
    },
  },
  methods: {
    buttonClick ($event, component) {
      if ($event.target.action) {
        this.$emit($event.target.action, $event, component)
      }

      this.$emit('event', { ...$event, '_type': 'button' })
    },

    fieldEvent ($event) {
      this.$emit('event', { ...$event, '_type': 'field' })
    },
    /**
     * Get the JSONPath of this component's parent.
     * In the future, list fields may contain layouts, these will not be root components anymore but
     * instead descendents of list fields. Since components can fire webhook events, they need path information
     * as well.
     * @return {*|[string]}
     */
    parentPath () {
      return ['$']
    },
  },
}
</script>
