<template>
  <div
    v-if="fileType === 'application/pdf'"
    style="padding: 0; height: 100%;"
  >
    <div class="pdf-viewer">
      <object
        :data="fileUrl"
        style="display: block; min-height: calc(80vh - 56px - 54px); width: 100%;"
        type="application/pdf"
      >
        <pdf
          ref="pdf"
          :src="loadingTask"
          style="display: block; height: auto; width: 100%;"
        />
      </object>
    </div>
  </div>
  <div
    v-else-if="fileType.includes('image/')"
    style="display: flex; align-items: center; justify-content: center"
  >
    <img
      :src="fileUrl"
      alt="fileType"
      style="max-width: 100%;"
    >
  </div>
  <div
    v-else-if="fileType.includes('audio/')"
    style="display: flex; align-items: center; justify-content: center; font-size: 30px"
  >
    <audio
      :src="fileUrl"
      autoplay
      controls
    >
      <strong> {{ $t('_.audio_not_available') }}</strong>
    </audio>
  </div>
  <div
    v-else-if="fileType.includes('video/')"
    style="display: flex; align-items: center; justify-content: center; font-size: 30px"
  >
    <video
      :src="fileUrl"
      controls
      autoplay
    >
      <strong> {{ $t('_.video_not_available') }}</strong>
    </video>
  </div>
  <div v-else-if="openInIframe.indexOf(fileType) > -1">
    <iframe
      :src="fileUrl"
      frameborder="0"
      height="750px"
      style="max-height: 100%; max-width: 100%"
      width="750px"
      name="fileView"
      @load="fileLoaded"
    />
  </div>
  <div
    v-else
    style="display: flex; align-items: center; justify-content: center; height: 100%; font-size: 30px"
  >
    <strong> {{ $t('_.no_preview_available') }} </strong>
  </div>
</template>

<script>
import pdf from 'pdfvuer'

export default {
  name: 'FileView',
  components: {
    pdf,
  },
  props: {
    // TODO add support for file resources
    resource: {
      type: Object,
      required: false,
      default: undefined,
    },
    src: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      fileUrl: '',
      fileType: '',
      openInIframe: [
        'application/javascript',
        'application/pdf',
        'application/json',
        'application/xhtml+xml',
        'application/xml',
        'application/x-latex',
        'application/x-sh',
        'text/calendar',
        'text/comma-separated-values',
        'text/css',
        'text/html',
        'text/html; charset=utf-8',
        'text/javascript',
        'text/plain',
        'text/richtext',
        'text/rtf',
        'text/tab-separated-values',
        'text/xml',
      ],
      loadingTask: null,
      numPages: undefined,
      pdf: null,
    }
  },
  mounted () {
    if (this.src) {
      this.fileUrl = this.src
    }
    if (this.type) {
      this.fileType = this.type
    }
    if (this.fileType === 'application/pdf') {
      this.loadingTask = pdf.createLoadingTask(this.fileUrl)
      this.loadingTask.then(pdf => {
        this.numPages = pdf.numPages
        this.fileLoaded()
      })
    }
  },
  methods: {
    fileLoaded () {
      this.$emit('fileLoaded')
    },
    print () {
      if (this.$refs.pdf) {
        this.$refs.pdf.print()
      }
      if (window.frames.fileView) {
        window.frames.fileView.print()
      }
    },
  },
}
</script>
<style>
.pdf-viewer {
  max-height: 100%;
  overflow-y: auto;
}
</style>
