<template>
  <div/>
</template>

<script>
export default {
  name: 'LayoutElement',
  props: {
    layoutPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    layoutElementIdentifier () {
      return null
    },

    newLayoutPath () {
      return this.makeNewLayoutPath(this.layoutElementIdentifier)
    },

    activeLayoutPath () {
      if (!this.$route.query || !this.$route.query.path) {
        return null
      }
      return this.$route.query.path
    },

    isActiveElement () {
      return this.isPathActive(this.newLayoutPath)
    },
  },
  methods: {
    makeNewLayoutPath (path) {
      return (this.layoutPath.length > 0 ? this.layoutPath + '.' : '') + path
    },

    setElementIsActive () {
      this.setActivePath(this.makeNewLayoutPath(this.layoutElementIdentifier))
    },

    isPathActive (path) {
      return path === this.activeLayoutPath
    },

    activePathStartsWith (path) {
      return this.activeLayoutPath && this.activeLayoutPath.startsWith(path)
    },

    setActivePath (path = null) {
      if (!this.$route.query || this.$route.query.path !== path) {
        history.pushState(
          {},
          null,
          this.$route.path + '?' + (new URLSearchParams({ path }).toString())
        )
      }
    },

    addActivePath (path) {
      this.setActivePath(this.makeNewLayoutPath(this.layoutElementIdentifier + (path ? '.' + path : '')))
    },
  },
}
</script>

<style scoped>

</style>
