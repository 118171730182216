<template>
  <a-row type="flex">
    <a-col
      :span="docScan ? 19 : 24"
      class="media-col"
      :class="{ 'withscan': docScan }"
    >
      <component
        :is="button ? 'a-upload' : 'a-upload-dragger'"
        class="upload-dragger"
        :action="fileUploadUrl"
        :data="requestData"
        :default-file-list="fileList"
        :file-list="fileList"
        :headers="fileUploadHeaders"
        :multiple="multiple"
        :before-upload="beforeUpload"
        :remove="removeFile"
        :show-upload-list="showUploadList"
        @change="uploadFile"
      >
        <div v-if="button">
          <a-button size="small">
            <a-icon type="upload"/>
          </a-button>
        </div>
        <div v-else>
          <p
            class="ant-upload-drag-icon"
            style="margin-top: 20px"
          >
            <a-icon type="inbox"/>
          </p>

          <p
            v-if="placeholder"
            class="ant-upload-text"
            style="margin-bottom: 20px"
          >
            {{ placeholder }}
          </p>
          <p
            v-else
            class="ant-upload-text"
            style="margin-bottom: 20px"
          >
            {{ $t('_.uploadPlaceholder') }}
          </p>
          <p
            class="ant-upload-text-small"
            style="margin-bottom: 20px"
          >
            {{ tr('_.select_file', true) }}
          </p>
        </div>
      </component>
    </a-col>
    <a-col
      v-show="docScan"
      :span="5"
      class="media-col"
    >
      <a-modal
        v-model="scanModal"
        title="Scan Document"
        width="800px"
        :destroy-on-close="true"
        :footer="null"
        class="document-scan-modal"
      >
        <WidgetScan
          :openstart="true"
          :filelist="fileList"
          :data="requestData"
          @change="uploadFile"
        />
      </a-modal>
      <div
        class="upload-scan"
        @click="scanModal = true"
      >
        <span class="scan-button">
          <div>
            <p style="margin:20px 0;">
              <a-icon type="scan"/>
            </p>
            <p v-text="tr('_.scan_document', true)"/>
          </div>
        </span>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import store from '@/store'
import { apiLink } from '@/utils'
import WidgetScan from '@/components/layout-components/widgets/scan/WidgetScan'
import { apiDelete } from '../api'

export default {
  name: 'FileUpload',
  components: { WidgetScan },
  props: {
    defaultFileList: {
      type: [Array, Object],
      default: undefined,
    },
    module: {
      type: Object,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showUploadList: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      default: undefined,
    },
    deleteOldFiles: {
      type: Boolean,
      default: false,
    },
    docScan: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      fileList: [],
      scanModal: false,
    }
  },
  computed: {
    fileUploadHeaders () {
      return { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }
    },
    fileUploadUrl () {
      return apiLink(['files'], store.state.currentSystem).toString()
    },
    requestData () {
      const data = {}
      if (this.module) {
        data['module'] = this.module.identifier
      }
      if (this.path) {
        data['path'] = this.path
      }
      return data
    },
  },
  mounted () {
    let defaultFileList = this.defaultFileList
    if (!defaultFileList || !this.multiple) {
      defaultFileList = [defaultFileList]
    }
    defaultFileList.forEach(file => {
      if (file) {
        this.fileList.push({
          uid: file.id,
          name: file.brezel_name,
          response: {
            data: file,
          },
          status: 'done',
        })
      }
    })
  },
  methods: {
    beforeUpload (file, fileList) {
      if (this.deleteOldFiles) {
        const activeElements = fileList.map(item => item.uid)
        const promises = []
        this.fileList.forEach(fileListItem => {
          if (activeElements.indexOf(fileListItem.uid) === -1) {
            promises.push(this.deleteFile(fileListItem))
            this.removeFile(fileListItem)
          }
        })
        return Promise.all(promises)
      }
      return true
    },
    deleteFile (item) {
      return apiDelete(['files', item.uid])
        .then(response => response.json())
        .then(response => {
          if (!response.success) {
            throw new Error('Delete not successful')
          }
        })
        .catch(console.error)
    },
    removeFile (file) {
      let index = this.fileList.findIndex(x => x.uid === file.uid)
      if (index !== -1) {
        this.fileList.splice(index, 1)
      }
      if (!this.fileList || this.fileList.length === 0) {
        this.fileList = null
      }
      this.$emit('change', {
        file: '',
        fileList: this.fileList,
      })
    },
    uploadFile (info) {
      info.fileList = info.fileList.map(file => {
        if (file.response) {
          file.name = file.response.data.name
          file.uid = file.response.data.id
        }
        return file
      })
      if (info.file.response) {
        info.file.name = info.file.response.data.name
        info.file.uid = info.file.response.data.id
      }
      this.scanModal = false
      if (this.multiple) {
        this.fileList = info.fileList
        this.$emit('change', {
          file: info.file,
          fileList: this.fileList,
        })
      } else {
        this.fileList = [info.file]
        this.$emit('change', {
          file: info.file,
          fileList: this.fileList,
        })
      }
    },
  },
}
</script>
<style scoped>
.upload-scan {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  border-top: 1px dashed #d9d9d9;
  transition: border-color .3s ease;
}

.upload-scan:hover {
  border-color: #1890ff;
}

.upload-scan .scan-button {
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  color: #666;
}

.upload-scan .scan-button i {
  color: #40a9ff;
  font-size: 46px;
}

.upload-scan .scan-button p {
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
  transition: color .3s ease;
}

.withscan {
  padding-right: 10px;
}

.ant-upload-text-small {
  display: none;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
  padding: 0px 10px;
}

/* New Button */
.upload-scan {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 4px;
  transition: border-color .3s ease;
  text-align: center;
  min-width: 90px;
}

.upload-scan .scan-button i {
  color: #ccc;
  transition: color .3s ease;
}

.upload-scan:hover .scan-button i {
  color: #1890ff;
}

.upload-scan:hover .scan-button p {
  color: #1890ff;
}

.upload-scan .scan-button {
  display: table-cell;
}

@media only screen and (max-width: 599px) {
  .ant-modal {
    border: 3px solid #ff0000;
  }

  /* .media-col {width:100%; margin-bottom:20px;} */
  .media-col {
    width: 50%;
  }

  .ant-upload-text-small {
    display: block;
  }

  .upload-scan .scan-button {
    padding: 0px 2px;
  }

  .upload-scan .scan-button p {
    font-size: 1rem;
  }

  .withscan {
    padding-right: 4px;
  }

  .ant-upload-text {
    display: none;
  }

  .scan-modal {
    border: 3px solid #ff0000;
  }
}
</style>
<style>
@media only screen and (max-width: 599px) {
  .document-scan-modal .ant-modal {
    top: 0px;
    padding-bottom: 0px;
    padding: 0px;
  }

  .document-scan-modal .ant-modal-body {
    padding: 0px;
  }

  .upload-dragger .ant-upload {
    padding: 0px !important;
  }
}
</style>
