<template>
  <div>
    <FieldListComponent
      ref="list"
      v-model="out_value"
      :disabled="disabled"
      :field="field"
      :module="module"
      :nested="nested"
      :parent="current"
      :resource="resource"
      :root-field-tree="rootFieldTree"
      :translation-prefix="translationPrefix"
      :type="options.mode"
      :layout="layout"
      :layout-mode="layoutMode"
      @event="propagateEvent($event.event, $event)"
      @add="fireAdd"
      @change="fireChange"
      @remove="fireRemove"
      @tree="tree"
    />
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldList',
  components: {
    FieldListComponent: () => import('@/components/FieldList.vue'),
  },
  extends: Field,
  props: {
    nested: Boolean,
  },
}
</script>

<style>
.fieldTable {
  min-width: 100%;
  width: auto;
}

.fieldTable,
.fieldTable td,
.fieldTable th {
  padding: 4px;
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
  font-weight:normal;
}

.fieldTable tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.fieldTable tr:nth-child(even) {
  background-color: #ffffff;
}

.nestedTable {
  border: 0;
}

.nestedTable tr:first-of-type td,
.nestedTable tr:first-of-type th {
  border-top: 0;
}

.nestedTable tr:last-of-type td {
  border-bottom: 0;
}

.nestedTable tr td:first-of-type {
  border-left: 0;
}

.nestedTable tr td:last-of-type {
  border-right: 0;
}
</style>
