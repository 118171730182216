<script>
import { apiDelete } from '../api'

export default {
  name: 'HandlesDelete',
  methods: {
    handleDelete (module, recordId) {
      apiDelete(['modules', module, 'resources', recordId])
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.$router.push('/' + this.$store.state.currentLocale + '/' + module)
          }
        })
        .catch(console.error)
    },
  },
}
</script>

<style scoped>

</style>
