import { fireWebhookEvent } from '@/webhooks'
import Api from '@/api'

export default class BrezelEvent {
  identifier
  module = null

  constructor ({ identifier, module = null }) {
    this.identifier = identifier
    this.module = module ? Api.getModule(module) : null
  }

  fire (entity = null, data = {}, localArgs = {}) {
    const entityId = typeof entity === 'object' && entity !== null ? entity.id : entity
    return fireWebhookEvent(this.identifier, this.module, entityId, data, localArgs)
  }
}
