<template>
  <div>
    <div style="display: flex">
      <a-select
        v-if="referencesAll"
        :title="field.identifier"
        :value="selectedModule"
        :dropdown-match-select-width="true"
        @dropdownVisibleChange="dropdownChange"
        @change="changedModule"
      >
        <!-- Wildcard -->
        <a-select-option
          v-for="(option, index) in selectModules"
          :id="option.value"
          :key="index"
          :value="option.value"
        >
          {{ option.name }}
        </a-select-option>
      </a-select>
      <a-select
        v-if="!referencesAll"
        ref="select"
        style="flex-grow: 1"
        :auto-focus="!screenIsMobile && options.autofocus"
        :default-value="defaultSelect"
        :disabled="disabled"
        :dropdown-match-select-width="false"
        :not-found-content="fetching ? undefined : null"
        :placeholder="options.placeholder"
        :title="field.identifier"
        :value="selectValue"
        :allow-clear="true"
        :filter-option="(inputValue, option) => option.componentOptions.children[0].children.length > 0 && option.componentOptions.children[0].children[1].text.trim().toLowerCase().includes(inputValue.trim().toLowerCase())"
        show-search
        @change="changedSelect"
        @search="searchResourcesDeb"
        @dropdownVisibleChange="dropdownChange"
      >
        <a-spin
          v-if="fetching"
          slot="notFoundContent"
          size="small"
        />
        <a-select-option
          v-for="(option, index) in selectOptions"
          :id="option.value"
          :key="index"
          :value="option.value"
        >
          <div style="display: flex; align-items: center; gap: 5px">
            <div
              v-if="options.icon"
              class="option-icon"
              :style="{ backgroundImage: iconMap[(option.value)] ? 'url(' + iconMap[(option.value)] + ')' : undefined }"
            />
            {{ option.name }}
          </div>
        </a-select-option>
        <a-select-option
          v-if="fetching"
          key="-1"
          :value="-1"
          disabled
        >
          <div style="text-align: center">
            <a-spin size="small"/>
          </div>
        </a-select-option>
      </a-select>
      <a-button
        v-if="editOption"
        type="link"
        size="small"
        class="optionBtnEdit"
        tabindex="-1"
        :disabled="!selectValue"
        @click="editTableModal()"
      >
        <a-icon type="edit"/>
      </a-button>
      <a-button
        v-if="addOption"
        type="link"
        size="small"
        class="optionBtnAdd"
        tabindex="-1"
        @click="addTableOpened = true"
      >
        <a-icon type="plus"/>
      </a-button>
      <a-button
        v-if="options.hideSearch !== true"
        type="link"
        size="small"
        class="optionBtnSearch"
        tabindex="-1"
        @click="openTableModal"
      >
        <a-icon type="search"/>
      </a-button>
      <a-icon
        v-if="options.prefix !== ''"
        :type="options.prefix"
      />
    </div>
    <a-modal
      v-model="tableOpened"
      width="75%"
      :closable="false"
      :body-style="modalBodyStyle"
      @ok="applyTableSelect()"
    >
      <layout-component-resource-table
        :max-selections="1"
        v-bind="tableSelectProps"
        @dblclick="applyTableSelect"
      />
    </a-modal>
    <a-modal
      v-model="addTableOpened"
      width="75%"
      :closable="false"
      :destroy-on-close="true"
    >
      <ModuleCreate
        ref="createSelect"
        :module-identifier="options.references"
        :in-modal="true"
        @created="addTableOpened = false; reloadSelect()"
      />
      <template #footer>
        <a-button
          key="back"
          @click="addTableOpened = false"
        >
          {{ $t('_.cancel') }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="createNewOption()"
        >
          {{ $t('_.save') }}
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="editTableOpened"
      width="75%"
      :closable="false"
      :destroy-on-close="true"
    >
      <ModuleEdit
        ref="editSelect"
        :module-identifier="options.references"
        :ressource-i-d="selectValue"
        :open-in-modal="true"
        @edited="optionEdited()"
      />
      <template #footer>
        <a-button
          key="back"
          @click="editTableOpened = false"
        >
          {{ $t('_.cancel') }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="saveEditOption()"
        >
          {{ $t('_.save') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'
import SelectMixin from '@/components/fields/select-mixin'
import { isNumeric } from '@/utils'
import ModuleCreate from '@/views/ModuleCreate'
import ModuleEdit from '@/views/ModuleEdit'

export default {
  name: 'FieldSelect',
  components: {
    ModuleCreate,
    ModuleEdit,
  },
  extends: Field,
  mixins: [SelectMixin],
  data () {
    return {
      selectedModule: this.value ? this.value.module_id : null,
      firstOpen: true,
      fetching: false,
      editModule: null,
    }
  },
  computed: {
    selectValue () {
      if (this.firstOpen) {
        return this.value ? this.value.id : null
      }
      if (this.references) {
        return this.out_value ? this.out_value.id : null
      }
      return this.out_value
    },
    defaultSelect () {
      if (typeof this.options.default === 'object') {
        return this.options.default.id
      } else {
        // Interpret default as option index
        const index = parseInt(this.options.default)
        if (isNumeric(index)) return this.defaultOptionByIndex(index)
      }
      return null
    },
  },
  watch: {
    value (v) {
      if (v && this.loadedResources.map(r => r.id).indexOf(v.id) === -1) {
        this.loadedResources.push(v)
      }
    },
  },
  mounted () {
    // If a default value is defined, dont lazy load options, load them right away
    if (this.hasDefaultValue() && this.shouldUseDefaultValue()) {
      this.loadData()
    }
    if (this.$refs.select && this.$refs.select.$el) {
      this.$refs.select.$el.addEventListener('keydown', (event) => {
        if (event.key !== 'Enter' && event.key !== 'Tab') {
          this.$refs.select.$el.querySelector('input.ant-select-search__field').click()
        }
      })
    }
    if (this.value) {
      this.selectOptions = [this.entityToOption(this.value)]
      this.loadIcon(this.value).then(() => this.$forceUpdate())
    }
  },
  methods: {
    hasDefaultValue () {
      return typeof this.options.default !== 'undefined'
    },
    dropdownChange (open) {
      this.dropdownOpened = open
      this.loadDataOnFirstLoad(open)
    },
    loadDataOnFirstLoad (open) {
      if (this.firstOpen && open) {
        this.loadData()
      }
    },
    loadData () {
      this.firstOpen = false
      this.loadInitialData()
    },
    loadInitialData () {
      this.fetching = true
      if (this.references) {
        if (this.referencesAll) {
          this.loadModules().then(() => this.loadOptions()).then(() => {
            this.fetching = false
            this.initSelectOptions()
          })
        } else {
          this.loadOptions().then(() => {
            this.fetching = false
            this.initSelectOptions()
          })
        }
      }
    },
    changedModule (option) {
      if (this.referencesAll) {
        this.selectedModule = option
        this.loadOptions()
      }
    },
    changedSelect (option) {
      if (this.references) {
        this.out_value = this.selectEntities.find(resource => resource.id === option)
      } else {
        this.out_value = option
      }
      this.selectOpen = false
    },
    getDefaultValue () {
      if (this.options.default !== null && this.options.default !== undefined) {
        return this.defaultSelect
      }
      return null
    },
    initSelectOptions () {
      if (this.shouldUseDefaultValue() && this.defaultSelect) {
        this.out_value = this.selectEntities.find(
          resource => resource.id === this.defaultSelect
        )
      }
    },
  },
}
</script>

<style scoped>
.option-icon {
  width: 20px;
  height: 20px;
  background-size: contain;
}
.optionBtnEdit {
  width: auto;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  border-left: 0px;
  height: auto
}

.optionBtnEdit:disabled:hover {border-color:#d9d9d9;}

.optionBtnAdd {
  width: auto;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  border-left: 0px;
  border-right: 0px;
  height: auto
}

.optionBtnSearch {
  width: auto;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  height: auto
}
</style>

<style>
@media (max-width: 767px) {
  .ant-select.ant-select-allow-clear .ant-select-selection__clear {
    pointer-events: none;
  }

  .ant-select-open.ant-select-allow-clear .ant-select-selection__clear {
    pointer-events: all;
    opacity: 1;
  }
}
</style>
