<template>
  <router-link :to="link">
    <slot>{{ entityItem.brezel_name }}</slot>
  </router-link>
</template>

<script>
import Entity from '@/api/entity'
import Api from '@/api'

export default {
  name: 'EntityLink',
  props: {
    entity: {
      required: true,
      type: Entity,
    },
    loadEntity: Boolean,
  },
  data () {
    return {
      entityItem: this.entity,
    }
  },
  computed: {
    link () {
      if (this.entityItem.module) {
        return '/' + this.$store.state.currentLocale + '/' + this.entityItem.module.identifier + '/' + this.entityItem.id
      }
      return '/'
    },
  },
  mounted () {
    if (this.loadEntity) {
      Api.fetchEntity(this.entity).then(entity => {
        this.entityItem = entity
      })
    }
  },
}
</script>
