<template>
  <div>
    <div v-if="withUpload">
      <FileUpload
        v-if="!isTrash"
        :module="module"
        :multiple="!field || multiple"
        :path="currentDirectory"
        :show-upload-list="false"
        :doc-scan="showDocScan"
        list-type="text"
        @change="fileUploaded"
      />
      <a-divider/>
    </div>
    <br>
    <a-row
      :gutter="16"
      align="middle"
      style="margin: 0"
      type="flex"
    >
      <a-col
        :md="{ span: 4 }"
        :xl="{ span: 6 }"
        :xs="{ span: 6, order: 0 }"
        class="file-select-col"
      >
        <a
          :title="tr('_.view_as_list', true)"
          @click="isGrid = false"
        >
          <a-icon
            type="profile"
          />
        </a>
        <a-divider type="vertical"/>
        <a
          :title="tr('_.view_as_cards', true)"
          @click="isGrid = true"
        >
          <a-icon
            type="appstore"
          />
        </a>
      </a-col>
      <a-col
        :md="{ span: 8, order: 1 }"
        :xl="{ span: 6 }"
        :xs="{ span: 24, order: 2 }"
        class="file-select-col"
      >
        <a-input-search
          v-model="search"
          placeholder="Search..."
          @search="reloadFileList"
        />
      </a-col>
      <a-col
        :md="{ span: 7, order: 2 }"
        :xl="{ span: 6 }"
        :xs="{ span: 24, order: 3 }"
        class="file-select-col"
      >
        <a-select
          v-model="orderBy"
          default-value="name"
          style="width: calc(100% - 40px);"
          @change="() => false"
        >
          <a-select-option value="name">
            Name
          </a-select-option>
          <a-select-option value="mime_type">
            Type
          </a-select-option>
          <a-select-option value="size">
            Size
          </a-select-option>
        </a-select>
        <a-select
          v-model="order"
          :show-arrow="false"
          class="border-left-none"
          default-value="ascend"
          style="width: 40px"
          @change="() => false"
        >
          <a-select-option value="ascend">
            <a-icon type="sort-ascending"/>
          </a-select-option>
          <a-select-option value="descend">
            <a-icon type="sort-descending"/>
          </a-select-option>
        </a-select>
      </a-col>
      <a-col
        :md="{ span: 5, order: 3 }"
        :xl="{ span: 6 }"
        :xs="{ span: 18, order: 1 }"
        class="file-select-col"
      >
        <div style="float: right">
          {{ count }} {{ $t('_.files') }}
          <a-divider type="vertical"/>
          {{ selectedList.length }} {{ $t('_.selected') }}
        </div>
      </a-col>
    </a-row>
    <a-divider/>
    <a-row
      v-if="!isTrash"
      :gutter="16"
      align="middle"
      style="margin: 0"
      type="flex"
    >
      <a-col
        :md="{ span: 20 }"
        :xs="{ span: 24 }"
      >
        <a-button-group size="small">
          <a-button
            size="small"
            :title="tr('_.reload', true)"
            @click="reloadFileList"
          >
            <a-icon :type="loading ? 'loading' : 'reload'"/>
          </a-button>
          <a-button
            :disabled="currentDirectory === '/'"
            :title="tr('_.go_to_parent_directory', true)"
            size="small"
            style="padding: 0 4px"
            @click="goToParentDirectory"
          >
            <a-icon type="up"/>
          </a-button>
        </a-button-group>
        <a-divider type="vertical"/>
        <a-breadcrumb style="display: inline-block">
          <a-breadcrumb-item>
            <a-icon
              type="home"
              @click="handleDirectoryOpen(directory)"
            />
          </a-breadcrumb-item>
          <a-breadcrumb-item
            v-for="(pathItem, index) in intermediatePath"
            :key="index"
          >
            <span
              style="cursor: pointer"
              @click="handleDirectoryOpen('/' + [...intermediatePath.slice(0, index), pathItem].join('/'))"
            >
              {{ pathItem }}
            </span>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-if="currentDirectory">
            {{ currentDirectory.split('/')[currentDirectory.split('/').length - 1] }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
      <a-col
        :md="{ span: 4 }"
        :xs="{ span: 24 }"
        style="display: inline-flex; align-items: center; justify-content: right"
      >
        <a-button-group
          v-if="selectedList.length > 0"
          style="margin-right: 14px"
        >
          <a-popconfirm
            :title="$t('_.are_you_sure')"
            @confirm="bulkDelete()"
          >
            <a-button
              :title="tr('_.delete', true)"
              type="danger"
              size="small"
            >
              <a-icon type="delete"/>
              <!-- {{ $t('_.delete') }} -->
            </a-button>
          </a-popconfirm>
        </a-button-group>
        <FileUpload
          v-if="!isTrash && withUploadSmall"
          button
          :module="module"
          :multiple="!field || multiple"
          :path="currentDirectory"
          :show-upload-list="false"
          list-type="text"
          style="display: inline-block; margin-right: 14px"
          @change="fileUploaded"
        />
        <a-popover
          v-model="dummyDirectoryVisible"
          :title="$t('_.create_new_folder')"
          trigger="click"
        >
          <div slot="content">
            <a-input
              v-model="newDirectoryName"
              :auto-focus="true"
              compact
              @pressEnter="createDirectory"
            />
            <a-divider style="margin: 14px 0"/>
            <div style="text-align: right">
              <a-button
                size="small"
                @click="dummyDirectoryVisible = false"
              >
                {{ $t('_.close') }}
              </a-button>
              <a-button
                size="small"
                style="margin-left: 7px"
                type="primary"
                @click="createDirectory"
              >
                {{ $t('_.save') }}
              </a-button>
            </div>
          </div>
          <a-button
            :title="tr('_.new_folder', true)"
            type="primary"
            size="small"
          >
            <a-icon type="folder-add"/>
            <!-- {{ $t('_.create_new_folder') }} -->
          </a-button>
        </a-popover>
      </a-col>
    </a-row>
    <a-divider v-if="!isTrash"/>
    <div>
      <a-list
        :data-source="fileList"
        :grid="isGrid ? gridView : listView"
        :class="{'list-dragover': dragover}"
        :loading="loading"
        item-layout="horizontal"
        size="small"
        @dragover.prevent="highlight"
        @dragleave.prevent="highlight"
        @drop.prevent="drop"
      >
        <div
          slot="renderItem"
          slot-scope="item, index"
        >
          <file-select-item
            :key="item.id"
            :ref="'fileSelect' + item.id"
            :default-selected="checkSelected(item.id)"
            :is-grid="isGrid"
            :item="item"
            :module="module"
            :selected="checkSelected(item.id)"
            @change="itemSelected"
            @delete="removeItem(index)"
            @restore="restoreItem(index)"
            @directoryOpen="handleDirectoryOpen"
            @uploaded="reloadFileList"
            @highlight="highlightLock = $event"
          />
        </div>
      </a-list>
    </div>
    <infinite-loading
      v-if="!done && fileList.length"
      style="margin-top: 20px"
      :force-use-infinite-wrapper="infiniteWrapper"
      @infinite="handleInfiniteOnLoad"
    >
      <div slot="spinner">
        <a-spin/>
      </div>
      <span slot="no-more"/>
    </infinite-loading>
  </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import { apiLink } from '@/utils'
import InfiniteLoading from 'vue-infinite-loading/src/components/InfiniteLoading'
import FileSelectItem from '@/components/FileSelectItem'
import FileUpload from '@/components/FileUpload'
import { EventBus } from '@/event-bus'
import { apiGet } from '@/api'
import Upload from '@/components/upload'

export default {
  name: 'FileSelect',
  components: {
    FileSelectItem,
    FileUpload,
    InfiniteLoading,
  },
  mixins: [Upload],
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    selected: {
      type: [Array, Object],
      default: undefined,
    },
    field: {
      type: Object,
      default: undefined,
    },
    module: {
      type: Object,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    infiniteWrapper: {
      type: Boolean,
      default: true,
    },
    withUpload: {
      type: Boolean,
      default: true,
    },
    withUploadSmall: {
      type: Boolean,
      default: false,
    },
    directory: {
      type: String,
      default: '/',
    },
    grid: {
      type: Boolean,
      default: false,
    },
    withScan: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      count: 0,
      currentDirectory: this.directory,
      done: false,
      dummyDirectoryVisible: false,
      fileList: [],
      gridView: { gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 6 },
      isGrid: this.grid,
      lastPage: '',
      listView: { gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
      loading: false,
      newDirectoryName: null,
      nextPage: 1,
      order: 'ascend',
      orderBy: 'name',
      perPage: 20,
      search: '',
      selectedList: this.selected || [],
      uploadList: [],
      visible: false,
      isTrash: false,
      dragover: false,
      highlightLock: false,
    }
  },
  computed: {
    showDocScan () {
      if (this.field && this.field.options) {
        if (this.field.options.scan === false) { return false }
      }
      return this.withScan
    },
    requestUrl () {
      const url = new URL(apiLink(['modules', this.module.identifier, 'resources'], store.state.currentSystem))
      const urlParams = {
        page: this.nextPage,
        results: this.perPage,
        sortField: this.orderBy,
        sortOrder: this.order,
        filters: {
          name: [this.search],
        },
      }
      if (!this.search) {
        urlParams.filters = [
          {
            field: 'virtual_path',
            operator: '=',
            value: this.currentDirectory.replace(/\/+/gm, '/'),
          },
        ]
      }
      if (this.isTrash) {
        urlParams.isTrash = 1
      }
      urlParams.filters = JSON.stringify(urlParams.filters)
      Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))
      return url
    },
    intermediatePath () {
      if (this.currentDirectory) {
        const path = this.currentDirectory.split('/')
        return path.slice(1, path.length - 1)
      }
      return []
    },
  },
  watch: {
    directory () {
      this.currentDirectory = this.directory
    },
    currentDirectory () {
      this.search = ''
      this.reloadFileList()
    },
    fileList () {
      this.done = this.fileList.length >= this.count
    },
    order () {
      this.reloadFileList()
    },
    orderBy () {
      this.reloadFileList()
    },
    highlightLock () {
      if (this.highlightLock) {
        this.dragover = false
      }
    },
    selected: {
      deep: true,
      handler: function (value) {
        this.setSelectedList(value)
      },
    },
  },
  created () {
    if (this.$route.name === 'module.trash') {
      this.isTrash = true
    }
  },
  mounted () {
    this.getFiles()
    this.setSelectedList(this.selected)
  },
  methods: {
    bulkDelete () {
      let selectedIds = this.selectedList.map(file => file.id)
      return fetch(
        apiLink(['files'], store.state.currentSystem).toString(), {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'application/json',
          },
          method: 'DELETE',
          body: JSON.stringify({
            ids: selectedIds,
          }),
        })
        .then(response => response.json())
        .then(response => {
          const action = response.failed.length === 0 ? 'success' : response.successful.length === 0 ? 'danger' : 'warning'
          this.$message[action](this.$t('_.x_successful_y_failed', {
            x: response.successful.length,
            y: response.failed.length,
          }))
          response.successful.forEach(id => {
            this.setSelectedList(this.selectedList.filter(item => item.id !== id))
            this.fileList = this.fileList.filter(item => item.id !== id)
          })
          this.$emit('change', this.selectedList)
        })
        .catch(console.error)
    },
    checkSelected (id) {
      if (this.selectedList) {
        return this.selectedList.findIndex(item => item && item.id === id) !== -1
      }
      return false
    },
    createDirectory () {
      this.createNewDirectory({
        'name': this.newDirectoryName,
        'module': this.module.identifier,
        'path': this.currentDirectory,
      })
        .then(response => {
          if (response.success) {
            this.reloadFileList()
            this.newDirectoryName = ''
            this.dummyDirectoryVisible = false
          }
        })
    },
    drop (e) {
      this.dragover = false
      if (this.highlightLock) {
        return
      }
      let files = e.dataTransfer.items || e.dataTransfer.files

      const directory = this.currentDirectory

      if (files) {
        this.getFilesDataTransferItems(files, directory, this.module.identifier)
          .then(response => {
            this.reloadFileList()
          })
      }
    },
    fileUploaded (eventData) {
      let file = eventData.file
      if (file.status === 'done') {
        this.reloadFileList()
        this.itemSelected(file.response.data)
      }
    },
    getFiles (paginated = false) {
      return apiGet(this.requestUrl)
        .then(response => response.json())
        .then(response => {
          if (response.data === undefined) {
            this.fileList = []
          } else if (paginated) {
            this.fileList = this.fileList.concat(response.data)
          } else {
            this.fileList = response.data
          }
          this.loading = false
          this.count = response.total
          ++this.nextPage
        })
        .catch(e => {
          console.error(e)
          this.$message.error(this.$t('_.something_went_wrong'))
        })
    },
    goToParentDirectory () {
      if (this.currentDirectory) {
        const path = this.currentDirectory.split('/')
        this.handleDirectoryOpen(path.slice(0, path.length - 1).join('/'))
      } else {
        this.handleDirectoryOpen('/')
      }
    },
    handleInfiniteOnLoad ($state) {
      this.loading = true
      this.getFiles(true)
        .then(() => {
          $state.loaded()
        })
    },
    handleDirectoryOpen (directory) {
      if (directory.length === 0) {
        directory = '/'
      }
      this.currentDirectory = directory
    },
    hideItem (index) {
      let selectedListChanged = false
      for (let i = 0; i < this.selectedList.length; i++) {
        if (this.selectedList[i] && this.selectedList[i].id === this.fileList[index].id) {
          this.selectedList.splice(i, 1)
          selectedListChanged = true
        }
      }
      if (selectedListChanged) {
        this.$emit('change', this.selectedList)
      }

      this.fileList.splice(index, 1)
      --this.count
      if (this.isTrash) {
        EventBus.$emit('refresh-counters')
      }
    },
    highlight (event) {
      if (this.highlightLock) {
        this.dragover = false
        return
      }
      if (event.type !== 'dragleave' && event.dataTransfer && event.dataTransfer.items) {
        event.dataTransfer.dropEffect = 'copy'
        this.dragover = true
      } else {
        this.dragover = false
      }
    },
    itemSelected (e) {
      let found = false
      for (let i = 0; i < this.selectedList.length; i++) {
        if (this.selectedList[i] && this.selectedList[i].id === e.id) {
          this.selectedList.splice(i, 1)
          found = true
          break
        }
      }
      if (!this.multiple) {
        this.selectedList = []
      }
      if (!found) {
        this.selectedList.push(e)
      }
      this.$emit('change', this.selectedList)
    },
    reloadFileList () {
      if (this.loading) return new Promise(resolve => resolve(true))
      this.nextPage = 1
      this.loading = true
      return this.getFiles()
    },
    removeItem (index) {
      this.hideItem(index)
      this.$message.success(this.$t('trash.delete_successful'))
    },
    restoreItem (index) {
      this.hideItem(index)
      this.$message.success(this.$t('trash.restore_successful'))
    },
    setSelectedList (selectedList) {
      if (selectedList) {
        if (Array.isArray(selectedList)) {
          this.selectedList = _.cloneDeep(selectedList) || []
        } else {
          this.selectedList = [_.cloneDeep(selectedList)]
        }
      } else {
        this.selectedList = []
      }
    },
  },
}
</script>

<style lang="scss">
.list-dragover {
  border: 2px dashed red !important;
  transition: none !important;
}

.ant-divider-horizontal {
  margin: 14px 0;
}

.ant-drawer-content {
  overflow: auto;
}

.border-left-none .ant-select-selection {
  border-left-color: transparent;
}

.ant-list-item .ant-card-head {
  display: flex;
  justify-content: center;
  min-height: 100px;
  background: #fafafa;
}

@media (max-width: 768px) {
  .file-select-col {
    margin-bottom: 10px;
  }
}
</style>
