import Cleave from 'cleave.js'

export default {
  directives: {
    cleave: {
      inserted: (el, binding) => {
        if (binding.value !== null) {
          el.cleave = new Cleave(el, binding.value || {})
        }
      },
      update: (el) => {
        if (el.cleave) {
          const event = new Event('input', { bubbles: true })
          setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
          }, 100)
        }
      },
    },
  },
}
