<template>
  <div v-if="cell.isButton">
    <a-button
      :type="cell.style ? cell.style : 'primary'"
      @click="cell.function(rowIdentification)"
    >
      <a-icon :type="typeof cell.icon === 'string' ? cell.icon : 'apartment'"/>
    </a-button>
  </div>
  <component
    :is="getFieldViewerComponent(cell.type)"
    v-else
    v-model="value"
    :field="cell"
    :module="module"
    :nested="true"
    :translation-prefix="translationPrefix"
    :layout-mode="layoutMode"
  />
</template>

<script>
import Field from '@/components/fields'

export default {
  name: 'ListCellViewer',
  mixins: [Field],
  props: {
    cell: {
      type: undefined,
      default: undefined,
    },
    value: {
      type: undefined,
      default: () => { return {} },
    },
    module: {
      type: undefined,
      default: undefined,
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    layoutMode: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: undefined,
    },
    parentIdentifier: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    rowIdentification () {
      return {
        selected: {
          rowIndex: this.index,
          fieldIdentifier: this.parentIdentifier,
        },
      }
    },
  },
}
</script>

<style scoped>

</style>
