<template>
  <div>
    <div style="display: flex; align-items: stretch; line-height: 21px">
      <div
        style="width: 100%;"
      >
        <div v-if="mode === 'select'">
          <a-select
            :auto-focus="!screenIsMobile && options.autofocus"
            :disabled="disabled"
            :default-value="defaultSelect"
            :dropdown-match-select-width="true"
            :placeholder="options.placeholder"
            :title="field.identifier"
            :value="multiselectValue"
            mode="multiple"
            :filter-option="useFilterOption"
            show-search
            style="width: 100%"
            @change="changedMultiselect"
            @search="searchResourcesDeb"
          >
            <a-select-option
              v-for="(option, index) in selectOptions"
              :id="option.value"
              :key="index"
              :value="option.value"
            >
              {{ option.name }}
            </a-select-option>
            <a-select-option
              v-if="!optionsLoaded"
              key="-1"
              :value="-1"
              disabled
            >
              <div style="text-align: center">
                <a-spin size="small"/>
              </div>
            </a-select-option>
          </a-select>
        </div>
        <div v-else-if="mode === 'transfer'">
          <a-transfer
            :data-source="transferOptions"
            :disabled="disabled"
            :target-keys="transferValue || []"
            :filter-option="filterOption"
            :titles="[$t('options'), $t('selected')]"
            :list-style="{
              flex: 1,
              height: '340px'
            }"
            style="display: flex; align-items: center;justify-content: space-evenly"
            :render="item => item.title"
            show-search
            @change="changedTransfer"
            @search="searchTransfer"
          />
        </div>
      </div>
      <a-button
        v-if="addOption"
        type="link"
        size="small"
        class="optionBtnAdd"
        tabindex="-1"
        @click="addTableOpened = true"
      >
        <a-icon type="plus"/>
      </a-button>

      <a-button
        v-if="showSearch"
        type="link"
        size="small"
        class="optionBtnSearch"
        @click="openTableModal"
      >
        <a-icon type="search"/>
      </a-button>
      <a-icon
        v-if="options.prefix !== ''"
        :type="options.prefix"
      />
    </div>
    <a-modal
      v-model="tableOpened"
      width="75%"
      :closable="false"
      :body-style="modalBodyStyle"
      @ok="applyTableSelect()"
    >
      <layout-component-resource-table
        v-bind="tableSelectProps"
        @dblclick="applyTableSelect"
      />
    </a-modal>
    <a-modal
      v-model="addTableOpened"
      width="75%"
      :closable="false"
      :destroy-on-close="true"
    >
      <ModuleCreate
        ref="createSelect"
        :module-identifier="options.references"
        :in-modal="true"
        @created="addTableOpened = false; reloadSelect()"
      />
      <template #footer>
        <a-button
          key="back"
          @click="addTableOpened = false"
        >
          {{ $t('_.cancel') }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="createNewOption()"
        >
          {{ $t('_.save') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'
import SelectMixin from '@/components/fields/select-mixin'
import ModuleCreate from '@/views/ModuleCreate'
import { isNumeric } from '@/utils'

export default {
  name: 'FieldSelect',
  components: {
    ModuleCreate,
  },
  extends: Field,
  mixins: [SelectMixin],
  computed: {
    multiselectValue () {
      if (this.references && Array.isArray(this.out_value)) {
        // TODO: remove this once the API has the new relation system where null (deleted relations) do not occur anymore
        return this.out_value.filter(resource => !!resource).map(resource => resource.id)
      }
      return this.out_value
    },
    defaultSelect () {
      if (Array.isArray(this.options.default)) {
        return this.calculateDefaultOptionsFromArray(this.options.default)
      }

      if (typeof this.options.default === 'object') {
        return [this.options.default.id]
      }

      // Interpret default as option index
      const index = parseInt(this.options.default)
      if (isNumeric(index)) {
        const defaultValue = this.defaultOptionByIndex(index)
        return defaultValue !== undefined ? [defaultValue] : []
      }

      return []
    },
    useFilterOption: (inputValue, option) => (
      option &&
      option.componentOptions.children.length > 0 &&
        (
          (option.componentOptions.propsData.value === -1) ||
          (
            option.componentOptions.children[0].text &&
            option.componentOptions.children[0].text.trim().toLowerCase().includes(inputValue.trim().toLowerCase())
          )
        )
      ),
    transferOptions () {
      return this.selectEntities.map(e => {
        return {
          key: e.id.toString(),
          title: e.brezel_name,
        }
      })
    },
    transferValue () {
      if (this.references && Array.isArray(this.out_value)) {
        return this.out_value.filter(resource => !!resource).map(resource => resource.id.toString())
      }
      return this.out_value
    },
  },
  mounted () {
    if (!this.references) return

    if (this.out_value === null || this.out_value === undefined) {
      this.out_value = []
    }

    if (this.referencesAll) {
      this.loadModules()
        .then(() => this.loadOptions())
        .then(() => this.initSelectOptions())
    } else {
      this.loadOptions()
        .then(() => this.initSelectOptions())
    }
  },
  methods: {
    calculateDefaultOptionsFromArray (optionArray) {
      let defaultOptions = []
      optionArray.forEach((option) => {
        if (typeof option === 'object') {
          defaultOptions.push(option.id)
          return
        }
        const index = parseInt(option)
        if (isNumeric(index)) {
          const optionByIndex = this.defaultOptionByIndex(index)
          if (optionByIndex !== undefined) defaultOptions.push(optionByIndex)
        }
      })
      return defaultOptions
    },
    searchTransfer (direction, value) {
      if (direction === 'left') {
        return this.searchResources(value)
      }
      return this.out_value.filter(option => this.filterOption(value, option))
    },

    changedMultiselect (options) {
      if (this.references) {
        this.out_value = options.map(id => this.selectEntities.find(resource => resource.id === id))
      } else {
        this.out_value = options
      }
    },
    changedTransfer (targetKeys) {
      if (this.references) {
        this.out_value = this.selectEntities.filter(resource => targetKeys.includes(resource.id.toString()))
      } else {
        this.out_value = targetKeys
      }
    },
    filterOption (inputValue, option) {
      return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    },
    getDefaultValue () {
      if (this.options.default !== null && this.options.default !== undefined) {
        return this.defaultSelect
      }
      return []
    },
    initSelectOptions () {
      if (this.shouldUseDefaultValue() && this.defaultSelect) {
        this.out_value = this.loadedResources.filter(
          resource => this.defaultSelect.includes(resource.id)
        )
      }
    },
  },
}
</script>

<style scoped>
  .optionBtnAdd {
    width:auto; display: flex; align-items: center;border-radius:0px; border: 1px solid #d9d9d9; padding: 0 11px;border-left:0px; border-right:0px; height:auto
  }
  .optionBtnSearch {
    width:auto; display: flex; align-items: center;border-radius:0px; border: 1px solid #d9d9d9; padding: 0 11px;height:auto
  }

</style>
