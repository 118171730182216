import { apiDelete, apiPost, apiPut } from '@/api/index'

export default class ApiKey {
  id
  name
  key
  active
  expiresAt
  deleted = false

  constructor (attributes = {}) {
    this.id = attributes.id
    this.name = attributes.name
    this.key = attributes.key
    this.active = attributes.active
    this.expiresAt = attributes.expires_at
  }

  save () {
    if (this.id) {
      return apiPut(['keys', this.id],
        {},
        JSON.stringify({ name: this.name, expires_at: this.expiresAt, active: this.active }),
        {
          'Content-Type': 'application/json',
        }
      ).then(response => response.json())
    }
    return apiPost(['keys'],
      {},
      JSON.stringify({ name: this.name, expires_at: this.expiresAt, active: this.active }),
      {
        'Content-Type': 'application/json',
      }
    ).then(response => response.json())
      .then(response => {
        Object.assign(this, response.data)
        return response
      })
  }

  delete () {
    return apiDelete(['keys', this.id])
      .then(response => {
        this.deleted = true
        return response
      })
      .then(response => response.json())
  }
}
