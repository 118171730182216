<template>
  <div>
    <table
      v-if="component.options.vertical_labels !== true"
      style="width: 100%"
      class="field-group-item-list"
    >
      <tr
        v-for="[field, index] in fields"
        :key="index"
        :style="getStyleForField(field, screenIsMobile)"
        class="field-group-item"
      >
        <!-- eslint-disable vue/no-v-html -->
        <td
          v-if="component.options.no_labels !== true"
          class="td-left-xs field-group-label"
          :style="{
            background: (index % 2 === 1 ? '#fafafa' : 'transparent'),
            paddingRight: '16px',
            textAlign: 'right',
            verticalAlign: 'top',
            whiteSpace: 'nowrap',
            width: '1px',
          }"
          v-html="tr(`${translationPrefix}.fields.${field.identifier}`)"
        />
        <td
          class="td-right-xs"
          :style="{
            background: (!screenIsMobile && index % 2 === 1 ? '#fafafa' : 'transparent')
          }"
        >
          <component
            :is="getFieldViewerComponent(field.type)"
            v-model="resource[field.identifier]"
            :field="field"
            :module="module"
            :translation-prefix="translationPrefix"
            :context="context"
            :layout-mode="mode"
            :test="field.options"
            @event="fieldEvent"
          />
        </td>
      </tr>
    </table>
    <div
      v-for="[field, index] in fields"
      v-else
      :key="index"
      :style="getStyleForField(field, screenIsMobile)"
      class="field-group-item-list"
    >
      <div v-if="component.options.no_labels !== true">
        <!-- eslint-disable vue/no-v-html -->
        <strong v-html="tr(`${translationPrefix}.fields.${field.identifier}`)"/>
      </div>
      <div class="field-group-item">
        <component
          :is="getFieldViewerComponent(field.type)"
          v-model="resource[field.identifier]"
          :field="field"
          :module="module"
          :translation-prefix="translationPrefix"
          :context="context"
          :layout-mode="mode"
          @event="fieldEvent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldViewer from '@/components/fields/FieldViewer'
import LayoutComponentFieldGroup from '@/components/layout-components/LayoutComponentFieldGroup'
import Fields from '@/components/fields'

export default {
  name: 'LayoutComponentFieldGroupShowContent',
  components: { FieldViewer },
  extends: LayoutComponentFieldGroup,
  mixins: [Fields],
}
</script>
