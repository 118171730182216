<template>
  <a-input-number
    v-model="out_value"
    :auto-focus="!screenIsMobile && options.autofocus"
    :disabled="disabled"
    :max="Number.isFinite(max) ? max : Infinity"
    :min="Number.isFinite(min) ? min : -Infinity"
    :placeholder="options.placeholder"
    :step="step ? step : 1"
    :title="field.identifier"
    decimal-separator=","
    class="type-number"
    style="width: 100%; min-width: 70px"
  >
    <a-icon
      v-if="options.prefix !== ''"
      slot="prefix"
      :type="options.prefix"
      style="color: rgba(0, 0, 0, 0.25)"
    />
  </a-input-number>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldNumber',
  extends: Field,
  computed: {
    min () {
      return this.options.min
    },

    max () {
      return this.options.max
    },

    step () {
      return this.options.step
    },
  },
  methods: {},
}
</script>

<style>
@media (max-width: 767px) {
  .field .ant-input-number-input {
    padding: 0 11px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}
</style>
