<template>
  <div>
    <h2 style="margin:10px; text-align:center;">
      Navigation
    </h2>
    <div
      style="margin: 10px; display:flex; flex-direction: row; flex-grow:4; justify-content:space-around;"
    >
      <div>
        <a-input
          v-model="startaddress"
          type="text"
          placeholder="Start Address"
          style="width:30vw; margin-bottom:10px;"
        />
        <br>
        <a-input
          v-model="destinationaddress"
          type="text"
          placeholder="Destination Address"
          style="width:30vw; margin-bottom:10px;"
        />
        <br>
        <a-button
          id="calculate"
          type="primary"
          @click="geocoderequeststart"
        >
          Calculate Route
        </a-button>
      </div>
      <div style="text-align:right; margin-top:10px;">
        <a-timeline mode="right">
          <a-timeline-item>Departure: {{ currentdate }}</a-timeline-item>
          <a-timeline-item>Arrival: {{ arrivaldate }}</a-timeline-item>
        </a-timeline>
        <h4> Travel Duration: {{ time }} </h4>
        <h4> Travel Distance: {{ distance }} </h4>
      </div>
    </div>
    <div style="display:flex; justify-content: space-around;">
      <iframe
        v-if="component.options.map === 'true'"
        id="ors"
        style="border:none; height:40vh; width:80vw; margin:10px;"
        :src="mapurl"
      />
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'

let requesturl = ''
let geocodeurl = ''
let currentdate = new Date().getHours() + ':' + ('0' + new Date().getMinutes()).slice(-2)
let arrivaldate = 0

export default {
  name: 'Widget',
  extends: LayoutComponent,
  props: [],
  data () {
    return {
      start: '',
      startaddress: '',
      destination: '',
      destinationaddress: '',
      time: 0,
      distance: 0,
      currentdate,
      arrivaldate,
      mapurl: '',
    }
  },
  methods: { // this can probably be coded a lot more beautiful
    geocoderequeststart () { // These functions get lat and long coordinates from the addresses in the input fields
      geocodeurl = 'https://api.openrouteservice.org/geocode/search?api_key=5b3ce3597851110001cf6248b028ac0d031046e7bab3c8332b3f2df2&text=' + this.startaddress
      fetch(geocodeurl)
        .then(response => response.json())
        .then(data => {
          this.start = data.features[0].geometry.coordinates[0].toString() + ',' + data.features[0].geometry.coordinates[1].toString()
          this.geocoderequestdest()
        })
    },
    geocoderequestdest () {
      geocodeurl = 'https://api.openrouteservice.org/geocode/search?api_key=5b3ce3597851110001cf6248b028ac0d031046e7bab3c8332b3f2df2&text=' + this.destinationaddress
      fetch(geocodeurl)
        .then(response => response.json())
        .then(data => {
          this.destination = data.features[0].geometry.coordinates[0].toString() + ',' + data.features[0].geometry.coordinates[1].toString()
          this.startrequest()
        })
    },
    startrequest () { // This is the main route request for OpenRouteService
      requesturl = 'https://api.openrouteservice.org/v2/directions/driving-car?api_key=5b3ce3597851110001cf6248b028ac0d031046e7bab3c8332b3f2df2&start=' + this.start + '&end=' + this.destination

      fetch(requesturl)
        .then(response => response.json())
        .then(data => {
          this.time = timeConvert(data.features[0].properties.summary.duration)
          this.distance = (data.features[0].properties.summary.distance / 1000).toFixed(2) + ' kilometers'
          let tempdate = new Date()
          tempdate.setSeconds(tempdate.getSeconds() + data.features[0].properties.summary.duration)
          this.arrivaldate = tempdate.getHours() + ':' + ('0' + tempdate.getMinutes()).slice(-2)

          let orsstart = this.start.split(',')
          let orsdest = this.destination.split(',')
          this.mapurl = 'https://classic-maps.openrouteservice.org/directions?n3=12&a=' + orsstart[1] + ',' + orsstart[0] + ',' + orsdest[1] + ',' + orsdest[0] + '&b=0&c=0&k1=en-US'
          document.getElementById('ors').contentWindow.location.reload()
        })
    },
  },
}

function timeConvert (n) { // Converts seconds to hours and minutes for better display
  let l = n / 60
  let hours = (l / 60)
  let rhours = Math.floor(hours)
  let minutes = (hours - rhours) * 60
  let rminutes = Math.round(minutes)
  return rhours + ' hour(s) and ' + rminutes + ' minute(s).'
}

</script>
