import { defaultLocale } from '@/i18n'
import Locale from '@/components/Locale'

export const routes = [
  {
    path: '/',
    redirect: `/${defaultLocale}`,
  },
  {
    path: '/:locale',
    component: Locale,
    children: [
      {
        path: '',
        name: 'localeRoot',
        component: () => import(/* webpackChunkName: "calendar" */ '@/views/Empty.vue'),
        meta: {
          title: '$navigation.empty',
          breadcrumbs: [
            { breadcrumbName: 'navigation.empty' },
          ],
        },
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar.vue'),
        meta: {
          title: '$navigation.calendar',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: 'navigation.calendar' },
          ],
        },
      },
      {
        path: 'license',
        name: 'license',
        component: () => import(/* webpackChunkName: "license" */ '@/views/License.vue'),
        meta: {
          title: '$navigation.license',
          breadcrumbs: [
            { breadcrumbName: 'navigation.license' },
          ],
        },
      },
      {
        path: 'apiKeys',
        name: 'apiKeys',
        component: () => import(/* webpackChunkName: "apiKeys" */ '@/views/ApiKeys.vue'),
        meta: {
          title: '$navigation.apiKeys',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: 'navigation.apiKeys' },
          ],
        },
      },
      {
        path: ':module_identifier',
        name: 'module',
        component: () => import(/* webpackChunkName: "module" */ '@/views/Module.vue'),
        meta: {
          title: ':module',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module' },
          ],
        },
      },
      {
        path: ':module_identifier/create',
        name: 'module.create',
        component: () => import(/* webpackChunkName: "module.create" */ '@/views/ModuleCreate.vue'),
        meta: {
          title: '+ :module',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module', path: ':module' },
            { breadcrumbName: '_.create' },
          ],
        },
      },
      {
        path: ':module_identifier/import',
        name: 'module.import',
        component: () => import(/* webpackChunkName: "module.import" */ '@/views/ModuleImport.vue'),
        meta: {
          title: ':module $import.title',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module', path: ':module' },
            { breadcrumbName: 'import.title' },
          ],
        },
      },
      {
        path: 'trash/:module_identifier',
        name: 'module.trash',
        component: () => import(/* webpackChunkName: "module.trashcan" */ '@/views/ModuleTrash.vue'),
        meta: {
          title: ':module $trash.title',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module', path: ':module' },
            { breadcrumbName: 'trash.title' },
          ],
        },
      },
      {
        path: ':module_identifier/:resource_identifier',
        name: 'module.show',
        component: () => import(/* webpackChunkName: "module.show" */ '@/views/ModuleShow.vue'),
        meta: {
          title: ':resource | :module',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module', path: ':module' },
            { breadcrumbName: ':resource' },
          ],
        },
      },
      {
        path: ':module_identifier/:resource_identifier/edit',
        name: 'module.edit',
        component: () => import(/* webpackChunkName: "module.edit" */ '@/views/ModuleEdit.vue'),
        meta: {
          // ":" to load from store, "$" to translate
          title: '✎ :resource | :module',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module', path: ':module' },
            { breadcrumbName: ':resource', path: ':resource' },
            { breadcrumbName: 'edit' },
          ],
        },
      },
      {
        path: ':module_identifier/:resource_identifier/copy',
        name: 'module.copy',
        component: () => import(/* webpackChunkName: "module.copy" */ '@/views/ModuleCreate.vue'),
        meta: {
          // ":" to load from store, "$" to translate
          title: '📋 :resource | :module',
          breadcrumbs: [
            { type: 'home' },
            { breadcrumbName: ':module', path: ':module' },
            { breadcrumbName: ':resource', path: ':resource' },
            { breadcrumbName: 'copy' },
          ],
        },
      },
      {
        path: '*',
        redirect: `/${defaultLocale}`,
      },
    ],
  },
]
