<template>
  <div class="with-padding">
    {{ striptags(value) }}
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'
import striptags from 'striptags'

export default {
  name: 'CellWYSIWYG',
  extends: FieldCell,
  methods: {
    striptags,
  },
}
</script>

<style scoped>

</style>
