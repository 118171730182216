<template>
  <div
    :brezel-component-id="component.id"
    :style="{ 'padding': component.options.no_padding === true ? 0 : false }"
    class="ant-card-body"
  >
    <a-collapse
      :accordion="component.options.accordion"
      :default-active-key="component.options.active_key"
      :bordered="component.options.bordered"
    >
      <a-collapse-panel
        v-for="(panel, panelKey) in component.options.panels"
        :key="panel.key ? panel.key : panelKey"
        :header="$t(`${translationPrefix}.collapse.${panel.title}`)"
      >
        <component
          :is="getLayoutComponent(subComponent, mode)"
          v-for="(subComponent, subComponentKey) in panel.components"
          :key="subComponentKey"
          :component="subComponent"
          :field-tree="fieldTree"
          :resource="resource"
          :translation-prefix="translationPrefix"
          :context="context"
        />
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import LayoutComponents from '@/components/layout-components'

export default {
  name: 'LayoutComponentCollapse',
  components: {
    LayoutComponent,
  },
  extends: LayoutComponent,
  mixins: [LayoutComponents],
}
</script>
