<template>
  <div>
    <a-input
      v-if="field.type === 'text' || field.type === 'email' || field.type === 'tel' || field.type === 'uniqid'"
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :placeholder="options.placeholder"
      :title="field.identifier"
      :type="field.type === 'email' ? 'email' : (field.type === 'tel' ? 'tel' : 'text')"
      @blur="fireBlur"
    >
      <a-icon
        v-if="options.prefix && options.prefix !== ''"
        slot="prefix"
        :type="options.prefix"
        style="color: rgba(0, 0, 0, 0.25)"
      />
    </a-input>

    <a-input
      v-else-if="field.type === 'password'"
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :placeholder="options.placeholder"
      :title="field.identifier"
      :autocomplete="options.allow_prefill ? '' : 'new-password'"
      :type="fieldProtection ? 'password' : 'text'"
    >
      <a-icon
        v-if="options.prefix && options.prefix !== ''"
        slot="prefix"
        :type="options.prefix"
        style="color: rgba(0, 0, 0, 0.25)"
      />
      <a-button
        slot="suffix"
        type="link"
        tabindex="-1"
        @click="toggleProtection"
      >
        <a-icon :type="fieldProtection ? 'eye' : 'eye-invisible'" />
      </a-button>
    </a-input>

    <a-time-picker
      v-else-if="field.type === 'time'"
      :auto-focus="!screenIsMobile && options.autofocus"
      :default-value="value ? moment(value, timeFormat) : null"
      :value="out_value ? moment(out_value, timeFormat) : null"
      :disabled="disabled"
      :format="timeFormat"
      :placeholder="options.placeholder"
      :title="field.identifier"
      style="width: 100%"
      @change="out_value = $event ? $event.format(timeFormat) : null"
    />

    <a-month-picker
      v-else-if="field.type === 'month'"
      :allow-clear="true"
      :auto-focus="!screenIsMobile && options.autofocus"
      :default-value="value && moment(out_value, defaultDateAndTimeFormats.month.store).isValid() ? moment(value, defaultDateAndTimeFormats.month.store) : null"
      :disabled="disabled"
      :format="monthFormat"
      :placeholder="options.placeholder"
      :title="field.identifier"
      :value="out_value && moment(out_value, defaultDateAndTimeFormats.month.store).isValid() ? moment(out_value, defaultDateAndTimeFormats.month.store) : null"
      style="width: 100%"
      @change="out_value = $event ? $event.format(defaultDateAndTimeFormats.month.store) : null"
    />

    <a-date-picker
      v-else-if="field.type === 'date'"
      :allow-clear="true"
      :auto-focus="!screenIsMobile && options.autofocus"
      :default-value="value && moment(out_value, defaultDateAndTimeFormats.date.store).isValid() ? moment(value, defaultDateAndTimeFormats.date.store) : null"
      :disabled="disabled"
      :format="dateFormat"
      :placeholder="options.placeholder"
      :title="field.identifier"
      :value="out_value && moment(out_value, defaultDateAndTimeFormats.date.store).isValid() ? moment(out_value, defaultDateAndTimeFormats.date.store) : null"
      style="width: 100%"
      @change="out_value = $event ? $event.format(defaultDateAndTimeFormats.date.store) : null"
    />

    <a-date-picker
      v-else-if="field.type === 'datetime'"
      :allow-clear="true"
      :auto-focus="!screenIsMobile && options.autofocus"
      :default-value="value && moment(value, defaultDateAndTimeFormats.dateTime.store).isValid() ? moment(value, defaultDateAndTimeFormats.dateTime.store) : null"
      :disabled="disabled"
      :format="dateTimeFormat"
      :placeholder="options.placeholder"
      :show-time="{ format: dateTimeTimeFormat }"
      :title="field.identifier"
      style="width: 100%"
      :value="out_value && moment(value, defaultDateAndTimeFormats.dateTime.store).isValid() ? moment(out_value, defaultDateAndTimeFormats.dateTime.store) : null"
      @change="out_value = $event ? $event.format(defaultDateAndTimeFormats.dateTime.store) : null"
    />

    <a-checkbox
      v-else-if="field.type === 'checkbox' && !field.options.mode"
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :title="field.identifier"
    />

    <a-switch
      v-else-if="field.type === 'checkbox' && field.options.mode === 'switch'"
      v-model="out_value"
    />

    <div
      v-else-if="field.type === 'currency'"
      style="display: flex; align-items: center; border: 1px solid #d9d9d9;"
    >
      <a-input-number
        v-model="out_value"
        :auto-focus="!screenIsMobile && options.autofocus"
        :disabled="disabled"
        :placeholder="options.placeholder"
        :step="getStepBasedOnDecimalDigits()"
        :title="field.identifier"
        decimal-separator=","
        style="width: 100%; margin-right: 11px; border: none; border-right: 1px solid #d9d9d9; border-top-right-radius: 0; border-bottom-right-radius: 0"
      />
      <a-icon
        v-if="options.prefix && options.prefix !== ''"
        :type="options.prefix"
        style="color: rgba(0, 0, 0, 0.25); margin-right: 11px"
      />
      <span
        v-else
        style="color: rgba(0, 0, 0, 0.25); margin-right: 11px"
      >
        {{ options.currency_code }}
      </span>
    </div>

    <a-slider
      v-else-if="field.type === 'range'"
      :auto-focus="!screenIsMobile && options.autofocus"
      :default-value="rangeDefault"
      :disabled="disabled"
      :max="options.max"
      :min="options.min"
      :step="options.step"
      :title="field.identifier"
      range
      @change="rangeOnChange"
    />

    <div v-else-if="field.type === 'incrementing'">
      <a-input
        v-if="out_value === null"
        :value="field.incrementing_prediction"
        :disabled="true"
        :title="field.identifier"
        type="text"
        @blur="fireBlur"
      >
        <a-icon
          v-if="options.prefix && options.prefix !== ''"
          slot="prefix"
          :type="options.prefix"
          style="color: rgba(0, 0, 0, 0.25)"
        />
      </a-input>
      <a-input
        v-else
        v-model="out_value"
        :auto-focus="!screenIsMobile && options.autofocus"
        :disabled="true"
        :placeholder="options.placeholder"
        :title="field.identifier"
        type="text"
        @blur="fireBlur"
      >
        <a-icon
          v-if="options.prefix && options.prefix !== ''"
          slot="prefix"
          :type="options.prefix"
          style="color: rgba(0, 0, 0, 0.25)"
        />
      </a-input>
    </div>

    <span v-else-if="field.type === 'signature'">
      <a-button @click.prevent="showSignature">{{ $t('_.show') }}</a-button>
      <a-drawer
        :height="screenIsMobile ? '100%' : '50%'"
        :title="tr(`${translationPrefix}.fields.${field.identifier}`,true)"
        :visible="visible"
        placement="bottom"
        wrap-class-name="signatureDrawer"
        @close="onCloseSignature"
      >
        <div class="buttons">
          <button
            class="ant-btn ant-btn-primary"
            @click.prevent="onCloseSignature"
          >{{ $t('_.done') }}</button>
          <button
            class="ant-btn ant-btn-primary"
            @click.prevent="undoSignature"
          >{{ $t('undo') }}</button>
        </div>
        <VueSignaturePad
          ref="signaturePad"
          :options="{ onEnd, onBegin: () => {$refs.signaturePad.resizeCanvas()} }"
          style="border: 1px solid rgb(217, 217, 217)"
          width="100%"
        />
      </a-drawer>
    </span>

    <a-rate
      v-else-if="field.type === 'rating'"
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      allow-half
    />

    <div v-else-if="field.type === 'color'">
      <a-button
        size="small"
        style="padding: 3px"
        class="color-field-btn"
        @click="openColorpicker"
      >
        <div
          :style="{backgroundColor: value}"
          style="width: 16px; height: 100%"
        />
      </a-button>
      <a-modal
        v-model="colorpickerVisible"
        :body-style="{height: 'auto !important', padding: '16px !important'}"
        @cancel="() => {out_value = fallbackColor}"
        @ok="() => {colorpickerVisible = false}"
      >
        <colorpicker
          :value="out_value ? out_value : 'white'"
          style="margin: 0 auto"
          @input="updateColor"
        />
      </a-modal>
    </div>

    <span v-else>{{ field.type }}</span>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { fieldIsRelation, fieldIsScalar, getFileURL, isScreenMobile, uniqid } from '@/utils'
import VueSignaturePad from 'vue-signature-pad'
import lodash from 'lodash'
import colors from 'vue-color'
import { openWebhookResponse } from '@/webhooks'
import CleaveMixin from '@/components/fields/cleave-mixin'
import LayoutDef from '@/layout/layout'
import { canUpdate } from '@/guard'
import Api from '@/api'

Vue.use(VueSignaturePad)

export default {
  name: 'Field',
  components: {
    'colorpicker': colors.Chrome,
  },
  mixins: [CleaveMixin],
  props: {
    field: {
      type: Object,
      required: false,
      default: () => {
        return {
          options: {},
        }
      },
    },
    listIndex: {
      type: [Object, Number],
      default: undefined,
    },
    listItemComponent: {
      type: Object,
      default: undefined,
    },
    module: {
      type: Object,
      default: undefined,
    },
    nested: Boolean,
    parent: {
      type: Object,
      default: undefined,
    },
    resource: {
      type: Object,
      default: undefined,
    },
    rootFieldTree: {
      type: Object,
      default: undefined,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: undefined,
    },
    initialValue: {
      type: String,
      default: '',
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    layout: {
      type: LayoutDef,
      default: () => new LayoutDef(),
    },
    layoutMode: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data () {
    return {
      centered: null,
      checked: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
      ],
      colorpickerVisible: false,
      current: this,
      defaultDateAndTimeFormats: {
        date: {
          store: 'YYYY-MM-DD',
          view: 'DD.MM.YYYY',
        },
        month: {
          store: 'YYYY-MM',
          view: 'MM.YYYY',
        },
        time: {
          store: 'HH:mm:ss',
          view: 'HH:mm',
        },
        dateTime: {
          store: 'YYYY-MM-DD HH:mm:ss',
          view: 'YYYY-MM-DD HH:mm',
          timeView: 'HH:mm',
        },
        week: {
          store: 'YYYY-WW',
          view: 'YYYY-WW',
        },
      },
      emit_value: this.value,
      fallbackColor: '',
      fileSelectVisible: false,
      help: '',
      last_emitted: this.value,
      listField: {
        freshVisible: false,
        fresh: {},
      },
      out_value: this.value,
      selectedFiles: [],
      screenIsMobile: isScreenMobile(),
      signaturePadInit: false,
      validateStatus: null,
      visible: false,
      fieldOptions: this.field.options || {},
      debouncers: {},
      fieldProtection: this.field.type === 'password',
    }
  },
  computed: {
    options () {
      return this.field.options || {}
    },

    hasMultiple () {
      return this.field.options.multiple || this.options.multiple
    },

    maskConfig () {
      return this.options.mask || null
    },

    dateTimeFormat () {
      return this.options && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.dateTime.store
    },

    dateTimeTimeFormat () {
      return this.options && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.dateTime.store
    },

    references () {
      return this.field.references ? this.field.references : this.options.references
    },
    dateFormat () {
      return this.field && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.date.view
    },
    monthFormat () {
      return this.field && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.month.view
    },
    defaultValue () {
      return this.getDefaultValue()
    },
    moment () {
      return moment
    },
    rangeDefault () {
      if (this.options.dual_thumb_mode === 1) {
        return this.value !== null ? JSON.parse(this.value) : [this.options.min, this.options.max]
      }
      return this.value !== null ? parseFloat(this.value) : this.options.min
    },
    disabled () {
      // disable all fields that have allowedOperations and the user can not update
      if (Array.isArray(this.field.allowedOperations) && canUpdate(this.field) === false) {
        return true
      }

      // disable by readonly, recipe and frontend_disabled properties
      return this.options.readonly === true ||
        this.options.readonly === 1 ||
        this.options.readonly ||
        (this.options && this.options.frontend_disabled === true) ||
        (this.options && this.options.frontend_disabled === 1) ||
        !!this.field.recipe ||
        !!(this.parent || {}).readonly
    },
    fieldIsRelation () {
      return fieldIsRelation(this.field.type)
    },
    fieldIsScalar () {
      return fieldIsScalar(this.field.type, this.options)
    },
    timeFormat () {
      return this.field && this.options.date_time_format ? this.options.date_time_format : this.defaultDateAndTimeFormats.time.view
    },
  },
  watch: {
    emit_value () {
      if (this.hasChanged()) {
        this.$emit('input', this.emit_value)
        this.fireChange()
        this.fireSync()
        this.last_emitted = this.emit_value
      }
    },
    out_value () {
      this.emit_value = this.out_value
    },
    value () {
      let value = this.value
      if ((this.field.type === 'number' || this.field.type === 'currency') && this.field.recipe) {
        value = parseFloat(value)
        if (this.field.type === 'number') {
          value = value.toFixed(this.options.decimal_digits || 0)
        }
        if (this.field.type === 'currency') {
          value = value.toFixed(this.options.decimal_digits || 2)
        }
      }
      if (this.field.type === 'checkbox') value = !!value
      this.out_value = value
    },
    'options.default' () {
      if (this.hasDefaultValue() && this.shouldUseDefaultValue()) {
        this.out_value = this.options.default
      }
    },
  },
  created () {
    this.$emit('tree', this)
    if (!this.parent && this.layout) {
      this.layout.registerField(this)
    }
  },
  mounted () {
    if (this.field.type === 'file') {
      this.loadSelectedList()
    }
    // Set default value and emit it
    this.out_value = this.shouldUseDefaultValue() ? this.getDefaultValue() : this.out_value
    if (this.isNew()) {
      this.$emit('input', this.out_value)
    }
    this.fireSync()
    this.fireLoad()
  },
  updated () {
    if (this.field.type === 'signature' && this.$refs.signaturePad && !this.signaturePadInit) {
      this.signaturePadInit = true
      this.$refs.signaturePad.fromDataURL(this.value)
    }
  },
  methods: {
    hasDefaultValue () {
      return typeof this.options.default !== 'undefined'
    },
    applyAntToImageInput () {
      // cant style image input directly
      let inputWrapper = document.getElementsByClassName('te-file-type')[0]
      let input = inputWrapper.getElementsByTagName('input')[0]
      input.setAttribute('id', 'fileUpload')
      input.style = 'display: none'
      let labelHTML = '<label id="fileUploadLabel" for="fileUpload" class="upload">Upload</label>'
      inputWrapper.insertAdjacentHTML('beforeend', labelHTML)

      input.addEventListener('change', function (file) {
        let label = document.getElementById('fileUploadLabel')
        let fileName = file.target.value.split('\\').pop()
        if (fileName) {
          label.innerHTML = fileName
        }
      })
    },
    fireChange () {
      this.propagateEvent('change', {
        event: 'on_change',
        component: this,
        target: this.field,
        value: this.emit_value,
        previousValue: this.last_emitted,
      })
    },
    fireBlur () {
      this.propagateEvent('blur', {
        event: 'on_blur',
        component: this,
        target: this.field,
        value: this.emit_value,
      })
    },
    fireSync () {
      this.propagateEvent('sync', {
        event: 'on_sync',
        component: this,
        target: this.field,
        value: this.emit_value,
      })
    },
    fireAdd ($event) {
      this.propagateEvent('add', $event)
      this.fireChange()
    },
    fireRemove ($event) {
      this.propagateEvent('remove', $event)
      this.fireChange()
    },
    fireLoad () {
      this.propagateEvent('load', {
        event: 'on_load',
        component: this,
        target: this.field,
        value: this.emit_value,
      })
    },
    getDefaultValue () {
      if (this.options.default !== null && this.options.default !== undefined) {
        // Default is specified
        if (this.field.type === 'uniqid' && this.options.default === true) {
          return uniqid()
        }
        return this.options.default
      }
      return null
    },
    getStepBasedOnDecimalDigits () {
      if (this.field.type === 'number' || this.field.type === 'currency') {
        return 1 / 10 ** Number(this.options.decimal_digits)
      }
      return 1
    },
    hasChanged () {
      if (typeof this.out_value === 'object' || Array.isArray(this.out_value)) {
        return !lodash.isEqual(this.emit_value, this.last_emitted)
      }
      return this.emit_value !== this.last_emitted
    },
    isNew () {
      return this.resourceIsNew() || this.listItemIsNew() || this.layoutMode === 'module.create'
    },
    /**
     * Get the JSONPath of this field.
     * @return {*|[string]}
     */
    jsonPath () {
      let path = this.parentPath()
      path.push(this.field.identifier)
      return path
    },
    listItemIsNew () {
      return this.parent && (!this.parent[this.listIndex] || !this.parent[this.listIndex].id)
    },
    /**
     * Get string identifying the field using either the field ID (in modules) or the identifier prefixed with the
     * translation key (used in modals).
     * @return string
     */
    getQualifiedIdentifier () {
      return this.field.id || `${this.translationPrefix}.${this.field.identifier}`
    },
    onCloseSignature () {
      this.visible = false
    },
    onEnd () {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.out_value = data
      this.$refs.signaturePad.fromDataURL(data)
    },
    openColorpicker () {
      this.colorpickerVisible = true
      this.fallbackColor = this.out_value
    },
    /**
     * Get the JSONPath of this field's parent.
     * @return {*|[string]}
     */
    parentPath () {
      if (this.parent) {
        let prepended = this.parent.parentPath()
        prepended.push(this.parent.field.identifier)
        prepended.push(this.listIndex)
        return prepended
      }
      return ['$']
    },
    /**
     * Emit an event and propagate it upwards to the field parents.
     * Vue-events emitted are generic "event" and specific change/blur/sync/etc
     *
     * @param event
     * @param $event
     */
    propagateEvent (event, $event) {
      if ($event.target.id !== this.field.id) {
        this._propagateEvent(event, $event)
        return
      }
      if (!this.debouncers[event]) {
        const wait = this.options.debounce_wait || 200
        this.debouncers[event] = lodash.debounce($event => this._propagateEvent(event, $event), wait)
      }
      this.debouncers[event]($event)
    },
    _propagateEvent (event, $event) {
      // Emit generic event
      this.$emit('event', $event)
      // Emit specific event
      this.$emit(event, $event)
    },
    rangeOnChange (value) {
      this.out_value = value
    },
    loadSelectedList () {
      let selectedList = this.out_value
      let fileList = []
      if (!selectedList || (this.fieldIsScalar && !Array.isArray(selectedList))) {
        selectedList = [selectedList]
      }
      selectedList.forEach(item => {
        if (item) {
          getFileURL(item.id, 'mini')
            .then(url => {
              fileList.push({
                'uid': item.id,
                'name': item.brezel_name,
                'status': 'done',
                'url': url,
                'brezelItem': item,
              })
            })
            .catch(() => {
              fileList.push({
                'uid': item.id,
                'name': item.brezel_name,
                'status': 'done',
                'brezelItem': item,
              })
            })
        }
      })
      this.selectedFiles = fileList
    },
    openFile (file) {
      Api.files().getFile(file.uid).then(file => openWebhookResponse(file))
    },
    resourceIsNew () {
      return this.resource && !this.resource.id
    },
    saveFiles () {
      this.out_value = [...this.$refs['fileSelect' + this.field.id].selectedList]
      if (this.fieldIsScalar) {
        this.out_value = this.out_value[0] || null
      }
      this.fileSelectVisible = false
      this.loadSelectedList()
    },
    shouldUseDefaultValue () {
      return this.isNew() && (this.out_value === undefined || this.out_value === null || this.out_value.length === 0)
    },
    showSignature () {
      this.screenIsMobile = isScreenMobile()
      this.visible = true
    },
    toggleProtection () {
      this.fieldProtection = !this.fieldProtection
    },
    tree (tree) {
      this.$emit('tree', tree)
    },
    undoSignature () {
      this.$refs.signaturePad.undoSignature()
      const { data } = this.$refs.signaturePad.saveSignature()
      this.out_value = data
      this.$refs.signaturePad.fromDataURL(data)
    },
    updateColor (color) {
      this.out_value = color.hex8
    },
  },
}
</script>

<style lang="scss">
.field .ant-input, .field .ant-input-number, .field .ant-input-number-input {
  min-width: 100px;
}

.field .ant-form-item-control-wrapper,
.field .ant-form-item-control,
.field .ant-form-item {
  width: 100%;
}

.field-group-item-list {
  .field-group-item .field .ant-form-item {
    margin-bottom: 8px;
  }

  .field-group-item:last-of-type .field .ant-form-item {
    margin-bottom: 0;
  }
}

.field .ant-form-item.ant-form-item-with-help {
  margin-bottom: 5px;
}

.field .ant-row.ant-form-item {
  margin-right: 0;
}

.field .type-number .ant-input-number-input {
  text-align: left !important;
}

.daySelector {
  padding-top: 8px;
  max-width: 350px;
}

.daySelector .ant-checkbox-group {
  display: flex;
  justify-content: space-around;
  padding: 4px;
}

.daySelector .ant-checkbox-wrapper {
  display: flex;
  flex-direction: column;
}

.daySelector .ant-checkbox-wrapper span {
  padding: 0;
}

.signatureDrawer .ant-drawer-wrapper-body {
  height: 100%;
}

.signatureDrawer .ant-drawer-body {
  height: calc(100% - 116px);
  padding: 16px 24px;
}

.signatureDrawer .buttons {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 0 16px;
}

.signatureDrawer .buttons button {
  margin-left: 16px;
}

.file-select-actions {
  position: absolute !important;
  top: 10px;
  right: 56px;
}

.vc-chrome {
  box-shadow: none !important;
  border: 1px solid #d9d9d9;
  font-family: inherit !important;
}

.field .ant-upload-picture-card-wrapper {
  display: flex;

  .ant-upload-list-picture-card-container {
    float: none;
    margin: 0;

    > span {
      display: block;
    }
  }

  .ant-upload-list-picture,
  .ant-upload-list-picture-card {
    .ant-upload-list-item {
      margin: 0;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    float: none;
    margin: 0;
  }
}

form {
  .ant-mentions,
  textarea.ant-input {
    margin-bottom: 0;
  }
}

</style>

<style lang="less" scoped>
@import (reference, less) "~ant-design-vue/lib/button/style/index.css";
@import (reference, less) "~ant-design-vue/lib/input/style/index.css";
@import (reference, less) "~ant-design-vue/es/card/style/index.css";

.field .ant-input-number-input {
  padding: 0 33px 0 11px;
}

.field .ant-input-number-disabled .ant-input-number-input {
  color: rgba(0, 0, 0, 0.65);
}

.field .ant-input-number-input[disabled=disabled] {
  padding: 0 11px;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
</style>
