<template>
  <div>
    <a-row
      type="flex"
      :gutter="16"
      style="margin-left: 0; margin-right: 0"
    >
      <a-col
        v-for="file in selectedFiles"
        :key="file.uid"
        :xs="12"
        :sm="8"
        :md="6"
        :lg="6"
        :xl="4"
        style="min-width: 128px"
        class="field-file-col"
      >
        <a-popover :content="file.name">
          <a-card
            class="field-file-card"
            style="height: 100%; display: flex; flex-direction: column; justify-content: flex-end;"
          >
            <a-icon
              v-if="file.brezelItem.mime_type === 'application/pdf'"
              slot="cover"
              class="file-icon"
              type="file-pdf"
            />
            <a-icon
              v-else-if="file.brezelItem.mime_type === 'image/svg'"
              slot="cover"
              class="file-icon"
              type="file-image"
            />
            <a-icon
              v-else-if="file.brezelItem.mime_type.includes('word')"
              slot="cover"
              class="file-icon"
              type="file-word"
            />
            <div
              v-else-if="file.brezelItem.mime_type.includes('image/') && !(file.brezelItem.mime_type.includes('svg'))"
              slot="cover"
              :style="'height: 64px; width: 100%; background: url(' + file.url + ') no-repeat center;background-size: contain;'"
            />
            <a-icon
              v-else
              slot="cover"
              type="file-unknown"
              class="file-icon"
            />
            <template slot="actions">
              <a-icon
                key="eye"
                type="eye"
                @click="openFile(file)"
              />
              <a-icon
                v-if="!disabled"
                key="delete"
                type="delete"
                @click="removeFile(file)"
              />
            </template>
          </a-card>
        </a-popover>
      </a-col>
      <a-col v-if="!disabled">
        <a-popover
          :content="$t('_.edit')"
        >
          <a-card
            :hoverable="true"
            style="height: 100%;"
            class="field-file-card"
            :body-style="editCardBodyStyle"
            @click="fileSelectVisible = true"
          >
            <a-icon type="edit"/>
          </a-card>
        </a-popover>
      </a-col>
    </a-row>
    <a-drawer
      ref="container"
      :closable="true"
      :title="$t(`modules.${options.references}.title`)"
      :visible="fileSelectVisible"
      height="90vh"
      placement="bottom"
      @close="() => {fileSelectVisible = false}"
    >
      <a-button-group class="file-select-actions">
        <a-button
          type="primary"
          @click="saveFiles"
        >
          <a-icon type="check-circle"/>
          {{ $t('_.save') }}
        </a-button>
      </a-button-group>
      <FileSelect
        :ref="'fileSelect' + field.id"
        v-model="out_value"
        :field="field"
        :multiple="hasMultiple"
        :module="fileModule"
        :infinite-wrapper="false"
        :directory="filePath"
      />
    </a-drawer>
  </div>
</template>

<script>
import Module from '@/module/module'
import Field from '@/components/fields/Field'
import FileSelect from '@/components/FileSelect'

export default {
  name: 'FieldFile',
  components: {
    FileSelect,
  },
  extends: Field,
  data () {
    return {
      editCardBodyStyle: {
        height: '100%',
        flex: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
  },
  computed: {
    fileModule () {
      return Module.byIdentifier(this.options.references)
    },
    filePath () {
      return this.options.path
    },
  },
  watch: {
    out_value () {
      this.loadSelectedList()
    },
  },
  methods: {
    removeFile (file) {
      let index = this.selectedFiles.findIndex(x => x.uid === file.uid)
      if (index !== -1) {
        this.selectedFiles.splice(index, 1)
      }
      if (this.hasMultiple) {
        let data = []
        this.selectedFiles.forEach(file => {
          data.push(file.brezelItem)
        })
        this.out_value = data
      } else {
        this.out_value = null
      }
      if (!this.out_value || this.out_value.length === 0) {
        this.out_value = null
      }
    },
  },
}
</script>

<style>
.file-icon {
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-icon > svg {
  width: 100%;
  height: 50%;
}

.field-file-card .default {
  background-size: contain;
}
</style>
