import 'isomorphic-fetch'
import 'ant-design-vue/dist/antd.less'
import '@/registerServiceWorker'
import FieldCalculator from '@kibro/brezel-recipes'
import FormulaParser from '@kibro/brezel-recipes/src/formula-parser'
import { guessSystem } from '@/utils'
import Brezel from '@/brezel'

window.FieldCalculator = FieldCalculator
window.FormulaParser = FormulaParser

const brezel = new Brezel(process.env.VUE_APP_API_URL, guessSystem())

const app = brezel.bootstrap().$mount('#app')

window.app = app

export default app
