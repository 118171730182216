<template>
  <div
    v-if="value"
    class="with-padding"
    style="justify-content: end"
  >
    {{ formated }}
  </div>
  <div
    v-else
    class="with-padding"
  />
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellCurrency',
  extends: FieldCell,
  computed: {
    formated () {
      let options = {
        style: 'currency',
        currency: this.options.currency_code || 'EUR',
      }
      return new Intl.NumberFormat(this.$store.state.currentLocale, options).format(this.value)
    },
  },
}
</script>

<style scoped>

</style>
