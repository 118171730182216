<template>
  <div
    :brezel-component-id="component.id"
    style="text-align: center"
  >
    <div class="donutbox">
      <div class="title">
        <h1>{{ component.options.headline }}</h1>
      </div>
      <a-spin
        v-if="loading"
        style="padding-left: 50%; padding-top: 20%; padding-bottom: 20%;"
      />
      <template v-else>
        <div
          v-if="dataPresent"
          style="height: 2%; width: 60%; margin: 0 0 0 0; padding: 0rem 5% 0rem 5%;"
        >
          <canvas :id="id"/>
        </div>
        <template v-else>
          {{ $t('_.no_data') }}
        </template>
        <FieldContainer
          v-if="instancesWithValuesLength > 5"
          v-model="filter"
          class="barChartSearchField"
          :field="filterField"
          :placeholder="tr('_.search', true)"
        />
      </template>
      <template v-if="!loading">
        <ul class="legend">
          <li
            v-for="instance in instancesFiltered"
            :key="instance.label"
            class="labelElement"
          >
            <div style="display: flex; align-items: center">
              <div
                class="labelColor"
                :style="{ background: instance.color }"
              />
              <h3 class="label">
                {{ instance.label }}
              </h3>
            </div>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import ColorScheme from 'color-scheme'
import FieldContainer from '@/components/FieldContainer'
import _ from 'lodash'
import tinycolor from 'tinycolor2'
import { redirect, uniqid } from '@/utils'
import Chart from 'chart.js/auto'
import centerTextPlugin from '@/components/layout-components/widgets/donut/plugins/centerText'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { fireWebhookEvent } from '@/webhooks'

export default {
  name: 'DonutWidget',
  components: {
    FieldContainer,
  },
  extends: LayoutComponent,
  props: [],
  data () {
    return {
      chart: null,
      dataPresent: false,
      filter: null,
      filterField: {
        type: 'text',
        placeholder: 'lel',
      },
      id: 'chart' + uniqid(),
      loadingTimeout: undefined,
      loading: true,
    }
  },
  computed: {
    centerText () {
      const text = _.get(this.component, 'options.centerText', false)
      return text !== null ? text : false
    },
    chartData () {
      return this.instancesFiltered.map(instance => instance.value)
    },
    nonNullchartData () {
      return this.chartData.filter(x => !!x)
    },
    colorValues () {
      return _.get(this.component, 'options.colorValues', [])
    },
    instances () {
      return _.get(this.component, 'options.instances', [])
    },
    instancesFiltered () {
      if (typeof this.filter === 'string') {
        return this.instances.filter(element => {
          let label = this.tr(element.label, true)
          if (typeof label !== 'string') {
            label = ''
          }
          return label.toLowerCase().includes(this.filter.toLowerCase())
        })
      }

      return this.instances
    },
    instancesWithValuesLength () {
      return this.instances.filter(value => value.value > 0).length
    },
  },
  watch: {
    nonNullchartData: function () {
      this.checkForValues()
    },
  },
  beforeDestroy () {
    this.clearLoadingTimeout()
    this.destroyChart()
  },
  mounted () {
    this.loadingTimeout = window.setTimeout(() => {
      if (!this.dataPresent) {
        let chartName = _.get(this.component, 'options.headline', this.name)
        console.info('No data received in 10 seconds, stopping loading animation for widget ' + chartName)
        this.clearLoadingTimeout()
      }
    }, 10000)
    this.initChartDeb = _.debounce(this.initChart, 500)
  },
  methods: {
    clearLoadingTimeout () {
      window.clearTimeout(this.loadingTimeout)
      this.loadingTimeout = -1
      this.checkForValues()
      this.loading = this.loadingTimeout !== -1 && !this.dataPresent
    },
    chartColors () {
      let colors = this.colorValues
      const scheme = new ColorScheme()

      // defined color scheme
      this.colorValues.forEach(element => {
        scheme.from_hex(element).scheme('mono').variation('pastel').distance(0.5).web_safe(true)
        colors = colors.concat(scheme.colors())
      })

      // fallback: generate color scheme based on #949494
      if (colors.length === 0) {
        scheme.from_hex('949494').scheme('mono').variation('pastel').distance(0.5).web_safe(true)
        colors = colors.concat(scheme.colors())
      }

      let allColors = []
      this.instancesFiltered.forEach((element, i) => {
          allColors.push(element.color || colors[i % colors.length])
        }
      )

      return this.mapToHexColors(allColors)
    },
    chartBorderWidths () {
      return this.instancesFiltered.map(() => 0)
    },
    getDataset (index = 0) {
      return _.get(this.chart, ['data', 'datasets', index])
    },
    getTrigger () {
      return _.get(this.chart, ['data', 'trigger'])
    },
    mapToHexColors (colors) {
      return colors.map(color => {
        const hexColorRegex = /^#([0-9a-f]{3}){1,2}$/i
        if (hexColorRegex.test(color)) return color

        const hexColorWithoutHashRegex = /^([0-9a-f]{3}){1,2}$/i
        if (hexColorWithoutHashRegex.test(color)) return '#' + color
      })
    },
    checkForValues () {
      if (this.nonNullchartData.length > 0) {
        if (!this.dataPresent) {
          this.dataPresent = true
          this.clearLoadingTimeout()
        }
        this.$nextTick(this.initChartDeb)
      }
    },
    addBordersToCurrentElement (itemIndex) {
      const dataset = this.getDataset()

      if (dataset.borderWidth) {
        let setWidth = 3
        if (dataset.borderWidth[itemIndex] !== 0) {
          setWidth = 0
        }
        dataset.borderWidth = this.chartBorderWidths()
        dataset.borderWidth[itemIndex] = setWidth
      }
    },
    showTooltipForCurrentElement (itemIndex) {
      const chartArea = this.chart.chartArea
      this.chart.tooltip.setActiveElements([{
        datasetIndex: 0,
        index: itemIndex,
      }], {
        x: (chartArea.left + chartArea.right) / 2,
        y: (chartArea.top + chartArea.bottom) / 2,
      })
    },
    handleClick (e, items) {
      const element = items.length >= 1 ? items[0] : items
      if (!element) return

      const trigger = _.get(this.getTrigger(), element.index, false)
      if (!trigger || !trigger.type) return

      switch (trigger.type) {
        case 'redirect': {
          this.fireRedirectTrigger(trigger)
          break
        }
        case 'webhook': {
          this.fireWebhookTrigger(trigger)
          break
        }
      }
    },
    handleLabelFocus (event, item) {
      this.addBordersToCurrentElement(item.index)
      this.showTooltipForCurrentElement(item.index)
      this.chart.update()
    },
    fireRedirectTrigger (trigger) {
      const mode = trigger.target_action ?? 'module'
      redirect(mode, {
        module_identifier: trigger.target_module,
        resource_identifier: trigger.target_resource,
      }, trigger.target_anchor)
    },
    fireWebhookTrigger (trigger) {
      fireWebhookEvent(trigger.identifier,
        _.get(trigger, 'target_module', null),
        _.get(trigger, 'target_resource', null),
        _.get(trigger, 'target-data', null)
      )
    },
    destroyChart () {
      if (this.chart !== undefined && this.chart !== null) {
        try {
          this.chart.destroy()
        } catch (e) {
          console.debug('Could not destroy chart', e)
        }
      }
    },
    getCanvasContext () {
      const canvasElement = document.getElementById(this.id)
      if (!canvasElement) return

      return canvasElement.getContext('2d')
    },
    initChart () {
      this.destroyChart()

      const ctx = this.getCanvasContext()
      if (!ctx) return

      let chartPlugins = []
      if (this.centerText !== false) chartPlugins.push(centerTextPlugin)
      if (_.get(this.component, 'options.displayDataLabels', false)) chartPlugins.push(ChartDataLabels)

      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: this.instancesFiltered.map(instance => this.tr(instance.label, true)),
          trigger: this.instancesFiltered.map(instance => _.get(instance, 'trigger', null)),
          datasets: [
            {
              data: this.chartData,
              backgroundColor: this.chartColors(),
              borderWidth: this.chartBorderWidths(),
            },
          ],
        },
        plugins: chartPlugins,
        options: {
          borderAlign: 'inner',
          borderColor: '#ffffff',
          hoverBorderColor: '#ffffff',
          hoverBorderWidth: 2,
          maintainAspectRatio: false,
          responsive: true,
          onClick: this.handleClick,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          plugins: {
            title: {
              align: 'center',
              text: this.component.options.headline,
              font: {
                size: 30,
              },
            },
            legend: {
              display: false,
              onClick: this.handleClick,
              onHover: this.handleLabelFocus,
              onLeave: this.handleLabelFocus,
              position: 'right',
              anchor: 'start',
              align: 'center',
              labels: {
                boxWidth: 20,
              },
            },
            'doughnut-centertext': {
              text: this.centerText,
            },
            datalabels: {
              color: function (context) {
                const backgroundColors = context.dataset.backgroundColor
                const backgroundColor = backgroundColors[context.dataIndex % backgroundColors.length]
                return tinycolor(backgroundColor).isDark() ? 'white' : 'black'
              },
              formatter: function (value) {
                if (_.isNumber(value)) return parseFloat(value.toFixed(2))
                return value
              },
              display: function (context) {
                const dataset = context.dataset
                const value = dataset.data[context.dataIndex]
                return value === 0 ? false : 'auto'
              },
              clamp: true,
              clip: true,
              font: {
                size: 16,
                weight: 500,
              },
            },
          },
        },
      })
    },
  },
}
</script>
<style scoped>

.donutbox {
  width: 100%;
  height: 100%;
  margin: 0.5rem 0 0.5rem 0;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.title {
  position: absolute;
  margin: 0 0 60% 0;
  left: 0;
  right: 0;
}

.legend {
  position: relative;
  padding: 0;
  margin-top: 10%;
  margin-bottom: 5%;
  list-style: none none;
}

.labelElement {
  left: 0;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  position: relative;
  display: flex;
  align-items: center;
}

.labelColor {
  left: 0;
  height: 10px;
  width: 10px;
}

.label {
  text-align: left;
  margin-left: 10px;
}

.barChartSearchField {
  display: none;
  position: absolute;
  margin: 4% 0 0 -2%;
}

</style>
