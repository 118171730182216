<template>
  <div>
    {{ value === null ? '' : formatFieldOutputValue(field, value) }}
  </div>
</template>

<script>
import 'codemirror/mode/php/php.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/lib/codemirror.css'
import { formatFieldOutputValue } from '@/utils'
import FieldViewer from '@/components/fields/FieldViewer'

export default {
  name: 'FieldViewerNumber',
  components: {},
  extends: FieldViewer,
  computed: {},
  methods: {
    formatFieldOutputValue,
  },
}
</script>
