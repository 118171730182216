<template style="border:0px;">
  <div
    :style="'padding: 20px; ' + styleCss"
    class="ressource_cards_view"
    :class="styleClass"
  >
    <div class="ressource_card_filter">
      <div style="display: inline-block;">
        <span style="margin-right:10px;margin-bottom:10px;">{{ $t('_.sort') }}</span>
        <a-select
          v-model="sortField"
          class="sortselect"
          style=" margin-right:10px;margin-bottom:10px;"
          :disabled="ressources.length === 0"
          @change="changeFilter"
        >
          <a-select-option value="">
            - {{ $t('cardsview.unsorted') }} -
          </a-select-option>
          <a-select-option
            v-for="option in columns"
            :key="option.id"
            :value="option"
          >
            {{ tr(`modules.${module.identifier}.fields.${option}`, true) }}
          </a-select-option>
        </a-select>
        <div
          class="sortOrder"
          style="margin-bottom:10px;"
        >
          <a-radio-group
            v-model="sortOrder"
            default-value="ascend"
            button-style="solid"
            :disabled="ressources.length === 0"
            @change="changeFilter"
          >
            <a-radio-button value="ascend">
              <a-icon type="sort-ascending" />
            </a-radio-button>
            <a-radio-button value="descend">
              <a-icon type="sort-descending" />
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <div class="searchbar">
        <a-input-search
          v-model="searchVal"
          :placeholder="`${$t('_.search')}`"
          style="max-width: 300px"
          @search="changeFilter"
        >
          <template #addonBefore>
            <a-select
              v-model="searchCol"
              style="min-width: 120px"
            >
              <a-select-option
                v-for="option in columns"
                :key="option.id"
                :value="option"
              >
                {{ tr(`modules.${module.identifier}.fields.${option}`, true) }}
              </a-select-option>
            </a-select>
          </template>
        </a-input-search>
      </div>
      <div class="refresh">
        <a-button
          size="small"
          @click="changeFilter"
        >
          <a-icon type="reload"/>
        </a-button>
      </div>
    </div>
    <div v-if="!dataloaded"/>
    <div v-else>
      <div v-if="ressources.length === 0">
        <!-- <a-empty :description="$t('_.no_data')" /> -->
        <router-link :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/create'">
          <div class="createNewCard">
            <div class="newCard">
              <a-icon
                type="plus"
                class="newCardIcon"
              />
              {{ $t('_.create') }}
            </div>
          </div>
        </router-link>
      </div>
      <a-row :gutter="16">
        <a-col
          v-for="(ressource, index) in ressources"
          :key="index"
          :span="6"
          :style="{ marginTop: '16px' }"
          class="cardview-col"
        >
          <a-card
            :bordered="true"
            class="the-card"
            hoverable
            :style="setCardRecipeStyle(ressource)"
            :class="setCardRecipeClass(ressource)"
          >
            <div
              slot="title"
              ref="cardhead"
              class="card-title"
              @click="redirect($event, index, '/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id)"
            >
              <router-link
                :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id"
              >
                {{ ressource[title_column] }}
              </router-link>
              <div class="action-buttons">
                <div v-show="showActionBtn">
                  <a-popconfirm
                    v-show="showDeleteAction"
                    :title="$t('_.are_you_sure')"
                    @confirm="deleteCard(ressource.id)"
                  >
                    <a-tooltip placement="top">
                      <template #title>
                        <span>{{ $t('_.delete') }}</span>
                      </template>
                      <a-button
                        v-show="showDeleteAction"
                        shape="circle"
                        size="small"
                        style="float:right;margin-left:5px;"
                        type="danger"
                      >
                        <a-icon type="delete"/>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>

                  <router-link
                    v-show="showEditAction"
                    :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id + '/edit'"
                  >
                    <a-tooltip placement="top">
                      <template #title>
                        <span>{{ $t('_.edit') }}</span>
                      </template>
                      <a-button
                        shape="circle"
                        size="small"
                        style="float:right;margin-left:5px; background: #0597d1;color:#fff;border-color:#0597d1;"
                      >
                        <a-icon type="edit"/>
                      </a-button>
                    </a-tooltip>
                  </router-link>

                  <router-link
                    v-show="showCopyAction"
                    :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id + '/copy'"
                  >
                    <a-tooltip placement="top">
                      <template #title>
                        <span>{{ $t('_.copy') }}</span>
                      </template>
                      <a-button
                        shape="circle"
                        size="small"
                        style="float:right;"
                      >
                        <a-icon type="copy"/>
                      </a-button>
                    </a-tooltip>
                  </router-link>
                </div>
                <div v-show="!showActionBtn && actions.length">
                  <a-dropdown :trigger="['click']">
                    <a-button
                      shape="circle"
                      style="float:right;"
                      class="circle-options-btn"
                      @click.prevent
                    >
                      <a-icon type="more"/>
                    </a-button>
                    <template #overlay>
                      <a-menu class="cards_dropdown_menu">
                        <a-menu-item
                          v-show="showEditAction"
                          key="0"
                        >
                          <router-link
                            :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id + '/edit'"
                          >
                            <a-icon type="edit"/>
                            {{ $t('_.edit') }}
                          </router-link>
                        </a-menu-item>
                        <a-menu-item
                          v-show="showCopyAction"
                          key="1"
                        >
                          <router-link
                            :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id + '/copy'"
                          >
                            <a-icon type="copy"/>
                            {{ $t('_.copy') }}
                          </router-link>
                        </a-menu-item>
                        <a-menu-divider v-show="showDeleteAction && showCopyAction || showEditAction" />
                        <a-menu-item
                          v-show="showDeleteAction"
                          key="2"
                        >
                          <a-popconfirm
                            :title="$t('_.are_you_sure')"
                            placement="bottom"
                            @confirm="deleteCard(ressource.id)"
                          >
                            <a-icon type="delete"/>
                            {{ $t('_.delete') }}
                          </a-popconfirm>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </div>
            </div>
            <router-link
              :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + ressource.id"
            >
              <div class="card-content">
                <table>
                  <tr
                    v-for="field in columns"
                    :key="field.index"
                  >
                    <td>{{ tr(`modules.${module.identifier}.fields.${field}`, true) }}</td>
                    <td v-if="module.getField(field).type === 'file'">
                      <a
                        v-for="files in ressource[field]"
                        :key="files.index"
                        @click="openFile(files)"
                      >
                        {{ files.name }}
                      </a>
                    </td>
                    <td v-else>
                      {{ getFieldValue(ressource[field], field) }}
                    </td>
                  </tr>
                </table>
              </div>
            </router-link>
            <template slot="actions">
              <a-icon
                v-for="button in custombuttons"
                :key="button.index"
                :type="button.icon"
                :alt="button.title"
                @click="openWebhook(button, ressource.id)"
              />
            </template>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <infinite-loading
      ref="InfiniteLoading"
      @infinite="infiniteHandler"
    >
      <div slot="no-more"/>
    </infinite-loading>
  </div>
</template>

<script>
    import Api, { apiGet } from '@/api'
    import { apiLink, getFileURL } from '@/utils'
    import InfiniteLoading from 'vue-infinite-loading/src/components/InfiniteLoading'
    import store from '@/store'
    import { EventBus } from '@/event-bus'
    import { fireWebhookEvent } from '@/webhooks'
    import _ from 'lodash'
    import FieldCalculator from '@kibro/brezel-recipes/src/field-calculator'

    export default {
        name: 'LayoutComponentCardsView',
        components: {
          InfiniteLoading,
        },
        props: {
            component: {
            type: Object,
            default: undefined,
            },
            modules: {
            type: Object,
            default: undefined,
            },
        },
        data () {
            return {
                manyErrors: false,
                title_column: '',
                nextPage: 1,
                perPage: 20,
                search: '',
                count: 0,
                ressources: [],
                columns: [],
                custombuttons: [],
                actions: [],
                cards: [],
                sortOrder: 'ascend',
                sortField: '',
                showDeleteAction: false,
                showCopyAction: false,
                showEditAction: false,
                showActionBtn: false,
                dataloaded: false,
                searchVal: '',
                searchCol: '',
            }
        },
        computed: {
          styleClass () {
            if (this.component.options && this.component.options.style && this.component.options.style.class) { return this.component.options.style.class } else { return '' }
          },
          styleCss () {
            if (this.component.options && this.component.options.style && this.component.options.style.css) { return this.component.options.style.css } else { return '' }
          },
          styleAction () {
            if (this.component.options && this.component.options.style && this.component.options.style.actions) {
              return this.component.options.style.actions
            } else {
              return 'dropdown'
            }
          },
        },
        created () {
          this.module = Api.getModule(this.component.options.module)
          this.searchCol = this.component.options.title_column
        },
        mounted () {
            this.title_column = this.component.options.title_column
            this.module = Api.getModule(this.component.options.module)
            if (this.component.options.columns) {
                this.columns = this.component.options.columns
            } else {
                var fields = this.module.fields
                var columnsArr = []
                fields.forEach(field => {
                    columnsArr.push(field.identifier)
                })
                this.columns = columnsArr
            }
            this.custombuttons = this.component.options.buttons
            this.actions = this.component.options.actions

            this.setActions()
            if (this.styleAction === 'buttons') {
              this.showActionBtn = true
            }
        },
         methods: {
             requestUrl () {
                this.module = Api.getModule(this.component.options.module)
                const url = new URL(apiLink(['modules', this.module.identifier, 'resources'], store.state.currentSystem))
                var urlParams = {
                    page: this.nextPage,
                    results: this.perPage,
                    sortOrder: this.sortOrder,
                    filters: '{"' + this.searchCol + '":["' + this.searchVal + '"]}',
                }
                if (this.sortField) {
                  urlParams.sortField = this.sortField
                }

                var filters = []
                filters = this.component.options.pre_filters
                if (filters) {
                    urlParams.pre_filters = JSON.stringify(filters)
                }
                Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))
                return url
            },
             getFieldValue (ressource, column) {
                var value = ''
                 var fieldtyp = this.module.getField(column).type
                 switch (fieldtyp) {
                    case 'select':
                        if (ressource !== null) {
                            value = ressource.title
                            }
                    break
                    case 'list':
                        if (ressource !== null) {
                            value = ressource
                        }
                        break
                    case 'choice':
                       value = this.tr(`modules.${this.module.identifier}.choice.${column}.${ressource}`, true)
                      break
                    default:
                        value = ressource
                    break
                 }
                return value
            },
            getRessources (paginated = false) {
                 const url = this.requestUrl()
                 this.dataloaded = false
                return apiGet(url)
                    .then(response => response.json())
                    .then(response => {
                   if (response.data === undefined) {
                        this.ressources = []
                    } else if (paginated) {
                        this.ressources = this.ressources.concat(response.data)
                    } else {
                        this.ressources = response.data
                    }
                    this.dataloaded = true
                    this.count = response.total
                    ++this.nextPage
                })
             },
            openFile (file) {
                getFileURL(file.id).then(url => {
                EventBus.$emit('open-buffer', {
                    url: url,
                    type: file.mime_type,
                    name: file.brezel_name,
                 })
                })
            },
             infiniteHandler ($state) {
                this.getRessources(true)
                    .then(() => {
                    $state.loaded()

                    if (this.count <= this.ressources.length) {
                        $state.complete()
                    }
                })
             },
             openWebhook (button, resourceIdentifier) {
                 fireWebhookEvent(button.trigger, this.module.identifier, resourceIdentifier, { context: this.context })
             },
             changeFilter (value) {
                    this.ressources = []
                    this.count = 0
                    this.nextPage = 1
                    this.$refs.InfiniteLoading.stateChanger.reset()
             },
             setCardRecipeStyle (data) {
              let css = ''
               if (this.component && _.has(this.component, 'options.card_settings')) {
                 if (this.component.options.card_settings.recipes.style) {
                    const headSetting = this.component.options.card_settings.recipes.style
                    const calculator = new FieldCalculator(data)
                    css = calculator.calculate(headSetting)
                 }
                return css
              }
             },
             setCardRecipeClass (data) {
              let css = ''
               if (this.component && _.has(this.component, 'options.card_settings')) {
                 if (this.component.options.card_settings.recipes.class) {
                    const headSetting = this.component.options.card_settings.recipes.class
                    const calculator = new FieldCalculator(data)
                    css = calculator.calculate(headSetting)
                 }
                return css
              }
             },
             deleteCard (id) {
                let entity = []
                entity.module = this.module
                entity.id = id
                Api.deleteEntity(entity).then(response => response.json()).then(response => {
                  if (response.success) {
                    this.$message.success(this.$t('trash.delete_successful'))
                    this.ressources = []
                    this.count = 0
                    this.nextPage = 1
                    this.$refs.InfiniteLoading.stateChanger.reset()
                  }
                })
             },
             setActions () {
                this.actions = this.component.options.actions
                this.actions.forEach(action => {
                  if (action === 'edit') { this.showEditAction = true }
                  if (action === 'delete') { this.showDeleteAction = true }
                  if (action === 'copy') { this.showCopyAction = true }
                })
             },
             redirect (event, id, route) {
               if (event.target === this.$refs.cardhead[id]) {
                this.$router.push(
                    {
                      path: route,
                    }
                )
              }
             },
        },
    }

</script>

<style scoped>
.createNewCard {
  border:2px dashed #ccc;
  width: 280px;
  height:277px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
  position:relative;
  display:inline-block;
}
.createNewCard:hover {
  border-color:#ddd;
}
.createNewCardBtn:hover {
  border-color:#e6e6e6;
}
.createNewCardBtn {
background: #ffffff;
    color: #737373;
    border: 3px solid #d9d9d9;
    border-radius: 100%;
    height: 100px;
    width: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight:bold;
    padding:0px;
}
.newCardIcon {
  text-align: center;
  width:100%;
  height:auto;
  margin-bottom:10px;
}
.newCard {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;}

.cards_dropdown_menu li a .anticon{ margin-right:10px;}
.cards_dropdown_menu .ant-dropdown-menu-item a{ padding-right:20px;}
.ressource_cards_view .card-title a{
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 30px;
}
.ressource_cards_view .card-title {
  position:relative;
}
.ressource_cards_view .action-buttons {
  position: absolute;
  right:0px;
  top: 50%;
  transform: translateY(-50%);
}
.ressource_cards_view .action-buttons:after {
  content: " ";
  display:block;
  clear:both;
}

.ressource_cards_view .the-card {border-radius:5px;border-top:4px solid #1890ff; }
.ressource_cards_view .the-card .card-content{max-height:220px; color:rgba(0, 0, 0, 0.65); height:220px; overflow-y: auto; padding:0px 0px;}
.ressource_cards_view table {width:100%;padding:0px;margin-top:10px;}
.ressource_cards_view table tr:first-child td{ border-top:0px; }
.ressource_cards_view table tr:last-child td{ border-bottom:0px; }
.ressource_cards_view table tr td:first-child{ border-left:0px; }
.ressource_cards_view table tr td:last-child{ border-right:0px; }
.ressource_cards_view table tr td{
    border-top: 1px solid #f3f3f3;
    width:50%;
    padding:6px 20px;
    vertical-align: top;
}

.searchbar { display:inline-block; margin-bottom:10px;margin-right:10px; vertical-align: bottom;}

.refresh { display:inline-block; margin: 0px 10px 10px 0px;}
.refresh button { border-radius:0px;height:32px;}
.ressource_card_filter {
    padding:0px;
}
.ressource_card_filter span{
    display: inline-block;
}
.ressource_card_filter .sortOrder {
    display: inline-block; padding:0px 0px;margin:0px 20px 10px 0px;
}
.ressource_card_filter .sortOrder a{
    padding:0px 5px; color: rgba(0, 0, 0, 0.65);
}
.ressource_card_filter .sortOrder a:hover{
    color: #1890ff;
}
.ressource_card_filter .sortOrder a.active{
    color: #1890ff;
}
@media only screen and (max-width: 420px) {
    .ressource_card_filter .ant-select { margin:0px; }
    .ressource_card_filter .sortOrder {margin:0px; }
    .ressource_card_filter span{ margin:0px;}
}
@media only screen and (min-width: 421px) {
 .sortselect {width:200px}
}

</style>

<style>
.newCardIcon svg {height:35px !important; width:auto;}
.circle-options-btn.ant-btn { height:32px; }
.circle-options-btn.ant-btn .anticon{ height:auto;}
.ressource_cards_view .the-card .ant-card-body {padding:0px !important;}
.ressource_cards_view .the-card .ant-card-actions {border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}
.ressource_cards_view .the-card .ant-card-head{
  border-bottom:0px;
  position:relative;
  padding:0px 12px;
 /* background:#fafafa;
  background: linear-gradient(45deg, rgba(24, 144, 255, 0) 0%, rgba(162, 162, 162, 0.1) 100%); */
}
.ressource_cards_view .the-card .ant-card-head:after{
 /* position:absolute; left:0px; */
  content:" "; display:block; height:3px; width:100%;
  border-bottom:1px solid rgb(169, 169, 169);
  /*
  background: rgb(111,139,166);
  background: linear-gradient(45deg, rgba(24, 144, 255, 1) 0%, rgba(163, 210, 255, 1) 100%); */
}

.ressource_cards_view .ant-card-head-title {
  padding: 10px 0;
}
@media only screen and (max-width: 420px) {
    .ressource_cards_view .cardview-col { min-width: 100%; }
}
@media only screen and (min-width: 421px) {
    .ressource_cards_view .cardview-col { min-width: 50%; }
}
@media only screen and (min-width: 600px ) {
    .ressource_cards_view .cardview-col { min-width: 33%; }
}

@media only screen and (min-width: 1100px) {
    .ressource_cards_view .cardview-col { min-width: 25%; }
}
</style>
