<template>
  <a-affix
    :offset-top="top"
  >
    <div
      class="affixButtonBar"
    >
      <slot/>
      <component
        :is="getLayoutComponent(subComponent, mode)"
        v-for="(subComponent, key) in components"
        :key="key"
        :component="subComponent"
        :field-tree="fieldTree"
        :resource="resource"
        :translation-prefix="translationPrefix"
        :context="context"
        v-on="$listeners"
      />
    </div>
  </a-affix>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import LayoutComponents from '@/components/layout-components'

export default {
  name: 'LayoutComponentAffix',
  components: {
    LayoutComponent,
  },
  extends: LayoutComponent,
  mixins: [LayoutComponents],
  computed: {
    top: function () {
      return this.component && this.component.options ? this.component && this.component.options.top : 0
    },
    components: function () {
      return this.component && this.component.options ? this.component.options.components : []
    },
  },
}
</script>
