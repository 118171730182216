<template>
  <div
    v-if="renderComponent"
    :brezel-component-id="component.id"
    :style="{ display, flexWrap, justifyContent, overflowX, padding: containerPadding }"
    class="button-component"
  >
    <template
      v-for="(button, index) in buttons"
    >
      <a-tooltip
        :key="`btn-${index}`"
        :style="{ padding, margin, flex, width: (button.options || {}).width || 'unset' }"
      >
        <template
          v-if="button.help"
          slot="title"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="tr(button.help, true)"/>
        </template>
        <template v-if="!Array.isArray(button.items) || button.items.length === 0">
          <component
            :is="button.popconfirm ? 'a-popconfirm' : 'div'"
            :title="typeof button.popconfirm === 'string' ? tr('popconfirm.' + button.popconfirm, true) : $t('_.are_you_sure')"
            :ok-text="$t('_.yes')"
            :cancel-text="$t('_.no')"
            @confirm="buttonClick(getCallbackArgs(button))"
          >
            <component
              :is="button.link && button.link.external ? 'a' : 'div'"
              v-bind="button.link && button.link.external ? button.link : {}"
            >
              <a-button
                v-bind="{...button, ...(button.options || {}).attrs}"
                :block="component.options.display_style === 'block'"
                :style="{ display: 'block', width: button.icon ? '' : '100%' }"
                :type="button.style"
                :size="button.size || size"
                :html-type="button.htmlType || (button.form ? 'submit' : 'button')"
                :disabled="isDisabled(button)"
                :class="(button.options || {}).class"
                @click="!button.popconfirm ? buttonClick(getCallbackArgs(button)) : ''"
              >
                {{ tr(getButtonName(button), true) }}
              </a-button>
            </component>
          </component>
        </template>
        <template v-else>
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <component
                  :is="dropDownItem.name ? 'a-menu-item' : 'a-menu-divider'"
                  v-for="(dropDownItem, dropDownItemIndex) in button.items"
                  :key="dropDownItemIndex"
                  @click="buttonClick(getCallbackArgs(dropDownItem))"
                >
                  <component
                    :is="dropDownItem.link && dropDownItem.link.external ? 'a' : 'div'"
                    v-bind="dropDownItem.link && dropDownItem.link.external ? dropDownItem.link : {}"
                  >
                    <a-icon
                      v-if="dropDownItem.icon"
                      :type="dropDownItem.icon"
                    />
                    {{ tr(dropDownItem.name, true) }}
                    <a-spin v-if="dropDownItem.loading"/>
                  </component>
                </component>
              </a-menu>
            </template>
            <a-button
              :block="component.options.display_style === 'block'"
              :style="{ display: 'block', width: '100%' }"
              :type="button.style"
            >
              <a-icon
                v-if="button.icon"
                :type="button.icon"
              />
              {{ tr(button.name, true) }}
              <a-icon type="down"/>
            </a-button>
          </a-dropdown>
        </template>
      </a-tooltip>
    </template>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'

export default {
  name: 'LayoutComponentButtons',
  extends: LayoutComponent,
  props: {
    'resource': {
      type: Object,
      default: undefined,
    },
    'value': {
      type: [String, Number, Object, Array, Boolean],
      default: undefined,
    },
    'selections': {
      type: [Array, Object, Boolean],
      default: undefined,
    },
    'disabled': {
      type: Boolean,
      default: undefined,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  data: function () {
    return {
      renderComponent: true,
    }
  },
  computed: {
    buttons () {
      return this.options.buttons.filter(b => !(b.options && b.options.hidden_from_frontend))
    },
    display () {
      if (this.component.options.display_style === 'block') {
        return 'block'
      }
      return 'flex'
    },
    flex () {
      if (this.component.options.display_style === 'scrollable' ||
        this.component.options.display_style === 'block') {
        return null
      }
      return this.component.options.flex || 'none'
    },
    flexWrap () {
      if (this.component.options.display_style === 'scrollable' ||
        this.component.options.display_style === 'block') {
        return null
      }
      return 'wrap'
    },
    justifyContent () {
      if (this.component.options.display_style === 'scrollable' ||
        this.component.options.display_style === 'block') {
        return null
      }
      return this.component.options.justify_content || 'flex-start'
    },
    overflowX () {
      if (this.component.options.display_style === 'scrollable') {
        return 'auto'
      }
      return 'none'
    },
    margin () {
      if (this.component.options.display_style === 'block') {
        return 'none'
      }
      return 0
    },
    padding () {
      if (this.component.options.display_style === 'block') {
        return '4px 8px'
      }
      return '8px 4px'
    },
    containerPadding () {
      if (this.component.options.display_style === 'block') {
        return 'none'
      }
      return '0 4px'
    },
  },
  created () {
  },
  methods: {
    getButtonName (button) {
      return button.title ? button.title : (button.identifier ? 'buttons.' + button.identifier : '')
    },
    getCallbackArgs (button) {
      return {
        buttonName: this.getButtonName(button),
        target: button,
        component: this,
        value: this.value,
        selected: this.selections,
      }
    },
    resolvePromise (promise, button) {
      promise
        .then(() => this.$set(button, 'loading', false))
        .catch(() => this.$set(button, 'loading', false))
    },
    isDisabled (button) {
      if (button && button.options && button.options.disabled === true) {
        return true
      }
      return this.disabled === true
    },
  },
}
</script>

<style lang="scss">
.button-component > div {
  &:first-of-type {
    margin-top: 4px;
  }

  &:last-of-type {
    margin-bottom: 4px;
  }
}
</style>
