<template>
  <div
    v-if="!value || value.length === 0"
    style="padding: 24px 32px"
  >
    {{ $t('_.no_data') }}
  </div>
  <div v-else-if="options.mode === 'layout'">
    <Layout
      v-for="item in field.getItems()"
      :key="item.getUID()"
      :layout="item.layout"
      :mode="layoutMode"
      :resource="item.value"
      :field-source="item"
      :module="module"
    />
  </div>
  <a-tabs
    v-else-if="options.mode === 'tabs'"
    :active-key="tabActiveKey"
    size="small"
    @tabClick="tabClick"
  >
    <a-tab-pane
      v-for="(item, itemIndex) in value"
      :key="itemIndex"
    >
      <template #tab>
        <span>
          {{ titleField(item) ? titleField(item) : (item.index + 1) }}
        </span>
      </template>
      <div class="tab-navigation">
        <a-icon
          v-show="!isFirstTab"
          type="arrow-left"
          @click="tabNavigate(-1)"
        />
        <div v-show="isFirstTab"/>
        <a-icon
          v-show="!isLastTab"
          type="arrow-right"
          @click="tabNavigate(1)"
        />
      </div>
      <div
        v-for="(child, child_index) in listCols"
        :key="child.identifier + '-' + child_index"
        :style="getStyleForListField(child)"
        style="margin-bottom: 16px;"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="child.isButton"
          style="font-weight: bold"
          v-html="tr(`buttons.${child.identifier}`)"
        />
        <div
          v-else
          style="font-weight: bold"
          v-html="tr(`${translationPrefix}.fields.${child.identifier}`)"
        />
        <div style="overflow-x: auto; overflow-scrolling: touch">
          <ListCellViewer
            v-model="item[child.identifier]"
            :cell="child"
            :module="module"
            :translation-prefix="translationPrefix"
            :layout-mode="layoutMode"
            :parent-identifier="field.identifier"
          />
        </div>
      </div>
    </a-tab-pane>
  </a-tabs>
  <table
    v-else
    :class="nested ? 'fieldTable nestedTable' : 'fieldTable'"
  >
    <tr>
      <th
        v-if="options.show_ordering"
        style="width: 1px"
      />
      <!-- eslint-disable vue/no-v-html -->
      <template v-for="(child, child_index) in listCols">
        <th
          v-if="child.isButton === true"
          :key="child_index"
          :style="getStyleForListField(child)"
          v-html="tr(`buttons.${child.identifier}`)"
        />
        <th
          v-else
          :key="child_index"
          :style="getStyleForListField(child)"
          v-html="tr(`${translationPrefix}.fields.${child.identifier}`)"
        />
      </template>
    </tr>
    <tr
      v-for="(item, itemIndex) in value"
      :key="itemIndex"
    >
      <td
        v-if="options.show_ordering"
        style="text-align: right; padding: 4px 8px;"
      >
        {{ itemIndex + 1 }}
      </td>
      <td
        v-for="(child, child_index) in listCols"
        :key="child_index"
        :style="getStyleForListField(child)"
      >
        <ListCellViewer
          v-model="item[child.identifier]"
          :cell="child"
          :index="itemIndex"
          :module="module"
          :translation-prefix="translationPrefix"
          :layout-mode="layoutMode"
          :parent-identifier="field.identifier"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import FieldViewer from '@/components/fields/FieldViewer'
import _ from 'lodash'
import Field from '@/components/fields'
import ListCellViewer from '@/components/fields/list/ListCellViewer'
import { buildCustomButtons } from '../../../utils'

export default {
  name: 'FieldViewerList',
  components: {
    ListCellViewer,
    Layout: () => import('@/components/Layout.vue'),
  },
  extends: FieldViewer,
  mixins: [Field],
  props: {
    nested: Boolean,
    context: undefined,
  },
  data: () => ({
    tabActiveKey: 0,
  }),
  computed: {
    isFirstTab () {
      return this.tabActiveKey === 0
    },
    isLastTab () {
      return this.tabActiveKey === this.value.length - 1
    },
    templateSpec () {
      return this.options.template
    },
    listCols () {
      if (!this.field.options.hasOwnProperty('buttons')) return this.field.children

      // Add Buttons to the Cols
      // copy children before modifying to prevent vue reference weirdness
      let cols = this.field.children.slice()
      let buttons = this.field.options.buttons ?? []
      for (let button of buttons) {
        const buttonIdentifier = button.title ?? button.trigger
        const webhookButton = buildCustomButtons(buttonIdentifier, button, this.context.module, this.context.entity.id, this.context)
        webhookButton['isButton'] = true
        webhookButton['identifier'] = buttonIdentifier
        const position = button.position ?? cols.length
        cols.splice(position, 0, webhookButton)
      }
      return cols
    },
  },
  created () {
    this.makeFields()
  },
  mounted () {
    this.tabActiveKey = _.get(this.value[0], 'index') || 0
  },
  methods: {
    makeFields () {
      const fields = []
      this.field.clear()
      this.field.addItems((this.value || []).concat().sort((a, b) => a.index - b.index))
      this.field.getItems().forEach(item => {
        fields.push(item.fields)
      })
      return fields
    },

    getStyleForListField: (field) => {
      field.options = field.options || {}
      const shouldHide = field.options.hidden === true || field.options.hidden_from_frontend === true
      return {
        display: shouldHide ? 'none' : undefined,
        padding: field.type === 'list' ? 0 : false,
      }
    },

    tabClick (key) {
      this.tabActiveKey = key
    },

    tabEdit (targetKey, action) {
      if (action === 'remove') {
        this.removeItem(targetKey)
      } else if (action === 'add') {
        this.addItem(targetKey)
      }
    },

    tabNavigate (steps) {
      this.tabActiveKey += steps
    },

    titleField (item) {
      return _.get(item, this.options.title_field) || item.index
    },
  },
}
</script>

<style scoped>
.field-list-item {
  border: 1px solid #eee;
  padding: 5px;
  margin-bottom: 5px;
}

.fieldTable {
  min-width: 100%;
  width: auto;
}

.fieldTable,
.fieldTable td,
.fieldTable th {
  padding: 4px;
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
}

.fieldTable tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.fieldTable tr:nth-child(even) {
  background-color: #ffffff;
}

.fieldTableActions {
  width: 1px;
}

.nestedTable {
  border: 0;
}

.nestedTable tr:first-of-type td,
.nestedTable tr:first-of-type th {
  border-top: 0;
}

.nestedTable tr:last-of-type td {
  border-bottom: 0;
}

.nestedTable tr td:first-of-type {
  border-left: 0;
}

.nestedTable tr td:last-of-type {
  border-right: 0;
}

.tab-navigation {
  position: relative;
  top: -8px;
  display: flex;
  justify-content: space-between;
}

.tab-navigation .anticon {
  cursor: pointer;
}

</style>
