<template>
  <div>
    <a-modal
      v-model="visible"
      :confirm-loading="submitting"
      centered
      class="webhook-modal"
      on-ok="handleOk"
      width="75%"
      @cancel="handleCancel"
    >
      <template #title>
        {{ title }}<span v-if="differsFromOriginal">*</span>
        <a-button-group
          v-if="autosaved && Array.isArray(autosaved)"
          style="float: right; margin-right: 40px"
        >
          <a-dropdown :trigger="['click']">
            <a
              class="ant-dropdown-link"
              @click="e => e.preventDefault()"
            >
              <a-icon type="history"/>
            </a>
            <a-menu
              slot="overlay"
              style="min-width:200px"
            >
              <a-menu-item
                :disabled="!differsFromFirstOriginal"
                @click="loadFirstOriginal"
              >
                <a-icon :type="differsFromFirstOriginal ? 'rollback' : 'check'"/> Original
              </a-menu-item>
              <a-menu-item
                v-for="autosaveItem in autosaved.slice().reverse()"
                :key="autosaveItem.time"
                icon="history"
                :style="{ fontWeight: isCurrentRevision(autosaveItem.data) ? 'bold' : 'normal' }"
                @click="loadAutosaved(autosaveItem)"
              >
                <a-icon
                  v-if="isCurrentRevision(autosaveItem.data)"
                  style="color: green"
                  type="check"
                />
                <a-icon
                  v-else
                  type="save"
                />
                {{ autosaveItem.time|formatTime }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-button-group>
      </template>
      <template slot="footer">
        <a-button
          key="back"
          @click="handleCancel"
        >
          {{ cancelText }}
        </a-button>
        <a-button
          key="submit"
          :loading="submitting"
          type="primary"
          @click="handleSubmit"
        >
          {{ submitText }}
        </a-button>
      </template>
      <a-form
        id="webhookForm"
        layout="vertical"
        @submit.prevent="handleSubmit"
      >
        <Layout
          ref="layout"
          :event="webhookInput.event"
          :layout="webhookInput.layout"
          :module="webhookInput.module"
          :resource="webhookInput.input"
          :translation-prefix="translationPrefix"
          :mode="mode"
          :context="webhookInput.context"
          :store-active-tab="false"
        />
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Layout from '@/components/Layout'
import Validations from '@/mixins/Validations'
import { handleToast } from '@/utils'
import { cloneDeep, isEqual, isEqualWith } from 'lodash'

export default {
  name: 'WebhookInput',
  components: {
    Layout,
  },
  filters: {
    formatTime (time) {
      const date = new Date(time)
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    },
  },
  mixins: [Validations],
  props: {
    webhookInput: {
      type: Object,
      default: () => {
        return {}
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      submitting: false,
      visible: this.value,
      resource: this.webhookInput.input,
      firstOriginal: cloneDeep(this.webhookInput.input),
      original: cloneDeep(this.webhookInput.input),
      onBeforeUnload: () => {
        this.autosave()
      },
    }
  },
  computed: {
    mode () {
      return this.webhookInput.mode || 'module.create'
    },

    autosaved () {
      return this.$store.getters.autosavedModal(this.autosaveKey)
    },

    autosaveKey () {
      let key = null
      if (this.webhookInput.event) {
        key = this.webhookInput.event.identifier + ':' + (this.webhookInput.module || {}).identifier
      }
      if (this.webhookInput.identifier) {
        key = this.webhookInput.identifier
      }
      if (key && this.webhookInput.context && this.webhookInput.context.entity) {
        key = `${key}:${this.webhookInput.context.entity.module_id}:${this.webhookInput.context.entity.module_id}`
      }
      if (this.webhookInput.autosaveKey) {
        key += `:${this.webhookInput.autosaveKey}`
      }
      return key
    },

    differsFromFirstOriginal () {
      return !this.isCurrentRevision(this.firstOriginal)
    },

    differsFromOriginal () {
      return !this.isCurrentRevision(this.original)
    },

    cancelText () {
      return this.getButtonText('cancel')
    },

    submitText () {
      return this.getButtonText('submit')
    },

    layout () {
      return this.webhookInput.layout
    },

    translationPrefix () {
      if (this.webhookInput.identifier) {
        return this.webhookInput.identifier
      }
      return null
    },

    title () {
      if (this.webhookInput.title) {
        return this.webhookInput.title
      }
      if (this.webhookInput.identifier) {
        return this.$t(this.webhookInput.identifier + '.title')
      }
      if (this.webhookInput.event) {
        return this.$t('events.' + this.webhookInput.event.identifier + '.title')
      }
      if (this.layout.identifier) {
        return this.$t('layouts.' + this.layout.identifier + '.title')
      }
      return this.layout.title
    },
  },
  watch: {
    value () {
      this.visible = this.value
    },

    visible () {
      this.$emit('input', this.visible)
    },
  },
  mounted () {
    this.$brezel.onBeforeUnload(this.onBeforeUnload)
  },
  destroyed () {
    this.$brezel.unregisterBeforeUnload(this.onBeforeUnload)
  },
  methods: {
    /**
     * Empty list fields are sometimes [] and sometimes null, so we need to pass a custom equal test to lodash's
     * isEqualWith.
     *
     * @param value
     * @param other
     * @returns {boolean}
     */
    customEqual (value, other) {
      if ((value === [] && other === null) || (value === null && other === [])) {
        return true
      }
      return isEqual(value, other)
    },

    isCurrentRevision (revision) {
      return isEqualWith(revision, this.webhookInput.input, this.customEqual)
    },

    getObjectDiff (obj1, obj2) {
      return Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key)
        } else if (isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key)
          result.splice(resultKeyIndex, 1)
        }
        return result
      }, Object.keys(obj2))
    },

    loadFirstOriginal () {
      this.webhookInput.input = cloneDeep(this.firstOriginal)
      this.original = cloneDeep(this.firstOriginal)
      this.$message.info(`Original revision restored`)
    },

    loadAutosaved (autosave) {
      if (autosave && autosave.data) {
        this.webhookInput.input = cloneDeep(autosave.data)
        this.original = cloneDeep(autosave.data)
        this.$message.info(`Revision ${this.$options.filters.formatTime(autosave.time)} restored`)
      }
    },

    autosave () {
      if (this.autosaveKey) {
        if (this.differsFromOriginal) {
          this.$store.commit('autosaveModal', { key: this.autosaveKey, modal: this.webhookInput.input })
          console.info(`Autosaved modal ${this.autosaveKey}`)
        }
      }
    },

    clearAutosaved () {
      this.$store.commit('clearAutosavedModal', this.autosaveKey)
    },

    handleCancel () {
      this.autosave()
      if (this.webhookInput.cancel) {
        return this.webhookInput.cancel().then(() => {
          this.visible = false
        })
      }
      this.visible = false
    },

    handleSubmit () {
      this.submitting = true
      this.webhookInput.submit({
        input: this.webhookInput.input,
        selected: this.getSelections(),
      }).then(anotherModal => {
        this.submitting = false
        this.clearAutosaved()
        // Because hiding the modal removes it from the DOM after the closing animation, we only hide it if no other
        // modal is triggered from the `submit` webhook. This way we can keep the global closing animation while
        // making it possible to open consecutive modals.
        // Explicit comparison with 'true' her is necessary because of js truthiness for objects
        if (anotherModal === true) return
        this.visible = false
      }).catch(error => {
        this.autosave()
        if (error.response) {
          error.response.json().then(data => {
            if (!data.success) {
              handleToast({ toast_type: 'error', message: this.$t('_.validation_error') })
            }
            this.processValidations(data)
          })
        }
        this.submitting = false
      })
    },

    getButtonText (text) {
      if (this.webhookInput.event && this.$te('events.' + this.webhookInput.event.identifier)) {
        return this.$t('events.' + this.webhookInput.event.identifier + '.' + text)
      }
      if (this.$te('layout.' + this.layout.identifier + '.' + text)) {
        return this.$t('layout.' + this.layout.identifier + '.' + text)
      }
      if (this.$te(`${this.translationPrefix}.${text}`)) {
        return this.$t(`${this.translationPrefix}.${text}`)
      }
      return this.$t('event.' + text)
    },

    getSelections () {
      const selections = {
        'default': [],
      }
      for (const [element] of this.layout.elements()) {
        if (element.selections || (element.options && element.options.selections)) {
          selections.default = selections.default.concat(element.selections || element.options.selections)
          if (element.identifier) {
            selections[element.identifier] = element.selections
          }
        }
      }
      return selections
    },
  },
}
</script>
