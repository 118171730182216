import jsonpath from 'jsonpath'
import {
  downloadBuffer,
  entityDecycler,
  getFilenameFromResponse,
  handleToast,
  openBuffer,
  redirect,
  refresh,
} from '@/utils'
import { EventBus } from '@/event-bus'
import { apiPost, fetchLayout } from '@/api'
import Modal from 'ant-design-vue/es/modal'
import Layout from '@/layout/layout'
import { apiGet } from './api'

export const openWebhookResponse = (response, args) => {
  if (response === undefined) {
    console.warn('Webhook response undefined')
    return
  }
  return response.blob().then(buffer => {
    if (buffer.size === 0) {
      console.warn('Webhook response empty')
      return
    }
    let type = buffer.type.toLowerCase()
    if (response.status !== 200) {
      // Open Json responses even if they where not successful
      if (type === 'application/json') return openWebhookJSON(buffer, args)
      return
    }
    let filename = getFilenameFromResponse(response.headers.get('content-disposition'))
    // TODO: disambiguate whether to show or download result based on content-disposition header (inline/attachment)
    switch (type) {
      case 'application/json':
        return openWebhookJSON(buffer, args)
      case 'application/pdf':
        if (!filename && args && args.resource && args.resource.brezel_name) {
          filename = args.resource.brezel_name + '.pdf'
        }
        return openBuffer(buffer, filename)
      case 'video/webm':
        return openBuffer(buffer, filename)
      case 'text/html':
      case 'text/html; charset=utf-8':
      case 'application/xml':
      case 'text/xml':
      case 'text/xml; charset=utf-8':
      case 'text/csv':
      case 'text/csv; charset=utf-8':
      case 'text/tab-separated-values':
      case 'text/tab-separated-values; charset=utf-8':
      case 'text/plain; charset=utf-8':
      case 'text/plain':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        if (filename === null) {
          return openBuffer(buffer, filename)
        }
        return downloadBuffer(buffer, filename)
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        if (filename === null) {
          openBuffer(buffer, filename)
          break
        }
        return downloadBuffer(buffer, filename)
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/webp':
      case 'image/svg+xml':
        if (filename) {
          filename = filename.replace('inline; filename=', '')
        }
        return openBuffer(buffer, filename)
      default:
        console.error(`Unhandled webhook response with mime type "${buffer.type}"`)
    }
  })
}

export const handleOAuth = (json, args) => {
  const oauthOptions = json.options
  if (oauthOptions.action === 'start') {
    // Redirect to the OAuth process
    const url = new URL(oauthOptions.url)
    url.searchParams.append('token', localStorage.getItem('accessToken'))
    url.searchParams.append('then', window.location.href)
    window.location.href = url.toString()
  }
}

export const handleRefresh = () => {
  refresh()
}

export const handleException = (json) => {
  handleToast({
    toast_type: 'warning',
    message: json.options.title,
    description: json.options.description,
  })
}

export const handleRedirect = (json) => {
  EventBus.$emit('handeRedirect')
  if (!json.to && !json.options) {
    return
  }
  if (json.to && json.to.module) {
    if (json.to.action) {
      if (json.to.resource) {
        redirect(json.to.action, {
          module_identifier: json.to.module,
          resource_identifier: json.to.resource,
        }, json.to.anchor, json.target, json.queryParams)
      } else {
        redirect(json.to.action, {
          module_identifier: json.to.module,
          resource_identifier: json.to.resource,
          resource: json.to.fill,
        }, json.to.anchor, json.target, json.queryParams)
      }
    } else {
      redirect('module', { module_identifier: json.to.module }, json.to.anchor, json.target, json.queryParams)
    }
    return
  }
  if (json.options && json.options.to.url) {
    let target = '_self'
    if (json.options.target && json.options.target !== null) { target = json.options.target }
    if (json.options.swap) {
      apiGet(['swap']).then(() => {
        window.open(
          json.options.to.url,
          target
        )
      })
    } else {
      window.open(
        json.options.to.url,
        target
      )
    }
  }
}

const handleWebhookSet = (json, args) => {
  if (!json.set) {
    return
  }
  const setInstructions = json.set
  setInstructions.forEach(setInstruction => {
    if (setInstruction.type === 'value') {
      setInstructionValue(setInstruction, args)
    }
    if (setInstruction.type === 'field') {
      setInstructionField(setInstruction, args)
    }
    if (setInstruction.type === 'add') {
      setInstructionAdd(setInstruction, args)
    }
  })
}

const setInstructionValue = (instruction, { resource }) => {
  jsonpath.apply(resource, instruction.selector, original => {
    return instruction.value
  })
}

const setInstructionField = (instruction, { resource, fieldTree, layout }) => {
  const nodes = jsonpath.nodes(fieldTree, instruction.selector)
  nodes.forEach(node => {
    const field = node.value
    if (instruction.properties) {
      if (instruction.properties.values) {
        // Set options of (multi)select <Field> component
        field.loadedResources = instruction.properties.values
      }
      if (instruction.properties.options) {
        // this behavior is DEPRECATED because it interferes with the "options" object
        // replace this with "values"
        // Set options of (multi)select <Field> component
        field.loadedResources = instruction.properties.options
      }
      for (let key in instruction.properties) {
        if (field.field && instruction.properties.hasOwnProperty(key)) {
          if (field.field.hasOwnProperty(key)) {
            field.$set(field.field, key, instruction.properties[key])
          } else {
            field.$set(field.field.options, key, instruction.properties[key])
          }
        }
      }
    }
  })
}

const setInstructionAdd = (instruction, { resource }) => {
  jsonpath.apply(resource, instruction.selector, original => {
    const list = Array.from(original || [])
    const value = Array.isArray(instruction.value) ? instruction.value : [instruction.value]
    const currentIndex = list.length > 0 ? list.sort((a, b) => b.index - a.index)[0].index + 1 : 0
    for (let i = 0; i < value.length; i++) {
      value[i].index = currentIndex + i
      if (Number.isInteger(value[i].index) === false) {
        value[i].index = list.length
      }
    }
    return Array.isArray(original) ? original.concat(value) : value
  })
}

export const runCheckpoint = (checkpoint, data, args) => {
  return apiPost(['webhook', 'checkpoint', checkpoint.id], {}, data ? JSON.stringify(data) : undefined)
    .then(response => {
      if (response === undefined || response.status !== 200) {
        const error = new Error()
        error.response = response
        throw error
      }
      return response
    })
    .then(response => openWebhookResponse(response, args))
}

const handleWebhookLink = (link, data, args) => {
  if (link.type === 'checkpoint') {
    const checkpoint = link.checkpoint
    if (data) {
      data.state = checkpoint.state
    }
    return runCheckpoint(checkpoint, data, args)
  }
  return new Promise((resolve, reject) => {
    resolve()
  })
}

/**
 * Opens modals triggered from webhooks.
 * Returns true if a modal was prompted by this webhook.
 * E.g. useful to detect if another modal was opened by the `submit` hook from a different modal
 *
 * @return {boolean}
 */
const handleWebhookModal = (json, args) => {
  if (!json.options) {
    return false
  }
  const definedLayout = json.options.layout

  if (definedLayout === null) {
    promptBasicModal(json.options, args)
    return true
  }

  if (typeof definedLayout === 'string') {
    fetchLayout(json.options.layout)
      .then(layout => promptLayout(layout, json, args))
    return true
  }

  if (typeof definedLayout === 'object') {
    promptLayout(json.options.layout, json, args)
    return true
  }

  return false
}

function promptBasicModal (options, args) {
  const modalFunc = options.type ? Modal[options.type] : Modal.confirm
  const modal = modalFunc({
    title: options.title ?? window.app.$t(`${options.identifier}.title`),
    onOk () {
      handleWebhookLink(options.on_confirm, {}, args).then(() => modal.destroy())
    },
    onCancel () {
      handleWebhookLink(options.on_cancel, {}, args).then(() => modal.destroy())
    },
  })
}

function promptLayout (layout, json, args) {
  EventBus.$emit('webhook-input', {
    layout: new Layout(layout),
    identifier: json.options.identifier,
    title: json.options.title,
    input: json.options.fill || {},
    mode: json.options.mode || 'module.create',
    context: args.context,
    autosaveKey: json.options.autosave_key,
    submit: input => new Promise((resolve, reject) => {
      handleWebhookLink(json.options.on_confirm, input, args).then(value => resolve(value)).catch(reject)
    }),
    cancel: () => new Promise((resolve, reject) => {
      handleWebhookLink(json.options.on_cancel, {}, args).then(resolve).catch(reject)
    }),
  })
}

export const handleWebhookJSON = (json, args) => {
  if (json.type !== 'webhook') {
    return json
  }
  switch (json.action) {
    case 'redirect':
      return handleRedirect(json)
    case 'set':
      return handleWebhookSet(json, args)
    case 'modal':
      return handleWebhookModal(json, args)
    case 'refresh':
      return handleRefresh()
    case 'exception':
      return handleException(json)
    case 'oauth':
      return handleOAuth(json, args)
    default:
      break
  }
}

const openWebhookJSON = (buffer, args) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(handleWebhookJSON(JSON.parse(this.result.toString()), args))
    }
    reader.readAsText(buffer)
  })
}

export const handleWebhookButtonInput = (webhook, module) => {
  return new Promise((resolve, reject) => {
    const resolveAfterInput = resolve
    EventBus.$emit('webhook-input', {
      event: webhook,
      module,
      layout: new Layout(webhook.layouts.find(layout => layout.type === 'detail')),
      input: {},
      submit: input => new Promise((resolve, reject) => {
        resolveAfterInput(input)
        resolve()
      }),
    })
  })
}

export const fireWebhookEvent = (webhookIdentifier, module, resourceIdentifier, data, localArgs = {}) => {
  const body = data ? JSON.stringify(data, entityDecycler) : null
  let moduleIdentifier = module
  if (typeof module === 'object' && module !== null) {
    moduleIdentifier = module.identifier
  }
  let apiRequest = ['webhook', webhookIdentifier]

  if (moduleIdentifier) {
    apiRequest.push(moduleIdentifier)
  }

  if (resourceIdentifier !== undefined && resourceIdentifier !== '') {
    apiRequest.push(resourceIdentifier)
  }

  return apiPost(apiRequest, {}, body, { 'Content-Type': 'application/json' })
    .then(response => openWebhookResponse(response, localArgs))
}

/**
 * Fetch the full definition of a module event.
 * @param identifier
 * @param moduleIdentifier
 * @return {Promise<any>}
 */
export const fetchModuleEvent = (identifier, moduleIdentifier) => apiGet(['modules', moduleIdentifier, 'events', identifier]).then(response => response.json())

/**
 * @param webhook
 * @param module
 * @param resourceIdentifier
 * @param localArgs
 * @param data
 */
export const webhookButtonHandler = (webhook, module, resourceIdentifier, localArgs = {}, data = {}) => {
  const inputLayout = webhook.layouts.find(layout => layout.type === 'detail')
  if (inputLayout) {
    // Open input modal before firing webhook event
    // DEPRECATED. Use action/modal instead.
    handleWebhookButtonInput(webhook, module).then(input => fireWebhookEvent(webhook.identifier, module, resourceIdentifier, input, localArgs))
  } else {
    // Fire webhook event right away, no input modal needed
    return fireWebhookEvent(webhook.identifier, module, resourceIdentifier, data, localArgs)
  }
}
