<template>
  <div
    :brezel-component-id="component.id"
    :class="component.options.size ? 'ant-card-head-' + component.options.size : 'ant-card-head'"
  >
    <div
      class="splitFieldContainer"
    >
      <div
        v-for="(element, index) in component.options.instances"
        :key="element.identifier"
        class="splitFieldField"
        :class="{splitFieldField: (component.options.instances.length - 1) === 0, first: index === 0, last: index === (component.options.instances.length - 1)}"
      >
        <div class="identifier">
          <h2>{{ element.identifier }}</h2>
        </div>
        <div
          class="splitFieldDescription"
          :style="[ (index === 0) ? {'border-bottom-right-radius': '0px', 'border-top-right-radius': '0px' } : { '': '' }, index === (component.options.instances.length - 1) ? { 'border-bottom-left-radius': '0px', 'border-top-left-radius': '0px' } : {'' : ''}]"
        >
          {{ element.identifier }}
        </div>
        <div class="value">
          <h3>{{ element.value }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import _ from 'lodash'

export default {
  name: 'LayoutComponentSplitField',
  extends: LayoutComponent,
  props: {
    translationPrefix: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      filter: null,
    }
  },
  computed: {
    ccolor: {
      get () {
        return this.bcolor
      },
      set (val) {
        this.bcolor = val
      },
    },
    instances () {
      return _.get(this.component, 'options.instances', [])
    },
    instancesFiltered () {
      if (typeof this.filter === 'string') {
        return this.instances.filter(element => {
          let identifier = this.tr(element.identifier, true)
          if (typeof identifier !== 'string') {
            identifier = ''
          }
          return identifier.toLowerCase().includes(this.filter.toLowerCase())
        })
      }
      return this.instances
    },
  },
  mounted () {
  },
  updated () {
    let valuechange = document.querySelectorAll('.valuechange')
    valuechange.forEach(element => {
      if (element.innerHTML > 0) {
        element.style.color = 'green'
        element.innerHTML = '↑' + element.innerHTML
      } else if (element.innerHTML < 0) {
        element.style.color = 'red'
        element.innerHTML = '↓' + element.innerHTML
      } else if (parseInt(element.innerHTML) === 0) {
        element.innerHTML = '→' + element.innerHTML
        element.style.color = 'rgba(0,0,0,0.2)'
      }
    })
  },
}
</script>
<style scoped>
.splitFieldContainer {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  border-radius: 5px;
  height: 10rem;
  width: 100%;
  margin: 0.5rem 0px 0.5rem 0px;
}

.splitFieldField {
  text-align: center;
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.splitFieldField.first {
  text-align: center;
  position: relative;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
}

.splitFieldField.last {
  text-align: center;
  position: relative;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100%;
  background: transparent;
}

.splitFieldField h2 {
  visibility: visible;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
  height: 3em;
  text-align: center;
}

.splitFieldDescription {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  border-radius: 5px;
  z-index: 1;
  color: black;
  position: absolute;
  margin: auto;
  font-size: 120%;
  height: 100%;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  transition: opacity .1s, visibility .1s;
}

.splitFieldField:hover .splitFieldDescription {
  visibility: visible;
  opacity: 1;
}

.splitFieldField:hover h2 {
  visibility: hidden;
}

.splitFieldField h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
  height: 3em;
  text-align: center;
  padding: 0px 1rem 0px 1rem;
}

.identifier {
  position: absolute;
  padding: 2rem;
  font-size: 70%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
}

.value {
  position: absolute;
  padding-top: 4rem;
  font-size: 120%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
</style>
