<template>
  <div
    :brezel-component-id="component.id"
    :style="{ 'padding': component.options.no_padding === true ? 0 : false }"
    :class="'ant-card-body ' + className"
  >
    <a-collapse
      v-if="component.options.collapse"
      :default-active-key="component.options.collapse.is_collapsed === true ? 0 : 1"
    >
      <a-collapse-panel
        key="1"
        :header="tr(`modules.${module.identifier}.collapse.${component.options.collapse.title}`, true)"
      >
        <layout-component-field-group-show-content
          v-bind="$props"
        />
      </a-collapse-panel>
    </a-collapse>
    <layout-component-field-group-show-content
      v-else
      v-bind="$props"
    />
  </div>
</template>

<script>
import LayoutComponentFieldGroup from '@/components/layout-components/LayoutComponentFieldGroup'
import LayoutComponentFieldGroupShowContent from '@/components/layout-components/LayoutComponentFieldGroupShowContent'

export default {
  name: 'LayoutComponentFieldGroupShow',
  components: {
    LayoutComponentFieldGroupShowContent,
  },
  extends: LayoutComponentFieldGroup,
  computed: {
    className () {
      if (this.component.options.class) { return this.component.options.class } else { return '' }
    },
  },
}
</script>
