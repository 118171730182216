import _ from 'lodash'

export class LayoutComponents {
  static getLayoutComponent (type, mode) {
    switch (type) {
      case 'field_group':
        switch (mode) {
          case 'module.edit':
          case 'module.create':
            return 'LayoutComponentFieldGroupInput'
          case 'module.show':
            return 'LayoutComponentFieldGroupShow'
          default:
            return 'LayoutComponentFieldGroup'
        }
      case 'buttons':
        return 'LayoutComponentButtons'
      case 'collapse':
        return 'LayoutComponentCollapse'
      case 'file_view':
        return 'LayoutComponentFileView'
      case 'files':
        return 'LayoutComponentFiles'
      case 'headline':
        return 'LayoutComponentHeadline'
      case 'text':
        return 'LayoutComponentText'
      case 'hr':
        return 'LayoutComponentHr'
      case 'resource_table':
        return 'LayoutComponentResourceTable'
      case 'resource_cards':
        return 'LayoutComponentCardsView'
      case 'view':
        return 'LayoutComponentView'
      case 'affix':
        return 'LayoutComponentAffix'
      case 'layout':
        return 'LayoutComponentLayout'
      case 'widget-bar':
        return 'LayoutComponentWidgetBar'
      case 'widget-donut':
        return 'LayoutComponentWidgetDonut'
      case 'splitField':
        return 'LayoutComponentWidgetSplitField'
      case 'widget-progress':
        return 'LayoutComponentWidgetProgress'
      case 'widget':
        return 'LayoutComponentWidget'
      case 'navigation':
        return 'LayoutComponentNavigation'
      case 'map':
        return 'LayoutComponentMap'
      case 'markdown':
        return 'LayoutComponentMarkdown'
      case 'calendar':
        return 'LayoutComponentCalendar'
      default:
        return 'LayoutComponent'
    }
  }
}

/**
 * Mixin to provide layout types to components
 */
export default {
  methods: {
    getLayoutComponent: (spec, mode) => {
      return LayoutComponents.getLayoutComponent(spec.type, _.get(spec, 'options.mode', mode))
    },
  },
}
