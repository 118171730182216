import { registerFieldEdit, registerFieldView } from '@/components/fields/index'
import Field from '@/components/fields/Field'
import FieldChoice from '@/components/fields/choice/FieldChoice'
import FieldWYSIWYG from '@/components/fields/wysiwyg/FieldWYSIWYG'
import FieldWeek from '@/components/fields/week/FieldWeek'
import FieldViewer from '@/components/fields/FieldViewer'
import FieldLink from '@/components/fields/link/FieldLink'
import FieldViewerLink from '@/components/fields/link/FieldViewerLink'
import FieldDateRange from '@/components/fields/date_range/FieldDateRange'
import FieldSelect from '@/components/fields/select/FieldSelect'
import FieldMultiselect from '@/components/fields/multiselect/FieldMultiselect'
import FieldViewerChoice from '@/components/fields/choice/FieldViewerChoice'
import FieldCode from '@/components/fields/code/FieldCode'
import FieldViewerCode from '@/components/fields/code/FieldViewerCode'
import FieldNumber from '@/components/fields/number/FieldNumber'
import FieldViewerNumber from '@/components/fields/number/FieldViewerNumber'
import FieldText from '@/components/fields/text/FieldText'
import FieldList from '@/components/fields/list/FieldList'
import FieldViewerList from '@/components/fields/list/FieldViewerList'
import FieldFile from '@/components/fields/file/FieldFile'
import FieldUpload from '@/components/fields/upload/FieldUpload'
import FieldViewerTel from '@/components/fields/tel/FieldViewerTel'
import FieldViewerEmail from '@/components/fields/email/FieldViewerEmail'
import FieldViewerSelect from '@/components/fields/select/FieldViewerSelect'
import FieldViewerWYSIWYG from '@/components/fields/wysiwyg/FieldViewerWYSIWYG'
import FieldCurrency from '@/components/fields/currency/FieldCurrency'
import FieldTextarea from '@/components/fields/textarea/FieldTextarea'

export const editors = {
  'default': Field,
  'code': FieldCode,
  'choice': FieldChoice,
  'currency': FieldCurrency,
  'wysiwyg': FieldWYSIWYG,
  'week': FieldWeek,
  'number': FieldNumber,
  'link': FieldLink,
  'list': FieldList,
  'dateRange': FieldDateRange,
  'multiselect': FieldMultiselect,
  'select': FieldSelect,
  'text': FieldText,
  'textarea': FieldTextarea,
  'email': FieldText,
  'tel': FieldText,
  'uniqid': FieldText,
  'file': FieldFile,
  'upload': FieldUpload,
}

export const viewers = {
  'default': FieldViewer,
  'code': FieldViewerCode,
  'link': FieldViewerLink,
  'list': FieldViewerList,
  'number': FieldViewerNumber,
  'choice': FieldViewerChoice,
  'tel': FieldViewerTel,
  'email': FieldViewerEmail,
  'select': FieldViewerSelect,
  'multiselect': FieldViewerSelect,
  'wysiwyg': FieldViewerWYSIWYG,
}

export function registerFields () {
  for (const type in editors) {
    registerFieldEdit(type, editors[type])
  }

  for (const type in viewers) {
    registerFieldView(type, viewers[type])
  }
}
