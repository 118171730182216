<template>
  <div class="with-padding">
    {{ formatDateTime(value, (field.options || {}).date_format || 'date') }}
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'
import { formatDateTime } from '@/utils'

export default {
  name: 'CellDate',
  extends: FieldCell,
  methods: {
    formatDateTime,
  },
}
</script>

<style scoped>

</style>
