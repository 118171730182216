import Vue from 'vue'
import Antd from 'ant-design-vue'
import {
  registerGlobalComponents,
  registerLayoutComponents,
} from '@/components/layout-components/component-registration'
import store from '@/store'
import i18n from '@/i18n'
import router from '@/router'
import App from '@/components/App'
import { registerFields } from '@/components/fields/types'
import { capitalizeFirstLetter, isAuthenticated } from './utils'
import Keymap from './keymap'
import { recipe, recipes } from '@/recipes'
import { apiGet } from '@/api'

export default class Brezel {
  options = {}
  bootstrapQueue = []
  bootstrapped = false
  app = null
  beforeUnloadHooks = []

  constructor (apiUrl, system, options = {}) {
    this.apiUrl = apiUrl
    this.system = system
    this.options = Object.assign({
      systemPath: `/systems/${this.system}`,
    }, options)
  }

  bootstrap (component = App) {
    store.commit('setApiUrl', this.apiUrl)
    store.commit('setCurrentSystem', this.system)
    store.commit('initialiseStore', { brezel: this })
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setUser' && isAuthenticated()) {
        // Login forces store persistence so that other tabs know the current user
        store.dispatch('persistStore').catch(console.error)
      }
    })
    window.onbeforeunload = () => {
      this.fireOnBeforeUnload()
      if (isAuthenticated()) {
        store.dispatch('persistStore').catch(console.error)
      }
    }
    // Overwrite the loaded store for these values
    store.commit('setApiUrl', this.apiUrl)

    Vue.config.productionTip = false
    Vue.use(Antd)
    registerLayoutComponents()
    registerGlobalComponents()
    registerFields()
    Vue.prototype.tr = function (string, disableHtml, fallbackString) {
      const fallbackStringArray = string.split('.')
      if (!fallbackString) {
        fallbackString = fallbackStringArray[fallbackStringArray.length - 1]
      }
      fallbackString = fallbackString.replace(/_/g, ' ')
      fallbackString = capitalizeFirstLetter(fallbackString)
      if (!disableHtml) {
        fallbackString = '<span title="' + string + '" onclick="console.info(\'' + string + '\');">' + fallbackString + '</span>'
      }
      const translatedString = this.$t(string)
      if (this.$t && this.$te && translatedString !== string) {
        return translatedString
      }
      return fallbackString || string
    }

    Vue.prototype.$systemPath = (path) => {
      return this.options.systemPath + '/' + path
    }

    Vue.prototype.$keymap = new Keymap()
    Vue.prototype.$brezel = this

    window['recipe'] = recipe
    window['recipes'] = recipes

    this.app = new Vue({
      i18n,
      router,
      store,
      // Bootstrap into passed component, default App.vue
      render: h => h(component),
    })
    this.fireOnBootstrap()
    this.bootstrapped = true
    return this.app
  }

  onBeforeUnload (func) {
    this.beforeUnloadHooks.push(func)
  }

  unregisterBeforeUnload (func) {
    this.beforeUnloadHooks.splice(this.beforeUnloadHooks.indexOf(func), 1)
  }

  fireOnBeforeUnload () {
    this.beforeUnloadHooks.forEach(func => func())
  }

  onBootstrap (func) {
    if (this.bootstrapped) {
      func()
      return
    }
    this.bootstrapQueue.push(func)
  }

  fireOnBootstrap () {
    this.bootstrapQueue.forEach(func => func())
    this.bootstrapQueue = []
  }

  logout () {
    return apiGet(['logout']).then(() => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('store')
      window.dispatchEvent(new Event('accessTokenChange'))
    })
  }
}
