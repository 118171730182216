<template>
  <div
    v-if="Array.isArray(value)"
    style="display: flex; flex-flow: nowrap"
    @click.stop
  >
    <div
      v-for="item in value"
      :key="item.id"
      style="padding: 7px; height: 40px"
      @click="openFile(item)"
    >
      <a-spin v-if="!item.directory && item.brezel_loading"/>
      <a v-else>
        <img
          v-if="item.brezel_thumb_url"
          :alt="item.brezel_name"
          :src="item.brezel_thumb_url"
          style="max-height: 100%;"
        >
        <div
          v-else-if="item.mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
          @click="downloadDocx(item)"
          @click.stop
        >
          {{ item.brezel_name }}
        </div>
        <div v-else>{{ item.brezel_name }}</div>
      </a>
    </div>
  </div>
  <div v-else-if="value">
    <a-spin v-if="!value.directory && value.brezel_loading"/>
    <a
      v-else
      :href="value.brezel_url"
      target="_blank"
    >
      <img
        v-if="value.brezel_thumb_url"
        :alt="value.brezel_name"
        :src="value.brezel_thumb_url"
        style="max-height: 100%;"
      >
      <div
        v-else-if="value.mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
        @click="downloadDocx(value)"
        @click.stop
      >
        {{ value.brezel_name }}
      </div>
      <div v-else>{{ value.brezel_name }}</div>
    </a>
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'
import { EventBus } from '@/event-bus'
import { apiGet } from '@/api'
import { downloadBuffer, getFileURL } from '@/utils'

export default {
  name: 'CellFile',
  extends: FieldCell,
  mounted () {
    if (this.value) {
      if (!Array.isArray(this.value)) {
        this.value = [this.value]
      }
      this.value.forEach(file => {
        if (file) {
          file.brezel_loading = true
          this.fileUrl(file)
        }
      })
    }
  },
  methods: {
    fileUrl (file) {
      if (file.directory) {
        return
      }
      getFileURL(file.id)
        .then(url => {
          this.$set(file, 'brezel_url', url)
        })
      getFileURL(file.id, 'mini')
        .then(thumbUrl => {
          this.$set(file, 'brezel_loading', false)
          this.$set(file, 'brezel_thumb_url', thumbUrl)
        })
        .catch(() => {
          this.$set(file, 'brezel_loading', false)
          this.$set(file, 'brezel_thumb_url', false)
        })
    },
    openFile (file) {
      EventBus.$emit('open-buffer', {
        url: file.brezel_url,
        type: file.mime_type,
        name: file.brezel_name,
      })
    },
    downloadDocx (file) {
        apiGet([file.path])
            .then(response => response.blob())
            .then(response => downloadBuffer(response, file.brezel_name))
    },
  },
}
</script>

<style scoped>

</style>
