<template>
  <div>
    <a-rate
      :value="globalrating"
      disabled
      :class="{ ratedisabled: showownrating }"
    /><span class="ratingcount">({{ ratingscount }})</span>
    <a
      id="collapse_rating"
      @click="showownrating = !showownrating"
    >
      {{ $t('kanban.rate') }}
    <!--  <a-icon style="font-size: 10px" type="caret-down" v-show="!showownrating" />
      <a-icon style="font-size: 10px" type="caret-up" v-show="showownrating" /> -->
    </a>
    <div
      v-show="showownrating"
      class="userrate"
    >
      <span class="yourtag">{{ $t('kanban.your') }}</span> <a-rate
        v-model="rating"
        @change="changeRating"
      />
    </div>
  </div>
</template>
<script>
import Api from '@/api'
import store from '@/store'

export default {
  props: {
    module: {
      type: Object,
      default: undefined,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
        type: Number,
        default: undefined,
    },
    activerating: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
     rating: 0,
     ressource: null,
     entityArr: [],
     userRated: false,
     userData: null,
     userID: null,
     showownrating: false,
     globalrating: 0,
     ratingscount: 0,
  }),
  computed: {
  },
  created () {
        this.globalrating = this.activerating
        this.userID = store.state.userInfo.id
        this.ratingscount = this.data.ratingcount
        Api.fetchEntity(this.id, this.module).then(entity => {
            this.entityArr = entity
            var ratinglist = entity.ratings
            ratinglist.forEach(rating => {
                if (rating.user.id === store.state.userInfo.id) {
                    this.rating = rating.rate
                    this.userRated = true
                }
            })
        })
  },
  methods: {
      changeRating (val) {
        Api.fetchEntity(this.id, this.module).then(entity => {
                    this.showownrating = false
        if (this.userRated) {
            entity.ratings[0].rate = val
            Api.updateEntity(entity).then(newentity => {
              var rating = newentity.resource.rating
              this.getActiveRating(rating)
            })
        } else {
            entity.ratings.push({ rate: val, user: store.state.userInfo })
            Api.saveEntity(entity).then(newentity => {
              var rating = newentity.resource.rating
              this.getActiveRating(rating)
              this.userRated = true
            })
        }
        })
      },
      getActiveRating (rating) {
          this.globalrating = rating
          this.$emit('change', { id: this.id, activerating: rating })
          this.getRatingCount()
      },
      getRatingCount () {
        Api.fetchEntity(this.id, this.module).then(entity => {
            this.ratingscount = entity.ratingscount
        })
      },
  },
}
</script>

<style lang="scss" scoped>
  .ratingcount {padding:0px 10px 0px 10px}
  .userrate {border-top:1px solid #eee;}
  .ratedisabled {filter: grayscale(1);}
  .yourtag {    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    height: 24px;
    margin-top: 3px;
    line-height: 22px;
    padding: 0 10px 0 10px;
    display: inline-block;}
</style>
