<template>
  <div>
    <a-input
      v-if="!autocomplete"
      v-model="out_value"
      v-cleave="maskConfig"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :placeholder="placeholder"
      :title="field.identifier"
      :type="type"
      @blur="fireBlur"
    >
      <a-icon
        v-if="options.prefix && options.prefix !== ''"
        slot="prefix"
        :type="options.prefix"
        style="color: rgba(0, 0, 0, 0.25)"
      />
      <a-icon
        v-if="field.options && options.copyButton && copySuccess === null"
        slot="addonAfter"
        type="copy"
        @click="copyOutValueToClipboard"
      />
      <a-icon
        v-if="copySuccess"
        slot="addonAfter"
        type="check-circle"
        style="color: rgb(82, 196, 26);"
        @click="copyOutValueToClipboard"
      />
      <a-icon
        v-if="copySuccess === false"
        slot="addonAfter"
        type="close-circle"
        style="color: #ff4d4f;"
        @click="copyOutValueToClipboard"
      />
    </a-input>
    <div v-else>
      <a-auto-complete
        v-if="autoCompleteType == 'location'"
        v-model="searchValue"
        v-cleave="maskConfig"
        option-label-prop="title"
        :auto-focus="!screenIsMobile && options.autofocus"
        :disabled="disabled"
        :placeholder="placeholder"
        :title="field.identifier"
        :type="type"
        @search="searchTimeOut"
        @blur="fireBlur"
      >
        <template #dataSource>
          <a-select-option
            v-for="(option, index) in searchRes"
            :key="index"
            :value="'' + index"
            :title="option.address.road + ' ' + ((option.address.house_number) ? option.address.house_number : '')"
            @click="setLocation(option)"
          >
            <span v-show="option.address.road">{{ option.address.road }} {{ option.address.house_number }}, </span>
            <span v-show="option.address.postcode">{{ option.address.postcode }} </span>
            <span v-if="option.address.city">{{ option.address.city }}</span>
            <span v-else-if="option.address.town">{{ option.address.town }}</span>
            <span v-else-if="option.address.village"> {{ option.address.village }} </span>
            <span v-else-if="option.address.city_district">{{ option.address.city_district }}</span>
          </a-select-option>
        </template>
        <a-input
          v-model="out_value"
          v-cleave="maskConfig"
          :title="field.identifier"
          autocomplete="chrome-off"
        />
      </a-auto-complete>
      <a-auto-complete
        v-if="autoCompleteType == 'list'"
        v-model="out_value"
        :data-source="listOptions"
        @search="searchTimeOut"
      />
    </div>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'
import { copyTextToClipboard, sleep } from '@/utils'
import { recipe } from '@/recipes'

export default {
  name: 'FieldText',
  extends: Field,
  data () {
    return {
      copySuccess: null,
      searchTimer: null,
      showResults: false,
      searchRes: '',
      searchValue: '',
      autoOptions: null,
      locationWatcher: null,
      listOptions: [],
    }
  },
  computed: {
    placeholder () {
      return this.options.placeholder
    },
    type () {
      if (this.field.type === 'email') return 'email'
      if (this.field.type === 'tel') return 'tel'
      return 'text'
    },
    autocomplete () {
      return !!((this.options && this.options.autocomplete && this.options.autocomplete.type))
    },
    autoCompleteType () {
      return this.options.autocomplete ? this.options.autocomplete.type : ''
    },
    autoCompleteList () {
      var Options = this.getAutoCompleteListSource()
      if (Array.isArray(Options)) {
        return Options
      }
      if (typeof Options === 'string') {
        return []
      }
      if (typeof Options === 'object') {
        return Object.keys(Options)
      }
      return Options
    },
  },
  created () {
    if (this.autocomplete && this.autoCompleteTyp === 'location') {
      this.locationWatcher = this.$watch('out_value', (newVal, oldVal) => {
        this.searchValue = this.out_value
      })
      this.searchValue = this.out_value
    }
  },
  mounted () {
  },
  methods: {
    async copyOutValueToClipboard () {
      if (copyTextToClipboard(this.out_value)) {
        this.copySuccess = true
        await sleep(1000)
        this.copySuccess = null
      } else {
        this.copySuccess = false
        await sleep(1000)
        this.copySuccess = null
      }
    },
    searchLocation () {
      let country = 'USA'
      let language = 'EN'
      if (this.options.autocomplete.input.country) {
        let CountryField = this.options.autocomplete.input.country
        country = this.resource[CountryField] ? this.resource[CountryField] : country
      }
      if (this.searchValue) {
        fetch('https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&street=' + this.searchValue + '&country=' + country + '&accept-language=' + language + '&limit=50&zoom=16')
        .then(response => response.json())
        .then(response => {
            this.searchRes = response
        })
      } else {
        this.searchRes = []
      }
    },
    searchTimeOut () {
        if (this.searchTimer) {
            clearTimeout(this.searchTimer)
            this.searchTimer = null
        }
        this.searchTimer = setTimeout(() => {
          if (this.autoCompleteType === 'location') {
            this.searchLocation()
            this.showResults = true
          } else if (this.autoCompleteType === 'list') {
            this.searchList(this.out_value)
          }
        }, 400)
    },
    setLocation (location) {
        let houseNumber = location.address.house_number ? location.address.house_number : ''
        this.out_value = location.address.road + ' ' + houseNumber

        if (this.options.autocomplete.output.city) {
          let cityFieldName = this.options.autocomplete.output.city
          let city = location.address.city ? location.address.city : location.address.town ? location.address.town : location.address.village ? location.address.village : location.address.city_district ? location.address.city_district : ''
          this.resource[cityFieldName] = city
        }
        if (this.options.autocomplete.output.zip) {
            let zipFieldName = this.options.autocomplete.output.zip
            this.resource[zipFieldName] = location.address.postcode
        }
        if (this.options.autocomplete.output.region) {
            let regionFieldName = this.options.autocomplete.output.region
            if (location.address.state) { this.resource[regionFieldName] = location.address.state } else if (location.address.city === 'Berlin') { this.resource[regionFieldName] = 'Berlin' }
        }
    },
    searchList (searchText) {
      if (searchText === '') {
        this.listOptions = []
      } else {
        let listarr = this.autoCompleteList.filter(d => d.toUpperCase().includes(searchText.toUpperCase()))
        this.listOptions = listarr.slice(0, 10)
      }
    },
    getAutoCompleteListSource () {
      let value = []
      if (this.options.autocomplete.recipes && this.options.autocomplete.recipes.values) {
        value = recipe(this.options.autocomplete.recipes.values, this.resource, this.resource)
      } else {
        value = this.options.autocomplete && this.options.autocomplete.values ? this.options.autocomplete.values : []
      }
      return value
    },
  },
}
</script>

<style scoped>
  .search-location-result {
    position:absolute;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    overflow-y: scroll;
    max-height: 200px;
  }
  .search-location-result ul { list-style:none; padding:0px; }
  .search-location-result ul li {
    position: relative;
    display: block;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
  }
  .search-location-result ul li:hover {
    background-color: #e6fcff;
  }
</style>
