import { apiGet } from './api'

export const loadStylesAsync = () => (
  apiGet(['styles'])
    .then(response => response.text())
    .then(response => {
      const style = document.createElement('style')
      style.setAttribute('type', 'text/css')
      style.innerHTML = response;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(style)
      return response
    })
)
