<template>
  <div class="ant-card-body brezel-history">
    <div v-if="controls">
      <div
        style="display: flex; gap: 15px"
        class="xs-block"
      >
        <a-button-group>
          <a-select v-model="sorting">
            <a-select-option value="newest">
              {{ $t('_.newest_first') }}
            </a-select-option>
            <a-select-option value="oldest">
              {{ $t('_.oldest_first') }}
            </a-select-option>
          </a-select>
          &nbsp;
          <a-button
            size="small"
            @click="loadHistory()"
          >
            <a-icon type="reload"/>
          </a-button>
        </a-button-group>
        <a-select
          v-model="author"
          :allow-clear="true"
          style="min-width: 200px"
          :placeholder="$t('_.user')"
          @change="loadHistory()"
        >
          <a-select-option
            v-for="user in authors"
            :key="user.id"
            :value="user.id"
          >
            {{ user.email }}
          </a-select-option>
        </a-select>
      </div>
      <br><br>
    </div>
    <Empty
      v-if="empty"
      :image="simpleImage"
    />
    <Icon
      v-if="loading"
      type="loading"
      :spin="true"
    />
    <div v-else>
      <Timeline :reverse="sorting !== 'newest'">
        <div
          v-for="(logs, page) in pages"
          :key="page"
        >
          <TimelineItem
            v-for="log in logs"
            :key="log.id"
            :color="getLogColor(log)"
          >
            <div slot="dot">
              <a-icon
                :type="getLogIcon(log)"
                style="font-size: 16px;"
              />
            </div>
            <div v-if="log.diff">
              {{ formatDate(log.created_at) }} –
              <span v-if="log.from_workflow">
                <a-icon type="robot"/>&nbsp;
              </span>
              <span v-if="log.user"><EntityLink :entity="makeEntity(log.user)">{{ log.user.email }}</EntityLink></span>
              <span v-else>System</span>
              <span v-if="log.diff.diff.changed">
                {{ $t('history.verb.changes') }}
                <a @click="toggleDiff(log)">
                  <span
                    v-for="(field, index) in sneakPeek(log)[0]"
                    :key="field"
                  >
                    <strong v-text="getFieldName(field)"/><span
                      v-if="index !== sneakPeek(log)[0].length - 1"
                    >, </span>
                  </span><span v-if="sneakPeek(log)[1] > 0"> {{ $t('_.and') }} {{ sneakPeek(log)[1] }} {{
                    $t('history.more')
                  }}...</span>
                </a>
              </span>
              <span v-else>
                {{ $t('_.saves') }}
              </span>
              &nbsp;
              <a-button
                v-if="log.diff.diff.changed"
                size="small"
                shape="circle"
                :type="shouldShowDiff(log) ? 'primary' : null"
                @click="toggleDiff(log)"
              >
                <a-icon :type="shouldShowDiff(log) ? 'up' : 'down'"/>
              </a-button>
              <div
                v-if="shouldShowDiff(log)"
                class="diffs"
              >
                <ul v-if="log.diff && log.diff.diff.changed">
                  <li
                    v-for="(diff, field) in log.diff.diff.diffs"
                    :key="field"
                  >
                    <DiffItem
                      :field="resource.module.getField(field)"
                      :diff="diff"
                      :resource="resource"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </TimelineItem>
        </div>
        <TimelineItem
          v-if="pages.length < lastPage"
          color="gray"
          :pending="loadingMore"
        >
          <a @click="loadHistory(pages.length + 1)">{{ $t('_.more') }}...</a>
        </TimelineItem>
        <TimelineItem
          v-if="pages.length === 0 && resource.updated_at && resource.created_at !== resource.updated_at && !config.history"
          color="orange"
        >
          <a-icon
            slot="dot"
            type="question-circle"
            style="font-size: 16px;"
          />
          {{ formatDate(resource.updated_at) }} – {{ $t('history.updated_by') }}
          <EntityLink
            :entity="makeEntity(resource.updated_by)"
            :load-entity="true"
          />
        </TimelineItem>
        <TimelineItem
          v-if="!config.history"
          color="green"
        >
          {{ formatDate(resource.created_at) }} – {{ $t('history.created_by') }}
          <EntityLink
            :entity="makeEntity(resource.created_by)"
            :load-entity="true"
          />
        </TimelineItem>
      </Timeline>
    </div>
  </div>
</template>

<script>
import Widget from '@/components/layout-components/widgets/Widget'
import { Icon, Timeline, Empty } from 'ant-design-vue'
import TimelineItem from 'ant-design-vue/es/timeline/TimelineItem'
import Entity from '@/api/entity'
import DiffItem from '@/components/layout-components/widgets/history/DiffItem'
import EntityLink from '@/components/EntityLink'
import { debounce, get } from 'lodash'

export default {
  name: 'WidgetHistory',
  components: {
    Timeline,
    TimelineItem,
    Icon,
    Empty,
    DiffItem,
    EntityLink,
  },
  extends: Widget,
  data () {
    return {
      loading: false,
      loadingMore: false,
      sorting: 'newest',
      pages: [],
      currentPage: 0,
      lastPage: 0,
      stats: {},
      searchHistory: debounce(user => this.search(user), 250),
      authors: [],
      author: null,
    }
  },
  computed: {
    controls () {
      return this.config.controls !== false
    },
    empty () {
      return Array.isArray(this.config.history) && this.config.history.length === 0
    },
  },
  watch: {
    'config.history' () {
      this.setHistory(this.config.history)
    },
  },
  mounted () {
    if (this.config.history) {
      this.setHistory(this.config.history)
    } else {
      this.loadHistory()
    }
  },
  methods: {
    simpleImage: () => Empty.PRESENTED_IMAGE_SIMPLE,
    loadHistory (page = 1, user = undefined) {
      this.loading = page === 1
      this.loadingMore = page !== 1
      this.resource.getHistory(page, user || this.author).then(logs => {
        this.currentPage = logs.current_page
        this.lastPage = logs.last_page
        this.pages[this.currentPage - 1] = logs.data
        this.pages[this.currentPage - 1].forEach(log => {
          this.stats[log.id] = this.getStats(log)
        })
        this.pages.splice(this.currentPage, this.pages.length)
        this.loading = false
        this.loadingMore = false
      })
      this.resource.getHistoryAuthors().then(authors => {
        this.authors = authors
      })
    },

    setHistory (history) {
      this.pages = [history]
      history.forEach(log => {
        this.stats[log.id] = this.getStats(log)
      })
    },

    getStats (log) {
      const diffs = Object.entries(get(log, 'diff.diff.diffs', {}))
      return {
        'add': diffs.filter(([_, diff]) => diff.mode.name === 'add').length,
      }
    },

    getFieldName (field) {
      return this.tr(`modules.${this.resource.module.identifier}.fields.${field}`, true)
    },

    formatDate (date) {
      const dateObj = new Date(date)
      if (this.isToday(dateObj)) {
        return `${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`
      }
      return dateObj.toLocaleString()
    },

    shouldShowDiff (log) {
      return log.show
    },

    toggleDiff (log) {
      log.show = !log.show
      this.$forceUpdate()
    },

    makeEntity (entity) {
      return new Entity(entity)
    },

    isToday (date) {
      const today = new Date()
      return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    },

    sneakPeek (log) {
      const diffs = log.diff.diff.diffs
      const len = Object.entries(diffs).length
      const maxLen = 3
      const rest = len - maxLen
      return [Object.entries(diffs).slice(0, maxLen).map(([key, diff]) => key), rest]
    },

    getLogColor (log) {
      switch (log.type) {
        case 'update':
          return 'blue'
        case 'save':
          return 'gray'
        default:
          return null
      }
    },

    getLogIcon (log) {
      switch (log.type) {
        case 'update':
          return 'edit'
        case 'save':
          return 'save'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
