<template>
  <div :class="{'textarea-container': !fullscreen, 'textarea-fullscreen': fullscreen}">
    <div
      v-if="fullscreen"
      style="display: flex; align-items: center; padding: 5px 20px"
    >
      <div style="flex-grow: 1;">
        <h1 style="margin: 0">
          {{ tr(`${translationPrefix}.fields.${field.identifier}`, true) }}
        </h1>
      </div>
      <a-button
        style="float: right"
        type="primary"
        size="small"
        @click="fullscreen = false"
      >
        <a-icon type="check"/>
      </a-button>
    </div>
    <a-textarea
      v-model="out_value"
      :auto-focus="!screenIsMobile && options.autofocus"
      :disabled="disabled"
      :placeholder="options.placeholder"
      :title="field.identifier"
      class="textarea"
      :auto-size="!fullscreen"
    >
      <a-icon
        v-if="options.prefix && options.prefix !== ''"
        slot="prefix"
        :type="options.prefix"
        style="color: rgba(0, 0, 0, 0.25)"
      />
    </a-textarea>
    <a-button
      v-if="!fullscreen && fullscreenButton"
      size="small"
      @click="fullscreen = true"
    >
      <a-icon type="fullscreen"/>
    </a-button>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldTextarea',
  extends: Field,
  data () {
    return {
      fullscreen: false,
    }
  },
  computed: {
    fullscreenButton () {
      return this.options.fullscreenButton
    },
  },
}
</script>

<style lang="scss" scoped>
.textarea-container {
  display: flex;
}

.textarea-fullscreen {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-y: auto;

  .textarea {
    height: 100% !important;
  }
}
</style>
