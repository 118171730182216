var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.component.options.vertical_labels !== true)?_c('table',{staticClass:"field-group-item-list",staticStyle:{"width":"100%"}},_vm._l((_vm.fields),function([field, index]){return _c('tr',{key:index,staticClass:"field-group-item",class:{required:(field.options && field.options.rules && field.options.rules.split('|').includes('required'))},style:(_vm.getStyleForField(field, _vm.screenIsMobile))},[(_vm.component.options.no_labels !== true)?_c('td',{staticClass:"field-group-label",style:({
          paddingTop: '5px',
          textAlign: 'right',
          verticalAlign: 'top',
          width: '1px',
          whiteSpace: 'nowrap',
          paddingRight: _vm.screenIsMobile ? '0' : '16px'
        }),domProps:{"innerHTML":_vm._s(_vm.tr(`${_vm.translationPrefix}.fields.${field.identifier}`))}}):_vm._e(),_c('td',{staticStyle:{"padding":"0"}},[_c('FieldContainer',{ref:"fieldContainer",refInFor:true,attrs:{"field":field,"module":_vm.module,"resource":_vm.resource,"root-field-tree":_vm.fieldTree,"translation-prefix":_vm.translationPrefix,"layout":_vm.layout,"mode":_vm.mode,"context":_vm.context},on:{"event":_vm.fieldEvent,"tree":function($event){return _vm.setFieldTree(field, $event)}},model:{value:(_vm.resource[field.identifier]),callback:function ($$v) {_vm.$set(_vm.resource, field.identifier, $$v)},expression:"resource[field.identifier]"}})],1)])}),0):_vm._l((_vm.fields),function([field, index]){return _c('div',{key:index,staticClass:"field-group-item-list",style:(_vm.getStyleForField(field, _vm.screenIsMobile))},[(_vm.component.options.no_labels !== true)?_c('div',{staticClass:"field-group-label",domProps:{"innerHTML":_vm._s(_vm.tr(`${_vm.translationPrefix}.fields.${field.identifier}`))}}):_vm._e(),_c('div',{staticClass:"field-group-item",staticStyle:{"overflow-x":"auto","overflow-scrolling":"touch"}},[_c('FieldContainer',{ref:"fieldContainer",refInFor:true,attrs:{"field":field,"module":_vm.module,"resource":_vm.resource,"root-field-tree":_vm.fieldTree,"translation-prefix":_vm.translationPrefix,"layout":_vm.layout,"mode":_vm.mode,"context":_vm.context},on:{"event":_vm.fieldEvent,"tree":function($event){return _vm.setFieldTree(field, $event)}},model:{value:(_vm.resource[field.identifier]),callback:function ($$v) {_vm.$set(_vm.resource, field.identifier, $$v)},expression:"resource[field.identifier]"}})],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }