<template>
  <div class="taskCard">
    <div class="flex">
      <div
        v-show="withphoto"
        class="photo-wrapper"
      >
        <div
          id="photoDiv"
          :style="task.photo ? {'background': 'url(' + task.photoUrl + ') no-repeat center center / cover'} : {'background': 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFFQTFRFAAAAZWVlY2NjZGRkZGRkY2NjY2NjY2NjY2NjZGRkZGRkZGRkZWVlZmZmZWVlZGRkZWVlZmZmZGRkYmJiYGBgZGRkZGRkY2NjZGRkZGRkZGRkCx9OXAAAABt0Uk5TAGCfz/9fUN+Qj+Cg0BQw768PTy8QgMBaQLBwZzLcHAAAAQNJREFUeJztlNsOgyAQRFFXvEDFK9X+/4cWbWMM7AI19qXpvGlyMrOwDGO/riTNALI0jwZ4AW8VPI4o4aDqYwKgjEgFlsLZahspQkRiE2Eb4SIigEgXyQKISwBcjyDBZAA5Mf6JQ3avsg4RZxbmxFpaTBRhsu3z1FFP7LZBwtyPFHz/4VOj2u743bWqCWRS5oEcWiI3NaC86Rq1jVBU/cDY0Fev3vD5jBm2lWb9RxKZcAJgIk0UhSjKhjShbUgTY4MTDU0A4IfmyUUl0z5EX4X4CGJ+pCkOwo8MqYpddxxhM9Jim+RMEKuRUzBGdUIDq/hiASLmMc/ToteIUi8PT6K/vqgnmggQjQHPl+UAAAAASUVORK5CYII=) no-repeat center 1px / cover'}"
        />
      </div>
      <p
        class="text-gray-700 font-semibold font-sans tracking-wide text-sm"
        style="margin-bottom:5px;"
      >
        <router-link
          v-if="!openinmodal"
          :to="'/' + $store.state.currentLocale + '/' + module.identifier + '/' + task.id"
        >
          {{ task.title }}
        </router-link>
        <a
          v-if="openinmodal"
          @click="showmodal = !showmodal"
        >
          {{ task.title }}
        </a>
        <a-modal
          v-model="showmodal"
          :title="task.title"
          :destroy-on-close="true"
          width="80%"
          :footer="null"
          centered
        >
          <Layout
            v-if="resource"
            ref="layout"
            :layout="layout"
            :module="module"
            :resource="resource"
            :mode="'module.show'"
            :context="context"
            :class="'kanban show ' + module.identifier"
          />
          <div style="text-align: center;">
            <a-spin
              v-if="!resource"
              size="large"
            />
          </div>
        </a-modal>
        <small class="id-tag">#{{ task.id }}</small>
        <br><span class="task-date">{{ task.date }}</span>
      </p>
    </div>
    <div class="flex mt-4 justify-between items-center"/>
    <div
      class="tags"
      style="display:none;"
    >
      <a-tag
        color="green"
      >
        tag
      </a-tag>
    </div>
    <rating
      v-if="withrating"
      :id="task.id"
      :module="module"
      :data="task"
      :activerating="task.rating"
      @change="newrating"
    />
  </div>
</template>
<script>
import Rating from './Rating.vue'
import Api, { fetchModuleLayout } from '@/api'
import Layout from '@/components/Layout'
import LayoutDef from '@/layout/layout'
export default {
  components: {
    Rating,
    Layout,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    module: {
      type: Object,
      default: undefined,
    },
    withrating: {
      type: Boolean,
      default: false,
    },
    withphoto: {
      type: Boolean,
      default: false,
    },
    openinmodal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
     ratingValue: 3,
     activerating: 0,
     showownrating: false,
     layout: null,
     showmodal: false,
     resource: null,
    }
  },
  computed: {
    badgeColor () {
      const mappings = {
        Design: 'purple',
        'Feature Request': 'teal',
        Backend: 'blue',
        QA: 'green',
        default: 'teal',
      }
      return mappings[this.task.type] || mappings.default
    },
    context () {
      return {
        entity: this.resource,
        module: this.module,
     }
    },
  },
  mounted () {
    this.activerating = this.task.rating
    this.getdata()
  },
  methods: {
    newrating (value) {
      this.activerating = value.activerating
      var id = value.id
      this.$emit('change', { id: id, rating: this.activerating })
      this.getdata()
    },
    getdata () {
      Api.fetchEntity(this.task.id, this.module).then(entity => {
        this.resource = entity
      })

      fetchModuleLayout(this.module.identifier, 'detail').then(layout => {
        this.layout = new LayoutDef(layout, this.module)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.flex.justify-between {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.task-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.75rem;
  border-radius: 9999px;
}
.font-semibold {
  font-weight: 600;
  font-size: .875rem;
}
.photo {
  width:30px;
  height:100%;
  border-radius:100%;
}
.photo-wrapper {
  width:50px;
  height:50px;
  background:#ccc;
  border-radius:100%;
  overflow:hidden;
  margin-right:10px;
}
.photo-wrapper #photoDiv{
  width:100%;
  height:100%;
  background-size: cover;
  background-position: center;

}
.id-tag {font-weight:normal; color: #777;}
.ant-tag {border-radius:4px;}
.task-date {font-size:14px; font-weight:normal;}
</style>
