import Vue from 'vue'
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import LayoutComponentHeadline from '@/components/layout-components/LayoutComponentHeadline'
import LayoutComponentFieldGroupShow from '@/components/layout-components/LayoutComponentFieldGroupShow'
import LayoutComponentFieldGroupInput from '@/components/layout-components/LayoutComponentFieldGroupInput'
import LayoutComponentFieldGroup from '@/components/layout-components/LayoutComponentFieldGroup'
import LayoutComponentCollapse from '@/components/layout-components/LayoutComponentCollapse'
import LayoutComponentResourceTable from '@/components/layout-components/LayoutComponentResourceTable'
import LayoutComponentCardsView from '@/components/layout-components/LayoutComponentCardsView'
import LayoutComponentButtons from '@/components/layout-components/LayoutComponentButtons'
import LayoutComponentFileView from '@/components/layout-components/LayoutComponentFileView'
import LayoutComponentHr from '@/components/layout-components/LayoutComponentHr'
import LayoutComponentView from '@/components/layout-components/LayoutComponentView'
import LayoutComponentAffix from '@/components/layout-components/LayoutComponentAffix'
import LayoutComponentText from '@/components/layout-components/LayoutComponentText'
import LayoutComponentFiles from '@/components/layout-components/LayoutComponentFiles'
import LayoutComponentLayout from '@/components/layout-components/LayoutComponentLayout'
import LayoutComponentWidgetDonut from '@/components/layout-components/widgets/donut/LayoutComponentWidgetDonut'
import LayoutComponentWidgetProgress from '@/components/layout-components/widgets/LayoutComponentWidgetProgress'
import LayoutComponentWidget from '@/components/layout-components/LayoutComponentWidget'
import LayoutComponentNavigation from '@/components/layout-components/LayoutComponentNavigation'
import LayoutComponentMap from '@/components/layout-components/LayoutComponentMap'
import LayoutComponentMarkdown from '@/components/layout-components/LayoutComponentMarkdown'
import LayoutComponentWidgetBar from '@/components/layout-components/widgets/LayoutComponentWidgetBar'
import WidgetCustom from '@/components/layout-components/widgets/custom/WidgetCustom'
import LayoutComponentCalendar from '@/components/layout-components/LayoutComponentCalendar'
import LayoutComponentWidgetSplitField from '@/components/layout-components/LayoutComponentWidgetSplitField'

export function registerLayoutComponents () {
  Vue.component('LayoutComponent', LayoutComponent)
  // Field Groups
  Vue.component('LayoutComponentFieldGroup', LayoutComponentFieldGroup)
  Vue.component('LayoutComponentFieldGroupInput', LayoutComponentFieldGroupInput)
  Vue.component('LayoutComponentFieldGroupShow', LayoutComponentFieldGroupShow)
  // Layout Components
  Vue.component('LayoutComponentButtons', LayoutComponentButtons)
  Vue.component('LayoutComponentCollapse', LayoutComponentCollapse)
  Vue.component('LayoutComponentFileView', LayoutComponentFileView)
  Vue.component('LayoutComponentHeadline', LayoutComponentHeadline)
  Vue.component('LayoutComponentText', LayoutComponentText)
  Vue.component('LayoutComponentHr', LayoutComponentHr)
  Vue.component('LayoutComponentResourceTable', LayoutComponentResourceTable)
  Vue.component('LayoutComponentCardsView', LayoutComponentCardsView)
  Vue.component('LayoutComponentView', LayoutComponentView)
  Vue.component('LayoutComponentAffix', LayoutComponentAffix)
  Vue.component('LayoutComponentFiles', LayoutComponentFiles)
  Vue.component('LayoutComponentLayout', LayoutComponentLayout)
  Vue.component('LayoutComponentWidgetBar', LayoutComponentWidgetBar)
  Vue.component('LayoutComponentWidgetDonut', LayoutComponentWidgetDonut)
  Vue.component('LayoutComponentWidgetSplitField', LayoutComponentWidgetSplitField)
  Vue.component('LayoutComponentWidgetProgress', LayoutComponentWidgetProgress)
  Vue.component('LayoutComponentWidget', LayoutComponentWidget)
  Vue.component('LayoutComponentNavigation', LayoutComponentNavigation)
  Vue.component('LayoutComponentMap', LayoutComponentMap)
  Vue.component('LayoutComponentMarkdown', LayoutComponentMarkdown)
  Vue.component('LayoutComponentCalendar', LayoutComponentCalendar)
}

export function registerGlobalComponents () {
  // Widgets
  Vue.component('WidgetCustom', WidgetCustom)
}
