<!--suppress JSDeprecatedSymbols -->
<template>
  <div style="position: relative">
    <a-affix
      :offset-top="0"
      class="button-bar"
    >
      <div
        ref="affixButtonBar"
        class="affixButtonBar"
      >
        <LayoutRow
          v-if="layoutDef.spec.affix"
          ref="tab"
          :field-tree="fieldTree"
          :mode="mode"
          :module="module"
          :resource="resource"
          :row="layoutDef.spec.affix"
          :translation-prefix="qualifiedTranslationPrefix"
          :context="context"
          :layout="layout"
          :layout-path="newLayoutPath"
          @event="anyEvent"
        />
        <layout-component-buttons
          v-else
          :component="affixButtons"
          @event="buttonClick"
        />
      </div>
    </a-affix>
    <WidgetCustom
      v-if="spec.widget"
      v-model="activeTab"
      :config="spec.widget"
      :tabs="sortedTabs"
      @tabClick="tabClick"
    >
      <template #default="{ tab }">
        <span v-if="tab">
          <layout-tab
            :field-tree="fieldTree"
            :mode="mode"
            :module="module"
            :resource="resource"
            :tab="tab"
            :translation-prefix="qualifiedTranslationPrefix"
            :context="context"
            :layout-path="newLayoutPath"
            @event="anyEvent"
          />
        </span>
      </template>
    </WidgetCustom>
    <div v-else-if="tabMode === 'tabs'">
      <a-tabs
        v-if="layoutDef.spec.tabs.length >= 2"
        v-model="activeTab"
        :tab-position="tabPosition"
        @tabClick="tabClick($event)"
      >
        <a-tab-pane
          v-for="tab in sortedTabs"
          :key="tab.frontendIndex"
        >
          <a-badge
            slot="tab"
            :count="tab.fieldsWithValidationError"
            :overflow-count="9"
          >
            <div
              :style="{ padding: tab.fieldsWithValidationError > 0 ? '0 14px 0 0' : '0' }"
              :class="{'tab-err': tab.fieldsWithValidationError > 0 }"
            >
              <a-icon
                v-if="tab.icon"
                :style="{marginRight: tab.identifier ? '8px' : '0'}"
                :type="tab.icon"
              />
              <span v-if="tab.identifier">{{ tr(`${qualifiedTranslationPrefix}.tabs.${tab.identifier}`, true) }}</span>
            </div>
          </a-badge>
          <layout-tab
            ref="tab"
            :field-tree="fieldTree"
            :mode="mode"
            :module="module"
            :resource="resource"
            :tab="tab"
            :translation-prefix="qualifiedTranslationPrefix"
            :context="context"
            :layout="layoutDef"
            :layout-path="newLayoutPath"
            @event="anyEvent"
          />
        </a-tab-pane>
      </a-tabs>
      <div v-else>
        <div
          v-for="tab in sortedTabs"
          :key="tab.frontendIndex"
        >
          <layout-tab
            :field-tree="fieldTree"
            :mode="mode"
            :module="module"
            :resource="resource"
            :tab="tab"
            :translation-prefix="qualifiedTranslationPrefix"
            :context="context"
            :layout="layout"
            :layout-path="newLayoutPath"
            @event="anyEvent"
          />
        </div>
      </div>
    </div>
    <div v-else-if="tabMode === 'tiles'">
      <a-card v-if="!tileOpen">
        <a-card-grid
          v-for="tab in sortedTabs"
          :key="tab.frontendIndex"
          :hoverable="true"
          class="layout-tile"
          @click="openTile(tab.frontendIndex)"
        >
          <a-badge
            :count="tab.fieldsWithValidationError"
            :overflow-count="9"
          >
            <div :style="{ padding: tab.fieldsWithValidationError > 0 ? '0 14px 0 0' : '0' }">
              <a-icon
                v-if="tab.icon"
                :type="tab.icon"
                class="layout-tile-icon"
              />
              <h3 v-if="tab.identifier">
                {{ tr(`${qualifiedTranslationPrefix}.tabs.${tab.identifier}`, true) }}
              </h3>
            </div>
          </a-badge>
        </a-card-grid>
      </a-card>
      <div
        v-for="tab in sortedTabs"
        :key="tab.frontendIndex"
      >
        <div v-if="activeTab === tab.frontendIndex && tileOpen">
          <a-breadcrumb>
            <a-breadcrumb-item>
              <span
                class="back"
                @click="tileOpen = false"
              >{{ $t('_.back') }}</span>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              {{
                tr(`${qualifiedTranslationPrefix}.tabs.${tab.identifier}`, true)
              }}
            </a-breadcrumb-item>
          </a-breadcrumb>
          <br>
          <layout-tab
            :field-tree="fieldTree"
            :mode="mode"
            :module="module"
            :resource="resource"
            :tab="tab"
            :translation-prefix="qualifiedTranslationPrefix"
            :context="context"
            :layout="layout"
            :layout-path="newLayoutPath"
            @event="anyEvent"
          />
        </div>
      </div>
    </div>
    <a-spin
      v-if="loading"
      style="position: fixed; bottom: 10px; right: 10px"
    />
  </div>
</template>

<script>
import LayoutTab from '@/components/LayoutTab'
import Layout from '@/layout/layout'
import CalculatesRecipes from '@/mixins/CalculatesRecipes'
import { isScreenMobile, shouldShow } from '@/utils'
import jsonpath from 'jsonpath'
import { fetchModuleEvent, fireWebhookEvent, handleWebhookButtonInput } from '../webhooks'
import LayoutElement from '@/components/LayoutElement'
import LayoutRow from './LayoutRow'

export default {
  name: 'Layout',
  components: { LayoutRow, LayoutTab },
  extends: LayoutElement,
  mixins: [CalculatesRecipes],
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    resource: {
      type: Object,
      default: undefined,
    },
    module: {
      type: Object,
      default: undefined,
    },
    event: {
      type: Object,
      default: undefined,
    },
    tabs: {
      type: Object,
      default: undefined,
    },
    layout: {
      type: Layout,
      default: () => new Layout(),
    },
    mode: {
      type: String,
      default: null,
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
    fieldSource: {
      type: Object,
      default: null,
    },
    root: {
      type: Boolean,
      default: () => undefined,
    },
    storeActiveTab: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      /**
       * Compute a JSON object (tree) holding all fields with their components, where the object has exactly the same
       * structure as the resource object. This makes it possible to use the same JSONPath for accessing a specific
       * field value and for accessing a specific field/<Field>.
       */
      fieldTree: {},
      activeTab: this.value,
      tileOpen: false,
      layoutDef: this.layout,
      oldLayout: null,
      loading: false,
    }
  },

  computed: {
    affixButtons () {
      return {
        type: 'buttons',
        options: {
          buttons: this.buttons,
          justify_content: 'flex-end',
        },
      }
    },

    layoutElementIdentifier () {
      return this.layout.identifier
    },

    spec () {
      return this.layoutDef.spec
    },

    qualifiedTranslationPrefix () {
      if (this.translationPrefix) {
        return this.translationPrefix
      }
      if (this.event) {
        return `events.${this.event.identifier}`
      }
      if (this.module) {
        return `modules.${this.module.identifier}`
      }
      return `layouts.${this.layoutDef.identifier}`
    },

    sortedTabs () {
      return Array.from(this.layoutDef.spec.tabs)
        .map((tab, index) => {
          tab.frontendIndex = index
          return tab
        })
        .filter(tab => shouldShow(tab, this.mode))
        .sort((a, b) => a.position - b.position)
    },

    tabPosition () {
      if (isScreenMobile()) {
        return 'top'
      }
      return this.layoutDef.spec.tab_position || 'top'
    },

    tabMode () {
      return this.layoutDef.spec.tab_mode || 'tabs'
    },

    isRoot () {
      if (this.root !== undefined) {
        return this.root
      }
      return this.layoutDef.isRoot
    },
  },

  watch: {
    activeTab () {
      this.$emit('tabChanged', this.activeTab)
      this.$emit('input', this.activeTab)
    },

    '$route' () {
      this.loadActiveTab()
    },

    layout () {
      this.makeLayout()
      this.layoutDef.setComponent(this)
    },

    layoutDef () {
      this.registerLayoutWatcher()
    },
  },

  created () {
    this.makeLayout()

    this.oldLayout = this.$keymap.layout
    this.$keymap.layout = this
  },

  mounted () {
    this.layoutDef.setComponent(this)
    if (['module.create', 'module.edit', 'module.show', 'module.index'].includes(this.mode) && this.isRoot) {
      this.watchCalculator('resource')
    }
    this.loadActiveTab()
  },

  destroyed () {
    this.$keymap.layout = this.oldLayout
  },

  methods: {
    loadActiveTab () {
      this.$nextTick().then(() => {
        if (this.sortedTabs[0] && this.activeTab === null && this.layout.spec.default !== null) {
          this.activeTab = this.sortedTabs[0].frontendIndex
        }
        if (this.storeActiveTab && this.activeLayoutPath) {
          const matchingTab = this.sortedTabs.find(tab => this.activePathStartsWith(this.newLayoutPath + '.' + tab.identifier))
          if (matchingTab) {
            this.activeTab = matchingTab.frontendIndex
          } else if (this.layout.spec.default === null) {
            this.activeTab = null
          }
        }
      })
    },

    tabClick (active) {
      const currentTab = this.sortedTabs.find(tab => tab.frontendIndex === active)
      if (this.storeActiveTab) {
        this.addActivePath(currentTab ? currentTab.identifier : null)
      }
    },

    buttonClick ($event, component) {
      // Buttons can both trigger a webhook and link to another target
      const target = $event.target

      if (!$event.setLoading) {
        $event.setLoading = (state = true) => {
          this.$set(target, 'loading', state)
          $event.component.$forceUpdate()
        }
      }

      if (target.function) {
        $event.setLoading()
        return Promise.resolve(target.function())
          .then(response => {
            $event.setLoading(false)
            return response
          })
      }

      if (target.action) {
        this.$emit(target.action, $event, component)
        return
      }
      // router link for internal links
      if (target.link && !target.link.external) {
        let link = '/' + this.$store.state.currentLocale + '/' + (target.link.href ? target.link.href : target.link)
        if (target.link.append) {
          link = this.$route.path + '/' + target.link.href
        }
        if (target.link.target && target.link.target !== '_self') {
          let routeData = this.$router.resolve(link)
          window.open(routeData.href, target.link.target)
        } else {
          this.$router.push(link)
        }
        return
      }
      if (!target.options || !target.options.events) {
        // Fallback/backwards compatibility for layout component buttons where
        // the identifier of the button corresponded to the identifier of a webhook/event.
        // This runs counter to the configuration of fields where we explicitly define events
        // to be triggered on blur/change/sync/load. It is therefore deprecated and could be removed
        // in the future.
        $event.target.options = target.options || {}
        if (target.identifier) {
          $event.target.options.events = {
            on_click: [target.identifier],
          }
        }
      }
      if ($event.target.options.events) {
        if (this.value) {
          $event.value = this.value
        }

        this.firePrototypeEvent({
          ...$event,
          event: 'on_click',
        })
      }
    },

    fieldEvent ($event) {
      this.firePrototypeEvent($event)
    },

    fireEvent (eventIdentifier, data) {
      return fireWebhookEvent(eventIdentifier,
        this.getModule(),
        this.getResourceId(),
        data,
        { resource: this.resource, fieldTree: this.fieldTree, layout: this.layout, context: this.context }
      )
    },

    prepareEvent (eventType, eventIdentifier, data) {
      const module = this.getModule()
      // deprecated
      if (eventType === 'on_click') {
        return fetchModuleEvent(eventIdentifier, module.identifier).then(event => {
          const inputLayout = event.layouts.find(layout => layout.type === 'detail')
          if (inputLayout) {
            return handleWebhookButtonInput(event, module)
              .then(input => this.fireEvent(eventIdentifier, { ...data, ...input }))
          }
          return this.fireEvent(eventIdentifier, data)
        })
      }
      return this.fireEvent(eventIdentifier, data)
    },

    firePrototypeEvent ($event) {
      const moduleIdentifier = this.module ? this.module.identifier : this.$route.params.module_identifier
      const target = $event.target
      let path = ''
      if ($event.component && $event.component.parentPath()) {
        const component = $event.component
        path = jsonpath.stringify(component.parentPath())
      }
      if (target.options && target.options.events) {
        Object.entries(target.options.events)
          .filter(([type, events]) => type === $event.event)
          .forEach(([type, events]) => {
            events = Array.isArray(events) ? events : [events]
            events.forEach(eventIdentifier => {
              if ($event.setLoading) {
                $event.setLoading()
              }
              const firePromise = this.prepareEvent(type, eventIdentifier, {
                selector: $event.selector || path, // What component/field causes this event?
                module_identifier: moduleIdentifier, // Module for the event
                prototype: { ...this.resource, module: undefined }, // What is the current entity state?
                value: $event.value, // What value does this component/field currently provide?
                previousValue: $event.previousValue, // What did the value of this component have before this event?
                selected: $event.selected, // What entities are currently selected in the layout?
              })
              if ($event.resolve) {
                $event.resolve(firePromise)
              }
              firePromise.finally(() => {
                if ($event.setLoading) {
                  $event.setLoading(false)
                }
              })
            })
          })
      }
    },

    getModule () {
      if (this.context && this.context.module) {
        return this.context.module
      }
      return this.module || this.$store.getters.getModuleByIdentifier(this.$route.params.module_identifier)
    },

    getResourceId () {
      if (this.context && this.context.entity) {
        // Use context entity for the event context
        // Example: in modals we want the entity the modal is overlaid over, not the "modal entity"
        return this.context.entity.id
      }
      if (this.resource) {
        // Use current entity for the event context
        return this.resource.id
      }
      return this.$route.params.resource_identifier
    },

    keyboardEventLoading (loading, command) {
      this.loading = loading
    },

    makeLayout () {
      if (typeof this.layout === 'object' && !(this.layout instanceof Layout)) {
        this.layoutDef = new Layout(this.layout, this.fieldSource ? this.fieldSource : this.module, this.root)
      }
      if (this.layout instanceof Layout) {
        this.layoutDef = this.layout
      }
    },

    openTile (tile) {
      this.activeTab = tile
      this.tileOpen = true
      this.tabClick(tile)
    },

    anyEvent ($event) {
      if ($event._type === 'button' || $event._type === 'keydown') {
        this.buttonClick($event)
      } else if ($event._type === 'field') {
        this.fieldEvent($event)
      }

      this.$emit('event', $event)
    },
  },
}
</script>

<style lang="scss">
.back {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.layout-tile {
  cursor: pointer;
  text-align: center;
}

.layout-tile-icon {
  font-size: 25px;
  margin-bottom: 10px;
}

.tab-err {
  color:#ff0000;
}

@media print {
  .ant-tabs-bar.ant-tabs-top-bar {
    display: none;
  }
}
</style>
