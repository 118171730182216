<template>
  <div class="widget">
    <div class="permissions-table">
      <a-affix
        :offset-top="48"
        @change="allAffixed = $event"
      >
        <a-row :class="{ 'affixed': allAffixed }">
          <a-col
            :xs="9"
            class="module-h"
            style="display: flex"
          >
            <a-checkbox
              :checked="getPermAll()"
              :indeterminate="getPermAll() === null"
              :disabled="readonly"
              @change="changeAll($event)"
            />
            <div style="padding-left: 10px; display: flex; gap: 10px">
              <a-input
                size="small"
                placeholder="Filter..."
                allow-clear
                @input="filterModules"
              />
              <a-tooltip
                :title="copied ? 'Copied!' : 'Copy config to clipboard'"
                @visibleChange="tooltipVisibleChanged"
              >
                <a-button
                  size="small"
                  @click="copyValue"
                >
                  <a-icon type="copy"/>
                </a-button>
              </a-tooltip>
            </div>
          </a-col>
          <a-col
            v-for="action in getModuleActions()"
            :key="action"
            :xs="3"
            class="checkbox-h"
          >
            <a-checkbox
              :checked="getModuleActionPermAll(action)"
              :indeterminate="getModuleActionPermAll(action) === null"
              :disabled="readonly"
              @change="changeModuleActionAll($event, action)"
            >
              {{ $t('_.permissions.' + action) }}
            </a-checkbox>
          </a-col>
        </a-row>
      </a-affix>
      <div
        v-for="module in filteredModules"
        :key="module.identifier"
        class="module-item"
      >
        <a-row class="module-row">
          <a-col
            :xs="9"
            class="module-cell"
          >
            <CheckIcon
              v-if="readonly"
              :permission="getModulePermAll(module)"
            />
            <a-checkbox
              v-else
              :checked="getModulePermAll(module)"
              :indeterminate="getModulePermAll(module) === null"
              :disabled="readonly"
              @change="changeModuleAll($event, module)"
            />
            <div
              class="module-open"
              @click="showFields[module.identifier] = !showFields[module.identifier]"
            >
              <a-icon :type="module.icon"/>&nbsp;
              <span>{{ $t('modules.' + module.identifier + '.title') }}</span>&nbsp;
              <a-icon :type="showFields[module.identifier] ? 'up' : 'down'"/>
            </div>
          </a-col>
          <a-col
            v-for="action in getModuleActions()"
            :key="action"
            :xs="3"
            class="checkbox-cell"
          >
            <CheckIcon
              v-if="readonly"
              :permission="getPerm(module, action)"
            />
            <a-checkbox
              v-else
              :checked="getPerm(module, action)"
              :disabled="readonly"
              @change="changePerm($event, module, action)"
            />
          </a-col>
        </a-row>
        <div
          v-if="showFields[module.identifier]"
          class="field-permissions"
        >
          <a-affix
            v-model="affixed[module.identifier]"
            :offset-top="90"
            @change="affixed[module.identifier] = $event"
          >
            <a-row :class="{ 'affixed': affixed[module.identifier] }">
              <a-col
                :xs="9"
                class="fields-h field-cell"
              >
                <span v-if="readonly">
                  <CheckIcon :permission="getFieldsAll(module)"/> <strong>All</strong>
                </span>
                <a-checkbox
                  v-else
                  :checked="getFieldsAll(module)"
                  :indeterminate="getFieldsAll(module) === null"
                  @change="changeFieldsAll($event, module)"
                >
                  <strong>
                    All
                  </strong>
                </a-checkbox>
              </a-col>
              <a-col
                :xs="3"
                class="checkbox-h"
              >
                <a-checkbox
                  :checked="getFieldActionPermAll(module, 'read')"
                  :indeterminate="getFieldActionPermAll(module, 'read') === null"
                  :disabled="readonly"
                  @change="changeFieldActionAll($event, module,'read')"
                >
                  {{ $t('_.permissions.read') }}
                </a-checkbox>
              </a-col>
              <a-col
                :xs="3"
                class="checkbox-h"
              >
                <a-checkbox
                  :checked="getFieldActionPermAll(module, 'list_add')"
                  :indeterminate="getFieldActionPermAll(module, 'list_add') === null"
                  :disabled="!hasListField(module) || readonly"
                  @change="changeFieldActionAll($event, module,'list_add')"
                >
                  {{ $t('_.permissions.create') }}
                </a-checkbox>
              </a-col>
              <a-col
                :xs="3"
                class="checkbox-h"
              >
                <a-checkbox
                  :checked="getFieldActionPermAll(module, 'write')"
                  :indeterminate="getFieldActionPermAll(module, 'write') === null"
                  :disabled="readonly"
                  @change="changeFieldActionAll($event, module,'write')"
                >
                  {{ $t('_.permissions.write') }}
                </a-checkbox>
              </a-col>
              <a-col
                :xs="3"
                class="checkbox-h"
              >
                <a-checkbox
                  :checked="getFieldActionPermAll(module, 'list_remove')"
                  :indeterminate="getFieldActionPermAll(module, 'list_remove') === null"
                  :disabled="!hasListField(module) || readonly"
                  @change="changeFieldActionAll($event, module,'list_remove')"
                >
                  {{ $t('_.permissions.delete') }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-affix>
          <FieldPermissionRow
            v-for="field in module.fields"
            :key="field.id"
            ref="fieldRow"
            :module="module"
            :field="field"
            :all-checked="getFieldPermAll"
            :all-change="changeFieldAll"
            :perm-checked="getFieldPerm"
            :perm-change="changeFieldPerm"
            :readonly="readonly"
            class="field-row"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from '@/components/layout-components/widgets/Widget'
import Api from '@/api'
import FieldPermissionRow from '@/components/layout-components/widgets/permissions/FieldPermissionRow'
import { copyTextToClipboard } from '@/utils'
import _ from 'lodash'
import CheckIcon from '@/components/layout-components/widgets/permissions/CheckIcon'

export default {
  name: 'WidgetPermissions',
  components: { CheckIcon, FieldPermissionRow },
  extends: Widget,
  data () {
    const showFields = {}
    const affixed = {}
    const modules = Api.getModules()
      .sort((a, b) => this.$t(`modules.${a.identifier}.title`).localeCompare(this.$t(`modules.${b.identifier}.title`)))
    const configField = this.config.field || 'modules'
    if (this.resource[configField] === undefined || this.resource[configField] === null || Array.isArray(this.resource[configField])) {
      this.resource[configField] = {}
    }
    const permissions = this.resource[configField]
    modules.forEach(module => {
      showFields[module.identifier] = false
      affixed[module.identifier] = false
    })

    const filterFunc = ($event) => {
      let text = $event.target.value
      if (!text || text.length === 0) {
        this.filteredModules = this.modules
        return
      }
      text = text.toLowerCase()
      this.filteredModules = this.modules.filter(module => this.$t(`modules.${module.identifier}.title`).toLowerCase().includes(text))
    }

    return {
      modules,
      permissions,
      showFields,
      affixed,
      filteredModules: modules,
      filterModules: _.debounce(filterFunc, 250),
      copied: false,
      allAffixed: false,
    }
  },
  computed: {
    readonly () {
      return this.mode === 'module.show'
    },
  },
  watch: {
    permissions () {
      this.resource[this.config.field || 'modules'] = this.permissions
    },
  },
  methods: {
    copyValue () {
      copyTextToClipboard(JSON.stringify(this.permissions))
      this.copied = true
    },

    tooltipVisibleChanged () {
      setTimeout(() => {
        this.copied = false
      }, 500)
    },

    /**
     * Convert true/false to {"moduleA": true, "moduleB": true}
     */
    fillRoot () {
      const allPerm = this.permissions
      if (typeof allPerm === 'boolean') {
        this.permissions = {}
        this.modules.forEach(module => {
          if (allPerm) {
            this.permissions[module.identifier] = true
          }
        })
      }
    },

    /**
     * Convert true/false { "create": true, ..., "fields": true }
     */
    fillModule (module) {
      this.fillRoot()
      const modulePerm = this.permissions[module.identifier]
      if (typeof modulePerm !== 'object') {
        this.permissions[module.identifier] = {
          read: modulePerm,
          create: modulePerm,
          update: modulePerm,
          delete: modulePerm,
          fields: modulePerm,
        }
      }
    },

    /**
     * Convert true/false to { "fieldA": true, "fieldB": true, ... }
     */
    fillFields (module) {
      this.fillModule(module)
      const fieldsPerm = this.permissions[module.identifier].fields
      if (typeof fieldsPerm !== 'object') {
        this.permissions[module.identifier].fields = {}
        module.getAllFieldsFlat().forEach(field => {
          this.permissions[module.identifier].fields[field.identifier] = fieldsPerm
        })
      }
    },

    /**
     * Convert true/false to { "read": true, "write": true }
     */
    fillField (module, field) {
      this.fillFields(module)
      const permission = this.permissions[module.identifier].fields[field.identifier]
      if (typeof permission !== 'object') {
        this.permissions[module.identifier].fields[field.identifier] = {
          read: permission,
          write: permission,
          list_add: field.type === 'list' ? permission : undefined,
          list_remove: field.type === 'list' ? permission : undefined,
        }
      }
    },

    /**
     * Get the value for the root all checkbox.
     *
     * @return {boolean|null}
     */
    getPermAll () {
      return this.mixedOrBool(this.modules.map(module => this.getModulePermAll(module)))
    },

    /**
     * Get the value for the module-specific all checkbox.
     *
     * @param module
     * @return {boolean|null}
     */
    getModulePermAll (module) {
      const actions = this.getModuleActions()
      const allModuleActions = this.mixedOrBool(actions.map(action => this.getPerm(module, action)))
      return this.mixedOrBool([allModuleActions, this.getFieldsAll(module)])
    },

    getFieldsAll (module) {
      return this.mixedOrBool(module.getAllFieldsFlat().map(field => this.getFieldPermAll(module, field)))
    },

    /**
     * Get the checkbox value (true/false/null) for a field.
     *
     * @return {boolean|null}
     * @param module
     * @param field
     */
    getFieldPermAll (module, field) {
      const actions = this.getFieldActions(field)
      return this.mixedOrBool(actions.map(action => this.getFieldPerm(module, field, action)))
    },

    /**
     * Get the checkbox value (true/false/null) for an action for all modules.
     *
     * @param action
     * @return {boolean|null}
     */
    getModuleActionPermAll (action) {
      return this.mixedOrBool(this.modules.map(module => this.getPerm(module, action)))
    },

    /**
     * Get the checkbox value (true/false/null) for a field-specific action for all fields of a module.
     *
     * @param module
     * @param action
     * @return {boolean|null}
     */
    getFieldActionPermAll (module, action) {
      return this.mixedOrBool(module.getAllFieldsFlat().map(field => this.getFieldPerm(module, field, action)))
    },

    /**
     * Return true if all permissions are true, false if they're false, and null if they're mixed.
     *
     * @param perms
     * @return {null|boolean}
     */
    mixedOrBool (perms) {
      const permSet = new Set(perms)
      const mixed = (permSet.has(true) && permSet.has(false)) || permSet.has(null)
      // If mixed, return null. Otherwise, return the first non-undefined result. Undefined means "ignore", i.e.
      // ignore 'list_add' in non-list fields.
      return mixed ? null : Array.from(permSet).filter(perm => perm !== undefined)[0]
    },

    getModuleActions () {
      return ['read', 'create', 'update', 'delete', 'history']
    },

    getFieldActions (field) {
      const actions = ['read', 'write']
      if (field.type === 'list') {
        actions.push('list_add', 'list_remove')
      }
      return actions
    },

    fieldHasAction (field, action) {
      return this.getFieldActions(field).includes(action)
    },

    /**
     * Get the checkbox value (true/false) for a field-specific permission.
     *
     * @param module
     * @param field
     * @param action
     * @return {boolean}
     */
    getFieldPerm (module, field, action) {
      if (!this.fieldHasAction(field, action)) {
        // Field cannot have this action.
        return undefined
      }
      const allPerm = this.permissions
      if (typeof allPerm === 'boolean') {
        return allPerm
      }
      if (!allPerm) {
        return false
      }
      const modulePerm = this.permissions[module.identifier]
      if (typeof modulePerm === 'boolean') {
        return modulePerm
      }
      if (!modulePerm) {
        return false
      }
      const fieldPerms = this.permissions[module.identifier].fields
      if (typeof fieldPerms === 'boolean') {
        return fieldPerms
      }
      if (typeof fieldPerms !== 'object') {
        return false
      }
      const fieldPerm = fieldPerms[field.identifier]
      if (typeof fieldPerm === 'boolean') {
        return fieldPerm
      }
      if (fieldPerm && typeof fieldPerm === 'object') {
        return fieldPerm[action] === true
      }
      return false
    },

    /**
     * Get the checkbox value (true/false) for a module action.
     *
     * @param module
     * @param action
     * @return {boolean}
     */
    getPerm (module, action) {
      const allPerm = this.permissions
      if (typeof allPerm === 'boolean') {
        return allPerm
      }
      const perm = allPerm[module.identifier]
      if (typeof perm === 'boolean') {
        return perm
      }
      if (perm && typeof perm === 'object') {
        return perm[action] === true
      }
      return false
    },

    /**
     * Set the value for the root all checkbox.
     *
     * @param e
     */
    changeAll (e) {
      this.fillRoot()
      this.permissions = e.target.checked
      this.updatePermissions()
    },

    changeModuleAll (e, module) {
      this.fillModule(module)
      this.$set(this.permissions, module.identifier, e.target.checked)
      this.updatePermissions()
    },

    changeModuleActionAll (e, action) {
      const translateFieldAction = {
        read: 'read',
        update: 'write',
        create: 'list_add',
        delete: 'list_remove',
      }
      this.modules.forEach(module => {
        this.fillModule(module)
        this.permissions[module.identifier][action] = e.target.checked
        this.changeFieldActionAll(e, module, translateFieldAction[action], false)
      })
      this.updatePermissions()
    },

    changeFieldActionAll (e, module, action, shouldUpdate = true) {
      module.getAllFieldsFlat().forEach(field => {
        if (this.fieldHasAction(field, action)) {
          this.fillField(module, field)
          this.permissions[module.identifier].fields[field.identifier][action] = e.target.checked
        }
      })
      if (shouldUpdate) {
        this.updatePermissions()
      }
    },

    changePerm (e, module, action) {
      this.fillModule(module)
      let permission = this.permissions[module.identifier]
      permission[action] = e.target.checked
      this.$set(this.permissions, module.identifier, permission)
      this.updatePermissions()
    },

    changeFieldAll (e, module, field) {
      this.fillFields(module)
      this.$set(this.permissions[module.identifier].fields, field.identifier, e.target.checked)
      this.updatePermissions()
    },

    changeFieldsAll (e, module) {
      this.fillModule(module)
      this.permissions[module.identifier].fields = e.target.checked
      this.updatePermissions()
    },

    changeFieldPerm (e, module, field, action) {
      this.fillField(module, field)
      const permission = this.permissions[module.identifier].fields[field.identifier]
      permission[action] = e.target.checked
      this.$set(this.permissions[module.identifier].fields, field.identifier, permission)
      this.updatePermissions()
    },

    updatePermissions () {
      this.optimizePermissions()
      this.$forceUpdate()
      if (this.$refs.fieldRow && Array.isArray(this.$refs.fieldRow)) {
        this.$refs.fieldRow.forEach(comp => comp.$forceUpdate())
      }
    },

    optimizePermissions () {
      const allPerm = this.getPermAll()
      if (allPerm === true || allPerm === false) {
        this.permissions = allPerm
        return
      }
      // First step: reduce all field objects and module objects to bool/undefined
      this.modules.forEach(module => {
        let modulePerm = this.getModulePermAll(module)
        if (modulePerm === null) {
          let fieldsPerm = this.getFieldsAll(module)
          // Reduce fields
          if (fieldsPerm === null) {
            module.getAllFieldsFlat().forEach(field => {
              const fieldPerm = this.getFieldPermAll(module, field)
              if (fieldPerm === true || fieldPerm === false) {
                this.permissions[module.identifier].fields[field.identifier] = fieldPerm ? true : undefined
              }
            })
          }
          fieldsPerm = this.getFieldsAll(module)
          if (fieldsPerm === true || fieldsPerm === false) {
            this.permissions[module.identifier].fields = fieldsPerm ? true : undefined
          }
          modulePerm = this.getModulePermAll(module)
        }
        if (modulePerm === true || modulePerm === false) {
          this.permissions[module.identifier] = modulePerm ? true : undefined
        }
      })
    },

    hasListField (module) {
      return !!module.getAllFieldsFlat().find(field => field.type === 'list')
    },
  },
}
</script>

<style lang="scss" scoped>
.affixed {
  background-color: white;
  box-shadow: 0 10px 8px -8px rgba(0, 0, 0, 0.15);
}

.widget {
}

.field-permissions {
  border-top: 1px solid #dddddd;
  padding-top: 5px;
}

.module-open {
  cursor: pointer;
  user-select: none;
  padding: 10px;
  margin-left: 5px;

  &:hover {
    background-color: #dddddd;
  }
}

.module-cell {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.checkbox-cell {
  padding: 10px;
  user-select: none;
}

.module-h {
  padding: 10px;
}

.fields-h {
  padding: 10px 10px 10px 40px;
}

.checkbox-h {
  padding: 10px;
}

.checkbox-cell, .checkbox-h {
  text-align: center;
}

.field-cell {
  padding-left: 40px;
}

.module-item:nth-of-type(2n) {
  background-color: #eee;

  .affixed {
    background-color: #eee;
  }
}
</style>
