<template>
  <div v-if="!options.multiple">
    <div v-if="mode === 'select'">
      <a-select
        :show-search="showSearch"
        :value="out_value"
        :allow-clear="clearable"
        :default-value="defaultValue"
        :disabled="disabled"
        @change="change"
      >
        <a-select-option
          v-for="(option, index) in values"
          :id="option"
          :key="index"
          :value="option"
        >
          {{ label(option) }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="mode === 'buttons'">
      <a-radio-group
        v-model="out_value"
        :default-value="defaultValue"
        button-style="solid"
        :disabled="disabled"
      >
        <a-radio-button
          v-for="(option, index) in values"
          :key="index"
          :value="option"
        >
          {{ label(option) }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="mode === 'radio'">
      <a-radio-group
        v-model="out_value"
        :default-value="defaultValue"
        :disabled="disabled"
      >
        <a-radio
          v-for="(option, index) in values"
          :key="index"
          :value="option"
        >
          {{ label(option) }}
        </a-radio>
      </a-radio-group>
    </div>
  </div>
  <div v-else>
    <div v-if="mode === 'select'">
      <a-select
        v-model="out_value"
        :allow-clear="clearable"
        :default-value="defaultValue"
        mode="multiple"
        :disabled="disabled"
        :show-search="showSearch"
      >
        <a-select-option
          v-for="(option, index) in values"
          :id="option"
          :key="index"
          :value="option"
        >
          {{ label(option) }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="mode === 'checkbox'">
      <a-checkbox-group
        v-model="out_value"
        :options="checkboxGroupOptions"
        :default-value="defaultValue"
        :disabled="disabled"
      />
    </div>
    <div v-if="mode === 'transfer'">
      <a-transfer
        :data-source="transferOptions"
        :target-keys="out_value || []"
        :filter-option="filterOption"
        :titles="[$t('options'), $t('selected')]"
        :list-style="{
          flex: 1,
        }"
        style="display: flex; align-items: center;justify-content: space-evenly"
        :render="item => item.title"
        show-search
        :disabled="disabled"
        @change="transferChanged"
      />
    </div>
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

export default {
  name: 'FieldChoice',
  extends: Field,
  computed: {
    checkboxGroupOptions () {
      return this.values.map(value => {
        return {
          'label': this.label(value),
          'value': value,
        }
      })
    },

    mode () {
      return this.options.mode || 'select'
    },

    showSearch () {
      return this.options.enable_search || false
    },

    clearable () {
      return this.options.allow_clear !== false
    },

    values () {
      const choices = this.getChoicesSource()
      if (Array.isArray(choices)) {
        return choices
      }
      if (typeof choices === 'string') {
        // No choice source was found in translations, notify the developer
        return ['choicesNotDefined']
      }
      if (typeof choices === 'object') {
        // Keys of objects are entries, values of object are translated entries
        return Object.keys(choices)
      }
      return choices
    },

    transferOptions () {
      return this.values.map(value => {
        return {
          key: value,
          title: this.label(value),
        }
      })
    },
  },
  mounted () {
    if (this.options.multiple) {
      this.out_value = this.out_value || []
    }
  },
  methods: {
    change (value) {
      this.out_value = value || null
    },

    label (value) {
      if (this.options && this.options.raw) {
        return value
      }
      return this.tr(`${this.translationPrefix}.choice.${this.field.identifier}.${value}`, true)
    },

    getChoicesSource () {
      if (typeof this.options.values === 'string') {
        // Get choices from translation <prefix>.choice.<field-option-values> object
        return this.tr(`${this.translationPrefix}.choice.${this.options.values}`, true)
      }
      if (!Array.isArray(this.options.values)) {
        // Get choices from translation <prefix>.choice.<field-identifier> object
        return this.tr(`${this.translationPrefix}.choice.${this.field.identifier}`, true)
      }
      // Get choices directly from field option
      return this.options.values
    },

    filterOption (inputValue, option) {
      return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    },

    transferChanged (targetKeys) {
      this.out_value = targetKeys
    },
  },
}
</script>

<style scoped>
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: #1890ff;
  border-color: #1890ff;
}
</style>
