<template>
  <div
    ref="editorWrapper"
    class="editorWrapper bordered"
  >
    <div
      :editor="editor"
    >
      <div
        v-if="editable"
        class="menubar"
      >
        <a-tabs
          class="tab-bar"
          :class="{ 'code-input-active': codeInputActive }"
          size="small"
          :tab-bar-style="{margin: 0}"
        >
          <a-tab-pane
            v-for="(tab, tabKey) in toolbar"
            :key="tabKey"
            :tab="$t('wysiwyg.' + tab.name)"
          >
            <a-tooltip
              v-for="(button, buttonKey) in tab.buttons"
              :key="buttonKey"
              :title="$t('wysiwyg.' + button.name)"
              :class="{ 'is-active': button.activeCheck ? button.activeCheck() : editor.isActive(button.name) }"
              class="menubar__button"
            >
              <div
                v-if="button.name === 'color'"
              >
                <a-icon
                  type="font-colors"
                  :style="{ color: color }"
                />
                <Field
                  :field="{ type: 'color', options: {} }"
                  :value="color"
                  style="opacity: 0; position: absolute; top: 0; left: 0"
                  @click.prevent
                  @change="changeColor"
                />
              </div>
              <div
                v-else-if="button.name === 'bg_color'"
              >
                <a-icon
                  type="highlight"
                  style="border-bottom: 2px solid white; position: relative; top: -2px;"
                  :style="{ borderColor: bgColor }"
                />
                <Field
                  :field="{ type: 'color', options: {} }"
                  :value="bgColor"
                  style="opacity: 0; position: absolute; top: 0; left: 0"
                  @click.prevent
                  @change="changeBgColor"
                />
              </div>
              <div v-else-if="button.name === 'image'">
                <a-icon
                  type="picture"
                  @click="imageModal = true"
                />
                <a-modal
                  :visible="imageModal"
                  width="70%"
                  @cancel="imageModal = false"
                  @ok="addImage"
                >
                  <MediaManager
                    v-model="image"
                    :module="imageModule"
                    :directory="imageDirectory"
                  />
                </a-modal>
              </div>
              <div
                v-else
                @click="button.action()"
              >
                {{ button.content }}
                <a-icon
                  v-if="button.icon"
                  v-bind="typeof button.icon === 'object' ? button.icon : {}"
                  :type="typeof button.icon === 'string' ? button.icon : ''"
                />
              </div>
            </a-tooltip>
          </a-tab-pane>

          <div
            slot="tabBarExtraContent"
            style="line-height: 2.2rem"
          >
            <a-tooltip
              v-if="allowRawInput"
              :title="codeInputActive ? $t('_.activate_text_input') : $t('_.activate_code_input')"
            >
              <div
                class="menubar__button"
                @click="codeInputActive = !codeInputActive"
              >
                <a-icon
                  v-if="codeInputActive"
                  type="font-size"
                />
                <a-icon
                  v-else
                  type="code"
                />
              </div>
            </a-tooltip>

            <div
              :title="$t('_.undo')"
              class="menubar__button"
              @click="editor.commands.undo"
            >
              <a-icon type="undo"/>
            </div>

            <div
              :title="$t('_.redo')"
              class="menubar__button"
              @click="editor.commands.redo"
            >
              <a-icon type="redo"/>
            </div>
          </div>
        </a-tabs>
      </div>
    </div>

    <editor-content
      v-if="!codeInputActive"
      :editor="editor"
      class="editor__content"
      @focusin="highlightWrapper"
      @focusout="removeHighlightFromWrapper"
    />

    <FieldCode
      v-else
      v-model="out_value"
    />
  </div>
</template>

<script>
import Field from '@/components/fields/Field'

import { EditorContent } from '@tiptap/vue-2'
import IconTable from '../../icons/Table'
import CombineCells from '../../icons/CombineCells'
import DeleteRow from '../../icons/DeleteRow'
import AddRowAfter from '../../icons/AddRowAfter'
import AddRowBefore from '../../icons/AddRowBefore'
import AddColBefore from '../../icons/AddColBefore'
import AddColAfter from '../../icons/AddColAfter'
import DeleteCol from '../../icons/DeleteCol'
import DeleteTable from '../../icons/DeleteTable'
import MediaManager from '@/components/MediaManager'
import EditorMixin from '@/components/fields/wysiwyg/EditorMixin'
import FieldCode from '../code/FieldCode'

export default {
  name: 'FieldWYSIWYG',
  components: {
    FieldCode,
    Field,
    EditorContent,
    MediaManager,
  },
  extends: Field,
  mixins: [EditorMixin],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      defaultToolbar: [
        {
          name: 'text_style',
          buttons: [
            {
              name: 'bold',
              icon: 'bold',
              action: () => this.editor.chain().toggleBold().focus().run(),
            },
            {
              name: 'italic',
              icon: 'italic',
              action: () => this.editor.chain().toggleItalic().focus().run(),
            },
            {
              name: 'strike',
              icon: 'strikethrough',
              action: () => this.editor.chain().toggleStrike().focus().run(),
            },
            {
              name: 'underline',
              icon: 'underline',
              action: () => this.editor.chain().toggleUnderline().focus().run(),
            },
            {
              name: 'block_quote',
              content: '"',
              action: () => this.editor.chain().toggleBlockquote().focus().run(),
            },
            {
              name: 'horizontal_rule',
              icon: 'line',
              action: () => this.editor.chain().setHorizontalRule().focus().run(),
            },
            {
              name: 'color',
            },
            {
              name: 'bg_color',
            },
            {
              name: 'media',
              icon: 'picture',
              action: () => this.editor.chain().setMedia().focus().run(),
            },
            {
              name: 'paragraph',
              content: 'P',
              action: () => this.editor.chain().setParagraph().focus().run(),
            },
            {
              name: 'small',
              content: 's',
              action: () => this.editor.chain().toggleSubscript().focus().run(),
              activeCheck: () => this.editor.isActive('subscript'),
            },
            {
              name: 'heading_1',
              content: 'H1',
              action: () => this.editor.chain().setHeading({ level: 1 }).focus().run(),
              activeCheck: () => this.editor.isActive('heading', { level: 1 }),
            },
            {
              name: 'heading_2',
              content: 'H2',
              action: () => this.editor.chain().setHeading({ level: 2 }).focus().run(),
              activeCheck: () => this.editor.isActive('heading', { level: 2 }),
            },
            {
              name: 'heading_3',
              content: 'H3',
              action: () => this.editor.chain().setHeading({ level: 3 }).focus().run(),
              activeCheck: () => this.editor.isActive('heading', { level: 3 }),
            },
            {
              name: 'heading_4',
              content: 'H4',
              action: () => this.editor.chain().setHeading({ level: 4 }).focus().run(),
              activeCheck: () => this.editor.isActive('heading', { level: 4 }),
            },
            {
              name: 'heading_5',
              content: 'H5',
              action: () => this.editor.chain().setHeading({ level: 5 }).focus().run(),
              activeCheck: () => this.editor.isActive('heading', { level: 5 }),
            },
            {
              name: 'heading_6',
              content: 'H6',
              action: () => this.editor.chain().setHeading({ level: 6 }).focus().run(),
              activeCheck: () => this.editor.isActive('heading', { level: 6 }),
            },
          ],
        },
        {
          name: 'lists',
          buttons: [
            {
              name: 'bullet_list',
              icon: 'unordered-list',
              action: () => this.editor.chain().toggleBulletList().focus().run(),
            },
            {
              name: 'ordered_list',
              icon: 'ordered-list',
              action: () => this.editor.chain().toggleOrderedList().focus().run(),
            },
          ],
        },
        {
          name: 'tables',
          buttons: [
            {
              name: 'create_table',
              icon: { component: IconTable },
              action: () => this.editor.chain().insertTable({
                rowsCount: 3,
                colsCount: 3,
                withHeaderRow: true,
              }).focus().run(),
            },
            {
              name: 'delete_table',
              icon: { component: DeleteTable },
              action: () => this.editor.chain().deleteTable().focus().run(),
            },
            {
              name: 'insert_col_before',
              icon: { component: AddColBefore },
              action: () => this.editor.chain().addColumnBefore().focus().run(),
            },
            {
              name: 'insert_col_after',
              icon: { component: AddColAfter },
              action: () => this.editor.chain().addColumnAfter().focus().run(),
            },
            {
              name: 'delete_column',
              icon: { component: DeleteCol },
              action: () => this.editor.chain().deleteColumn().focus().run(),
            },
            {
              name: 'insert_row_before',
              icon: { component: AddRowBefore },
              action: () => this.editor.chain().addRowBefore().focus().run(),
            },
            {
              name: 'insert_row_after',
              icon: { component: AddRowAfter },
              action: () => this.editor.chain().addRowAfter().focus().run(),
            },
            {
              name: 'delete_row',
              icon: { component: DeleteRow },
              action: () => this.editor.chain().deleteRow().focus().run(),
            },
            {
              name: 'combine_cells',
              icon: { component: CombineCells },
              action: () => this.editor.chain().mergeCells().focus().run(),
            },
            {
              name: 'toggle_header_row',
              content: 'H',
              action: () => this.editor.chain().toggleHeaderRow().focus().run(),
            },
          ],
        },
      ],
      toolbar: [],
      codeInputActive: false,
      updatedFromEditor: false,
      presets: {
        seo_only: ['bold', 'italic', 'strike', 'underline', 'block_quote', 'horizontal_rule'],
      },
      imageModal: false,
    }
  },
  computed: {
    color () {
      return this.editor.getAttributes('textStyle').style ? this.editor.getAttributes('textStyle').style.color : '#000'
    },
    bgColor () {
      return this.editor.getAttributes('textStyle').style ? this.editor.getAttributes('textStyle').style.backgroundColor : '#fff'
    },
    allowRawInput () {
      return this?.options?.allow_raw_input ?? true
    },
  },
  watch: {
    out_value () {
      if (this.updatedFromEditor) {
        this.updatedFromEditor = false
        return
      }
      this.editor.commands.setContent(this.out_value)
    },
    codeInputActive (isActive) {
      if (isActive) {
        this.toolbar = []
      } else {
        this.toolbar = this.defaultToolbar
      }
    },
    disabled (value) {
      if (value) {
        this.editor.options.editable = false
        this.$refs.editorWrapper.style.cursor = 'not-allowed'
        this.$refs.editorWrapper.style.background = '#f5f5f5'
      } else {
        this.editor.options.editable = true
        this.$refs.editorWrapper.style.cursor = 'auto'
        this.$refs.editorWrapper.style.background = 'none'
        this.editor.chain().run()
      }
    },
  },
  created () {
    let toolbar = this.defaultToolbar
    let customConfiguredToolbarElements = []

    // support legacy option
    if (this.options.seo_only) this.options.toolbar_preset = 'seo_only'

    // set button config to the defined preset
    if (this.options.toolbar_preset && this.presets.hasOwnProperty(this.options.toolbar_preset)) {
      customConfiguredToolbarElements = this.presets[this.options.toolbar_preset]
    }

    // If toolbar elements are explicitly defined, add them to the button config.
    // This enables "extending" of presets
    if (this.options.toolbar) {
      customConfiguredToolbarElements = customConfiguredToolbarElements.concat(this.options.toolbar)
    }

    // Apply the button config if it exists
    if (customConfiguredToolbarElements.length) {
      toolbar.forEach((tab, index) => { toolbar[index].buttons = tab.buttons.filter(button => customConfiguredToolbarElements.indexOf(button.name) !== -1) })
    }

    this.toolbar = toolbar.filter(tab => tab.buttons.length > 0)
  },
  mounted () {
    this.setupEditorEvents()
    this.setupEditorStyles()
    this.editor.commands.setContent(this.out_value)
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    setupEditorStyles () {
      const editorHeight = this.options.height || '300px'
      let wrapperStyles = '--editorHeight:' + editorHeight + ';'
      if (this.disabled) {
        this.editor.options.editable = false
        wrapperStyles += 'cursor: not-allowed !important; background: #f5f5f5;'
      }
      this.$refs.editorWrapper.setAttribute('style', wrapperStyles)
    },
    changeColor (event) {
      this.editor.commands.setColor(event.value)
    },
    changeBgColor (event) {
      this.editor.commands.setHighlight({ color: event.value })
    },
    addImage () {
      this.editor.chain().setImage({ src: this.image }).run()
    },
    setupEditorEvents () {
      this.editor.on('focus', this.highlightWrapper)
      this.editor.on('blur', this.removeHighlightFromWrapper)
    },
    highlightWrapper () {
      this.$refs.editorWrapper.classList.add('focused')
    },
    removeHighlightFromWrapper () {
      this.$refs.editorWrapper.classList.remove('focused')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.editorWrapper.focused {
  border-color: $input-border-color-active;
  box-shadow: 0 0 0 2px rgb($input-border-color-active, 0.2);
}

.editorWrapper:focus-within {
  box-shadow: 0 0 0 2px rgb($input-border-color-active, 0.2);
}
</style>
