<template>
  <div class="widget">
    <div>
      <div
        v-if="config.view && debug"
        class="reload"
        @click="loadWidget"
      >
        <a-icon type="reload"/>
      </div>
    </div>
    <a-spin v-if="loading"/>
    <div v-else-if="code">
      <Runtime
        :runtime-config="{ code, requires: preRequiredObjects, components, dataScope }"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot
          v-for="(_, name) in $slots"
          :slot="name"
          :name="name"
        />
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
        >
          <slot
            :name="name"
            v-bind="slotData"
          />
        </template>
      </Runtime>
    </div>
    <div
      v-if="renderError"
      class="render-error"
    >
      <div>
        <a-icon
          type="exclamation-circle"
          style="color: red"
        />
        Error
      </div>
      <small v-if="identifier">The widget "{{ identifier }}" failed to load.</small>
      <small v-else>The widget failed to load.</small>
    </div>
  </div>
</template>

<script>
import { WidgetObject } from '@/components/layout-components/widgets/custom/widgets'
import Api, * as apiFunctions from '@/api'
import * as utils from '@/utils'
import store from '@/store'
// if you are using vue-cli, you have to import the css separately
import 'vue-live/lib/vue-live.esm.css'
import lodash from 'lodash'
import { editors, viewers } from '@/components/fields/types'
import { Button, Icon, Modal, Popover, Timeline } from 'ant-design-vue'
import Widget from '@/components/layout-components/widgets/Widget'
import Module from '@/module/module'
import Entity from '@/api/entity'
import TimelineItem from 'ant-design-vue/es/timeline/TimelineItem'
import EntityLink from '@/components/EntityLink'
import Runtime from '@/components/layout-components/widgets/runtime/Runtime'
import * as webhooks from '@/webhooks'
import moment from 'moment'

export const self = {
  name: 'WidgetCustom',
  components: {
    Runtime,
  },
  extends: Widget,
  data () {
    const widget = new WidgetObject()
    widget.register(this.config.identifier)
    return {
      code: null,
      loading: false,
      renderError: null,
      renderedComponent: null,
      preRequiredObjects: {
        lodash,
        api: Api,
        module: Module,
        entity: Entity,
        webhooks,
        utils,
        store,
        apiFunctions,
        moment,
      },
      components: {
        ...Object.fromEntries(Object.entries(editors).map(editor => [editor[1].name, editor[1]])),
        ...Object.fromEntries(Object.entries(viewers).map(viewer => [viewer[1].name, viewer[1]])),
        Button,
        Icon,
        Timeline,
        TimelineItem,
        EntityLink,
        Popover,
        Modal,
        CustomWidget: self,
      },
      widget,
      dataScope: {
        widget,
        component: this.component,
        entity: this.resource,
        ...this.config.data,
      },
    }
  },
  computed: {
    debug () {
      return process.env.VUE_APP_DEBUG
    },
  },
  mounted () {
    this.loadWidget()
    for (const event in this.$listeners) {
      this.widget.on(event, this.$listeners[event])
    }
  },
  methods: {
    loadWidget () {
      if (this.config.code) {
        this.loadCode()
      }
      if (this.config.view) {
        this.loadView()
      }
    },

    loadCode () {
      this.code = this.config.code
    },

    loadView () {
      this.loading = true
      this.renderError = null
      Api.fetchView(this.config.view).then(view => {
        this.code = view
        this.loading = false
      })
    },

    handleError (e) {
      console.error(e)
      this.renderError = e
    },
  },
}
export default self
</script>

<style>
audio, canvas, embed, iframe, img, object, svg, video {
  /* Tailwind, if imported, would alter this */
  vertical-align: initial !important;
}
</style>

<style lang="scss" scoped>
.render-error {
  padding: 10px;
}

.widget {
  position: relative;

  &:hover {
    .reload {
      display: block;
    }
  }
}

.reload {
  display: none;
  padding: 5px;
  position: absolute;
  background-color: #eee;
  top: -24px;
  border: 1px solid #ddd;
  left: -1px;
  font-size: 12px;
  width: 24px;
  height: 24px;
  line-height: 9px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: black;
    color: white;
  }
}
</style>
