<template>
  <a
    :href="value ? 'tel:' + value : 'javascript:void(0)'"
    :class="'with-padding' + (value ? '' : ' disabled') + (displayAsIcon ? ' has-icon' : '')"
  >
    <a-icon
      v-if="displayAsIcon"
      type="phone"
    />
    <span
      v-else
      style="pointer-events: none"
      v-text="value"
    />
  </a>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellTel',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
