<template>
  <div
    id="app"
    style="height: 100%"
    :class="routeCssClass"
  >
    <password-reset
      v-if="hasToken"
      @error="tokenError"
    />
    <login
      v-else-if="authenticated === false"
      @login="removeErrorMessageOnSuccessfulLogin"
    >
      <template slot="logo">
        <slot name="loginLogo"/>
      </template>
    </login>
    <error
      v-else-if="errorOrInformationShown"
      :is-error="errorOrInformationIsError"
      :type="errorOrInformationType"
    />
    <div
      v-else-if="loading"
      style="text-align:center; position: absolute; top: calc(50% - 40px); width: 100%"
    >
      <a-progress
        type="circle"
        :percent="loadingProgress"
      />
    </div>
    <a-config-provider
      v-else
      :locale="locale"
    >
      <a-layout :style="{ 'min-height': '100vh' }">
        <a-layout-sider
          v-if="!screenIsMobile && hasSidebar"
          v-model="$store.state.menuCollapsed"
          :collapsible="true"
          :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
          :trigger="null"
          :width="menuWidthDesktop"
          theme="light"
        >
          <router-link
            :to="`/${$store.state.currentLocale}/`"
          >
            <slot name="logo">
              <div class="menu-logo">
                <img
                  :src="$systemPath('logo.svg')"
                  alt="logo"
                  style="width: 100%; max-height: 100px"
                >
              </div>
            </slot>
          </router-link>

          <Menu
            :key="menuKey"
            :menu-items="menuItems"
            :loaded="menuLoaded"
          />
        </a-layout-sider>
        <a-layout
          :class="getLayoutClasses()"
          :style="getLayoutStyles()"
        >
          <a-layout-content class="layoutContent">
            <a-row
              :style="{ padding: screenIsMobile ? '9px 16px' : '8px 24px', background: '#fff', marginBottom: '12px', fontSize: screenIsMobile ? '24px' : '18px' }"
              align="middle"
              class="breadcrumb-bar"
              type="flex"
            >
              <a-col
                :sm="18"
                :xs="14"
              >
                <a-row
                  :style="{ padding: '0' }"
                  align="middle"
                  type="flex"
                >
                  <a
                    v-if="hasSidebar"
                    @click="screenIsMobile ? openMobileMenu() : $store.commit('toggleMenu')"
                  >
                    <a-icon
                      :style="{ 'margin-right': '24px', 'cursor': 'pointer' }"
                      :type="getButtonStyle()"
                      class="trigger"
                    />
                  </a>
                  <a-breadcrumb
                    :routes="appRoutes"
                    :style="{ display: 'inline-block', width: 'calc(100% - 75px)', overflowX: screenIsMobile ? 'auto': 'hidden', whiteSpace: 'nowrap', textAlign: 'left', lineHeight: '24px', textOverflow: screenIsMobile ? 'unset' : 'ellipsis' }"
                  >
                    <template
                      slot="itemRender"
                      slot-scope="{route, routes, paths}"
                    >
                      <router-link
                        v-if="route.type === 'home'"
                        to="/"
                      >
                        <a-icon
                          class="home-button"
                          type="home"
                        />
                      </router-link>
                      <span v-else-if="routes.indexOf(route) === routes.length - 1">
                        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                        <span
                          v-if="route.breadcrumbName.charAt(0) === ':'"
                          v-text="getBreadcrumbText(route.breadcrumbName)"
                        />
                        <span
                          v-else
                          v-text="tr(route.breadcrumbName, true)"
                        />
                      </span>
                      <router-link
                        v-else-if="route.breadcrumbName.charAt(0) === ':'"
                        :to="`/${$store.state.currentLocale}/${replaceBreadcrumbs(paths.join('/'), routes, $store)}`"
                        v-text="getBreadcrumbText(route.breadcrumbName)"
                      />
                      <router-link
                        v-else
                        :to="`/${$store.state.currentLocale}/${paths.join('/')}`"
                        v-text="tr(route.breadcrumbName, true)"
                      />
                    </template>
                  </a-breadcrumb>
                </a-row>
              </a-col>
              <a-col
                :sm="6"
                :style="{ textAlign: 'right' }"
                :xs="10"
                class="top-menu-wrapper"
              >
                <div class="locale-switch">
                  <a-dropdown
                    v-show="Object.keys(locales).length > 1"
                    :trigger="['click']"
                  >
                    <a
                      class="ant-dropdown-link"
                      @click.prevent
                    >
                      <a-icon type="global"/>
                      <span class="locale-title">{{ locales[$store.state.currentLocale].name }}</span>
                    </a>
                    <template #overlay>
                      <a-menu
                        mode="inline"
                      >
                        <a-menu-item
                          v-for="(lang, langCode) in locales"
                          :key="langCode"
                          :selectable="false"
                        >
                          <a @click="changeLocale(langCode)">
                            {{ lang.name }}
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
                <a
                  class="trash-button"
                  :title="tr('trash.title', true)"
                  @click="goToTrash()"
                >
                  <a-icon
                    :style="{'cursor': 'pointer' }"
                    type="delete"
                    class="trigger"
                  />
                </a>
                <a
                  style="display: none;"
                  @click="openSearch"
                >
                  <a-icon
                    :style="{'cursor': 'pointer' }"
                    :type="'search'"
                    class="trigger"
                  />
                </a>
                <a @click="openOptions">
                  <a-tooltip
                    v-if="userAvatar"
                    :title="($store.state.user || {}).brezel_name"
                  >
                    <a-avatar
                      :src="userAvatar"
                      class="avatar"
                    />
                  </a-tooltip>
                  <a-avatar v-else>
                    {{
                      ($store.state.user || {}).brezel_name.replace(/[^A-Za-z\s]/g, '').split(' ').map((n) => n[0]).join('')
                    }}
                  </a-avatar>
                </a>
              </a-col>
            </a-row>
            <a-row
              v-if="$store.state.licenseInfo.licenseWarning"
              class="license-warning"
            >
              <a-col :xs="24">
                <span>{{ $t('_.license.no_license_text') }}</span>&nbsp;
                <router-link :to="licenseLink">
                  {{ $t('_.license.goToLicense') }}
                </router-link>
              </a-col>
            </a-row>
            <a-row
              v-if="isTrialPeriod"
              class="license-warning trial"
            >
              <a-col :xs="24">
                <span>{{ $t('_.license.trialInformation', { days: daysOfTrialLeft }) }}</span>&nbsp;
                <router-link :to="licenseLink">
                  {{ $t('_.license.goToLicense') }}
                </router-link>
              </a-col>
            </a-row>
            <div
              class="routerView"
              :class="`route-${$route.name}`"
            >
              <div class="usernameForPrinting">
                {{ ($store.state.user || {}).brezel_name }}
              </div>
              <router-view
                v-if="showRouterView"
                :key="$route.path"
              />
            </div>
          </a-layout-content>
        </a-layout>
        <a-drawer
          v-if="screenIsMobile"
          :visible="mobileMenuVisible"
          placement="left"
          wrap-class-name="mobileDrawer"
          @close="onCloseMobileMenu"
        >
          <div
            class="menu-logo"
            style="width: calc(100% - 56px - 20px)"
          >
            <slot name="logo">
              <img
                :src="$systemPath('logo.svg')"
                alt="logo"
                style="width: 100%; max-height: 100px"
              >
            </slot>
          </div>

          <Menu
            :menu-items="menuItems"
            :loaded="menuLoaded"
            @click="onCloseMobileMenu"
          />
        </a-drawer>
        <a-drawer
          :visible="searchVisible"
          height="100%"
          placement="top"
          title="Search"
          wrap-class-name="searchDrawer"
          @close="onCloseSearch"
        >
          <div style="padding: 10px;">
            <a-input
              id="searchInput"
              placeholder="Search"
              size="large"
              @change="onSearch"
            />
          </div>
          <div class="searchResults">
            <div
              v-for="(result, result_index) in searchResults"
              :key="result_index"
            >
              {{ result }}
            </div>
          </div>
        </a-drawer>
        <a-drawer
          :title="$t('_.options')"
          :visible="optionsVisible"
          placement="right"
          wrap-class-name="optionsDrawer"
          @close="onCloseOptions"
        >
          <div class="optionsContent">
            <div
              class="userInfo"
              style="display: flex; justify-content: center; align-items: center"
            >
              <img
                v-if="userAvatar"
                :src="userAvatar"
                style="max-width: 100%"
              >
              <a-avatar
                v-else
                :size="256"
                icon="user"
                shape="square"
              />
            </div>
            <a-menu
              :selectable="false"
              mode="inline"
            >
              <a-menu-item>
                <router-link
                  v-if="$store.state.userInfo && $store.state.userInfo.module"
                  :to="`/${$store.state.currentLocale}/${$store.state.userInfo.module.identifier}/${$store.state.user.id}`"
                  @click="onCloseOptions();"
                >
                  <a-icon type="user"/>
                  <span v-text="($store.state.user || {}).brezel_name"/>
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <router-link
                  :to="`/${$store.state.currentLocale}/apiKeys`"
                  @click="onCloseOptions();"
                >
                  <a-icon type="key"/>
                  <!-- eslint-disable vue/no-v-html -->
                  <span v-html="tr('_.api.keys')"/>
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="webauthnAvailable">
                <WebauthnMenuEntry/>
              </a-menu-item>
              <a-menu-item v-if="showLicense">
                <router-link
                  :to="licenseLink"
                  @click="onCloseOptions();"
                >
                  <a-icon type="download"/>
                  <span v-html="tr('_.license.license')"/>
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="installable()">
                <a @click="installer();onCloseOptions();">
                  <a-icon type="download"/>
                  {{ $t('_.install') }}
                </a>
              </a-menu-item>
              <a-menu-item v-if="installableiOS()">
                <a @click="displayiOSInstallHelp();onCloseOptions();">
                  <a-icon type="download"/>
                  {{ $t('_.install') }}
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="clearWorkboxCaches();onCloseOptions();">
                  <a-icon type="reload"/>
                  {{ $t('_.clearWorkboxCaches') }}
                </a>
              </a-menu-item>
              <a-menu-item v-if="fullscreenEnabled">
                <a
                  v-if="fullscreenActive"
                  @click="deactivateFullscreen();onCloseOptions();"
                >
                  <a-icon type="fullscreen-exit"/>
                  {{ $t('_.deactivateFullscreen') }}
                </a>
                <a
                  v-else
                  @click="activateFullscreen();onCloseOptions();"
                >
                  <a-icon type="fullscreen"/>
                  {{ $t('_.activateFullscreen') }}
                </a>
              </a-menu-item>
              <a-menu-item v-if="$keymap.keymap.length > 0">
                <a
                  @click="keymapVisible = true"
                >
                  <a-icon type="control"/>
                  {{ $t('_.keymap') }}
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="openInfo();onCloseOptions();">
                  <a-icon type="info-circle"/>
                  {{ $t('_.info') }}
                </a>
              </a-menu-item>
              <a-menu-item>
                <a
                  class="menu-item-danger"
                  @click="logout"
                >
                  <a-icon type="logout"/>
                  {{ $t('_.logout') }}
                </a>
              </a-menu-item>
            </a-menu>
            <a-drawer
              :title="$t('_.info')"
              :visible="infoVisible"
              @close="closeInfo"
            >
              <div style="text-align: center;">
                <slot name="logo">
                  <img
                    :src="$systemPath('logo.svg')"
                    alt="logo"
                    style="width: 50%"
                  >
                </slot>
                <br>
                <span style="font-size: xx-large;">{{ $store.state.systemName }}</span>
                <br>
                <span style="font-size: larger">{{ $store.state.description }}</span>
                <div style="font-size: small; text-align: center">
                  <span>
                    {{ currentYear }}
                  </span>
                  <a-divider type="vertical"/>
                  <span>
                    {{ 'kibro/brezel-spa@' + packageVersion }}
                  </span>
                </div>
              </div>
            </a-drawer>
          </div>
        </a-drawer>
        <WebhookInput
          v-if="webhookInput !== null"
          v-model="webhookInputVisible"
          :webhook-input="webhookInput"
        />
        <a-modal
          v-model="fileViewVisible"
          :body-style="{maxWidth: 'calc(100vw - 16px)', padding: 0, width: '1280px'}"
          :destroy-on-close="true"
          :title="$t('modules.files.modal.preview') + ': ' + fileName"
          centered
          class="file-modal"
          visible="fileViewVisible"
          width="auto"
          :z-index="10000"
          @cancel="fileViewVisible = false"
        >
          <FileView
            ref="fileView"
            :src="fileURL"
            :type="fileType"
            @fileLoaded="fileLoaded"
          />
          <template slot="footer">
            <a
              :download="fileName"
              :href="fileURL"
            >
              <a-button
                key="download"
                type="primary"
              >
                {{ $t('_.download') }}
              </a-button>
            </a>
          </template>
        </a-modal>
        <a-modal
          v-model="iosInstallHelpVisible"
          :destroy-on-close="true"
          :title="$t('ios_install_help.title')"
          centered
          class="ios-modal"
          visible="iosInstallHelpVisible"
          width="75%"
          @cancel="iosInstallHelpVisible = false"
        >
          {{ $t('ios_install_help.text') }}
          <img
            :src="require('@/assets/ios_install_help.png')"
            width="100%"
          >
          <template slot="footer">
            <a-button
              key="ok"
              type="primary"
              @click="iosInstallHelpVisible = false"
            >
              {{ $t('_.ok') }}
            </a-button>
          </template>
        </a-modal>

        <a-modal
          v-if="$keymap.keymap.length > 0"
          v-model="keymapVisible"
          :destroy-on-close="true"
          :title="$t('_.keymap')"
          centered
          width="75%"
          :footer="null"
          @cancel="keymapVisible = false"
        >
          <a-list>
            <a-list-item
              v-for="(item, index) of $keymap.keymap"
              :key="index"
            >
              <div class="commandName">
                <strong>
                  {{
                    $te('keymap.' + item.identifier + '.title') ? tr('keymap.' + item.identifier + '.title', true) : item.identifier
                  }}
                </strong>
              </div>
              <div class="commandDescription">
                {{
                  $te('keymap.' + item.identifier + '.description') ? tr('keymap.' + item.identifier + '.description', true) : ''
                }}
              </div>
              <div class="commandKeys">
                <span
                  v-for="(command, commandIndex) in item.commands"
                  :key="commandIndex"
                >
                  <a-divider
                    v-if="commandIndex !== 0"
                    type="vertical"
                  />
                  <span
                    v-for="(letter, letterIndex) in parseCommand(command)"
                    :key="letterIndex"
                  >
                    <span v-if="letterIndex !== 0"> + </span>
                    <a-tag style="margin-right: 0;">{{ letter }}</a-tag>
                  </span>
                </span>
              </div>
            </a-list-item>
          </a-list>
        </a-modal>

        <a-modal
          v-if="$keymap.getAvailableCommands().length > 0"
          v-model="quickActionsVisible"
          :destroy-on-close="true"
          :title="$t('_.quick_actions')"
          centered
          class="quick-actions-modal"
          width="75%"
          :footer="null"
          :z-index="11037"
          @cancel="quickActionsVisible = false"
        >
          <a-list>
            <a-list-item
              v-for="(item, index) of $keymap.getAvailableCommands()"
              :key="index"
              ref="quickAction"
              tabindex="0"
              @click="executeCommands(item)"
              @keydown.up="commandNavigateUp(index)"
              @keydown.down="commandNavigateDown(index)"
              @keydown.enter="executeCommands(item)"
            >
              <div class="commandName">
                <strong>
                  {{
                    $te('keymap.' + item.identifier + '.title') ? tr('keymap.' + item.identifier + '.title', true) : item.identifier
                  }}
                </strong>
              </div>
              <div class="commandDescription">
                {{
                  $te('keymap.' + item.identifier + '.description') ? tr('keymap.' + item.identifier + '.description', true) : ''
                }}
              </div>
              <div class="commandKeys">
                <span
                  v-for="(command, commandIndex) in item.commands"
                  :key="commandIndex"
                >
                  <a-divider
                    v-if="commandIndex !== 0"
                    type="vertical"
                  />
                  <span
                    v-for="(letter, letterIndex) in parseCommand(command)"
                    :key="letterIndex"
                  >
                    <span v-if="letterIndex !== 0"> + </span>
                    <a-tag style="margin-right: 0;">{{ letter }}</a-tag>
                  </span>
                </span>
              </div>
            </a-list-item>
          </a-list>
        </a-modal>
      </a-layout>
    </a-config-provider>
  </div>
</template>

<script>
import {
  apiLink,
  evaluateExpressionItem,
  exportLink,
  handleToast,
  isAuthenticated,
  isScreenMobile,
  setDocumentTitle,
  setLocale,
} from '@/utils'
import { loadLanguagesAsync, locales } from '@/i18n'
import store from '@/store'
import { EventBus } from '@/event-bus'
import Error from '@/components/Error'
import FileView from '@/components/FileView'
import Menu from '@/components/Menu'
import Login from '@/views/Login'
import PasswordReset from '@/views/PasswordReset'
import WebhookInput from '@/views/WebhookInput'
import brotcast from '@kibro/brezel-brotcast'
import { loadStylesAsync } from '@/styles'
import Api, { apiDelete, apiGet } from '../api'
import packageJson from '../../package.json'
import { createAvatar } from '@dicebear/avatars'
import * as style from '@dicebear/avatars-initials-sprites'
import _ from 'lodash'
import { runCheckpoint } from '@/webhooks'
import WebauthnMenuEntry from './WebauthnMenuEntry'
import moment from 'moment'

let installPrompt

export default {
  name: 'App',
  components: {
    WebauthnMenuEntry,
    Error,
    FileView,
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
    Login,
    PasswordReset,
    WebhookInput,
  },
  data () {
    return {
      authenticated: isAuthenticated(),
      count: 0,
      dropdownNeedsFirstPaint: false,
      errorOrInformationIsError: true,
      errorOrInformationShown: false,
      errorOrInformationType: 404,
      fileURL: '',
      fileName: '',
      fileType: '',
      fileViewVisible: false,
      fileLoadCallback: () => {
      },
      infoVisible: false,
      iosInstallHelpVisible: false,
      installer: undefined,
      keymapVisible: false,
      loading: true,
      loadingReasons: {
        general: false,
        modules: false,
        translations: false,
        styles: false,
      },
      locale: {},
      menuItems: [],
      menuLoaded: false,
      menuWidthDesktop: 228,
      mobileMenuVisible: false,
      modules: [],
      optionsVisible: false,
      overlayClasses: 'overflow hidden',
      packageVersion: packageJson.version,
      quickActionsVisible: false,
      appRoutes: [],
      showRouterView: true,
      screenIsMobile: isScreenMobile(),
      searchResults: {},
      searchValue: '',
      searchVisible: false,
      shiftKeyInfo: {
        buffer: false,
        lastPressTime: null,
        up: false,
      },
      showLicense: false,
      webhookInput: null,
      webhookInputVisible: false,
      windowInnerHeight: undefined,
      hasToken: false,
      menuKey: 0,
      userAvatar: null,
      finishQueue: [],
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    },
    env () {
      return process.env
    },
    fullscreenActive () {
      return this.windowInnerHeight === screen.availHeight
    },
    fullscreenEnabled () {
      return document.fullscreenEnabled
    },
    locales () {
      return locales
    },
    routeCssClass () {
      const prefix = (this.$route.params.module_identifier || this.$route.name)
      return [
        this.authenticated ? '' : 'login',
        _.kebabCase(this.$route.name),
        prefix,
        (this.$route.params.resource_identifier ? prefix + '-' + this.$route.params.resource_identifier : ''),
      ]
    },
    loadingProgress () {
      const allParts = Object.values(this.loadingReasons)
      const unfinished = allParts.filter(isLoading => !isLoading).length
      return 100 * unfinished / allParts.length
    },
    webauthnAvailable () {
      return typeof navigator.credentials !== 'undefined' &&
        _.get(store, 'state.userInfo.module.options.webauthn') !== false
    },
    hasSidebar () {
      return this.$store.state.sidebar
    },
    licenseLink () {
      return '/' + this.$store.state.currentLocale + '/license'
    },
    isTrialPeriod () {
      return this.$store.state.licenseInfo.plan &&
        this.$store.state.licenseInfo.plan.pivot.trial !== null &&
        this.$store.state.licenseInfo.plan.pivot.stripe_subscription_id === null
    },
    daysOfTrialLeft () {
      let a = moment(this.$store.state.licenseInfo.plan.pivot.license_expires_at)
      let b = moment()
      return a.diff(b, 'days')
    },
  },
  watch: {
    'count' () {
      if (this.count === 1) this.$nextTick(this.createDropdown())
    },
    '$route' () {
      this.appRoutes = this.$route.meta.breadcrumbs

      this.resetButtonBar()

      if (this.$route.params && this.$route.params.locale) {
        setLocale(this, this.$route.params.locale, true)
      }

      setDocumentTitle(this)
      if (typeof this.$route.query.token !== 'undefined') {
        this.hasToken = true
      }
      if (typeof this.$route.query.accessToken !== 'undefined') {
        localStorage.setItem('accessToken', this.$route.query.accessToken)
        window.dispatchEvent(new Event('accessTokenChange'))
        // remove access token from url
        const query = Object.assign({}, this.route.query)
        delete query.accessToken
        this.$router.replace({ query })
      }
      if (this.$route.query.checkpoint) {
        this.onFinish(() => {
          runCheckpoint({ id: this.$route.query.checkpoint }, {}, {}).then(() => {
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {
                checkpoint: undefined,
              },
            })
          })
        })
      }
    },
    '$store.state.user': function (user) {
      this.generateUserAvatar(user)
    },
    'authenticated' () {
      if (this.authenticated) {
        this.fetchDefault()
      }
    },
    'webhookInputVisible' () {
      if (!this.webhookInputVisible) {
        /* The ant modal closing animation takes 200ms.
         * After that we reset webhookInput which also removes the element from the DOM because of a v-if at the top.
         * This way each modal gets a fresh initialization and all init events fire, and we get a closing animation
         * which does not get displayed if the element gets removed from the DOM right away.
         */
        setTimeout(() => {
          this.webhookInput = null
        }, 200)
      }
    },
  },
  created () {
    const manifest = document.querySelector('#manifest')
    // get manifest
    fetch('/manifest.json')
      .then(response => response.json())
      .then(response => {
        if (!response.name) {
          throw new Error('No manifest found')
        }
        manifest.setAttribute('href', '/manifest.json')
      })
      .catch(() => {
          manifest.setAttribute('href', apiLink(['manifest'], this.$store.state.currentSystem))
        }
      )

    // fetch default if authenticated
    if (this.authenticated) {
      this.fetchDefault()
    }

    const accessTokenChange = () => {
      this.authenticated = isAuthenticated()
    }
    window.addEventListener('accessTokenChange', accessTokenChange)
    window.addEventListener('storage', () => {
      accessTokenChange()
      this.$store.commit('initialiseStore', { brezel: this.$brezel })
    })

    this.initPWAinstalltion()
  },
  mounted () {
    // register resizing event
    window.addEventListener('resize', () => {
      this.windowInnerHeight = window.innerHeight

      this.screenIsMobile = isScreenMobile()
    })

    EventBus.$on('redirect', location => {
      location.path = '/' + this.$store.state.currentLocale
      if (!location.target || location.target === '_self') {
        if (location.queryParams) {
          location.query = location.queryParams
        }

        this.$router.push(location).catch((e) => {
          if (e.name === 'NavigationDuplicated') {
            this.showRouterView = false
            this.$nextTick(() => {
              this.showRouterView = true
            })
          }
        })
      } else {
        let routeData = this.$router.resolve(location)
        let queryAaString = location.queryParams ? '?' + new URLSearchParams(location.queryParams).toString() : ''
        window.open(routeData.href + (queryAaString || ''), location.target)
      }
    })
    EventBus.$on('redirectHome', () => {
      window.location.href = '/' + this.$store.state.currentLocale
    })
    EventBus.$on('webhook-input', e => {
      this.setWebhookInput(e)
    })
    EventBus.$on('open-buffer', e => {
      this.setFileView(e)
    })
    EventBus.$on('refresh', () => {
      window.history.go()
    })
    EventBus.$on('showErrorOrInformation', args => {
      this.showErrorOrInformation(args[0], args[1])
    })

    document.addEventListener('keydown', $event => {
      const now = new Date()
      if ($event.key === 'Shift' || $event.key === 'ShiftLeft') {
        if (this.shiftKeyInfo.buffer) {
          if (now - this.shiftKeyInfo.lastPressTime < 250 && this.shiftKeyInfo.up) {
            this.quickActionsVisible = true
            this.shiftKeyInfo.buffer = false
            this.shiftKeyInfo.lastPressTime = null
            this.$forceUpdate()
            this.$nextTick(() => {
              if (this.$refs.quickAction && this.$refs.quickAction[0]) {
                this.$refs.quickAction[0].$el.focus()
              }
            })
          } else {
            this.shiftKeyInfo.lastPressTime = now
            this.shiftKeyInfo.up = false
          }
        } else {
          this.shiftKeyInfo = {
            buffer: true,
            lastPressTime: now,
            up: false,
          }
        }
      }
    })
    document.addEventListener('keyup', $event => {
      if (($event.key === 'Shift' || $event.key === 'ShiftLeft') && this.shiftKeyInfo.buffer) {
        this.shiftKeyInfo.up = true
      }
    })

    if (this.$router.currentRoute.name === 'localeRoot') {
      this.goToStart()
    }
  },
  updated () {
    this.$nextTick(() => {
      if (this.$refs.moreButton !== undefined && this.dropdownNeedsFirstPaint) {
        this.dropdownNeedsFirstPaint = false
        this.$nextTick(this.createDropdown())
      }
    })
  },
  methods: {
    activateFullscreen () {
      document.documentElement.requestFullscreen()
    },
    checkButtonVisibility (firstLoad) {
      return false
    },
    clearWorkboxCaches () {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName)
        })
        EventBus.$emit('redirectHome')
      })
    },
    commandNavigateDown (index) {
      if (index !== (this.$keymap.getAvailableCommands().length - 1)) {
        this.$refs.quickAction[index + 1].$el.focus()
      }
    },
    commandNavigateUp (index) {
      if (index !== 0) {
        this.$refs.quickAction[index - 1].$el.focus()
      }
    },
    createDropdown () {
      let moreButton = this.$refs.moreButton.$el
      moreButton.click()
      this.overlayClasses = 'overflow'
      this.$nextTick(() => {
        if (this.count === 1) {
          this.$nextTick(this.checkButtonVisibility(true))
        } else {
          this.count++
        }
      })
    },
    customizeAppearance () {
      // set custom icons for each client
      document.getElementById('favicon').href = this.$systemPath('/favicon.ico')
      document.getElementById('mask-icon').href = this.$systemPath('/safari-pinned-tab.png')
      document.getElementById('apple-touch-icon').href = this.$systemPath('/apple-touch-icon.png')
      document.getElementById('tile-image').content = this.$systemPath('/mstile.png')

      // set custom colors for each client
      document.getElementById('mask-icon').setAttribute('color', this.$store.state.color)
      document.getElementById('msapplication-TileColor').setAttribute('content', this.$store.state.color)

      // set custom title for each client
      document.title = this.$store.state.systemName
    },
    deactivateFullscreen () {
      document.exitFullscreen()
    },
    exportLink,
    executeCommands (commands) {
      this.quickActionsVisible = false
      this.$keymap.executeCommands([commands])
    },
    fetchDefault () {
      this.loading = true
      const fetches = []

      // general Information
      fetches.push(this.fetchGeneral())

      // modules
      fetches.push(this.fetchModules())

      // license
      fetches.push(this.fetchLicense())

      // translations
      this.loadingReasons.translations = true
      fetches.push(loadLanguagesAsync().then(() => {
          this.loadingReasons.translations = false
          this.$forceUpdate()
        })
      )

      // styles
      this.loadingReasons.styles = true
      fetches.push(loadStylesAsync().then(() => {
          this.loadingReasons.styles = false
          this.$forceUpdate()
        })
      )

      Promise.all(fetches)
        .then(() => {
          setTimeout(() => {
            this.loading = false
            this.processFinishQueue()
          }, 100)
        })
        .catch(this.reactToApiDown)
    },
    onFinish (func) {
      if (!this.loading) {
        func()
        return
      }
      this.finishQueue.push(func)
    },
    processFinishQueue () {
      this.finishQueue.forEach(func => {
        func()
      })
      this.finishQueue = []
    },
    fetchGeneral () {
      this.loadingReasons.general = true
      return apiGet(['general'])
        .then(response => response.json())
        .then((general) => {
          store.commit('setSystemName', general.client.brezel_name)
          store.commit('setDescription', general.client.description)
          store.commit('setColor', general.client.color)
          store.commit('setBrezelInfo', general.info)
          store.commit('setUserInfo', general.me)
          store.commit('setSidebar', general.me.sidebar !== false)
          store.commit('setClient', general.client)

          if (general.me) {
            this.generateUserAvatar(general.me)

            this.$keymap.keymap = general.me.keymap || []

            if (general.me.start && this.$route.name === 'localeRoot') {
              this.$router.replace(general.me.start)
            }
          }

          if (general.info.spa.menuWidthDesktop) {
            this.menuWidthDesktop = general.info.spa.menuWidthDesktop
          }

          if (general.info.spa.defaultLocale) {
            setLocale(general.info.spa.defaultLocale)
          }

          this.showLicense = general.licensePlansDefined

          this.customizeAppearance()
          this.initBrotcast()
          this.loadingReasons.general = false
          this.$forceUpdate()
        })
    },
    fetchLicense () {
      return Api.fetchCurrentPlan().then(json => {
        store.commit('setLicenseInfo', json)
      })
    },
    generateUserAvatar (user) {
      if (user.avatar) {
        this.setUserAvatar(user)
      } else {
        this.createUserAvatar(user)
      }
    },
    setUserAvatar (user) {
      apiGet(['files', user.avatar.id], {
        'size': 'default',
      })
        .then(response => response.blob())
        .then(response => {
          this.userAvatar = URL.createObjectURL(response)
        })
    },
    createUserAvatar (user) {
      this.userAvatar = createAvatar(style, {
        seed: user.avatar_seed,
        dataUri: true,
      })
    },
    fetchMenu () {
      apiGet(['menus'])
        .then(response => response.json())
        .then((responseJson) => {
          let defaultModuleIdentifier = 'calendar'
          if (responseJson.length === 0) {
            this.menuItems = [
              {
                identifier: 'calendar',
                icon: 'calendar',
                key: 'calendar',
                children: [],
              },
              ...this.modules,
            ]
          } else {
            this.menuItems = responseJson
            const defaultModule = this.menuItems.find(item => item.default)
            if (defaultModule) {
              defaultModuleIdentifier = defaultModule.identifier
            }
          }
          this.$store.commit('setDefaultModule', defaultModuleIdentifier)
          if (this.$router.currentRoute.name === 'localeRoot') {
            this.goToStart()
          }
          this.menuLoaded = true
        })
        .catch(console.error)
    },
    fetchModules () {
      this.loadingReasons.modules = true
      return Api.fetchModules(true)
        .then((modules) => {
          modules = modules.filter(item => {
            return item.parent_module_id === null
          })
          this.modules = []
          this.$nextTick(function () {
            this.modules = modules
            this.menuKey += 1

            // menu
            this.fetchMenu()
          })
          this.loadingReasons.modules = false
          this.$forceUpdate()
        })
        .catch(this.reactToApiDown)
    },
    goToStart () {
      this.$router.push(this.$store.getters.startPage)
    },
    fileLoaded () {
      this.fileLoadCallback(this.$refs.fileView)
    },
    getBreadcrumbText (breadcrumbName) {
      return evaluateExpressionItem(breadcrumbName, this)
    },
    getButtonStyle () {
      if (this.$store.state.menuCollapsed || this.screenIsMobile) {
        return 'menu-unfold'
      }
      return 'menu-fold'
    },
    getLayoutClasses () {
      if (this.screenIsMobile || !this.hasSidebar) {
        return ''
      }
      if (this.$store.state.menuCollapsed) {
        return 'collapsed-sider'
      }
      return 'open-sider'
    },
    getLayoutStyles () {
      if (this.screenIsMobile || this.$store.state.menuCollapsed || !this.hasSidebar) {
        return ''
      }
      return 'margin-left: ' + this.menuWidthDesktop + 'px'
    },
    goToTrash () {
      this.$router.push('/' + this.$store.state.currentLocale + '/trash/' + this.$route.params.module_identifier)
    },
    handleDelete (recordId) {
      apiDelete(['modules', this.$route.params.module_identifier, 'resources', recordId])
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.$router.push('/' + this.$store.state.currentLocale + '/' + this.$route.params.module_identifier)
          }
        })
        .catch(console.error)
    },
    initBrotcast () {
      brotcast.init({
        apiUrl: store.getters.apiUrl,
        wsHost: store.state.brezelInfo.brotcast.host,
        wsPort: store.state.brezelInfo.brotcast.port,
        key: store.state.brezelInfo.brotcast.key,
        client: store.getters.system,
        system: store.state.brezelInfo.brotcast.system,
        accessToken: localStorage.getItem('accessToken'),
      })
      this.listenToBrotcastChannels()
    },
    listenToBrotcastChannels () {
      brotcast.ready().then(() => {
        brotcast.userChannel(this.$store.state.userInfo).listen('.notification.toast', data => {
          handleToast(data)
        })
      })
    },
    installed () {
      return ('standalone' in window.navigator && window.navigator.standalone)
    },
    installable () {
      return !this.installed() && installPrompt
    },
    installableiOS () {
      return !this.installed() && this.isiOSandSupportsPWA()
    },
    isiOSandSupportsPWA () {
      return 'serviceWorker' in navigator && !installPrompt && !!navigator.platform.match(/iPhone|iPod|iPad/)
    },
    displayiOSInstallHelp () {
      this.iosInstallHelpVisible = true
    },
    initPWAinstalltion () {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        installPrompt = e
      })
      this.installer = () => {
        installPrompt.prompt()
        installPrompt.userChoice.then(result => {
          if (result.outcome === 'accepted') {
            console.info('PWA installation accepted')
          } else {
            console.warn('PWA installation denied')
          }
          installPrompt = null
        })
      }
    },
    logout () {
      this.$brezel.logout()
    },
    closeInfo () {
      this.infoVisible = false
    },
    onCloseMobileMenu () {
      this.mobileMenuVisible = false
    },
    onCloseOptions () {
      this.optionsVisible = false
    },
    onCloseSearch () {
      this.searchVisible = false
    },
    onSearch () {
      this.searchValue = document.getElementById('searchInput').value
      if (this.searchValue !== '') {
        let url = new URL(apiLink(['search'], store.state.currentSystem))
        let urlParams = {
          searchParams: this.searchValue,
        }
        Object.keys(urlParams).forEach(key => url.searchParams.append(key, urlParams[key]))
        apiGet(url)
          .then(response => response.json())
          .then((responseJson) => {
            if (responseJson.searchParams === this.searchValue) {
              delete responseJson.searchParams
              this.searchResults = responseJson
            }
          })
      } else {
        this.searchResults = []
      }
    },
    openInfo () {
      this.infoVisible = true
    },
    openMobileMenu () {
      this.mobileMenuVisible = true
    },
    openOptions () {
      this.optionsVisible = true
    },
    openSearch () {
      this.searchVisible = true
      this.$nextTick(() => document.getElementById('searchInput').focus())
    },
    parseCommand (command) {
      if (Array.isArray(command)) {
        return command
      }
      return command.split('+')
    },
    replaceBreadcrumbs (string, routes, store) {
      for (let x in routes) {
        if (routes[x].breadcrumbName !== undefined && routes[x].breadcrumbName.charAt(0) === ':') {
          let replaceWith = ''
          const expressionResult = evaluateExpressionItem(routes[x].breadcrumbName, this, 'id')
          if (expressionResult) {
            replaceWith = expressionResult
          }
          string = string.replace(routes[x].breadcrumbName, replaceWith)
        }
      }
      return string
    },
    resetButtonBar () {
      this.buttons = []
      this.dropdownNeedsFirstPaint = false
      this.count = 0
    },
    setFileView (file) {
      if (file.url && file.type && file.name) {
        this.fileURL = file.url
        this.fileType = file.type
        this.fileName = file.name
        this.fileViewVisible = true
        this.fileLoadCallback = file.onLoad
      }
    },
    setWebhookInput (webhook) {
      this.webhookInput = webhook
      this.webhookInputVisible = true
    },
    showErrorOrInformation (isError, type) {
      this.errorOrInformationIsError = isError
      this.errorOrInformationShown = true
      this.errorOrInformationType = type
    },
    reactToApiDown (error) {
      console.error(error)
      this.showErrorOrInformation(false, 503)
    },
    removeErrorMessageOnSuccessfulLogin () {
      this.errorOrInformationShown = false
    },
    tokenError () {
      this.hasToken = false
      handleToast({
        toast_type: 'error',
        message: this.$t('errors.401_password_request_find_message'),
        description: this.$t('errors.401_password_request_find_description'),
      })
    },
    changeLocale (locale) {
      setLocale(this, locale, true)
    },
  },
}
</script>

<style lang="less">
.example {
  @import '../themes/example';
}

.ant-card {
  border-radius: 0;
}
</style>

<style lang="scss">
@import "../styles/wysiwyg";
@import "../styles/code_editor";
</style>

<style lang="scss">
@mixin scrollbars($size, $radius, $base-color, $foreground-color: mix($base-color, black, 75%), $background-color: mix($base-color, white,  50%)) {
  // For Google Chrome
  & *::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  & *::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $radius;
  }

  & *::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: $radius;
  }

  // For Internet Explorer and Firefox
  & * {
    scrollbar-face-color: $foreground-color !important;
    scrollbar-track-color: $background-color !important;
    scrollbar-width: thin !important;
    scrollbar-color: $foreground-color $background-color !important;
  }
}

html, body {
  @include scrollbars(8px, 5px, lightgrey);
}

body {
  position: relative;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  overflow-x: hidden;
}

.top-menu-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  line-height: 24px;
}

.locale-switch {
  display: inline-block;
  margin-right: 16px;
  font-size: 0.85rem;
}

.locale-switch a {
  color: rgba(0, 0, 0, 0.65);
}

.locale-switch .locale-title {
  padding-left: 8px;
}

.trash-button {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.85rem;
  margin-right: 16px;
}

.routerView .ant-spin {
  display: block;
  padding-top: 24px;
}

.layoutContent {
  margin: 12px 12px 0;
  overflow: initial;
}

.routerView {
  background: #fff;
  width: 100%;
  padding: 16px 24px 24px;
}

.webhook-modal .ant-modal-body {
  height: auto !important;
}

.file-modal .ant-modal {
  padding: 8px 0;
}

.quick-actions-modal .ant-list-item {
  cursor: pointer;
  transition: .3s;
  padding: 8px 14px;

  &:hover, &:focus {
    background: #e6f7ff;
  }
}

.license-warning {
  padding: 8px 24px;
  background: red;
  margin-bottom: 12px;
  font-size: 18px;
  color: white;
  opacity: 0.5;
}
.license-warning.trial {
  background: #1890ff;
}
.license-warning a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  .layoutContent {
    margin: 0;
  }

  .routerView {
    padding: 16px;
  }
}

.visible-xs {
  display: none;
}

@media screen and (max-width: 576px) {
  // ANTD XS
  .hide-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
  .xs-block {
    display: block !important;
  }
  .drawer-xs .ant-drawer-content-wrapper {
    min-width: 250px;
  }
  .fixedMenuBarButton span {
    display: none;
  }
  .td-left-xs {
    width: 50% !important;
    white-space: normal !important;
    word-break: break-word;
  }
  .td-right-xs {
    vertical-align: top;
    word-break: break-word;
  }
  .module-show .dateRange .ant-calendar-picker-input {
    padding: 0;
    border: none;
  }
  .module-show .dateRange .ant-calendar-range-picker-separator {
    display: none;
  }

  .module-show .dateRange .ant-calendar-range-picker-input {
    width: 100%;
    text-align: left;
    padding: 0;
    height: 21px;
  }
}

.visible-xs {
  display: none;
}

@media screen and (max-width: 576px) {
  // ANTD XS
  .hide-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
  .xs-block {
    display: block !important;
  }
  .drawer-xs .ant-drawer-content-wrapper {
    min-width: 250px;
  }
  .fixedMenuBarButton span {
    display: none;
  }
  .td-left-xs {
    width: 50% !important;
    white-space: normal !important;
    word-break: break-word;
  }
  .td-right-xs {
    vertical-align: top;
    word-break: break-word;
  }
  .module-show .dateRange .ant-calendar-picker-input {
    padding: 0;
    border: none;
  }
  .module-show .dateRange .ant-calendar-range-picker-separator {
    display: none;
  }

  .module-show .dateRange .ant-calendar-range-picker-input {
    width: 100%;
    text-align: left;
    padding: 0;
    height: 21px;
  }
  .locale-switch {
    margin-right: 8px;
  }
  .locale-switch .locale-title {
    display: none;
  }
  .trash-button {
    margin-right: 8px;
  }
}

@media screen and (max-width: 768px) {
  // ANTD XS+MD
  .routerView {
    padding: 0;

    .ant-card-wider-padding {
      .ant-card-head {
        padding: 0 16px;
        min-height: auto;

        .ant-card-head-title {
          padding: 8px 0;
        }
      }

      .ant-card-body {
        padding: 8px 16px;
      }
    }
  }
}

.searchDrawer .ant-drawer-content .ant-drawer-body {
  padding: 0;
  height: calc(100% - 56px);
}

.mobileDrawer {
  .ant-drawer-content {
    padding-top: env(safe-area-inset-top);

    .ant-drawer-body {
      padding: 0;
      height: calc(100% - 12px);
    }
  }

  .ant-drawer-wrapper-body {
    overflow-x: hidden;
    position: relative;
  }

  .ant-drawer-content-wrapper {
    transform: translateX(calc(-100% - env(safe-area-inset-left))) !important;
  }

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      transform: translateX(0) !important;
    }
  }
}

.changeLanguageDrawer .ant-drawer-content .ant-drawer-body {
  padding: 0;
}

.ant-layout-sider-children {
  overflow-y: scroll;
  padding-bottom: 24px;
}

.optionsDrawer {
  .ant-drawer-content {
    padding-top: env(safe-area-inset-top);

    .ant-drawer-body {
      padding: 0;
      height: calc(100% - 55px);
    }
  }

  .optionsContent {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-drawer-content-wrapper {
    right: env(safe-area-inset-right) !important;
    transform: translateX(calc(100% + env(safe-area-inset-left))) !important;
  }

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      transform: translateX(0) !important;
    }
  }
}

.userActions .ant-divider-vertical {
  margin: 0;
}

.searchResults {
  padding: 10px;
  overflow-scrolling: touch;
  overflow-y: scroll;
  height: calc(100vh - 116px);
}

.affixButtonBar {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 10px 8px -8px rgba(0, 0, 0, 0.15);
}

.fixedMenuBarButton {
  padding: 8px;
}

.hidden {
  display: none !important;
}

.overflow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: white;
}

.overflow .fixedMenuBarButton button {
  width: 100%;
  text-align: left;
}

.affixButtonBar .fixedMenuBarButton span:empty {
  margin-left: 0 !important;
}

.ant-table-selection-col {
  width: 40px !important;
}

.affixButtonBar .fixedMenuBarButton span:empty {
  margin-left: 0 !important;
}

.ant-table-selection-col {
  width: 40px !important;
}

.menu-logo {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  margin: 12px auto;
  min-height: 50px;
  max-height: 100px;
  max-width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout {
  transition: all 200ms;

  &.collapsed-sider {
    margin-left: 80px;
  }
}

.menu-item-danger {
  color: red !important;
}

button.ant-btn {
  font-size: 0.875rem;
}

.ant-btn .anticon {
  height: 1em;
  width: 1em;
}

.usernameForPrinting {
  display: none;
  white-space: nowrap;
}

.ant-timeline-item-content {
  top: -.375rem
}

.brezel-history {
  padding: 0px;
}

.ant-popover-message-title {
  padding-left: 1.25rem;
}

.ant-timeline-item-content {
  top: -.375rem
}

.brezel-history {
  padding: 0px;
}

.ant-popover-message-title {
  padding-left: 1.25rem;
}

@media print {
  * {
    overflow: visible !important;
  }

  html {
    height: auto !important;

    body {
      height: auto !important;

      .ant-layout-sider {
        visibility: hidden;
      }

      .ant-menu,
      .breadcrumb-bar,
      .button-bar,
      .button-component,
      .view-filters,
      .view-print-action {
        display: none !important;
      }

      .usernameForPrinting {
        display: block;
        text-align: right;
      }

      .ant-layout {
        background: white !important;
        min-height: 0 !important;

        &.open-sider, &.collapsed-sider {
          margin-left: 0 !important;
        }

        .layoutContent {
          margin: 0;

          .routerView {
            padding: 0;

            .layout-row {
              margin: 0 !important;
            }

            .layout-col {
              padding: 0 !important;
            }
          }
        }
      }

      a {
        color: black !important;
      }

      .ant-table {
        color: black;
      }
    }
  }
}

.home-button {
  cursor: pointer;
  color: #1890ff;
}

.ant-popover-buttons {
  display: flex;
}

span.avatar.ant-avatar.ant-avatar-circle.ant-avatar-image {
  outline: 1px solid rgba(0, 0, 0, 0.5);
}
</style>
