<template>
  <div
    ref="container"
    class="resource-table-component"
  >
    <layout-component-headline
      v-if="component && component.options.identifier"
      :component="component"
      :translation-prefix="translationPrefix"
    />
    <a-affix
      v-if="tableBarVisible"
      ref="tableBar"
      :target="barTarget"
      style="margin-left: 40px"
    >
      <a-row
        v-if="selectable && bulkActions"
        class="resource-table-bar"
        style="padding: 8px"
        type="flex"
        justify="start"
      >
        <layout-component-buttons
          style="transition: opacity 0.1s"
          v-bind="$props"
          :disabled="selectedRowKeys.length === 0"
          :component="bulkActions"
          :selections="selectedRowKeys"
          size="small"
          @event="anyEvent"
          @delete="bulkDelete"
        />
      </a-row>
    </a-affix>
    <a-table
      ref="antTable"
      :bordered="true"
      :custom-row="customRow"
      :data-source="data"
      :expand-row-by-click="true"
      :loading="loading"
      :pagination="false"
      :row-key="record => record.id"
      :row-selection="selectable ? { selectedRowKeys: selectedRowKeys, onSelect: changeLayoutSelection, onSelectAll: changeLayoutSelectionMulti } : null"
      :scroll="{ x: true }"
      size="middle"
      @change="handleTableChange"
    >
      <a-table-column
        v-for="fieldColumn in fieldColumns"
        :key="fieldColumn.key"
        :data-index="fieldColumn.key"
        :title="getTitleFor(fieldColumn)"
        :filter-dropdown-visible="filterVisible === fieldColumn"
        :sorter="fieldColumn.isSortable"
        :filtered-value="fieldColumn.filter"
        :sort-order="sorter && sorter.columnKey === fieldColumn.key ? sorter.order : false"
        :width="(fieldColumn.field.options || {}).column_width"
        @filterDropdownVisibleChange="visible => onFilterDropDown(visible)"
      >
        <template slot-scope="text, record, index">
          <div
            class="cell"
            :style="(fieldsThatIgnoreRowStyling.includes(fieldColumn.field.type) ? '' : rows[index].settings.style) + ';' + ((fieldColumn.cells[index] || {}).settings || {}).style"
            :has-custom-cell-style="!!((fieldColumn.cells[index] || {}).settings || {}).style"
            v-bind="(fieldColumn.cells[index] || {}).settings"
          >
            <div
              class="content"
              style="width: 100%;"
            >
              <a-popover
                v-if="fieldColumn.hasPopover"
                :title="getTranslatedTitleFor(fieldColumn)"
                placement="topLeft"
              >
                <template slot="content">
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    v-if="fieldColumn.hasBasePopover"
                    v-html="$t(`${translationPrefix}.popovers.${fieldColumn.key}`)"
                  />
                  <div v-else-if="fieldColumn.hasLegendPopover">
                    <ul style="margin-bottom: 0; padding-left: 0; list-style: none;">
                      <li
                        v-for="choice in fieldColumn.fieldChoices"
                        :key="choice"
                      >
                        <img
                          :src="$systemPath(`/icons/${fieldColumn.moduleIdentifier}/${fieldColumn.key}/${choice}.png`)"
                          :alt="choice"
                          style="height: 0.875rem; position: relative; top: 0.125rem; padding-right: 0.125rem;"
                        >
                        <strong
                          v-if="choice === record[fieldColumn.key]"
                          v-text="tr(`${translationPrefix}.choice.${fieldColumn.key}.${choice}`, true)"
                        />
                        <span
                          v-else
                          v-text="tr(`${translationPrefix}.choice.${fieldColumn.key}.${choice}`, true)"
                        />
                      </li>
                    </ul>
                  </div>
                </template>
                <component
                  :is="getFieldCellComponent(fieldColumn.field.type)"
                  :field="fieldColumn.field"
                  :value="record[fieldColumn.key]"
                  :cell="fieldColumn.cells[index]"
                  :parent-cell-has-styling="!!((fieldColumn.cells[index] || {}).settings || {}).style"
                  :parent-cell-style-string="(fieldsThatIgnoreRowStyling.includes(fieldColumn.field.type) ? '' : rows[index].settings.style) + ';' + ((fieldColumn.cells[index] || {}).settings || {}).style"
                  style="height: 40px; display: flex; align-items: center"
                />
              </a-popover>
              <component
                :is="getFieldCellComponent(fieldColumn.field.type)"
                v-else
                :field="fieldColumn.field"
                :value="record[fieldColumn.key]"
                :cell="fieldColumn.cells[index]"
                :parent-cell-has-styling="!!((fieldColumn.cells[index] || {}).settings || {}).style"
                :parent-cell-style-string="(fieldsThatIgnoreRowStyling.includes(fieldColumn.field.type) ? '' : rows[index].settings.style) + ';' + ((fieldColumn.cells[index] || {}).settings || {}).style"
                style="height: 40px; display: flex; align-items: center"
              />
            </div>
          </div>
        </template>
        <div
          slot="filterDropdown"
          ref="filterDropdown"
          slot-scope="{ setSelectedKeys, confirm, clearFilters, column }"
          :selected="filterVisible === fieldColumn"
          class="custom-filter-dropdown"
        >
          <a-input
            v-ant-ref="c => searchInput = c"
            :placeholder="`${$t('_.search')} ${column.title}`"
            :value="fieldColumn.filter ? fieldColumn.filter[0] : null"
            style="width: 208px; margin-bottom: 8px; display: block;"
            @change="e => {fieldColumn.filter = e.target.value ? [e.target.value] : []; setSelectedKeys(fieldColumn.filter)}"
            @pressEnter="() => handleSearch(fieldColumn.filter, confirm)"
          />
          <a-button
            size="small"
            style="width: 100px; margin-right: 8px"
            type="primary"
            @click="() => {setSelectedKeys(fieldColumn.filter); handleSearch(fieldColumn.filter, confirm)}"
          >
            <a-icon type="search"/>
            {{ $t('_.searching') }}
          </a-button>
          <a-button
            size="small"
            style="width: 100px"
            @click="() => {fieldColumn.filter = []; setSelectedKeys(fieldColumn.filter); handleReset(clearFilters)}"
          >
            {{ $t('_.reset') }}
          </a-button>
        </div>
        <a-icon
          v-if="!fieldColumn.filterHidden"
          slot="filterIcon"
          slot-scope="filtered"
          :data-filtered="filtered"
          :class="'ant-table-filter-icon ant-dropdown-trigger' + (Array.isArray(fieldColumn.filter) && fieldColumn.filter.length > 0 ? ' --filtered' : '') + (fieldColumn.filterHidden ? ' --hidden' : '')"
          type="search"
          @click.stop="toggleFilter(fieldColumn)"
        />
      </a-table-column>
      <a-table-column
        v-if="!component || (component && !component.options.hide_crud_buttons) || (component && buttons && buttons.length > 0)"
        key="action"
        fixed="right"
      >
        <span
          slot="title"
          class="hide-xs"
        >
          {{ $t('_.actions') }}
        </span>
        <template slot-scope="text, record">
          <div
            class="actions"
          >
            <template v-if="(!component || !component.options.hide_crud_buttons)">
              <router-link
                v-if="canShow(record) && !isTrash"
                ref="view"
                :to="'/' + $store.state.currentLocale + '/' + moduleIdentifier + '/' + record.id"
              >
                <a-badge :dot="record._unseen === 1">
                  <a-button size="small">
                    <a-icon type="eye"/>
                  </a-button>
                </a-badge>
              </router-link>
              <span
                v-if="canCopy(record)"
                class="hide-xs"
              >&nbsp;</span>
              <router-link
                v-if="canCopy(record)"
                :to="'/' + $store.state.currentLocale + '/' + moduleIdentifier + '/' + record.id + '/copy'"
              >
                <a-button
                  size="small"
                  type="secondary"
                  class="hide-xs"
                >
                  <a-icon
                    type="copy"
                  />
                </a-button>
              </router-link>
              <span v-if="canShow(record) && canEdit(record)">&nbsp;</span>
              <router-link
                v-if="canEdit(record) && !isTrash"
                :to="'/' + $store.state.currentLocale + '/' + moduleIdentifier + '/' + record.id + '/edit'"
              >
                <a-button
                  size="small"
                  type="primary"
                  class="hide-xs"
                >
                  <a-icon
                    type="edit"
                  />
                </a-button>
              </router-link>
              <span
                v-if="canEdit(record) && canDelete(record)"
                class="hide-xs"
              >&nbsp;</span>
              <a-button
                v-if="canEdit(record) && canDelete(record) && isTrash"
                size="small"
                type="primary"
                class="hide-xs"
                @click="handleRestore(record)"
              >
                <a-icon type="reload"/>
              </a-button>
              <a-popconfirm
                v-if="canDelete(record)"
                :title="$t('_.are_you_sure')"
                placement="left"
                class="hide-xs"
                @confirm="handleDelete(record)"
              >
                <a-button
                  size="small"
                  type="danger"
                >
                  <a-icon type="delete"/>
                </a-button>
              </a-popconfirm>
            </template>
            <template v-if="component && !isTrash">
              <span
                v-for="button in buttons"
                :key="button.frontendIndex"
              >
                <a-popconfirm
                  v-if="canDelete(record) && button.action === 'delete'"
                  :title="$t('_.are_you_sure')"
                  placement="left"
                  @confirm="handleDelete(record)"
                >
                  <a-button
                    size="small"
                    :type="button.type ? button.type : 'primary'"
                    style="margin-left: 2px; margin-right: 2px"
                  >
                    <a-icon
                      v-if="button.icon"
                      :type="button.icon"
                    />
                    <span v-if="button.title">
                      {{ $t('buttons.' + button.title) }}
                    </span>
                  </a-button>
                </a-popconfirm>
                <a-button
                  v-else
                  size="small"
                  :type="button.type ? button.type : 'primary'"
                  style="margin-left: 2px; margin-right: 2px"
                  @click="handleButtonClick(button, record)"
                >
                  <a-icon
                    v-if="button.icon"
                    :type="button.icon"
                  />
                  <span v-if="button.title">
                    {{ $t('buttons.' + button.title) }}
                  </span>
                </a-button>
              </span>
            </template>
          </div>
        </template>
      </a-table-column>
      <template
        slot="footer"
        slot-scope="currentPageData"
      >
        <a-row>
          <a-col
            :xs="0"
            :sm="0"
            :md="12"
            :lg="12"
          >
            {{ $t('_.page') }} {{ pagination.current ? pagination.current : 1 }}

            <span
              v-if="selectedRowKeys.length > 0"
            >
              | {{ $t('_.selected_x_entries', { x: selectedRowKeys.length }) }}
            </span>
          </a-col>
          <a-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :style="{'text-align': 'right'}"
          >
            {{ $t('_.showing_x_out_of_y_entries', { x: currentPageData.length, y: pagination.total }) }}
            <a-divider type="vertical"/>
            <a-input-number
              v-model="pagination.pageSize"
              :min="1"
              :precision="0"
              size="small"
              @blur="changePageSize"
              @pressEnter="$event.target.blur()"
            />
            / {{ $t('_.page') }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24"/>
        </a-row>
      </template>
    </a-table>
    <a-row
      ref="tableFooter"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <a-col
        flex="1px"
        class="entityTableReload"
      >
        <a-button
          type="link"
          @click="fetchResourcesWithParams(pagination.pageSize, pagination.current, filters, sorter)"
        >
          <a-icon type="reload"/>
        </a-button>
      </a-col>
      <a-col flex="1px">
        <a-button
          v-if="resettable"
          type="primary"
          size="small"
          @click="resetTableSettings()"
        >
          <a-icon type="filter"/>
          {{ $t('_.reset_table_settings') }}
        </a-button>
      </a-col>
      <a-col
        v-if="error"
        flex="auto"
      >
        An error occurred. Please refresh the table.
      </a-col>
      <a-col
        flex="auto"
        style="text-align: right"
      >
        <a-pagination
          v-if="pagination.current !== undefined"
          :current="pagination.current"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          size="small"
          show-less-items
          @change="changePageTo"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  apiLink,
  formatFieldOutputValue,
  guessSystem,
  handleResponseError,
  isNotEmptyObject,
  isScreenMobile,
  shouldShow,
} from '@/utils'
import store from '@/store'
import * as guard from '@/guard'
import { EventBus } from '@/event-bus'
import { fireWebhookEvent } from '@/webhooks'
import striptags from 'striptags'
import Fields from '@/components/fields'
import _, { get, isEqual, debounce } from 'lodash'
import Field from '@/layout/field'
import { apiDelete, apiPatch, apiPut } from '@/api'
import FieldColumn from '@/layout/column'
import FieldCalculator from '@kibro/brezel-recipes/src/field-calculator'
import TableRow from '@/layout/row'
import Module from '@/module/module'
import HasPreFilters from '@/components/HasPreFilters'
import { apiGet } from '../api'

export default {
  name: 'ResourceTableComponent',
  mixins: [Fields, HasPreFilters],
  props: {
    component: {
      type: Object,
      default: undefined,
    },
    module: {
      type: Object,
      default: undefined,
    },
    resource: {
      type: Object,
      default: undefined,
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    maxSelections: {
      type: Number,
      default: undefined,
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
    elementIdentifier: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data () {
    return {
      allowedOperations: null,
      blockClick: false,
      data: [], // raw table data
      fieldColumns: [], // FieldColumn objects
      rows: [], // TableRow objects
      filters: null,
      loading: false,
      moduleIdentifier: (this.component ? this.component.options.module : this.module.identifier),
      pagination: {},
      preFilters: {},
      // rowsPerPage: 15,
      searchInput: null,
      selectedResources: [], // The resources that were selected
      sorter: null,
      isScreenMobile: isScreenMobile(),
      filterVisible: null,
      recipeCalculator: null,
      isTrash: false,
      fieldsThatIgnoreRowStyling: [],
      resettable: false,
      tableBarVisible: false,
      error: null,
      debouncedReload: debounce(() => this.fetchResources(), 1000),
    }
  },
  computed: {
    componentFilters () {
      return this.component.options.pre_filters ?? this.component.options.filters
    },
    selectable () {
      return !this.component || (this.component && this.component.options.selectable !== false)
    },
    // The selection value that is used by the ant-design table
    selectedRowKeys () {
      return this.selectedResources.map(item => item ? item.id : null)
    },
    // The value of selected items that is sent by events
    selectedValue () {
      return this.selectedRowKeys
    },
    bulkActions () {
      let buttons = _.get(this, 'component.options.bulk_actions') || (this.canDelete({}) ? [
        {
          action: 'delete',
          title: '_.bulk_delete',
          style: 'danger',
          icon: 'delete',
          popconfirm: 'are_you_sure',
        },
      ] : [])

      if (!buttons || buttons.length === 0) {
        return false
      }

      return {
        type: 'buttons',
        options: {
          buttons: buttons,
          justify_content: 'flex-start',
        },
      }
    },
    buttons () {
      return (this.component.options.buttons || []).map((component, index) => {
        component.frontendIndex = index
        return component
      }).filter(component => shouldShow(component, this.component.mode))
    },
  },
  watch: {
    resource: {
      deep: true,
      handler: function (value) {
        if (this.component && this.componentFilters) {
          if (this.setPreFilterFieldData(value)) {
            this.debouncedReload()
          }
        }
      },
    },
    'component.options.selections': {
      deep: true,
      handler: function (value) {
        if (value) {
          this.selectedResources = value
        }
      },
    },
    data () {
      // nothing
      this.deleteEmptyFilterWrapper()
    },
    selectedResources () {
      if (this.loading) return
      if (!this.tableBarVisible && this.selectedResources.length > 0) {
        this.addTableBar()
      }
      if (this.tableBarVisible && this.selectedResources.length === 0) {
        this.removeTableBar()
      }
    },
  },
  created () {
    this.loading = true
    if (this.$route.name === 'module.trash') {
      this.isTrash = true
    }
    if (this.component) {
      if (this.componentFilters) {
        this.setPreFilterFieldData()
      }
      if (this.component.options.selections) {
        this.selectedResources = this.component.options.selections
      }
    }
    if (!this.module || !this.module.resource_table_fields) {
      const module = Module.byIdentifier(this.moduleIdentifier)
      const fields = module.fields.filter(field => field.can('READ') && field.options.show_in_resource_table !== false)
      this.createTableByFields(fields)
    } else {
      this.createTableByFields(this.module.resource_table_fields)
    }
    this.loadFilters()
    this.fetchResourcesWithParams(this.pagination.pageSize, this.pagination.current, this.filters, this.sorter)
    window.addEventListener('resize', () => {
      if (this.fieldColumns.length > 0) {
        this.checkFixedActions()
      }
    })
    window.addEventListener('click', ev => {
      this.filterVisible = null
    })
  },
  methods: {
    anyEvent ($event) {
      this.$emit('event', $event)
    },
    addTableBar () {
      if (this.$refs.container) {
        this.$refs.container.style.height = (this.$refs.antTable.$el.clientHeight + this.$refs.tableFooter.$el.clientHeight) + 'px'
        this.tableBarVisible = true
        this.$forceUpdate()
        this.$nextTick().then(() => {
          this.$refs.container.scrollTo(0, this.$refs.tableBar.$el.clientHeight)
        })
      }
    },
    removeTableBar () {
      this.tableBarVisible = false
      if (this.$refs.container) {
        this.$refs.container.style.height = 'auto'
        this.$refs.container.style.paddingTop = 0
        this.$refs.container.scrollTo(0, 0)
      }
    },
    barTarget () {
      return this.$refs.container
    },
    buildRequestUrl (params) {
      let route = ['table']
      const url = new URL(apiLink(route, store.state.currentSystem))
      let urlParams = {
        results: this.pagination.pageSize,
        ...params,
        isTrash: this.isTrash ? 1 : null,
      }

      urlParams.filters = this.parseFilters(urlParams.filters)

      if (this.component) {
        // Append data defined in resource_table component options to request
        let options = this.component.options
        urlParams = {
          module: options.module,
          pre_filters: this.preFilters,
          ...urlParams,
        }
        if (options.columns) {
          if (typeof options.columns === 'object') {
            urlParams.columns = options.columns.fetch ?? options.columns.display
          } else {
            urlParams.columns = options.columns
          }
        }
      } else {
        urlParams = {
          module: this.moduleIdentifier,
          ...urlParams,
        }
      }

      Object.keys(urlParams)
        .filter(key => urlParams[key])
        .forEach(key => {
          if (_.isObject(urlParams[key]) || _.isArray(urlParams[key])) {
            urlParams[key] = JSON.stringify(urlParams[key])
          }
          url.searchParams.append(key, urlParams[key])
        })

      return url
    },
    computeResettable () {
      let filterExists = false
      for (const col of this.fieldColumns) {
        if (Array.isArray(col.filter) && col.filter.length > 0) {
          filterExists = true
          break
        }
      }
      this.resettable = filterExists || !!((this.sorter || {}).order) || (this.pagination.pageSize && this.pagination.pageSize !== 15)
    },
    isNotEmptyObject,
    striptags,
    setupCells () {
      this.data.forEach((row, index) => {
        this.fieldColumns.forEach(fieldColumn => {
          fieldColumn.setRow(index, row)
        })

        if (this.rows[index] instanceof TableRow) {
          this.rows[index].data = row
          this.rows[index].rowIndex = index
        } else {
          this.rows[index] = new TableRow(row, index)
        }

        const tableRow = this.rows[index]

        if (this.component && _.has(this.component, 'options.row_settings')) {
          const rowSettings = this.component.options.row_settings
          tableRow.settings = Object.assign({}, rowSettings)
        }
      })
    },
    calculateRecipes () {
      this.fieldColumns.forEach(fieldColumn => {
        if (fieldColumn.settings.recipes) {
          this.data.forEach((row, index) => {
            const calculator = new FieldCalculator(row)
            for (const property in fieldColumn.settings.recipes) {
              const recipe = fieldColumn.settings.recipes[property]
              fieldColumn.cells[index].settings[property] = calculator.calculate(recipe)
            }
          })
        }
      })
      this.rows.forEach(row => {
        if (row.settings.recipes) {
          const calculator = new FieldCalculator(row.data)
          for (const property in row.settings.recipes) {
            if (row.settings.recipes.hasOwnProperty(property)) {
              const recipe = row.settings.recipes[property]
              row.settings[property] = calculator.calculate(recipe)
            }
          }
        }
      })
    },
    bulkDelete ($event, component, resolve) {
      let url = new URL(apiLink(['modules', this.moduleIdentifier, 'resources'], store.state.currentSystem))
      if ($event.setLoading) {
        $event.setLoading()
      }
      const firePromise = apiPatch(url, [], JSON.stringify({
        entities: this.selectedRowKeys,
      }), {
        'Content-Type': 'application/json',
      })
        .then(response => response.json())
        .then(response => {
          const action = response.failed.length === 0 ? 'success' : response.successful.length === 0 ? 'danger' : 'warning'
          this.$message[action](this.$t('_.x_successful_y_failed', {
            x: response.successful.length,
            y: response.failed.length,
          }))
          this.selectedResources = []
          this.removeTableBar()
          this.handleTableChange(this.pagination, this.filters, this.sorter)
        })
        .finally(() => {
          if ($event.setLoading) {
            $event.setLoading(false)
          }
        })
      if ($event.resolve) {
        $event.resolve(firePromise)
      }
      return firePromise
    },
    canCopy (entity) {
      return get(this.module.buttons, 'copy.display', false) &&
        this.canShow(entity) &&
        this.canCreate() &&
        !this.isTrash
    },
    canCreate () {
      return guard.canCreate(this.module)
    },
    canDelete (entity) {
      return entity.allowedOperations ? guard.canDelete(entity) : guard.canDelete(this.module)
    },
    canEdit (entity) {
      return entity.allowedOperations ? guard.canUpdate(entity) : guard.canUpdate(this.module)
    },
    canShow (entity) {
      return entity.allowedOperations ? guard.canRead(entity) : guard.canRead(this.module)
    },
    changePageTo (page) {
      this.pagination.current = page
      this.handleTableChange(this.pagination, this.filters, this.sorter)
    },
    changePageSize () {
      if (this.pagination.pageSize > 0 && Number.isInteger(this.pagination.pageSize)) {
        this.handleTableChange(this.pagination, this.filters, this.sorter)
      }
    },
    changeLayoutSelection (record, selected) {
      if (!Array.isArray(record)) {
        record = [record]
      }

      const ids = record.map(item => item.id)
      if (selected) {
        this.selectedResources.push(...record)
      } else {
        this.selectedResources = this.selectedResources
          .filter(item => item && item.id && ids.indexOf(item.id) === -1)
      }

      if (this.maxSelections && this.selectedResources.length > this.maxSelections) {
        this.selectedResources.splice(0, this.selectedResources.length - this.maxSelections)
      }

      if (this.component) {
        this.component.options.selections = this.selectedResources
      }
    },
    changeLayoutSelectionMulti (selected, selectedRows, changeRows) {
      this.changeLayoutSelection(changeRows, selected)
    },
    checkFixedActions () {
      this.isScreenMobile = isScreenMobile()
    },
    toggleFilter (field) {
      if (this.filterVisible !== field) {
        this.filterVisible = field
        this.onFilterDropDown(true)
      } else {
        this.filterVisible = null
      }
    },
    onFilterDropDown (visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.filterDropdown.filter(x => x.attributes.selected)[0].children[0].focus()
        }, 0)
      }
    },
    createTableByFields (fields) {
      this.fieldColumns = []
      if (store.getters.isMultiClientAndCurrentIsDefaultClient) {
        const clientId = new Field({
          'identifier': 'client_id',
          'type': 'client',
        })
        clientId.module = { identifier: this.moduleIdentifier }
        const clientColumn = new FieldColumn(clientId)
        this.fieldColumns.push(clientColumn)
      }
      let cols = fields.map(field => field.identifier)
      if (this.component && this.component.options && this.component.options.columns) {
        cols = this.component.options.columns
        if (typeof cols === 'object' && cols.hasOwnProperty('display')) {
          cols = cols.display
        }
      }
      cols.forEach(col => {
        let field
        if (typeof col === 'string' && col.includes('.')) {
          const relationFieldIdentifier = col.split('.')[0]
          const fieldIdentifier = col.split('.')[1]
          const relationField = fields.find(field => field.identifier === relationFieldIdentifier)
          const relationFieldModule = Module.byIdentifier(relationField.options.references)
          field = relationFieldModule.getField(fieldIdentifier)
          field.module = { identifier: relationFieldModule.identifier }
        } else {
          let existingField = fields.find(field => field.identifier === col)
          if (!existingField) {
            if (typeof col === 'string') {
              existingField = { identifier: col, type: 'text', value: '' }
            } else {
              existingField = col
            }
          }
          field = new Field(existingField)
          field.module = { identifier: this.moduleIdentifier }
        }
        if (field.type === 'list') {
          return
        }
        const column = new FieldColumn(field, col)
        if (this.component) {
          const columnSettings = _.get(this.component, 'options.column_settings')
          if (columnSettings) {
            column.settings = Object.assign({}, columnSettings[column.key])
          }
        }
        this.fieldColumns.push(column)
      })
    },
    customRow (record, rowIndex) {
      return {
        props: {
          style: this.rows[rowIndex].settings.style,
        },
        on: {
          click: (e) => {
            if (e.target.className === 'ant-table-selection-column') {
              return
            }
            if (this.blockClick) {
              this.blockClick = false
              return
            }
            if (e.target.localName === 'a') {
              window.open(e.target.href, '_blank').focus()
              return
            }
            if (_.get(this.component, 'options.select_on_click')) {
              const index = this.selectedRowKeys.indexOf(record.id)
              this.changeLayoutSelection(record, index === -1)
              return
            }
            if (
              (e.target.localName !== 'button') &&
              (!this.isTrash) &&
              !_.get(this.component, 'options.prevent_open_on_click')
            ) {
              this.navigateToModuleShow(record)
            }
          },
          dblclick: (e) => {
            this.blockClick = true
            this.$emit('dblclick', record)
          },
        },
      }
    },
    fetchResources (params = {}, tries = 0) {
      this.loading = true
      apiGet(this.buildRequestUrl(params))
        .then(handleResponseError)
        .then(response => response.json())
        .then(response => {
          const resource = response.resources
          const pagination = { ...this.pagination }
          pagination.pageSize = parseInt(resource.per_page)
          pagination.total = resource.total
          pagination.current = resource.current_page
          this.loading = false
          this.data = Object.freeze(resource.data)
          this.$emit('new-data', this.data)
          this.setupCells()
          this.calculateRecipes()
          this.pagination = pagination
          if (this.component && response.allowedOperations) {
            this.allowedOperations = response.allowedOperations
          }
          this.error = null
        })
        .catch((e) => {
          console.error(e)
          if (e.status !== 403) {
            this.$message.error(this.$t('_.something_went_wrong'))
            this.error = e
            this.loading = false
          }
        })
    },
    formatFieldOutputValue: formatFieldOutputValue,
    setPreFilterFieldData (value) {
      const newPreFilters = this.getFiltersWithContext(
        this.component.options.pre_filters ?? this.component.options.filters,
        value ?? this.resource,
        this.context
      )
      const changed = !isEqual(this.preFilters, newPreFilters)
      this.preFilters = newPreFilters
      return changed
    },
    getTitleFor (fieldColumn) {
      if (fieldColumn.field.options.no_title_in_entity_table === true) {
        return ''
      }
      return this.getTranslatedTitleFor(fieldColumn)
    },
    getTranslatedTitleFor (fieldColumn) {
      return this.tr(`modules.${this.moduleIdentifier}.fields.${fieldColumn.field.identifier}`, true)
    },
    handleDelete (record) {
      let url = new URL(apiLink(['modules', this.moduleIdentifier, 'resources', record.id], store.state.currentSystem))
      if (this.isTrash) {
        url = new URL(apiLink(['modules', this.moduleIdentifier, 'trash', record.id], store.state.currentSystem))
      }
      apiDelete(url)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            if (this.pagination.current !== undefined && this.pagination.current > Math.ceil((this.pagination.total - 1) / this.pagination.pageSize)) {
              this.pagination.current--
            }
            this.handleTableChange(this.pagination, this.filters, this.sorter)
            EventBus.$emit('refresh-counters')
          }
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
    handleRestore (record) {
      apiPut(['modules', this.moduleIdentifier, 'trash', record.id])
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            if (this.pagination.current !== undefined && this.pagination.current > Math.ceil((this.pagination.total - 1) / this.pagination.pageSize)) {
              this.pagination.current--
            }
            this.handleTableChange(this.pagination, this.filters, this.sorter)
            EventBus.$emit('refresh-counters')
          }
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
    handleButtonClick (button, record) {
      if (button.action === 'view') {
        this.navigateToModuleShow(record)
        return
      }
      if (button.action === 'delete') {
        this.handleDelete(record)
        return
      }
      return fireWebhookEvent(button.event,
        this.component.options.module,
        record.id,
        undefined,
        { context: this.context }
      )
    },
    handleReset (clearFilters) {
      clearFilters()
      delete this.filters[this.filterVisible.identifier]
      this.toggleFilter(this.filterVisible)
      this.fetchResources()
      this.computeResettable()
      this.saveFilters()
    },
    resetTableSettings () {
      let filtersEmpty = this.fieldColumns.every(col => !col.filters)

      if (!filtersEmpty) {
        this.loading = true
        this.fieldColumns = this.fieldColumns.map(col => ({ ...col, filter: [] }))
        this.pagination.pageSize = 15
        this.$refs.antTable.$emit('change', this.pagination, null, null)
        this.computeResettable()
      }

      this.fieldColumns.forEach(fieldColumn => {
        fieldColumn.filter = []
      })

      this.filters = {}
      this.sorter = {}
      this.handleTableChange(this.pagination, this.filters, this.sorter)
      this.computeResettable()
    },
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.toggleFilter(this.filterVisible)
    },
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current !== undefined ? pagination.current : 1
      this.pagination = pager
      this.filters = filters
      this.sorter = sorter
      this.fetchResourcesWithParams(pagination.pageSize, pagination.current, filters, sorter)
      this.computeResettable()
      this.saveFilters()
    },
    getCurrentTableStore () {
      if (this.component && this.component.options && this.component.options.remember_filters) {
        return this.$store.state.resourceTables[this.elementIdentifier] || {}
      }
      return this.$route.query.tables ? (JSON.parse(this.$route.query.tables)[this.elementIdentifier] || {}) : {}
    },
    putTableStore (data) {
      if (this.component && this.component.options && this.component.options.remember_filters) {
        this.$store.state.resourceTables[this.elementIdentifier] = data
        if (!data.filters && !data.sorter) {
          delete this.$store.state.resourceTables[this.elementIdentifier]
        }
        return
      }
      let newQuery = { ...this.$route.query }
      newQuery.tables = newQuery.tables ? (JSON.parse(newQuery.tables) || {}) : {}
      newQuery.tables[this.elementIdentifier] = data
      if (!data.filters && !data.sorter) {
        delete newQuery.tables[this.elementIdentifier]
      }
      if (Object.keys(newQuery.tables).length === 0) {
        delete newQuery['tables']
      }
      if (newQuery.tables) {
        newQuery.tables = JSON.stringify(newQuery.tables)
      }
      this.$router.replace({ query: newQuery })
    },
    saveFilters () {
      const currentTableStore = this.getCurrentTableStore()
      let filteredFilters = Object.fromEntries(Object.entries(this.filters || {}).filter(([key, value]) => value.length > 0))
      let filteredSorter = Object.fromEntries(Object.entries(this.sorter || {}).filter(([key, value]) => value.length > 0))
      if (Object.keys(filteredFilters).length === 0) {
        filteredFilters = undefined
      }
      if (Object.keys(filteredSorter).length === 0) {
        filteredSorter = undefined
      }
      currentTableStore.filters = filteredFilters
      currentTableStore.sorter = filteredSorter
      this.putTableStore(currentTableStore)
    },
    loadFilters () {
      const currentTableStore = this.getCurrentTableStore()
      this.filters = currentTableStore.filters || {}
      this.fieldColumns.forEach(fieldColumn => {
        fieldColumn.filter = this.filters[fieldColumn.identifier]
      })
      if (currentTableStore.sorter) {
        this.sorter = currentTableStore.sorter
      }
      this.computeResettable()
    },
    fetchResourcesWithParams (pageSize, currentPage, filters, sorter) {
      const params = {
        results: pageSize,
        page: currentPage,
        filters,
      }
      if (sorter !== null) {
        params['sortField'] = sorter.field
        params['sortOrder'] = sorter.order
      }
      this.fetchResources(params)
    },
    navigateToModuleShow (record) {
      this.$router.push('/' + this.$store.state.currentLocale + '/' + this.moduleIdentifier + '/' + record.id)
    },
    openFile (file) {
      EventBus.$emit('open-buffer', {
        url: file.brezel_url,
        type: file.mime_type,
        name: file.brezel_name,
      })
    },
    parseFilters (filters) {
      if (!filters) return []
      const output = []
      const module = Module.byIdentifier(this.moduleIdentifier)
      // TODO the default filter should depend on the field type
      Object.keys(filters).forEach(fieldIdentifier => {
        const field = module.getField(fieldIdentifier)
        let column = fieldIdentifier
        if (field) {
          column = field.isRelation() ? field.identifier + '.brezel_name' : field.identifier
        }
        filters[fieldIdentifier].forEach(filterValue => {
          output.push({
            field: column,
            operator: 'like',
            value: '%' + filterValue + '%',
          })
        })
      })
      return output
    },
    deleteEmptyFilterWrapper () {
      const emptyFilterWrapper = this.$el.querySelectorAll('.ant-table-filter-icon:empty')
      for (let filter of emptyFilterWrapper) {
        filter.remove()
      }
    },
    guessSystem,
  },
}
</script>

<style lang="scss">
.resource-table-component {
  overflow-y: auto;
}

.resource-table-bar {
  height: 39px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 !important;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 13px 18px rgba(0, 0, 0, 0.1);
}

.ant-table th {
  padding: 8px !important;
  white-space: nowrap;
  background-color: #f6f6f6 !important;
}

.ant-table-column-title {
  font-weight: normal;
}

.ant-table td {
  padding: 8px 7px 8px 8px !important;
  white-space: nowrap;
}

.resource-table-component .ant-table td {
  padding: 0 !important;
}

.ant-table-footer {
  padding: 8px !important;
}

.ant-table-pagination.ant-pagination {
  margin: 8px 0 0 0;
}

.ant-table ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ant-table ::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 0px;
}

.ant-table ::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 0px;
}

@media print {
  .ant-table-fixed-right, .ant-table-fixed-columns-in-body {
    display: none;
  }
}

.ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-icon {
  left: 0;
}

.ant-table-thead > tr > th .ant-table-header-column:not(:only-child) {
  padding-left: 22px;
}

.ant-table-bordered .ant-table-thead > tr > th:nth-last-child(2), .ant-table-bordered .ant-table-tbody > tr > td:nth-last-child(2) {
  border-right: none;
}

.ant-table-fixed-columns-in-body:last-of-type {
  width: 0;
}

.ant-table-footer .ant-input-number-sm {
  width: 70px !important;
}

body .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 8px !important;

  &:hover {
    .anticon-filter,
    .ant-table-filter-icon {
      &:hover {
        background: none;
        color: #bfbfbf;

        i.ant-table-filter-icon {
          background: #e5e5e5;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .anticon-filter,
  .ant-table-filter-icon {
    cursor: default;
  }

  i.ant-table-filter-icon {
    cursor: pointer;

    &.--filtered {
      color: #108ee9;
    }

    &.--hidden {
      display: none;
    }
  }
}

@media print {
  .entityTableReload,
  .ant-table-filter-icon,
  .ant-dropdown-trigger,
  .ant-table-column-sorter {
    display: none !important;
  }
}

.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  margin-top: -0.375rem !important;
}
</style>

<style scoped>
.actions {
  padding: 3px;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}
</style>
