<template>
  <div
    v-if="value"
    class="with-padding"
  >
    <span v-if="Array.isArray(value)">{{ value.map(a => a.brezel_name).join(', ').substring(0, 50) }}</span>
  </div>
</template>
<script>
import CellSelect from '@/components/fields/select/CellSelect'

export default {
  name: 'CellMultiselect',
  extends: CellSelect,
}
</script>

<style scoped>

</style>
