<template>
  <div class="with-padding">
    <a
      :href="value"
      target="_blank"
    >
      {{ value }}
    </a>
  </div>
</template>

<script>
import FieldCell from '@/components/fields/FieldCell'

export default {
  name: 'CellLink',
  extends: FieldCell,
}
</script>

<style scoped>

</style>
