<template>
  <div>
    <a-row :gutter="50">
      <a-col :xs="{ span: showInfo ? 16 : 24 }">
        <FileSelect
          :module="module"
          :grid="true"
          :selected="selected"
          :multiple="false"
          :directory="directory"
          :with-scan="withScan"
          @change="selectImage"
        />
      </a-col>
      <a-col
        v-if="showInfo"
        :xs="{ span: 8 }"
        style="padding-left: 10px; border-left: 1px solid #ddd"
      >
        <h2>{{ image.brezel_name }}</h2>
        <div style="text-align: center; overflow: auto">
          <a-spin v-if="loadingImage"/>
          <img
            v-else
            :src="imageUrl"
            alt=""
          ><br>
        </div>
        <a-form layout="horizontal">
          <a-form-item label="Alt Text">
            <a-input
              placeholder="Alt"
              :value="alt"
              @input="$emit('update:alt', $event.target.value)"
            />
          </a-form-item>
          <a-form-item label="Link">
            <a-input
              placeholder="Link"
              :value="link"
              @input="$emit('update:link', $event.target.value)"
            />
          </a-form-item>
          <a-form-item label="Size">
            <div style="display: flex; align-items: center; gap: 5px">
              <a-input
                placeholder="Width"
                :value="width"
                @input="$emit('update:width', $event.target.value)"
              /> &times;
              <a-input
                placeholder="Height"
                :value="height"
                @input="$emit('update:height', $event.target.value)"
              />
            </div>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import FileSelect from '@/components/FileSelect'
import { getFileURL } from '@/utils'

export default {
  name: 'MediaManager',
  components: {
    FileSelect,
  },
  model: {
    event: 'change',
  },
  props: {
    module: {
      type: Object,
      default: undefined,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: undefined,
    },
    alt: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: undefined,
    },
    height: {
      type: [String, Number],
      default: undefined,
    },
    withUpload: { type: Boolean, default: true },
    gridView: { type: Boolean, default: true },
    directory: {
      type: String,
      default: '/',
    },
    withScan: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      selected: [this.value],
      image: this.value,
      imageUrl: null,
      loadingImage: false,
    }
  },
  computed: {
    showInfo () {
      return this.image
    },
  },
  watch: {
    value () {
      this.image = this.value
      this.selected = [this.value]
    },

    image () {
      this.loadImage()
    },
  },
  mounted () {
    if (this.image) {
      this.loadImage()
    }
  },
  methods: {
    selectImage (selected) {
      const image = selected[0]
      if (image && this.image && this.image.id === image.id) {
        return
      }
      if (image && image.directory) {
        this.image = null
        return
      }
      if (!image) {
        this.image = null
        return
      }
      this.image = image
      this.$emit('change', this.image)
    },

    loadImage () {
      if (!this.image) {
        return
      }
      this.loadingImage = true
      getFileURL(this.image.id, 'default')
        .then(url => {
          this.loadingImage = false
          this.imageUrl = url
        })
        .catch(res => {
          this.loadingImage = false
          console.error(res)
        })
    },
  },
}
</script>

<style scoped>

</style>
