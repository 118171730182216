<template>
  <a-menu
    v-if="loaded"
    ref="menu"
    :open-keys="openKeys"
    :selected-keys="selectedKeys"
    :style="{ 'border-right': '0', 'width': 'calc(100% - 2px)'} "
    mode="inline"
    @openChange="onOpenChange"
  >
    <template v-for="menuItem in menuItems">
      <a-menu-item
        v-if="menuItem.children.length === 0"
        :key="getKey(menuItem)"
        :class="('menu-item-' + menuItem.identifier)"
        :title="tr(`modules.${menuItem.identifier}.title`, true, menuItem.identifier)"
        @click="emitClick"
      >
        <router-link :to="'/' + $store.state.currentLocale + '/' + menuItem.identifier">
          <a-icon :type="menuItem.icon"/>
          <span v-text="tr('modules.' + menuItem.identifier + '.title', true, menuItem.identifier)"/>
        </router-link>
      </a-menu-item>
      <sub-menu
        v-else
        :key="'module.' + menuItem.identifier"
        :class="('menu-item-' + menuItem.identifier)"
        :module="menuItem"
        @click="emitClick"
      />
    </template>
  </a-menu>
  <a-spin
    v-else
    :delay="1"
    style="width: 100%; text-align: center;"
  />
</template>

<script>
import Menu from 'ant-design-vue/es/menu'
import store from '@/store'

function getKey (menuItem) {
  return menuItem.identifier === 'calendar' ? 'calendar' : (menuItem.key || 'module.' + menuItem.identifier)
}

const SubMenu = {
  template: `
    <a-sub-menu
      :key="'module.' + module.identifier"
      v-bind="$props"
      v-on="$listeners"
    >
    <span
      slot="title"
      :title="tr('modules.' + module.identifier + '.title', true, module.identifier)"
    >
        <a-icon :type="module.icon"/>
        <span v-text="tr('modules.' + module.identifier + '.title', true, module.identifier)"/>
      </span>
    <template v-for="childModule in module.children">
      <a-menu-item
        v-if="childModule.children.length === 0"
        :key="getChildKey(childModule)"
        :class="('menu-item-' + childModule.identifier)"
        :title="tr('modules.' + childModule.identifier + '.title', true, childModule.identifier)"
        @click="emitClick"
      >
        <router-link :to="'/' + $store.state.currentLocale + '/' + childModule.identifier">
          <a-icon :type="childModule.icon"/>
          <span v-text="tr('modules.' + childModule.identifier + '.title', true, childModule.identifier)"/>
        </router-link>
      </a-menu-item>
      <sub-menu
        v-else
        :key="childModule.key"
        :module="childModule"
        @click="emitClick"
      />
    </template>
    </a-sub-menu>
  `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    module: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getChildKey (childModule) {
      return getKey(childModule)
    },
    emitClick () {
      this.$emit('click')
    },
  },
}

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Menu',
  components: {
    'sub-menu': SubMenu,
    'a-menu-item': Menu.Item,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      openKeys: [],
      rootSubmenuKeys: [],
      selectedKeys: [String],
    }
  },
  watch: {
    '$route' () {
      this.changeHighlight(this.$route)
    },
  },
  created () {
    this.changeHighlight(this.$route)
  },
  methods: {
    changeHighlight (route) {
      if (route.params.module_identifier !== undefined) {
        this.selectedKeys = ['module.' + route.params.module_identifier]
      } else {
        this.selectedKeys = [route.name]
        store.commit('setModule', {})
      }
    },
    emitClick () {
      this.$emit('click')
    },
    getKey (menuItem) {
      return getKey(menuItem)
    },
    onOpenChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
  },
}
</script>
