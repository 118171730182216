<template>
  <div>
    <a-spin
      v-if="loading"
      style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);"
    >
      <a-icon
        slot="indicator"
        style="font-size: 50px;"
        type="loading"
      />
    </a-spin>
    <a-row
      v-else
      :style="{ height: '100vh' }"
      align="middle"
      justify="center"
      type="flex"
    >
      <a-col
        :style="{ padding: '10px', maxWidth: '350px' }"
        :xs="24"
      >
        <slot name="logo">
          <img
            v-if="logoUrl !== ''"
            :src="logoUrl"
            :style="{ maxWidth: '100%', margin: '0 auto 26px auto', display: 'block' }"
            alt="logo"
          >
        </slot>
        <!-- Forgot Password Form -->
        <div v-if="forgotPassword">
          <a-form
            layout="vertical"
            @submit="sendForgotPasswordMail"
          >
            <strong>{{ $t('username') }}</strong>
            <FieldContainer
              v-model="fields.username.value"
              :field="fields.username"
              :style="{ marginBottom: '18px' }"
            />
            <a-form-item>
              <a-button
                :disabled="fields.submit.loading"
                :loading="fields.submit.loading"
                :style="{ width: '100%' }"
                html-type="submit"
                type="primary"
              >
                {{ $t('get_new_password') }}
              </a-button>
            </a-form-item>
          </a-form>
          <p>
            <a
              href="javascript:void(0)"
              @click="forgotPassword = false"
            >

              <a-icon type="left-circle"/>
              {{ $t('back_to_login') }}
            </a>
          </p>
        </div>
        <!-- Registration Form -->
        <div v-else-if="showRegister">
          <a-form
            layout="vertical"
            @submit="register"
          >
            <strong>{{ $t('username') }}</strong>
            <FieldContainer
              v-model="fields.username.value"
              :field="fields.username"
              :style="{ marginBottom: '18px' }"
            />
            <strong>{{ $t('password') }}</strong>
            <FieldContainer
              v-model="fields.password.value"
              :field="fields.password"
              :style="{ marginBottom: '18px' }"
            />
            <a-form-item style="margin-bottom: 8px">
              <a-button
                :disabled="fields.submit.loading"
                :loading="fields.submit.loading"
                :style="{ width: '100%' }"
                html-type="submit"
                type="primary"
              >
                {{ $t('register') }}
              </a-button>
            </a-form-item>
          </a-form>
          <p>
            <a
              href="javascript:void(0)"
              @click="showRegister = false"
            >
              <a-icon type="left-circle"/>
              {{ $t('back_to_login') }}
            </a>
          </p>
        </div>
        <!-- Login Form -->
        <div v-else>
          <a-form
            layout="vertical"
            @submit="handleSubmit"
          >
            <div class="transition-container">
              <transition
                :name="transitionType"
                @after-enter="transitionType = 'forward'"
              >
                <div
                  v-if="state === 'login'"
                  key="login"
                >
                  <strong>{{ $t('username') }}</strong>
                  <FieldContainer
                    v-model="fields.username.value"
                    :field="fields.username"
                    :style="{ marginBottom: '18px' }"
                  />
                  <strong>{{ $t('password') }}</strong>
                  <FieldContainer
                    v-model="fields.password.value"
                    :field="fields.password"
                    :style="{ marginBottom: '18px' }"
                  />
                </div>
                <div
                  v-if="state === 'twoFactor'"
                  key="twoFactor"
                  style="margin-bottom: 5px"
                >
                  <strong>
                    <a-icon
                      type="arrow-left"
                      @click="backToLogin"
                    />
                    {{ $t('2fa.input') }}</strong>
                  <a-input v-model="twoFactor.code"/>
                  <div class="resend-code">
                    <a
                      href="javascript:void(0)"
                      @click="twoFactor.resend = true; handleSubmit($event)"
                    >
                      <a-icon type="reload"/>
                      {{ $t('2fa.code_resend') }}
                    </a>
                  </div>
                </div>
              </transition>
            </div>
            <a-form-item style="margin-bottom: 8px;">
              <div style="display: flex">
                <a-button
                  :disabled="fields.submit.loading"
                  :loading="fields.submit.loading"
                  :style="{ width: '100%' }"
                  html-type="submit"
                  type="primary"
                >
                  {{ $t('login') }}
                </a-button>
                <a-button
                  v-if="fields.webauthn.visible"
                  :loading="fields.webauthn.loading"
                  :disabled="fields.webauthn.loading"
                  :style="{ width: '4rem' }"
                  @click="webauthnLogin"
                >
                  <a-icon-fingerprint
                    v-if="!fields.webauthn.loading"
                  />
                </a-button>
              </div>
            </a-form-item>
          </a-form>
          <p
            v-if="canRegister"
            style="float: left"
          >
            <a
              href="javascript:void(0)"
              @click="showRegister = true"
            >

              <a-icon type="user-add"/>
              {{ $t('register') }}
            </a>
          </p>
          <p style="text-align: right">
            <a
              href="javascript:void(0)"
              @click="forgotPassword = true"
            >
              {{ $t('forgot_pw') }}
              <a-icon type="question-circle"/>
            </a>
          </p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { handleToast, logoExists, setLocale } from '@/utils'
import store from '@/store'
import { loadPublicLanguagesAsync } from '@/i18n'
import FieldContainer from '@/components/FieldContainer'
import { loadStylesAsync } from '@/styles'
import { apiGet, apiPost } from '../api'
import AIconFingerprint from '../components/icons/Fingerprint'
import Webauthn from '../mixins/Webauthn'

export default {
  name: 'Login',
  components: {
    AIconFingerprint,
    FieldContainer,
  },
  mixins: [Webauthn],
  data () {
    return {
      canRegister: false,
      customLogoUrl: this.$systemPath('logo.svg'),
      fields: {
        username: {
          value: this.$store.state.user.email !== '' ? this.$store.state.user.email : undefined,
          id: 'username',
          type: 'email',
          prefix: 'user',
          autofocus: 1,
          validateStatus: '',
          help: '',
        },
        password: {
          value: undefined,
          id: 'password',
          type: 'password',
          prefix: 'lock',
          validateStatus: '',
          help: '',
          options: {
            allow_prefill: true,
          },
        },
        submit: {
          loading: false,
        },
        webauthn: {
          loading: false,
          visible: false,
        },
      },
      forgotPassword: false,
      loading: true,
      logoUrl: '',
      reactedToApiDown: false,
      showRegister: false,
      twoFactor: {
        code: null,
        resend: false,
        response: null,
      },
      state: 'login',
      transitionType: 'forward',
    }
  },
  computed: {
    apiUrl () {
      return store.state.apiUrl
    },
  },
  created () {
    let userLang = navigator.language || navigator.userLanguage

    setLocale(this, userLang.split('-')[0], false)

    this.loadPreLoginInformation()

    this.checkAndSetCustomLogo()

    loadPublicLanguagesAsync()
      .then(() => {
        this.loading = false
        this.setTitle()
      })
      .catch(this.reactToApiDown)

    loadStylesAsync()

    this.checkForRegistration()
  },
  mounted () {
    if (localStorage.getItem('webauthn')) {
      this.fields.webauthn.visible = true
      this.webauthnLogin()
    }
  },
  methods: {
    loadPreLoginInformation () {
      apiGet(['pre-login'])
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('' + response.status)
          }
          return response
        })
        .then(response => response.json())
        .then(response => {
          if (response.spa.defaultLocale) {
            setLocale(this, response.spa.defaultLocale, false)
          }
        })
        .catch(console.error)
    },
    checkAndSetCustomLogo () {
      logoExists(this.customLogoUrl)
        .then(() => {
          this.logoUrl = this.customLogoUrl
        })
        .catch(() => {
          this.logoUrl = '/brezel_icon.svg'
        })
    },
    setTitle () {
      document.title = this.$t('login') + ' | ' + this.$store.state.systemName
    },
    checkForRegistration () {
      apiGet(['can-register'])
        .then((response) => {
          if (response.status === 503) {
            throw new Error('' + response.status)
          }
          return response
        })
        .then(response => response.json())
        .then(response => {
          this.canRegister = response.is_allowed
        })
        .catch(console.error)
    },
    handleTwoFactorResponse (response) {
      this.twoFactor.response = response
      this.twoFactor.required = true
      this.state = 'twoFactor'
      if (this.twoFactor.response.issued) {
        handleToast({
          toast_type: 'success',
          message: this.$t('2fa.code_issued'),
        })
      }
    },
    loginSucceeded (data) {
      let accessToken = data.accessToken
      if (accessToken) {
        if (!this.handleLoginStrategy(data, data.globalLogin)) {
          return
        }
        this.fields.username.validateStatus = 'success'
        this.fields.password.validateStatus = 'success'
        localStorage.setItem('accessToken', accessToken)
        window.dispatchEvent(new Event('accessTokenChange'))
        store.commit('setClient', data.client)
        store.commit('setIsMultiClientSystem', data.isMultiClientSystem)
        store.commit('setUser', data.user)
        this.$emit('login')
      }
    },
    handleLoginStrategy (data, strategy) {
      switch (strategy) {
        case 'sameSite':
          store.commit('setCurrentSystem', data.user.client.hostnames[0].fqdn)
          return true
        case 'redirect':
          const host = data.user.client.hostnames[0].fqdn
          window.location.href = host + '?' + new URLSearchParams({ 'accessToken': data.accessToken }).toString()
          return false
        default:
          return true
      }
    },
    handleSubmit (e) {
      e.preventDefault()
      this.reloadIfApiIsDownOrDo(() => {
        this.fields.submit.loading = true
        this.fields.username.validateStatus = 'validating'
        this.fields.password.validateStatus = 'validating'

        let loginData = {
          username: this.fields.username.value,
          password: this.fields.password.value,
        }

        if (this.twoFactor.required) {
          loginData.code = this.twoFactor.code
          loginData.resendCode = this.twoFactor.resend
          this.twoFactor.resend = false
        }

        apiPost(
          ['login'],
          {},
          JSON.stringify(loginData),
          { 'Content-Type': 'application/json' }
        )
          .then((response) => {
            this.fields.submit.loading = false
            if (response.status === 503) {
              this.reactToApiDown()

              throw new Error('' + response.status)
            }
            if (response.status === 401) {
              this.fields.username.validateStatus = 'error'
              this.fields.password.validateStatus = 'error'

              throw new Error('' + response.status)
            }
            return response
          })
          .then(response => response.json())
          .then((response) => {
            this.fields.username.validateStatus = ''
            this.fields.password.validateStatus = ''
            const data = response.data
            if (data.twoFactor) {
              this.handleTwoFactorResponse(data.twoFactor)
            }
            if (data.authenticated) {
              this.loginSucceeded(data)
            }
            if (!response.success) {
              const error = new Error()
              error.errors = response.errors
              throw error
            }
          })
          .catch(e => {
            this.fields.username.validateStatus = ''
            this.fields.password.validateStatus = ''
            this.fields.submit.loading = false

            if (e.errors) {
              e.errors.forEach(error => {
                handleToast({
                  toast_type: 'error',
                  message: error.message,
                })
              })
            } else {
              handleToast({
                toast_type: 'error',
                message: this.$t('401_login_message'),
                description: this.$t('401_login_description'),
              })
            }
            console.error(e)
          })
      })
    },
    register (e) {
      e.preventDefault()
      this.reloadIfApiIsDownOrDo(() => {
        this.fields.submit.loading = true
        this.fields.username.validateStatus = 'validating'
        this.fields.password.validateStatus = 'validating'

        apiPost(
          ['register'],
          {},
          JSON.stringify({
            username: this.fields.username.value,
            password: this.fields.password.value,
          }),
          { 'Content-Type': 'application/json' }
        )
          .then((response) => {
            this.fields.submit.loading = false
            if (response.status === 409 || response.status === 503) {
              throw new Error('' + response.status)
            }
            return response
          })
          .then(response => response.json())
          .then((response) => {
            let accessToken = response.accessToken
            if (accessToken !== undefined) {
              this.fields.username.validateStatus = 'success'
              this.fields.password.validateStatus = 'success'
              localStorage.setItem('accessToken', accessToken)
              window.dispatchEvent(new Event('accessTokenChange'))
              store.commit('setUser', response.user)
              this.$emit('login')
            }
          })
          .catch(e => {
            if (e.message === '409') {
              this.fields.username.validateStatus = 'error'
              this.fields.password.validateStatus = ''
              handleToast({
                toast_type: 'error',
                message: this.$t('409_username_already_taken_message'),
                description: this.$t('409_username_already_taken_description'),
              })
            } else if (e.message === '503') {
              this.fields.username.validateStatus = 'error'
              this.fields.password.validateStatus = 'error'
              handleToast({
                toast_type: 'error',
                message: this.$t('503_register_not_allowed_message'),
                description: this.$t('503_register_not_allowed_description'),
              })
            }
            console.error(e)
          })
      })
    },
    sendForgotPasswordMail (e) {
      e.preventDefault()
      this.reloadIfApiIsDownOrDo(() => {
        this.fields.submit.loading = true
        this.fields.username.validateStatus = 'validating'

        apiPost(
          ['password', 'create'],
          {},
          JSON.stringify({
            email: this.fields.username.value,
            reset_path: '/' + this.$route.params.locale,
          }),
          { 'Content-Type': 'application/json' }
        )
          .then((response) => {
            this.fields.submit.loading = false
            if (response.status !== 200) {
              this.fields.username.validateStatus = 'error'

              throw new Error('' + response.status)
            }
            return response
          })
          .then(response => response.json())
          .then((response) => {
            this.fields.username.validateStatus = 'success'
            handleToast({
              toast_type: 'success',
              message: this.$t('200_password_request_create_message'),
              description: this.$t('200_password_request_create_description'),
            })
          })
          .catch(e => {
            this.fields.username.validateStatus = 'error'
            this.fields.submit.loading = false
            handleToast({
              toast_type: 'error',
              message: this.$t('errors.401_password_request_create_message'),
              description: this.$t('errors.401_password_request_create_description'),
            })
            console.error(e)
          })
      })
    },
    reactToApiDown () {
      if (this.reactedToApiDown === false) {
        this.reactedToApiDown = true
        this.loading = false
        handleToast({
          toast_type: 'error',
          message: this.$t('503.message'),
          description: this.$t('503.description'),
        })
      }
    },
    reloadIfApiIsDownOrDo (callback) {
      if (this.reactedToApiDown) {
        window.location.reload()
      } else {
        callback()
      }
    },
    backToLogin () {
      this.transitionType = 'backwards'
      this.state = 'login'
    },
    async webauthnLogin () {
      const storageData = localStorage.getItem('webauthn')
      if (!storageData) return

      const username = JSON.parse(storageData).username
      this.fields.webauthn.loading = true

      try {
        let loginOptions = await apiPost(
          ['webauthn', 'login', 'options'],
          {},
          JSON.stringify({ 'username': username }),
          { 'Content-Type': 'application/json' }
        )
          .then(response => {
            if (response.status >= 300) {
              throw new Error('' + response.status)
            }
            return response.json()
          })
          .catch(error => {
            // Creating the webauthn login options failed, for example because no user
            // with the given name exists or the webauthn credentials were removed.
            // Either way, we won't display webauthn as a login option after that.
            if (['401', '403'].indexOf(error.message) !== -1) {
              this.fields.webauthn.visible = false
            }
          })

        if (!loginOptions) {
          throw new Error('Could not create credential options')
        }

        let credential = this.preparePublicKeyCredentials(
          await navigator.credentials.get({
            publicKey: this.prepareLoginOptions(loginOptions),
          })
        )

        apiPost(
          ['webauthn', 'login'],
          { username: username },
          JSON.stringify(credential),
          { 'Content-Type': 'application/json' }
        )
          .then(response => {
            this.fields.webauthn.loading = false
            if (response.status >= 300) {
              throw new Error('' + response.status)
            }
            return response.json()
          })
          .then(response => {
            if (!response.success) {
              throw new Error('Login failed')
            }
            this.loginSucceeded(response.data)
          })
          .catch(error => {
            if (error.message === '401') {
              handleToast({
                toast_type: 'error',
                message: this.$t('401_login_message'),
                description: this.$t('401_login_description'),
              })
            } else if (error.message === '503') {
              handleToast({
                toast_type: 'error',
                message: this.$t('503.message'),
                description: this.$t('503.description'),
              })
            }
          })
      } catch (e) {
        console.error(e)
        this.fields.webauthn.loading = false
      }
    },
  },
}
</script>

<style scoped>
.transition-container {
  position: relative;
  transition: all 0.2s linear;
}

.backwards-leave {
  transition: all 0.2s linear;
  transform: translateX(0);
}

.backwards-leave-to- {
  transform: translateX(50%);
}

.forward-enter {
  transform: translateX(50%);
  position: absolute;
}

.forward-enter-active, .backwards-leave-active {
  position: absolute;
  top: 0;
  margin-left: 50px;
  width: 100%;
}

.backwards-enter-active {

}

.forward-leave-active {
}

.forward-enter-to, .backwards-enter-to {
  transform: translateX(0);
  transition: all 0.2s linear;
  opacity: 1;
}

.forward-leave-to, .backwards-enter {
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.2s linear;
}

.resend-code {
  margin: 10px 0;
}
</style>
