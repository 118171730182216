<template>
  <div
    :brezel-field-id="field.id"
    :brezel-field-identifier="field.identifier"
    class="field"
  >
    <a-form-item
      v-if="field.type !== 'layout'"
      :has-feedback="true"
      :validate-status="field.validateStatus || validateStatus || (field.options || {}).validationState"
      :help="_validateMessage"
    >
      <component
        :is="getFieldComponent(field.type, field.options)"
        ref="field"
        v-bind="$attrs"
        :field="field"
        :value="value"
        :translation-prefix="translationPrefix"
        :layout="layout"
        :layout-mode="mode"
        :context="context"
        :style="(field.options || {}).style"
        @event="fieldEvent"
        @input="input"
        @tree="setFieldTree"
      />
      <a-button
        v-if="field.canBeEnabled"
        type="link"
        size="small"
        class="fieldEnableBtn"
        tabindex="-1"
        @click="field.enable()"
      >
        <a-icon type="unlock"/>
      </a-button>
      <div
        v-if="extras.length > 0"
        slot="extra"
      >
        <div
          v-for="(extra, index) in extras"
          :key="index"
          class="extra"
        >
          <small v-text="extra"/>
        </div>
      </div>
    </a-form-item>
    <div
      v-else
      v-text="field.options.placeholder"
    />
  </div>
</template>

<script>
import Field from '@/components/fields/Field'
import Fields from '@/components/fields'
import LayoutDef from '@/layout/layout'

export default {
  name: 'FieldContainer',
  components: {
    Field,
  },
  mixins: [
    Fields,
  ],
  inheritAttrs: false,
  props: {
    field: {
      type: Object,
      default: undefined,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: undefined,
    },
    translationPrefix: {
      type: String,
      required: false,
      default: '',
    },
    layout: {
      type: LayoutDef,
      default: () => new LayoutDef(),
    },
    mode: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data () {
    return {
      'validateStatus': null,
      'validateMessage': null,
    }
  },
  computed: {
    _validateMessage () {
      let message = null
      if (this.validateMessage) {
        message = this.validateMessage
      } else if (this.field && this.field.validateMessage) {
        message = this.field.validateMessage
      } else if (this.field && this.field.options && this.field.options.validateMessage) {
        message = this.field.options.validateMessage
      }
      if (typeof message === 'string' || message instanceof String) {
        message = message.replace(new RegExp('<br>', 'g'), '\n')
      }
      return message
    },

    extras () {
      let extras = []
      if (this.field.options) {
        if (this.field.options.help) {
          extras.push(this.$t(`${this.translationPrefix}.help.${this.field.identifier}`))
        }
        if (this.field.options.ignore_when_empty) {
          extras.push(this.$t('_.will_be_ignored_when_empty'))
        }
        if (this.field.options.extras) {
          extras = extras.concat(this.field.options.extras)
        }
      }
      return extras
    },
  },
  methods: {
    input ($event) {
      this.$emit('input', $event)
    },

    fieldEvent ($event) {
      this.$emit('event', $event)
    },

    setFieldTree ($event) {
      this.$emit('tree', $event)
    },
  },
}
</script>

<style>
.extra {
  margin-bottom: 5px;
}

.field .ant-input-number-input {
  padding: 0 33px 0 11px;
  text-align: right;
}

.field .ant-input-number-disabled .ant-input-number-input {
  color: rgba(0, 0, 0, 0.65);
}

.field .ant-input-number-input[disabled=disabled] {
  padding: 0 11px;
}

.ant-form-item-children {
  display: block;
  position: relative;
}

.ant-form-item-children-icon {
  top: 16px !important;
}

.ant-form-vertical .ant-row.ant-form-item {
  padding-bottom: 0;
}

.ant-btn.fieldEnableBtn {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
