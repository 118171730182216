export default class FieldCell {
  row
  column
  settings = {}

  constructor (row, column) {
    this.row = row
    this.column = column
    this.settings = Object.assign({}, (column || {}).settings)
  }
}
