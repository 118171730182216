<template>
  <div>
    <h2 style="margin-top:10px;">
      {{ tr(component.options.headline, true) }}
    </h2>
    <GChart
      type="BarChart"
      :options="chartOptions"
      :data="chartData"
      style="height:10vh;"
    />
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import { GChart } from 'vue-google-charts'

export default {
  name: 'ProgressWidget',
  components: {
    GChart,
  },
  extends: LayoutComponent,
  props: [],
  data () {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [[' ', ' '], [' ', this.component.options.current / (this.component.options.max / 100)]],
      chartOptions: {
        theme: 'maximized',
        colors: [this.component.options.color],
        bar: { width: '100%' },
        chartArea: {
          top: 20,
          left: 20,
          right: 20,
          bottom: 20,
          backgroundColor: {
            stroke: '#000000',
            strokeWidth: 3,
            // fill: 'yellow', //rest of the progressbar can be filled with a color instead of being transparent
            // opacity: '1',
          },
        },
        hAxis: {
          gridlines: { color: 'transparent' },
          textPosition: 'none',
          viewWindow: {
            min: 0,
            max: 100,
          },
        },
        vAxis: {
          gridlines: { color: 'transparent' },
        },
        gridlines: { color: 'transparent' },
        legend: { position: 'none' },
        enableInteractivity: false, // chart does not react to mouse-hover anymore
        animation: {
          duration: 500,
          easing: 'out',
        },
      },
    }
  },
  watch: {
    component: {
      deep: true,
      handler () {
        // this redraws the chart when a value changes
        this.chartData = [[' ', ' '], [' ', this.component.options.current / (this.component.options.max / 100)]]
      },
    },
  },
  mounted () {
    window.addEventListener('resize', function () {
    })
  },
}

</script>
