<template>
  <div
    :brezel-field-id="field.id"
    :brezel-field-identifier="field.identifier"
    class="field"
  >
    <span v-if="field.type === 'password' || options.hide_value">&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;</span>

    <span v-else-if="field.type === 'time'">{{ echoDateTime(value, 'time') }}</span>

    <span v-else-if="field.type === 'date'">{{ echoDateTime(value, 'date') }}</span>

    <span v-else-if="field.type === 'month'">{{ echoDateTime(value, 'month') }}</span>

    <span v-else-if="field.type === 'datetime'">{{ echoDateTime(value, 'datetime') }}</span>

    <a-checkbox
      v-else-if="field.type === 'checkbox' && !field.options.mode"
      :checked="value"
      disabled
    />

    <a-switch
      v-else-if="field.type === 'checkbox' && field.options.mode === 'switch'"
      v-model="out_value"
      disabled
    />

    <div
      v-else-if="field.type === 'currency'"
      style="text-align: right; white-space: nowrap;"
    >
      {{ value === null ? '' : formatFieldOutputValue(field, value) }}
    </div>

    <div v-else-if="field.type === 'range'">
      {{ range }}
    </div>

    <div v-else-if="field.type === 'wysiwyg' && value">
      <div class="viewer editor__content">
        <div v-html="value"/>
      </div>
    </div>

    <div
      v-else-if="field.type === 'signature' && value"
      style="padding: 4px 0 4px;"
    >
      <img
        v-if="value"
        :src="value"
        alt="signature"
        style="border: 1px solid rgb(217, 217, 217); background-size: contain; width: 100%; max-width: 25vw"
      >
    </div>

    <div
      v-else-if="field.type === 'dateRange' && value"
      class="dateRange"
    >
      <template v-if="dateRangeValid">
        <a-range-picker
          v-if="startDate !== null && endDate !== null"
          :default-value="[startDate, endDate]"
          :format="dateTimeFormat"
          :value="[startDate, endDate]"
          disabled
        />
        <span v-if="fullDay">
          <a-divider type="vertical"/>
          {{ $t(`_.fullDay`) }}
        </span>
        <div
          v-if="options.select_weekdays"
          class="daySelector"
        >
          <a-checkbox-group
            :options="daySelector"
            :value="weekdays"
            disabled
          />
        </div>
      </template>
      <template v-else>
        {{ $t('_.no_data') }}
      </template>
    </div>

    <div
      v-else-if="field.type === 'upload' || field.type === 'file'"
      @click="handleFileClick"
    >
      <div v-if="options.viewMode === 'img'">
        <img
          v-for="(file, key) in defaultFileList"
          :key="key"
          :src="file.url"
          :alt="file.name"
          style="max-width: 100%;"
        >
      </div>
      <a-upload
        v-else
        :disabled="true"
        :file-list="defaultFileList"
        :remove="() => false"
        :list-type="options.viewMode === 'list' ? 'picture' : 'picture-card'"
        :class="{ 'field-file-list': options.viewMode === 'list' }"
        @preview="openFile"
      />
    </div>

    <div
      v-else-if="field.type === 'layout'"
      v-html="options.placeholder"
    />

    <a-rate
      v-else-if="field.type === 'rating'"
      :default-value="value"
      allow-half
      disabled
    />

    <a-button
      v-else-if="field.type === 'color'"
      size="small"
      style="padding: 3px"
      class="color-field-btn"
    >
      <div
        :style="{backgroundColor: value}"
        style="width: 16px; height: 100%"
      />
    </a-button>

    <div v-else-if="field.type === 'choice'">
      <div v-if="Array.isArray(value)">
        <ul
          style="margin-bottom: 0; padding-left: 0; list-style: none;"
        >
          <li
            v-for="(option, index) in value"
            :key="index"
          >
            {{ $t(`${translationPrefix}.choice.${field.identifier}.${option}`) }}
          </li>
        </ul>
      </div>
      <span v-else>{{ value ? $t(`${translationPrefix}.choice.${field.identifier}.${value}`) : '' }}</span>
    </div>

    <span
      v-else
      style="white-space: pre-line"
    >{{ value ? value : '' }}</span>
  </div>
</template>

<script>
import moment from 'moment'
import { echoDateTime, formatFieldOutputValue, getFileURL, isScreenMobile } from '@/utils'
import { openWebhookResponse } from '@/webhooks'
import { mapGetters } from 'vuex'
import Api from '@/api'
import Field from '@/layout/field'

export default {
  name: 'FieldViewer',
  props: {
    field: {
      type: Object,
      default: () => {
        return new Field({ options: { } })
      },
    },
    module: {
      type: Object,
      default: undefined,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: undefined,
    },
    translationPrefix: {
      type: String,
      default: undefined,
    },
    layoutMode: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      dateTimeFormat: '',
      daySelector: [
        { label: 'Mo', value: 1 },
        { label: 'Tu', value: 2 },
        { label: 'We', value: 3 },
        { label: 'Th', value: 4 },
        { label: 'Fr', value: 5 },
        { label: 'Sa', value: 6 },
        { label: 'Su', value: 7 },
      ],
      defaultFileList: [],
      endDate: moment(),
      fullDay: false,
      out_value: this.value,
      screenIsMobile: isScreenMobile(),
      selectOptions: [],
      startDate: moment(),
      weekdays: [],
    }
  },
  computed: {
    ...mapGetters([
      'getModuleIdentifierById',
    ]),
    options () {
      return this.field.options || {}
    },
    moment () {
      return moment
    },
    range () {
      if (this.value === null) {
        return ''
      }
      if (this.options.dual_thumb_mode === 1) {
        return this.value.join(' ' + this.$t(`to`) + ' ')
      }
      return this.value
    },
    selectedOption () {
      if (this.value === null) {
        return []
      }
      if (this.options.references || this.options.referencedBy) {
        return this.value
      }
      return this.selectOptions.find(option => option.value === this.value)
    },
    selectedOptions () {
      if (this.options.references) {
        return this.value
      }
      if (this.value !== null) {
        if (typeof this.selectOptions.filter !== 'undefined') {
          return this.selectOptions.filter(option => this.value.includes(option.value))
        }
        return {}
      }
      return []
    },
    dateRangeValid () {
      let dateRange = this.value
      return (dateRange.start !== null && dateRange.end !== null) && dateRange.length !== 0
    },
    fieldIsRelation () {
      return ['select', 'multiselect', 'upload', 'file'].includes(this.field.type)
    },
    fieldIsScalar () {
      return this.fieldIsRelation && (
        (['upload', 'file'].includes(this.field.type) && !this.options.multiple) ||
        (this.field.type === 'select' && !this.options.multiple)
      )
    },
  },
  watch: {
    value () {
      if (this.field.type === 'file' || this.field.type === 'upload') {
        this.getFileList()
      }
      if (this.field.type === 'dateRange') {
        this.parseDateRange()
      }
    },
  },
  mounted () {
    if ((this.field.type === 'select' || this.field.type === 'multiselect') && this.options.references) {
      if (this.value) {
        this.selectOptions = {
          value: this.value.id,
          name: this.value.brezel_name,
        }
      }
    } else if (this.field.type === 'dateRange') {
      this.parseDateRange()
    } else if (this.field.type === 'file' || this.field.type === 'upload') {
      this.getFileList()
    }

    this.fireShowEvent()
  },
  methods: {
    echoDateTime: echoDateTime,
    formatFieldOutputValue: formatFieldOutputValue,

    fireShowEvent () {
      this.propagateEvent('show', {
        event: 'on_show',
        component: this,
        target: this.field,
        value: this.value,
      })
    },

    propagateEvent (eventIdentifier, $event) {
      this.$emit('event', $event)
    },

    parentPath () {
      return ['$']
    },

    getFileList () {
      let fileList = []
      let value = this.value
      if (value) {
        if (this.fieldIsScalar) {
          value = [value]
        }
        value.forEach(item => {
          getFileURL(item.id, 'default')
            .then(url => {
              let fileUrl = ''
              if (item.mime_type.includes('image') && !item.mime_type.includes('svg')) { fileUrl = url }
              if (item.mime_type.includes('pdf')) { fileUrl = 'data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%2764 64 896 896%27 data-icon=%27file-pdf%27 width=%271em%27 height=%271em%27 fill=%27red%27 aria-hidden=%27true%27 focusable=%27false%27 class=%27%27%3E%3Cpath d=%27M531.3 574.4l.3-1.4c5.8-23.9 13.1-53.7 7.4-80.7-3.8-21.3-19.5-29.6-32.9-30.2-15.8-.7-29.9 8.3-33.4 21.4-6.6 24-.7 56.8 10.1 98.6-13.6 32.4-35.3 79.5-51.2 107.5-29.6 15.3-69.3 38.9-75.2 68.7-1.2 5.5.2 12.5 3.5 18.8 3.7 7 9.6 12.4 16.5 15 3 1.1 6.6 2 10.8 2 17.6 0 46.1-14.2 84.1-79.4 5.8-1.9 11.8-3.9 17.6-5.9 27.2-9.2 55.4-18.8 80.9-23.1 28.2 15.1 60.3 24.8 82.1 24.8 21.6 0 30.1-12.8 33.3-20.5 5.6-13.5 2.9-30.5-6.2-39.6-13.2-13-45.3-16.4-95.3-10.2-24.6-15-40.7-35.4-52.4-65.8zM421.6 726.3c-13.9 20.2-24.4 30.3-30.1 34.7 6.7-12.3 19.8-25.3 30.1-34.7zm87.6-235.5c5.2 8.9 4.5 35.8.5 49.4-4.9-19.9-5.6-48.1-2.7-51.4.8.1 1.5.7 2.2 2zm-1.6 120.5c10.7 18.5 24.2 34.4 39.1 46.2-21.6 4.9-41.3 13-58.9 20.2-4.2 1.7-8.3 3.4-12.3 5 13.3-24.1 24.4-51.4 32.1-71.4zm155.6 65.5c.1.2.2.5-.4.9h-.2l-.2.3c-.8.5-9 5.3-44.3-8.6 40.6-1.9 45 7.3 45.1 7.4zm191.4-388.2L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z%27%3E%3C/path%3E%3C/svg%3E' }
              fileList.push({
                'uid': item.id,
                'name': item.brezel_name,
                'status': 'done',
                'url': fileUrl,
              })
            })
            .catch(() => {
              fileList.push({
                'uid': item.id,
                'name': item.brezel_name,
                'status': 'done',
              })
            })
        })
      }
      this.defaultFileList = fileList
    },
    handleFileClick (event) {
      if (event.target.classList.contains('ant-upload-list-item-info')) {
        let icon = event.target.parentNode.querySelector('.anticon.anticon-eye-o')
        icon.click()
      }
    },
    parseDateRange () {
      let dateRange = this.value
      this.dateTimeFormat = (this.options.date_time_format ? this.options.date_time_format : 'YYYY-MM-DD HH:mm')
      if (dateRange) {
        if (this.dateRangeValid) {
          this.startDate = moment(dateRange.start, this.dateTimeFormat)
          this.endDate = moment(dateRange.end, this.dateTimeFormat)
        } else {
          this.startDate = null
          this.endDate = null
        }
        this.weekdays = dateRange.weekdays
        this.fullDay = dateRange.fullDay
      }
    },
    openFile (file) {
      Api.files().getFile(file.uid).then(blob => openWebhookResponse(blob, file.name))
    },
  },
}
</script>

<style>

.dateRange * {
  cursor: default !important;
}

.ant-calendar-picker .ant-input-disabled {
  background-color: inherit;
  color: inherit;
}

.daySelector {
  padding-top: 8px;
}

.daySelector .ant-checkbox-group {
  display: flex;
  justify-content: space-around;
  padding: 4px;
}

.daySelector .ant-checkbox-wrapper {
  display: flex;
  flex-direction: column;
}

.daySelector .ant-checkbox-wrapper span {
  padding: 0;
}

.daySelector .ant-checkbox-disabled + span {
  color: inherit;
}

.ant-upload-list-item-info:before {
  left: 0;
}

.ant-upload-list-item-actions > a {
  pointer-events: all !important;
  opacity: 1 !important;
}

.field .field-file-list .ant-upload-list-item-thumbnail {
  top: 0;
  left: 0;
}
</style>

<style lang="less">
//@import (reference, less) "~ant-design-vue/es/card/style/index.css";

.viewer {
  width: 100%;
  padding: 2px;
}
</style>
