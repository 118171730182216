<template>
  <span v-if="nothingSelected">-</span>
  <div v-else-if="viewAsTable">
    <div class="ant-table-scroll">
      <div
        class="ant-table-body"
        style="overflow-x: scroll"
      >
        <table
          class="ant-table ant-table-bordered"
          style="width: 100%;"
        >
          <thead class="ant-table-thead">
            <tr>
              <th
                v-for="(col, colIndex) in options.showColumns"
                :key="colIndex"
                v-text="tr(`modules.${module.identifier}.fields.${col}`, true)"
              />
            </tr>
          </thead>
          <tbody class="ant-table-tbody">
            <tr
              v-for="(option, optionIndex) in generatedSelectedOptions"
              :key="optionIndex"
            >
              <td
                v-for="(col, colIndex) in options.showColumns"
                :key="colIndex"
              >
                <router-link
                  v-if="option && showLinkFor(option, col)"
                  :to="'/' + $store.state.currentLocale + '/' + getModuleIdentifierById(option.module_id) + '/' + option.id"
                >
                  {{ option[col] }}
                </router-link>
                <component
                  :is="getFieldViewerComponent(field.type)"
                  v-else-if="option"
                  v-model="option[col]"
                  :field="getFieldByIdentifier(col)"
                  :module="referencedModule"
                  :translation-prefix="translationPrefix"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    v-else
    class="options"
  >
    <span
      v-for="(option, index) in generatedSelectedOptions"
      :key="index"
    >
      <router-link
        v-if="option && showLinkFor(option)"
        class="option-item"
        :to="'/' + $store.state.currentLocale + '/' + getModuleIdentifierById(option.module_id) + '/' + option.id"
      >
        <VDropdown
          :triggers="['hover']"
          :auto-hide="false"
          :distance="10"
          :instant-move="true"
          :overflow-padding="100"
          :delay="{hide: 100}"
          placement="top"
        >
          <div
            class="option-tag"
            :class="{multiple: generatedSelectedOptions.length > 1}"
          >
            <span
              v-if="options.icon"
              class="option-icon"
              :style="{ backgroundImage: iconMap[(option.id)] ? 'url(' + iconMap[(option.id)] + ')' : undefined }"
            />
            {{ option.brezel_name }}
          </div>
          <template
            #popper
          >
            <div style="padding: 12px">
              <Layout
                :module="referencedModule"
                :layout="getSummaryLayout()"
                mode="module.show"
                :resource="option"
              />
            </div>
          </template>
        </VDropdown>
      </router-link>
      <span v-else-if="option">{{ option.brezel_name }}</span>
    </span>
  </div>
</template>

<script>
import FieldViewer from '@/components/fields/FieldViewer'
import Fields from '@/components/fields'
import Module from '@/module/module'
import { getFileURL } from '@/utils'
import Layout from '@/components/Layout'
import { Menu as VDropdown } from 'floating-vue'
import 'floating-vue/dist/style.css'

export default {
  name: 'FieldViewerSelect',
  components: { FieldViewer, Layout, VDropdown },
  extends: FieldViewer,
  mixins: [
    Fields,
  ],
  data () {
    return {
      iconMap: {},
    }
  },
  computed: {
    generatedSelectedOptions () {
      if (this.fieldIsScalar) {
        return [this.selectedOption]
      }
      return this.selectedOptions
    },
    nothingSelected () {
      return (this.selectedOption && this.selectedOption.length < 0) ||
        (this.selectedOptions && this.selectedOptions.length < 0)
    },
    viewAsTable () {
      return this.options.showMode === 'table' && Array.isArray(this.options.showColumns)
    },
    referencedModule () {
      return Module.byIdentifier(this.options.references)
    },
  },
  watch: {
    selectOptions () {
      if (this.options.icon) {
        Promise.all(this.generatedSelectedOptions.map(this.loadIcon)).then(() => {
          this.$forceUpdate()
        })
      }
    },
  },
  methods: {
    getSummaryLayout () {
      return this.referencedModule.getSummaryLayout()
    },
    showLinkFor (option, col = undefined) {
      let isString = true
      if (col && option[col]) {
        isString = typeof option[col] === 'string'
      }
      return option.module_id && this.getModuleIdentifierById(option.module_id) && isString
    },
    showImgPreview (value) {
      if (!value) return false
      return !!value.mime_type && value.mime_type.includes('image')
    },
    getFieldByIdentifier (identifier) {
      const fields = this.referencedModule.getFields()
      return fields.find((a) => a.identifier === identifier)
    },
    loadIcon (entity) {
      if (!this.iconMap[entity.id] || !entity[this.options.icon]) {
        return getFileURL(entity[this.options.icon].id, 'mini').then(url => {
          this.iconMap[entity.id] = url
          return url
        })
      }
      return Promise.resolve(this.iconMap[entity.id])
    },
  },
}
</script>

<style scoped>
.option-item {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.option-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>

<style>
.v-popper__inner {
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/variables";

.options {
  display: inline-flex;
  gap: 5px;
}

.option-tag {
  cursor: pointer;
  transition: all 0.2s;
  font-size: inherit;
  background-color: inherit;
  color: $input-border-color-active;
  padding: 2px 5px;
  margin-top: -2px;
  margin-left: -5px;
  margin-right: 5px;

  &.multiple {
    border: 1px solid rgba(black, 0.1);
    border-radius: 2px;
  }

  &:hover {
    color: white;
    background-color: $input-border-color-active;
  }
}
</style>
