<script>
export default {
  name: 'AIconFingerprint',
  data () {
    return {
      Svg: {
        template: `
<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8895 2.97009C14.4572 1.28187 10.2163 1.49589 6.98964 3.60928L5.83228 2.10365C9.68372 -0.418936 14.7373 -0.672253 18.83 1.34082C22.9607 3.37253 26 7.64643 26 13.9603C26 15.6289 25.2946 16.9441 24.1879 17.8304C23.1014 18.7005 21.6849 19.1138 20.3043 19.1138C18.9238 19.1138 17.5073 18.7005 16.4208 17.8304C15.3141 16.9441 14.6087 15.6289 14.6087 13.9603C14.6087 13.2185 14.3574 12.7498 14.0707 12.473C13.7778 12.1902 13.3904 12.0498 13 12.0498C12.6096 12.0498 12.2222 12.1902 11.9293 12.473C11.6426 12.7498 11.3913 13.2185 11.3913 13.9603C11.3913 18.0296 13.4148 20.402 15.965 21.4668C18.583 22.5598 21.8905 22.3317 24.4876 20.9012L25.5124 22.4866C22.3955 24.2033 18.3986 24.5091 15.1408 23.1489C11.8151 21.7604 9.3913 18.6924 9.3913 13.9603C9.3913 12.8131 9.79216 11.8649 10.4837 11.1972C11.1691 10.5354 12.086 10.2036 13 10.2036C13.914 10.2036 14.8309 10.5354 15.5163 11.1972C16.2078 11.8649 16.6087 12.8131 16.6087 13.9603C16.6087 15.112 17.0773 15.912 17.7314 16.4359C18.4057 16.9759 19.337 17.2677 20.3043 17.2677C21.2717 17.2677 22.203 16.9759 22.8773 16.4359C23.5314 15.912 24 15.112 24 13.9603C24 8.23569 21.2839 4.63966 17.8895 2.97009ZM4.8103 5.52284C3.12685 7.48365 2 10.2773 2 13.9603C2 15.6297 2.35013 17.4824 2.77503 19.0664C3.20156 20.6564 3.68357 21.9035 3.90842 22.3545L2.09158 23.1263C1.79469 22.5308 1.2767 21.1617 0.833664 19.5101C0.388999 17.8525 0 15.8384 0 13.9603C0 9.92068 1.24161 6.70713 3.24285 4.37619L4.8103 5.52284ZM13.088 4.88271C17.317 4.88258 21.3043 8.08822 21.3043 13.9603H19.3043C19.3043 8.95928 16.0582 6.72877 13.0881 6.72885C11.564 6.7289 10.0394 7.3028 8.89605 8.46507C7.75611 9.62388 6.94264 11.4243 6.97451 13.9496C7.01814 17.4077 8.05931 20.1444 9.6646 22.2008L8.04199 23.2801C6.17311 20.8861 5.02259 17.7714 4.97464 13.9711C4.93791 11.0602 5.88138 8.7834 7.41541 7.224C8.94602 5.66807 11.0124 4.88277 13.088 4.88271Z"/>
</svg>
`,
      },
    }
  },
  template: `
    <a-icon :component="Svg"/>
  `,
}
</script>
