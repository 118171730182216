<template>
  <div>
    <table
      v-if="component.options.vertical_labels !== true"
      style="width: 100%"
      class="field-group-item-list"
    >
      <tr
        v-for="[field, index] in fields"
        :key="index"
        :style="getStyleForField(field, screenIsMobile)"
        class="field-group-item"
        :class="{required:(field.options && field.options.rules && field.options.rules.split('|').includes('required'))}"
      >
        <!-- eslint-disable vue/no-v-html -->
        <td
          v-if="component.options.no_labels !== true"
          :style="{
            paddingTop: '5px',
            textAlign: 'right',
            verticalAlign: 'top',
            width: '1px',
            whiteSpace: 'nowrap',
            paddingRight: screenIsMobile ? '0' : '16px'
          }"
          class="field-group-label"
          v-html="tr(`${translationPrefix}.fields.${field.identifier}`)"
        />
        <td style="padding: 0">
          <FieldContainer
            ref="fieldContainer"
            v-model="resource[field.identifier]"
            :field="field"
            :module="module"
            :resource="resource"
            :root-field-tree="fieldTree"
            :translation-prefix="translationPrefix"
            :layout="layout"
            :mode="mode"
            :context="context"
            @event="fieldEvent"
            @tree="setFieldTree(field, $event)"
          />
        </td>
      </tr>
    </table>
    <div
      v-for="[field, index] in fields"
      v-else
      :key="index"
      :style="getStyleForField(field, screenIsMobile)"
      class="field-group-item-list"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="component.options.no_labels !== true"
        class="field-group-label"
        v-html="tr(`${translationPrefix}.fields.${field.identifier}`)"
      />
      <div
        style="overflow-x: auto; overflow-scrolling: touch"
        class="field-group-item"
      >
        <FieldContainer
          ref="fieldContainer"
          v-model="resource[field.identifier]"
          :field="field"
          :module="module"
          :resource="resource"
          :root-field-tree="fieldTree"
          :translation-prefix="translationPrefix"
          :layout="layout"
          :mode="mode"
          :context="context"
          @event="fieldEvent"
          @tree="setFieldTree(field, $event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldContainer from '@/components/FieldContainer'
import LayoutComponentFieldGroup from '@/components/layout-components/LayoutComponentFieldGroup'
import Fields from '@/components/fields'

export default {
  name: 'LayoutComponentFieldGroupInputContent',
  components: { FieldContainer },
  extends: LayoutComponentFieldGroup,
  mixins: [
    Fields,
  ],
}
</script>
