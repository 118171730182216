import FieldItem from '@/layout/field-item'
import { groupByDotPath, uniqid } from '@/utils'
import Layout from '@/layout/layout'
import { can } from '@/guard'
import Module from '@/module/module'
import { hri } from 'human-readable-ids'
import _ from 'lodash'

export default class Field {
  _items = []
  _layout = null
  _parent = null
  children = []
  options = {}

  validateStatus = null
  validateMessage = null

  constructor (properties = {}, parent = null) {
    // Set attributes
    // setting _layout & co to their defaults prevents "Vue: too much recursion" during FieldList rendering
    Object.assign(this, { ...properties, _layout: null, _parent: null, _items: [] })

    if (this.children.length === 0 && properties.fields) {
      this.children = properties.fields
    }

    this.options = {}
    // We have to make fresh objects so that different fields do not share the same options.
    Object.assign(this.options, properties.options || {})

    this.children = this.children.map(childSpec => new Field(childSpec, this))

    this._uid = uniqid()
    this._parent = parent
  }

  getUID () {
    return this._uid
  }

  getItems () {
    return this._items
  }

  getItemsSorted () {
    return this._items.concat().sort((a, b) => a.value.index - b.value.index)
  }

  clear () {
    this._items.splice(0, this._items.length)
  }

  syncItems (items) {
    let layoutChanged = false
    items.forEach((value, index) => {
      if (this._items[index]) {
        this._items[index].value = value
      } else {
        this._addItem(value, index)
        layoutChanged = true
      }
    })
    const toRemove = []
    this._items.forEach((item, index) => {
      if (index >= items.length) {
        toRemove.push(index)
        layoutChanged = true
      }
    })
    // Remove in reverse order so that indices are not messed up
    toRemove.sort((a, b) => b - a)
    toRemove.forEach(index => {
      this._items.splice(index, 1)
    })
    if (layoutChanged) {
      this.update()
    }
  }

  addItems (items) {
    items.forEach((item, index) => {
      this._addItem(item, index)
    })
    this.update()
  }

  addItem (value, index = null) {
    const item = this._addItem(value, index)
    this.update()
    return item
  }

  _addItem (value, index = null) {
    const childSpecs = this.children
    const childFields = childSpecs.map(childSpec => new Field(childSpec, this))
    const item = new FieldItem(value, childFields)
    if (index) {
      this._items[index] = item
    } else {
      this._items.push(item)
    }
    if (this.options.template) {
      item.layout = new Layout(this.options.template, item, false)
    }
    return item
  }

  removeItem (index) {
    this._items.splice(index, 1)
    this.update()
  }

  setComponent ($component) {
    this.$component = $component
    return this
  }

  setLayout (layout) {
    this._layout = layout
    return this
  }

  update () {
    if (this._layout) {
      this._layout.update()
    }
    if (this._parent) {
      this._parent.update()
    }
  }

  getChild (identifier) {
    const field = this.children.find(child => child.identifier === identifier)
    if (field) {
      return new Field(field, this)
    }
    return null
  }

  toJSON () {
    return { ...this, _parent: undefined }
  }

  can (action) {
    return can(action, this)
  }

  isRelation () {
    return ['select', 'multiselect', 'file', 'upload'].includes(this.type)
  }

  isList () {
    return this.type === 'list'
  }

  getExampleValue (withRelations = [], idsOnly = false) {
    switch (this.type) {
      case 'select':
      case 'file':
      case 'upload':
        // Take explicit from withRelations
        const referencing = Module.byIdentifier(this.options.references)
        return referencing.getExampleEntity(true, idsOnly, withRelations, idsOnly)
      case 'list':
        const items = []
        const fields = this.children || (this.options.fields ? this.options.fields.map(field => new Field(field)) : [])
        for (let i = 0; i < Math.ceil(Math.random() * 5); i++) {
          const item = Object.fromEntries(fields.map(child => [child.identifier, child.getExampleValue(withRelations ? groupByDotPath(withRelations)[child.identifier] : (child.options.with || [])), idsOnly]))
          items.push(item)
        }
        return items
      case 'choice':
        const values = this.options.values || []
        return values[Math.ceil(Math.random() * values.length)]
      case 'text':
        return hri.random()
      case 'number':
        return Math.ceil(Math.random() * 1000)
      default:
        return null
    }
  }

  get canBeEnabled () {
    return _.get(this.options, 'allow_enabling', false)
  }

  enable () {
    this.options.allow_enabling = false
    this.options.frontend_disabled = false
  }

  getOption (option, defaultValue) {
    return _.get(this.options, option, defaultValue)
  }
}
