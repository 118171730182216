import router from './router'
import store from './store'

export const defaultKeymap = [
  {
    identifier: 'SaveEntity',
    action: 'save',
    commands: [
      'Ctrl+S',
    ],
    scope: [
      {
        layout: 'module.edit',
      },
      {
        layout: 'module.create',
      },
    ],
  },
  {
    identifier: 'SaveAndContinueEntity',
    action: 'saveAndContinue',
    commands: [
      ['Ctrl', 'Shift', 'S'],
    ],
    scope: {
      layout: 'module.edit',
    },
  },
  {
    identifier: 'EditEntity',
    link: {
      append: true,
      href: 'edit',
    },
    scope: {
      layout: 'module.show',
    },
    commands: [
      ['Ctrl', 'E'],
    ],
  },
]

export default class Keymap {
  get keymap () {
    return this.#keymap
  }

  set keymap (value) {
    if (typeof value === 'string') {
      value = JSON.parse(value)
    }
    this.#keymap = this.#parseKeymap([...defaultKeymap, ...value])
  }

  #keymap = []
  layout = null

  constructor (...args) {
    Object.assign(this, args)

    document.addEventListener('keydown', $event => this.handleKeyDown($event))
  }

  #parseKeymap (keymap) {
    if (!keymap) return

    for (const keymapItem of keymap) {
      if (!Array.isArray(keymapItem.commands)) {
        keymapItem.commands = [keymapItem.commands]
      }
      keymapItem.commands = keymapItem.commands.map(command => {
        let keys = command
        if (!Array.isArray(command) && typeof command === 'string') {
          keys = command.trim().split('+')
        }
        keys = keys.map(key => key.toLowerCase())
        return keys.join('+')
      })
    }
    return keymap
  }

  handleKeyDown ($event) {
    if (!this.keymap) return
    if ($event.key === undefined) return
    if (['Control', 'Alt', 'Shift', 'OS'].indexOf($event.key) !== -1) return
    let pressedButtonsString = $event.key
    for (const cmdKey of ['shift', 'alt', 'ctrl']) {
      if ($event[cmdKey + 'Key']) {
        pressedButtonsString = cmdKey + '+' + pressedButtonsString
      }
    }
    pressedButtonsString = pressedButtonsString.toLowerCase()
    let activeCommands = this.getAvailableCommands()
      .filter(keymap => keymap.commands.indexOf(pressedButtonsString) !== -1)
    if (activeCommands.length > 0) {
      $event.preventDefault()

      this.executeCommands(activeCommands)
    }
  }

  getAvailableCommands () {
    const output = []
    for (const action of this.keymap) {
      if (!this.layout && !action.link) continue

      if (action.scope) {
        const scope = Array.isArray(action.scope) ? action.scope : [action.scope]
        let scopeActive = false
        scope.forEach(scopeItem => {
          // check if all of the scope filters pass
          if (
            (!scopeItem.layout || this.layout?._props.mode === scopeItem.layout) &&
            (!scopeItem.module || this.layout?._props.module.identifier === scopeItem.module) &&
            (!scopeItem.route || router.currentRoute.path === scopeItem.route)
          ) {
            scopeActive = true
          }
        })
        if (!scopeActive) continue
      }
      output.push(action)
    }
    return output
  }

  executeCommands (commands = []) {
    const layout = this.layout

    commands.forEach(command => {
      if (layout) {
        layout.anyEvent({
          _type: 'keydown',
          target: command,
          event: 'keyboard',
          setLoading: (loading = true) => layout.keyboardEventLoading(loading, command),
        })
      } else {
        // router link for internal links
        if (command.link && !command.link.external) {
          let link = '/' + store.state.currentLocale + '/' + (command.link.href ? command.link.href : command.link)
          if (command.link.append) {
            link = this.$route.path + '/' + command.link.href
          }
          if (command.link.target && command.link.target !== '_self') {
            let routeData = router.resolve(link)
            window.open(routeData.href, command.link.target)
          } else {
            router.push(link)
          }
        }
      }
    })
  }
}
