<template>
  <div>
    <iframe
      v-if="options.show_as_iframe === true"
      :src="value"
      v-bind="options.frame_options"
    />
    <a
      v-else
      :href="link"
      target="_blank"
    >{{ link }}</a>
  </div>
</template>

<script>
import FieldViewer from '@/components/fields/FieldViewer'
import _ from 'lodash'

export default {
  name: 'FieldViewerLink',
  extends: FieldViewer,
  computed: {
    link () {
      if (!_.isString(this.value)) {
        return ''
      }
      let link = this.value.toLowerCase().trim()
      if (!link.includes('://')) {
        link = 'https://' + link
      }
      return link
    },
  },
}
</script>

<style scoped>

</style>
