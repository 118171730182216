import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import MediaImage from '@/components/fields/wysiwyg/extensions/media/MediaImage'

const Media = Node.create({
  name: 'media',

  draggable: true,

  addOptions: {
    module: null,
    directory: null,
  },

  inline () {
    return true
  },

  group () {
    return 'inline'
  },

  addNodeView () {
    return VueNodeViewRenderer(MediaImage)
  },

  addAttributes () {
    return {
      id: {
        default: null,
      },
      alt: {
        default: null,
      },
      link: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      preview: {
        default: null,
      },
    }
  },

  parseHTML () {
    return [
      {
        tag: 'brezel-media',
      },
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return ['brezel-media', HTMLAttributes]
  },

  addCommands () {
    return {
      setMedia: options => ({ commands }) => {
        this.options.open = true
        return commands.insertContent({
          type: 'media',
        })
      },
    }
  },
})

export default Media
