<template>
  <a-row
    :key="row.position"
    :brezel-row-id="row.id"
    :gutter="16"
    type="flex"
    :style="(row.options || {}).style"
  >
    <layout-col
      v-for="col in cols"
      :key="col.frontendIndex"
      ref="col"
      :col="col"
      :field-tree="fieldTree"
      :module="module"
      :prop-mode="propMode"
      :resource="resource"
      :translation-prefix="translationPrefix"
      :context="context"
      class="layout-col"
      :layout="layout"
      :layout-path="newLayoutPath"
      @event="anyEvent"
    />
  </a-row>
</template>

<script>
import LayoutCol from '@/components/LayoutCol'
import LayoutDef from '@/layout/layout'
import { shouldShow } from '@/utils'
import LayoutElement from '@/components/LayoutElement'

export default {
  name: 'LayoutRow',
  components: {
    LayoutCol,
  },
  extends: LayoutElement,
  props: {
    fieldTree: {
      type: Object,
      default: undefined,
    },
    propMode: {
      type: String,
      default: undefined,
    },
    resource: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      default: undefined,
    },
    row: {
      type: Object,
      required: true,
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      required: false,
      default: undefined,
    },
    layout: {
      type: LayoutDef,
      default: () => new LayoutDef(),
    },
  },
  computed: {
    layoutElementIdentifier () {
      return this.row.identifier || this.row.frontendIndex
    },

    cols () {
      return this.row.cols
        .map((col, index) => {
          col.frontendIndex = index
          return col
        })
        .filter(col => shouldShow(col, this.propMode))
    },
  },
  methods: {
    anyEvent ($event) {
      this.$emit('event', $event)
    },
  },
}
</script>

<style>
.layout-row {
  margin-bottom: 16px;
}

.layout-row.--last-row {
  margin-bottom: 0;
}
</style>
