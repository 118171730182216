<template>
  <div>Widget</div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'

export default {
  name: 'Widget',
  extends: LayoutComponent,
  props: {
    resource: {
      type: Object,
      required: false,
      default: undefined,
    },
    mode: {
      type: String,
      required: false,
      default: undefined,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    identifier () {
      return this.config.identifier
    },
  },
}
</script>

<style scoped>

</style>
