<template>
  <div
    :brezel-component-id="component.id"
    style="padding: 0 32px;"
  >
    <FileView
      :resource="resource"
      :src="src"
      :type="type"
    />
  </div>
</template>

<script>
import LayoutComponent from '@/components/layout-components/LayoutComponent'
import FileView from '@/components/FileView'

export default {
  name: 'LayoutComponentFileView',
  components: {
    FileView,
  },
  extends: LayoutComponent,
  props: {
    resource: {
      type: Object,
      default: undefined,
    },
    src: {
      type: String,
      default: undefined,
    },
    type: {
      type: Object,
      default: undefined,
    },
  },
}
</script>
