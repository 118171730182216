import Vue from 'vue'
import Vuex from 'vuex'
import Module from '@/module/module'
import BrezelEvent from '@/api/event'
import { isAuthenticated } from '@/utils'
import { loadPublicLanguagesAsync } from '@/i18n'

Vue.use(Vuex)

const storeVersion = 3.3

const findDeep = (data, key, id) => {
  const moduleInData = data.find(module => module[key] === id)
  if (moduleInData) {
    return moduleInData
  }
  let moduleInChildren
  data.forEach(module => {
    if (module.children) {
      moduleInChildren = findDeep(module.children, id)
    }
  })
  if (moduleInChildren) {
    return moduleInChildren
  }
  return null
}

export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    breadcrumbData: {},
    brezelInfo: {},
    client: {},
    color: '',
    currentLocale: '',
    currentSystem: '',
    description: '',
    defaults: {
      timeFormat: {
        store: 'HH:mm:ss',
        view: 'HH:mm',
      },
      dateFormat: {
        store: 'YYYY-MM-DD',
        view: 'DD.MM.YYYY',
      },
      monthFormat: {
        store: 'YYYY-MM',
        view: 'MM.YYYY',
      },
      dateTimeFormat: {
        store: 'YYYY-MM-DD HH:mm:ss',
        view: 'DD.MM.YYYY HH:mm',
      },
    },
    menuCollapsed: false,
    module: {
      identifier: '',
      name: '',
    },
    isMultiClientSystem: false,
    resource: {
      identifier: '',
      brezel_name: '',
    },
    systemName: '',
    user: {
      email: '',
      id: '',
      name: '',
    },
    userInfo: {},
    licenseInfo: {},
    version: storeVersion,
    modules: [],
    layouts: [],
    events: {},
    defaultModule: null,
    sidebar: false,
    resourceTables: {},
    autosave: {
      modals: {},
    },
  },
  getters: {
    getModuleById: state => (id) => {
      return findDeep(state.modules, 'id', id)
    },
    getModuleByIdentifier: state => (identifier) => {
      return findDeep(state.modules, 'identifier', identifier)
    },
    getModuleIdentifierById: state => (id) => {
      const module = findDeep(state.modules, 'id', id)
      if (typeof module === 'object' && module !== null && module.hasOwnProperty('identifier')) {
        return module.identifier
      }
      return ''
    },
    isMultiClientAndCurrentIsDefaultClient: state => {
      return state.isMultiClientSystem && (state.client.id === 0 || state.client.global === true)
    },
    system: state => state.brezelInfo.system,
    user: state => state.user,
    apiUrl: state => state.apiUrl,
    getEvent: state => (identifier) => {
      const event = state.events[identifier]
      return event ? new BrezelEvent(event) : null
    },
    stringify: state => () => {
      const replacer = (key, value) => {
        if (key === 'modules') {
          return undefined
        }
        return value
      }
      return JSON.stringify(state, replacer)
    },
    startPage: state => {
      if (state.userInfo && state.userInfo.start) {
        // Explicit per-user start page
        return state.userInfo.start
      }
      if (state.defaultModule) {
        // Default start page is the default module
        return `/${state.currentLocale}/${state.defaultModule}`
      }
      return null
    },
    autosavedModal: state => key => {
      return state.autosave.modals[key]
    },
  },
  mutations: {
    setApiUrl (state, url) {
      state.apiUrl = url
    },
    initialiseStore (state, { brezel }) {
      if (localStorage.getItem('store')) {
        let store = JSON.parse(localStorage.getItem('store'))
        if (store.version !== storeVersion) {
          state.version = storeVersion
          if (isAuthenticated()) {
            brezel.logout().then(() => {
              brezel.onBootstrap(() => {
                loadPublicLanguagesAsync().then(() => {
                  brezel.app.$notification.info(window.app.$t('toasts.store_logged_out'))
                })
              })
            })
          }
          return
        }
        this.replaceState(Object.assign(state, store))
      }
      state.modules = state.modules.map(module => new Module(module))
    },
    setIsMultiClientSystem (state, isMultiClientSystem) {
      state.isMultiClientSystem = isMultiClientSystem
    },
    setBreadcrumbData (state, breadcrumbData) {
      state.breadcrumbData = breadcrumbData
    },
    setClient (state, client) {
      state.client = client
    },
    setColor (state, color) {
      state.color = color
    },
    setCurrentLocale (state, locale) {
      state.currentLocale = locale
    },
    setCurrentSystem (state, system) {
      state.currentSystem = system
    },
    setDescription (state, description) {
      state.description = description
    },
    setModule (state, module) {
      state.module = module
    },
    putModule (state, module) {
      state.modules = state.modules.filter(mod => mod.identifier !== module.identifier)
      state.modules.push(module)
    },
    setModules (state, modules) {
      state.modules = modules
    },
    setLayouts (state, layouts) {
      state.layouts = layouts
    },
    setSystemName (state, systemName) {
      state.systemName = systemName
    },
    setUser (state, user) {
      state.user = user
    },
    toggleMenu (state) {
      state.menuCollapsed = !state.menuCollapsed
    },
    setBrezelInfo (state, info) {
      state.brezelInfo = info
    },
    setUserInfo (state, info) {
      state.userInfo = info
    },
    setDefaultModule (state, defaultModule) {
      state.defaultModule = defaultModule
    },
    setSidebar (state, sidebar) {
      state.sidebar = sidebar
    },
    setLicenseInfo (state, info) {
      state.licenseInfo = info
      if (info.license !== null && info.plan === null) {
        state.licenseInfo.licenseWarning = true
      } else {
        state.licenseInfo.licenseWarning = false
      }
    },
    putEvent (state, event) {
      state.events[event.identifier] = event
    },
    autosaveModal (state, { key, modal }) {
      state.autosave.modals[key] = state.autosave.modals[key] || []
      state.autosave.modals[key].push({
        time: new Date().toUTCString(),
        data: modal,
      })
    },
    clearAutosavedModal (state, key) {
      delete state.autosave.modals[key]
    },
  },
  actions: {
    persistStore (store) {
      localStorage.setItem('store', store.getters.stringify())
    },
  },
})
