var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.component.options.vertical_labels !== true)?_c('table',{staticClass:"field-group-item-list",staticStyle:{"width":"100%"}},_vm._l((_vm.fields),function([field, index]){return _c('tr',{key:index,staticClass:"field-group-item",style:(_vm.getStyleForField(field, _vm.screenIsMobile))},[(_vm.component.options.no_labels !== true)?_c('td',{staticClass:"td-left-xs field-group-label",style:({
          background: (index % 2 === 1 ? '#fafafa' : 'transparent'),
          paddingRight: '16px',
          textAlign: 'right',
          verticalAlign: 'top',
          whiteSpace: 'nowrap',
          width: '1px',
        }),domProps:{"innerHTML":_vm._s(_vm.tr(`${_vm.translationPrefix}.fields.${field.identifier}`))}}):_vm._e(),_c('td',{staticClass:"td-right-xs",style:({
          background: (!_vm.screenIsMobile && index % 2 === 1 ? '#fafafa' : 'transparent')
        })},[_c(_vm.getFieldViewerComponent(field.type),{tag:"component",attrs:{"field":field,"module":_vm.module,"translation-prefix":_vm.translationPrefix,"context":_vm.context,"layout-mode":_vm.mode,"test":field.options},on:{"event":_vm.fieldEvent},model:{value:(_vm.resource[field.identifier]),callback:function ($$v) {_vm.$set(_vm.resource, field.identifier, $$v)},expression:"resource[field.identifier]"}})],1)])}),0):_vm._l((_vm.fields),function([field, index]){return _c('div',{key:index,staticClass:"field-group-item-list",style:(_vm.getStyleForField(field, _vm.screenIsMobile))},[(_vm.component.options.no_labels !== true)?_c('div',[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.tr(`${_vm.translationPrefix}.fields.${field.identifier}`))}})]):_vm._e(),_c('div',{staticClass:"field-group-item"},[_c(_vm.getFieldViewerComponent(field.type),{tag:"component",attrs:{"field":field,"module":_vm.module,"translation-prefix":_vm.translationPrefix,"context":_vm.context,"layout-mode":_vm.mode},on:{"event":_vm.fieldEvent},model:{value:(_vm.resource[field.identifier]),callback:function ($$v) {_vm.$set(_vm.resource, field.identifier, $$v)},expression:"resource[field.identifier]"}})],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }